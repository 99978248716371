import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateFormModal = _resolveComponent("CreateFormModal")!
  const _component_a_button_group = _resolveComponent("a-button-group")!
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_UpdateFormModal = _resolveComponent("UpdateFormModal")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_space, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_button_group, null, {
          default: _withCtx(() => [
            (_ctx.createSpec)
              ? (_openBlock(), _createBlock(_component_CreateFormModal, {
                  key: 0,
                  title: _ctx.createModalTitle || `${_ctx.title} 생성`,
                  label: `${_ctx.createModalTitle || _ctx.title} 등록`,
                  uri: _ctx.uri,
                  "input-spec": _ctx.createSpec,
                  rules: _ctx.createRules,
                  onAfterSubmit: _ctx.fetchData
                }, null, 8, ["title", "label", "uri", "input-spec", "rules", "onAfterSubmit"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "toolbar"),
        (_ctx.exportable)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              href: _ctx.csvExportUrl
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_download_outlined)
              ]),
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createTextVNode(" CSV 다운로드 "))
              ]),
              _: 1
            }, 8, ["href"]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createVNode(_component_a_table, {
      columns: _ctx.columns,
      "data-source": _ctx.tableData,
      loading: _ctx.loadingDataSource,
      scroll: _ctx.scroll,
      size: "small",
      pagination: false,
      style: {"padding":"20px 0 20px 0"},
      bordered: ""
    }, {
      title: _withCtx(() => [
        _createElementVNode("b", null, _toDisplayString(_ctx.title), 1)
      ]),
      tags: _withCtx((tags) => [
        _createElementVNode("span", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags, (tag) => {
            return (_openBlock(), _createBlock(_component_a_tag, {
              key: tag,
              color: "blue"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ])
      ]),
      bodyCell: _withCtx(({ column, record }) => [
        (column.type === 'edit')
          ? (_openBlock(), _createBlock(_component_a_space, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_UpdateFormModal, {
                  title: _ctx.updateModalTitle || `${_ctx.title} 수정`,
                  "resource-id": record[_ctx.primaryKey],
                  uri: _ctx.uri,
                  "input-spec": _ctx.updateSpec!!,
                  rules: _ctx.updateRules,
                  params: _ctx.params,
                  onAfterSubmit: _ctx.fetchData
                }, {
                  header: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, "updateModalHeader", _normalizeProps(_guardReactiveProps(data)))
                  ]),
                  footer: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, "updateModalFooter", _normalizeProps(_guardReactiveProps(data)))
                  ]),
                  _: 2
                }, 1032, ["title", "resource-id", "uri", "input-spec", "rules", "params", "onAfterSubmit"])
              ]),
              _: 2
            }, 1024))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["columns", "data-source", "loading", "scroll"])
  ], 64))
}