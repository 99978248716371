import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateFormModal = _resolveComponent("CreateFormModal")!
  const _component_bulk_action_modal = _resolveComponent("bulk-action-modal")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "제품",
    uri: "/api/products",
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    scroll: { x: 2800, y: 650 },
    columns: _ctx.columns,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    exportable: "",
    deletable: "",
    "row-selection": _ctx.rowSelection,
    rowDeletable: _ctx.rowDeletable
  }, {
    actions: _withCtx(({ uri }) => [
      _createVNode(_component_CreateFormModal, {
        title: "병합",
        label: "병합",
        uri: `${uri}/merge`,
        "input-spec": _ctx.mergingSpec,
        rules: _ctx.mergingRules
      }, null, 8, ["uri", "input-spec", "rules"]),
      _createVNode(_component_bulk_action_modal, {
        "resource-ids": _ctx.rowSelection.selectedRowKeys,
        uri: `${uri}/consumer-guide`,
        "input-spec": _ctx.consumerGuideSpec,
        title: "연구소 팁 입력",
        label: "연구소 팁 입력"
      }, null, 8, ["resource-ids", "uri", "input-spec"])
    ]),
    _: 1
  }, 8, ["filter-spec", "create-spec", "update-spec", "columns", "create-rules", "update-rules", "row-selection", "rowDeletable"]))
}