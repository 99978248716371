<template>
    <div style="background-color: #f9fefe; padding: 13px; width: auto">
        <a-row>
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <nutrient-input
                    v-model:value="form.nutrientName"
                    name="nutrientName"
                    @select="(args) => setNutrient(args, form)"
                    width="200px"
                    :disabled="disabled"
                />
                <a-form-item name="amount">
                    <korean-number-input
                        class="amount-input"
                        v-model:value="form.amount"
                        :addon-after="form.unit"
                        :disabled="disabled"
                    />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="add" :disabled="disabled">
                        영양소 추가
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row>
            <draggable :list="valueRef" item-key="nutrientName">
                <template #item="{ element, index }">
                    <a-form
                        :model="element"
                        :rules="rules"
                        layout="inline"
                        style="border-color: #6c2bf2"
                    >
                        <a-form-item>
                            <swap-outlined :rotate="90" />
                        </a-form-item>
                        <nutrient-input
                            v-model:value="element.nutrientName"
                            :name="`nutrientName${index}`"
                            @select="(args) => setNutrient(args, element)"
                            width="280px"
                            mode="default"
                            :disabled="element.isCalculated || disabled"
                        />
                        <a-form-item :name="`amount${index}`">
                            <korean-number-input
                                class="amount-input"
                                v-model:value="element.amount"
                                :addon-after="element.unit"
                                :disabled="element.isCalculated || disabled"
                            />
                        </a-form-item>
                        <a-form-item>
                            <a-button
                                type="danger"
                                @click="() => pop(index)"
                                :disabled="element.isCalculated || disabled"
                            >
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<style lang="scss">
.amount-input {
    .ant-input-number-input {
        width: 100px;
    }

    margin-right: 150px;
}
</style>

<script setup lang="ts">
import { ref, toRef, watch, defineProps } from 'vue'
import NutrientInput from '@/components/input/NutrientInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'

type Amounts = {
    id?: number
    nutrientName?: string
    amount: number
    isCalculated: boolean
    isImportant: boolean
    unit?: string
}

const props = defineProps({
    rules: {
        type: Object,
        default: () => ({
            nutrientName: [required],
            amount: [required, aboveOrEqualToZero],
            unit: [required],
            isCalculated: [required],
            isImportant: [required],
        }),
    },
    productId: {
        type: Number,
    },
    value: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const form = ref<Amounts>({
    amount: 0,
    isCalculated: false,
    isImportant: false,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: Amounts) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (form.value.unit == undefined) {
            alert('영양소를 셀렉트 박스에서 클릭 해서 선택해주세요.')
            return
        }
        if (props.value != null) {
            push(form.value)
            form.value = {
                amount: 0,
                isCalculated: false,
                isImportant: false,
            }
        }
    })
}

const setNutrient = (
    {
        name,
        unit,
    }: {
        name: string
        unit: string
    },
    form: Amounts
) => {
    form.nutrientName = name
    form.unit = unit
}

watch(
    () => valueRef.value,
    (value: Array<any>) => {
        const fat = value.find((v) => v.nutrientName == '지방')
        const saturatedFat = value.find((v) => v.nutrientName == '포화지방')
        const unsaturatedFat = value.find(
            (v) => v.nutrientName == '불포화지방산'
        )
        const transFat = value.find((v) => v.nutrientName == '트랜스지방산')
        const unsaturatedFatAmount =
            (fat?.amount || 0) -
            ((saturatedFat?.amount || 0) + (transFat?.amount || 0))

        if (
            fat != undefined &&
            (saturatedFat != undefined || transFat != undefined) &&
            unsaturatedFat == undefined
        ) {
            valueRef.value.push({
                nutrientName: '불포화지방산',
                amount: unsaturatedFatAmount,
                unit: 'g',
            })
        }

        if (
            unsaturatedFat != undefined &&
            (saturatedFat != undefined || transFat != undefined) &&
            unsaturatedFatAmount != unsaturatedFat.amount
        ) {
            unsaturatedFat.amount = unsaturatedFatAmount
        }
    },
    { deep: true }
)
</script>
