<template>
    <div>
        <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :multiple="true"
            :action="uploadUrl"
            :withCredentials="true"
            @change="handleChange"
        >
            <img v-if="imageUrl" :src="imageUrl" alt="file" />
            <div v-else>
                <loading-outlined v-if="loading" />
                <plus-outlined v-else />
                <div class="ant-upload-text">Upload</div>
            </div>
        </a-upload>
        <div>
            <a-alert
                message="파일명은 영문으로 바꿔서 올려주세요"
                type="error"
            />
        </div>
        <div>
            <a-card title="업로드된 파일 목록" style="width: 100%">
                <p v-for="f in fileList" :key="f.uid">
                    <span v-if="f.response != undefined">
                        {{ f.response.result }}
                        ({{ parseInt(f.size / 1024) }}KB)
                    </span>
                </p>
            </a-card>
        </div>
        <a-input v-model:value="path" addon-before="경로" />
    </div>
</template>

<script lang="ts">
import { baseUrl } from '@/fetchTemplate'
import { defineComponent, Ref, ref } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'FileManager',
    mixins: [],
    components: {
        LoadingOutlined,
        PlusOutlined,
    },

    setup() {
        const loading = ref(false)
        const imageUrl = ref('')
        const path: Ref<string> = ref('')
        const fileList = ref([])

        return {
            loading,
            imageUrl,
            path,
            fileList,
        }
    },

    computed: {
        uploadUrl() {
            const includeRoot = this.path.startsWith('/')
            const path =
                includeRoot || this.path == '' ? this.path : `/${this.path}`
            return `${baseUrl}/api/file?path=${path}`
        },
    },

    methods: {
        formatUrl(): string {
            const includeRoot = this.path.startsWith('/')
            const path =
                includeRoot || this.path == '' ? this.path : `/${this.path}`
            return `${baseUrl}/api/file?path=${path}`
        },

        handleChange(info: any) {
            if (info.file.status === 'uploading') {
                this.loading = true
                return
            }
            if (info.file.status === 'done') {
                const allDone = !info.fileList.some(
                    (f: any) => f.status !== 'done'
                )
                if (allDone) {
                    this.loading = false
                    this.fileList = info.fileList
                }
            }
        },
    },
})
</script>
