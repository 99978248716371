import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "필라이즈 프레스",
    uri: "/api/press",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns,
    "load-on-mount": ""
  }, {
    detail: _withCtx(({ record }) => [
      _createVNode(_component_a_descriptions, {
        title: "프레스 상세",
        bordered: true,
        layout: "vertical",
        column: 1,
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "제목" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.title), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "발행자" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.publisher), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "기사 링크" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.url), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "발행일자" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.publishedAt), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "등록일시" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.creationTime), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["create-spec", "update-spec", "filter-spec", "create-rules", "update-rules", "columns"]))
}