import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { useEnumTypeStore } from '@/store/enumType'
import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'TemplateInput',
  props: {
    value: {
        type: String,
    },
    typeName: {
        type: String,
        default: 'default-format-args',
    },
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const change = (value: any) => {
    emits('update:value', value)
}

const options = ref<Array<any>>([])

const fetchOptions = async () => {
    const enumTypeStore = useEnumTypeStore()
    options.value = enumTypeStore.getEnumType(props.typeName).map((it: any) => {
        return { value: it.value, label: `{${it.label}}` }
    })
}

onMounted(async () => {
    fetchOptions()
})

return (_ctx: any,_cache: any) => {
  const _component_a_mentions_option = _resolveComponent("a-mentions-option")!
  const _component_a_mentions = _resolveComponent("a-mentions")!

  return (_openBlock(), _createBlock(_component_a_mentions, {
    class: "template__input",
    value: __props.value,
    onChange: change,
    prefix: "$",
    style: {"height":"auto"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (opt) => {
        return (_openBlock(), _createBlock(_component_a_mentions_option, {
          key: opt.value,
          value: opt.label
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(opt.label.replace('{', '').replace('}', '')), 1)
          ]),
          _: 2
        }, 1032, ["value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})