import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["hidden"]
const _hoisted_2 = ["hidden"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input_number = _resolveComponent("a-input-number")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_a_input_number, {
      class: "korean-number-input",
      value: _ctx.value,
      onChange: _ctx.change,
      onPressEnter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pressEnter'))),
      addonAfter: _ctx.addonAfter,
      placeholder: _ctx.placeholder,
      min: 0,
      style: _normalizeStyle({
                color: (_ctx.value >= 10000 && 'red') || 'black',
                minWidth: _ctx.width,
                width: _ctx.width,
            }),
      disabled: _ctx.disabled
    }, _createSlots({ _: 2 }, [
      (_ctx.hasAddonAfterSlot)
        ? {
            name: "addonAfter",
            fn: _withCtx(() => [
              _renderSlot(_ctx.$slots, "addonAfter")
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["value", "onChange", "addonAfter", "placeholder", "style", "disabled"]),
    _createElementVNode("p", {
      hidden: _ctx.value < 10000,
      style: _normalizeStyle({
                color: (_ctx.value >= 10000 && 'red') || 'darkgray',
                width: '90px',
                whiteSpace: 'nowrap',
                overflow: 'none',
            })
    }, _toDisplayString(_ctx.numToKorean(_ctx.value)), 13, _hoisted_1),
    _createElementVNode("p", {
      hidden: _ctx.value >= 10000
    }, null, 8, _hoisted_2)
  ]))
}