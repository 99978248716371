<template>
    <a-spin tip="Loading..." :spinning="loading">
        <div class="admin-login">
            <div class="login-container">
                <h1 class="company-name">P A</h1>
                <form class="login-form">
                    <a-button
                        class="login-button"
                        size="large"
                        @click.prevent="loginWithCognito"
                        style="background-color: #6c2bf2; color: whitesmoke"
                    >
                        <span>Pillyze 로그인</span>
                        <template #icon>
                            <img
                                :width="25"
                                src="/img/icons/apple-icon-72x72.png"
                                style="float: left"
                            />
                        </template>
                    </a-button>
                    <a-button
                        v-if="loginType"
                        class="logout-button"
                        type="primary"
                        @click="logout"
                        danger
                    >
                        로그아웃
                    </a-button>
                </form>
            </div>
        </div>
    </a-spin>
</template>

<style scoped>
#login-kakao-button {
    display: block;
}

@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.admin-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;

    .login-container {
        padding: 20px;
        background: white;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        text-align: center;

        .company-name {
            font-size: 4rem;
            font-weight: 900;
            background: linear-gradient(
                90deg,
                #6b2ef2,
                #a782e2,
                violet,
                blue,
                orange,
                red,
                yellow
            );
            background-size: 200% 200%;
            background-clip: text;
            color: transparent;
            animation: gradient-animation 5s ease infinite;
        }

        .login-form {
            .login-button,
            .logout-button {
                width: 183px;
                height: 45px;
                padding: 10px 20px;
                font-weight: 600;
                margin: 5px 0;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                display: block;
            }

            .logout-button {
                &:hover {
                    background-color: #45a049;
                }
            }
        }
    }
}
</style>

<script lang="ts">
//@ts-nocheck
import * as Kakao from '@/assets/kakao.js'
import { adminApi } from '@/fetchTemplate'
import { stringify } from 'qs'
import { defineComponent, ref } from 'vue'
import { useUserStore } from '@/store/user'
import { useEnumTypeStore } from '@/store/enumType'
import { useStandardUnitStore } from '@/store/standardUnit'
import { useCompanionStore } from '@/store/companion'
import { useLayoutStore } from '@/store/advertisement'
import { useCrmSmsTemplateStore, useCrmBizmTemplateStore } from '@/store/crm'

Kakao.init('d90b46c8da739fd3e868046433b111b7')

export default defineComponent({
    props: {
        code: {
            type: String,
        },
        provider: {
            type: String,
        },
    },
    computed: {
        kakaoToken() {
            return this.userStore.kakaoToken
        },
        loginType() {
            return this.userStore.loginType
        },
    },
    setup() {
        const userStore = useUserStore()
        const enumTypeStore = useEnumTypeStore()
        const standardUnitStore = useStandardUnitStore()
        const companionStore = useCompanionStore()
        const layoutStore = useLayoutStore()
        const crmSmsTemplateStore = useCrmSmsTemplateStore()
        const crmBizmTemplateStore = useCrmBizmTemplateStore()
        const loading = ref(false)
        const toggleLoading = () => {
            loading.value = !loading.value
        }
        return {
            userStore,
            enumTypeStore,
            standardUnitStore,
            companionStore,
            layoutStore,
            crmSmsTemplateStore,
            crmBizmTemplateStore,
            toggleLoading,
            loading,
        }
    },
    async mounted() {
        if (
            this.code != null &&
            this.kakaoToken == null &&
            this.provider === 'kakao'
        ) {
            try {
                this.toggleLoading()
                const response = await adminApi(`/api/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        socialLoginProvider: 'KAKAO',
                        code: this.code,
                    }),
                })
                Kakao.Auth.setAccessToken(response.kakaoToken)
                this.$store.dispatch('setToken', response.kakaoToken)
                this.standardUnitStore.fetchStandardUnit()
                this.companionStore.fetchCompanion()
                this.enumTypeStore.dispatchEnums([
                    'nutrient-group-name',
                    'trigger-box-content-format-args',
                    'default-format-args',
                ])
                this.layoutStore.fetchAdLayout()
                this.crmSmsTemplateStore.fetchCrmSmsTemplates()
                this.crmBizmTemplateStore.fetchCrmBizmTemplates()
                this.$router.push('/')
            } catch (e) {
                this.error = true
            } finally {
                this.toggleLoading()
            }
        } else if (this.kakaoToken != null && this.provider === 'kakao') {
            this.$router.push('/')
        } else if (this.code != null && this.provider === 'cognito') {
            this.toggleLoading()
            try {
                const queryParam = stringify({
                    code: this.code,
                    redirectUri: process.env.VUE_APP_COGNITO_REDIRECT_URI,
                })
                await adminApi(`/api/auth/cognito/token?${queryParam}`)

                await this.userStore.fetchUser()
                this.standardUnitStore.fetchStandardUnit()
                this.companionStore.fetchCompanion()
                this.enumTypeStore.dispatchEnums([
                    'nutrient-group-name',
                    'trigger-box-content-format-args',
                    'default-format-args',
                ])
                this.layoutStore.fetchAdLayout()
                this.$router.push('/')
            } catch (e) {
                this.error = true
            } finally {
                this.toggleLoading()
            }
        }
    },
    methods: {
        async loginWithKakao() {
            await Kakao.Auth.authorize({
                redirectUri: process.env.VUE_APP_KAKAO_REDIRECT_URI,
                prompt: 'login',
            })
        },
        loginWithCognito() {
            const clientId = '7ivp8v22jlrq4bgbeg7nnrck18'
            const redirectUri = encodeURIComponent(
                process.env.VUE_APP_COGNITO_REDIRECT_URI
            )
            const responseType = 'code' // 또는 'token', 인증 플로우에 따라 결정
            const scope = encodeURIComponent('email openid phone profile')
            const cognitoDomain =
                'pillyze.auth.ap-northeast-2.amazoncognito.com'
            const loginUrl = `https://${cognitoDomain}/login?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`

            window.location.href = loginUrl
        },

        async logout() {
            try {
                if (this.loginType === 'KAKAO') {
                    await Kakao.Auth.logout()
                }
                await adminApi(`/api/auth/logout`, {
                    method: 'POST',
                })
            } catch (e) {
                console.log(e)
            } finally {
                this.userStore.$reset()
            }
        },
    },
})
</script>
