import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!

  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { style: { borderBottom: '1px solid #E9E9E9' } }, "입력 preset", -1)),
    (_ctx.radio)
      ? (_openBlock(), _createBlock(_component_a_radio_group, {
          key: 0,
          value: _ctx.checked,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event)),
          options: _ctx.options,
          onChange: _ctx.check
        }, null, 8, ["value", "options", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_checkbox_group, {
          key: 1,
          value: _ctx.checked,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checked) = $event)),
          options: _ctx.options,
          onChange: _ctx.check
        }, null, 8, ["value", "options", "onChange"]))
  ]))
}