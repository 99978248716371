<script setup lang="tsx">
import { computed, defineProps, onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import WeekSelectInput from '@/components/input/WeekSelectInput.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { getDateList } from '@/util/date-util'
import NestedResourceTable from '@/components/NestedResourceTable.vue'
import { formatLocalDate, formatLocalDateTime } from '@/util/formmater'
import { differenceInDays, format, isBefore } from 'date-fns'
import { ko } from 'date-fns/locale'
import { Button, message } from 'ant-design-vue'
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    LinkOutlined,
} from '@ant-design/icons-vue'
import globalFormState from '@/components/globalFormState'
import { required } from '@/util/input-validation'
import { stringify } from 'qs'
import { debounce } from 'lodash'
import Draggable from 'vuedraggable'
import Tiptap from '@/components/input/Tiptap.vue'
import { useFormeWeekStore } from '@/store/formeWeek'
import { useEnumTypeStore } from '@/store/enumType'

const props = defineProps({
    userId: {
        type: String,
        required: true,
    },
})

const loading = ref(false)
const findFactorFail = ref(false)

const table = ref<any>(null)

const hideWeeklyMission = async (id: string) => {
    loading.value = true
    await adminApi(`/api/forme/diet/mission/hide/${props.userId}/${id}`, {
        method: 'POST',
    })
    await fetchTable()
    loading.value = false
}

//const { selectedWeek, getWeekList } = useFormeWeek
const { state, getWeekList } = useFormeWeekStore()
const { selectedWeek } = state

const columns = computed(() => {
    const dateTileList =
        selectedWeek != null && selectedWeek != null
            ? getDateList(selectedWeek?.startDate, selectedWeek?.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      customRender: (column: any) => {
                          if (column.value) {
                              return '✅'
                          } else {
                              return ''
                          }
                      },
                  })
              )
            : []
    return [
        {
            title: 'ID',
            dataIndex: 'userMissionId',
            width: 80,
            fixed: 'left',
        },
        {
            title: '타입',
            dataIndex: 'type',
        },
        {
            title: '종류',
            dataIndex: 'category',
        },
        {
            title: '미션',
            dataIndex: 'name',
            customRender: ({ value, record }) => {
                if (value.includes('{변수}')) {
                    return value.replace('{변수}', record.value)
                }
                return value
            },
            customCell: (record: Record<any, any>) => {
                if (record.feedbackScore === 1) {
                    return {
                        style: {
                            backgroundColor: '#FFF9DE',
                        },
                    }
                } else if (record.feedbackScore === 5) {
                    return {
                        style: {
                            backgroundColor: '#FFEFF2',
                        },
                    }
                }
            },
        },
        {
            title: '가중치 점수',
            dataIndex: 'weight',
        },
        ...dateTileList,
        {
            title: '달성률',
            dataIndex: 'completeRatio',
            align: 'center',
            customRender: ({ value, record }: any) => {
                return `${value}%`
            },
            customCell: (record: any) => {
                const ratio = record.completeRatio
                if (ratio >= 85) {
                    return {
                        style: {
                            backgroundColor: '#efffff',
                        },
                    }
                } else if (ratio >= 70 && ratio < 85) {
                    return {
                        style: {
                            backgroundColor: '#effff4',
                        },
                    }
                } else if (ratio >= 55 && ratio < 70) {
                    return {
                        style: {
                            backgroundColor: '#fff9de',
                        },
                    }
                }
                return {
                    style: {
                        backgroundColor: '#ffeff2',
                    },
                }
            },
        },
        {
            title: '수정 날짜',
            dataIndex: 'updatedAt',
            customRender: formatLocalDate,
        },
        {
            title: '상태',
            dataIndex: 'hidden',
            customRender: ({
                value,
                record,
            }: {
                value: any
                record: Record<any, any>
            }) => {
                return value ? (
                    <Button
                        style="background-color: #9ba1aa; color: white"
                        loading={loading.value}
                        size="middle"
                        onClick={async () =>
                            await hideWeeklyMission(record.userMissionId)
                        }
                    >
                        {loading.value == false && <EyeInvisibleOutlined />}
                    </Button>
                ) : (
                    <Button loading={loading.value} size="middle">
                        <EyeOutlined
                            onClick={async () =>
                                await hideWeeklyMission(
                                    record.userMissionId
                                ).then()
                            }
                        />
                    </Button>
                )
            },
            align: 'center',
        },
        {
            title: '수정',
            type: 'edit',
            align: 'center',
            fixed: 'right',
        },
    ]
})

const userDetail = ref<any>({})
const setUserDetail = async (userId: string) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/user/${userId}?weekNumber=${
            selectedWeek.weekNumber || 1
        }`
    )
    userDetail.value = response.result || {}
}

onMounted(async () => {
    await getWeekList(props.userId)
    await setUserDetail(props.userId)
    getWeeklyMessage(props.userId, selectedWeek?.weekNumber || 1)
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['forme-category'])
})

const { setFormValues, form, fetchFormSpec } = globalFormState

const rules = {
    priority: [required],
    category: [required],
    type: [required],
    startDate: [required],
    endDate: [required],
    weight: [required],
    name: [required],
}

const selectMissionId = (value: any) => {
    let name = value.name
    if (value.name != null && value.name.includes('{변수}')) {
        name = value.name.replace('{변수}', value.variable)
    }
    setFormValues({
        category: value.category,
        type: value.type,
        name: name,
        nameTemplate: value.name,
        value: value.variable,
    })
}

const createSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
        readonly: true,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀)',
        value: '커스텀',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름(치환할 값은 {변수}로)',
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
        input: (event: any) => {
            if (form.value?.nameTemplate?.includes('{변수}')) {
                setFormValues({
                    name: form.value.nameTemplate?.replace(
                        '{변수}',
                        event.target.value
                    ),
                })
            }
        },
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    guide: {
        label: '코칭 가이드',
        component: InputComponents.Tiptap,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const updateSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
        disabled: true,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀(?))',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름',
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
        input: (event: any) => {
            if (form.value?.nameTemplate?.includes('{변수}')) {
                setFormValues({
                    name: form.value.nameTemplate?.replace(
                        '{변수}',
                        event.target.value
                    ),
                })
            }
        },
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
        disabled: true,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    weekNumber: {
        label: '주차',
        component: InputComponents.WeekSelectInput,
        userId: props.userId,
        input: (value: any, resourceId: any) => {
            fetchFormSpec(
                `/api/forme/diet/mission/${props.userId}/${resourceId}?weekNumber=${value}`
            )
        },
    },
    guide: {
        label: '코칭 가이드',
        component: InputComponents.Tiptap,
        disabled: () => isBefore(new Date(), selectedWeek?.startDate),
    },
    feedbackScore: {
        hidden: true,
    },
    feedback: {
        label: '유저 피드백',
        readonly: true,
        type: InputType.PILLZ,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const filterParams = ref<Record<string, any>>({
    weekNumber: 1,
})

const writeMessage = async (
    userId: string,
    formeMessageType: string,
    message: string,
    targetDate: Date,
    weekNumber: number,
    formeUserMissionId?: number
) => {
    loading.value = true
    await adminApi(`/api/forme/diet/message`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: userId,
            formeUserMissionId,
            formeMessageType,
            message,
            targetDate,
            weekNumber,
        }),
    })
    await setUserDetail(props.userId)
    loading.value = false
}

const writeWmNote = debounce(async (userId: string, wmNote: string) => {
    await adminApi(`/api/forme/diet/wm/note/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            note: wmNote,
        }),
    })
    message.success('WM 메모가 성공적으로 수정되었습니다.')
}, 500)

const onWriteWmNote = (value: string) => {
    userDetail.value.wmMemo = value
    writeWmNote(props.userId, value)
}

const writeNotionLink = debounce(async (userId: string, notionLink: string) => {
    await adminApi(`/api/forme/diet/wm/notion/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            notion: notionLink,
        }),
    })
    message.success('노션 링크가 성공적으로 수정되었습니다.')
}, 500)

const onWriteNotionLink = (value: string) => {
    userDetail.value.notionLink = value
    writeNotionLink(props.userId, value)
}

const weeklyMessage = ref('')

const getWeeklyMessage = async (userId: string, weekNumber: number) => {
    const params = {
        weekNumber: weekNumber,
        formeMessageType: 'WEEKLY',
    }
    const response = await adminApi<ApiResponse<string>>(
        `/api/forme/diet/message/${userId}?${stringify(params)}`
    )
    weeklyMessage.value = response.result || ''
}

const fetchData = async () => {
    await setUserDetail(props.userId)
    table.value.fetchData()
    getWeeklyMessage(props.userId, selectedWeek?.weekNumber || 1)
}

const onUpdateCategory = async (value: any) => {
    filterParams.value.category = value
    fetchTable()
}

const fetchTable = async () => {
    table.value.fetchData()
}

const reoderDietFactorTypeList = async (dietFactorTypeList: any[]) => {
    await adminApi(`/api/forme/diet/reorder/find-factor/${props.userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dietFactorTypeList),
    })
}

const onReorderDietFactorTypeList = async () => {
    await reoderDietFactorTypeList(
        userDetail.value.dietFactorTypeList.map((it: any) => it.dietFactorType)
    )
}

const completeMission = async () => {
    loading.value = true
    await adminApi(
        `/api/forme/diet/mission/complete/${props.userId}?weekNumber=${selectedWeek.weekNumber}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    loading.value = false
}

const createMissionByDietFactor = async () => {
    loading.value = true
    try {
        await adminApi(
            `/api/forme/diet/mission-by-diet-factor/${props.userId}`,
            {
                method: 'POST',
            }
        )
        await setUserDetail(props.userId)
    } catch (e) {
        console.error(e)
        findFactorFail.value = true
    } finally {
        loading.value = false
    }
}

const completeFindFactor = async () => {
    loading.value = true
    await adminApi(`/api/forme/diet/complete/find-factor/${props.userId}`, {
        method: 'POST',
    })
    setUserDetail(props.userId)
    loading.value = false
}

const isFuture = computed(() =>
    isBefore(new Date(), selectedWeek?.startDate || new Date())
)

const collapseKey = ref<Array<number>>([])
</script>
<template>
    <a-flex vertical>
        <a-collapse v-model:active-key="collapseKey">
            <a-collapse-panel key="1" header="사용자 정보">
                <a-card>
                    <template #title>
                        <a-typography-title
                            :level="4"
                            style="display: inline; margin-right: 10px"
                            >{{ userDetail.userName }}
                        </a-typography-title>
                        <a-typography-text type="secondary"
                            >{{ userDetail.age }}대 /
                            {{ userDetail.gender == 'MALE' ? '남' : '여' }} /
                            {{ userDetail.pregnant ? '임신' : '' }} ID
                            {{ userDetail.userId }}
                        </a-typography-text>
                    </template>
                    <a-descriptions :column="4">
                        <a-descriptions-item label="키"
                            >{{ userDetail.height }}
                        </a-descriptions-item>
                        <a-descriptions-item label="시작 체중"
                            >{{ userDetail.startWeight }}kg (BMI
                            {{ Math.round(userDetail.startBmi) || 0 }})
                        </a-descriptions-item>
                        <a-descriptions-item label="현재 체중"
                            >{{ userDetail.weight }}kg (BMI
                            {{ Math.round(userDetail.bmi) || 0 }})
                        </a-descriptions-item>
                        <a-descriptions-item label="목표 체중"
                            >{{ userDetail.targetWeight }}kg (BMI
                            {{ Math.round(userDetail.targetBmi) || 0 }})
                        </a-descriptions-item>
                        <a-descriptions-item label="건강고민">
                            {{ userDetail.higList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="건강 검진">
                            {{ userDetail.mcuList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="복용약물">
                            {{ userDetail.drugList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="알러지">
                            {{ userDetail.allergyList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="기저질환">
                            {{ userDetail.diseaseList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="가족력">
                            {{ userDetail.familyDiseaseList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="체형 고민">
                            {{ userDetail.bodyShapeList?.join(', ') }}
                        </a-descriptions-item>
                        <a-descriptions-item label="비만 가족력">
                            {{ userDetail.familyObesityList?.join(', ') }}
                        </a-descriptions-item>
                    </a-descriptions>
                    <a-form :label-col="{ span: 2 }" :label-align="'left'">
                        <a-form-item label="범인 타입">
                            <a-space wrap>
                                <draggable
                                    v-model="userDetail.dietFactorTypeList"
                                    @change="onReorderDietFactorTypeList"
                                    item-key="dietFactorType"
                                >
                                    <template #item="{ element }">
                                        <a-tag :key="element.dietFactorType">
                                            <a-typography-text strong
                                                >{{ element.category + ' ' }}
                                            </a-typography-text>
                                            <a-typography-text
                                                >{{ element.reason }}
                                            </a-typography-text>
                                        </a-tag>
                                    </template>
                                </draggable>
                            </a-space>
                            <a-space style="float: right" direction="vertical">
                                <div style="float: right">
                                    <span v-if="findFactorFail">
                                        범인 찾기 실패
                                    </span>
                                    <span
                                        v-if="userDetail.findFactorAt"
                                        style="margin-right: 10px"
                                    >
                                        범인찾기 날짜:
                                        {{
                                            userDetail.findFactorAt != null
                                                ? formatLocalDateTime(
                                                      userDetail.findFactorAt
                                                  )
                                                : ' '
                                        }}
                                    </span>
                                    <a-button
                                        @click="createMissionByDietFactor"
                                        :loading="loading"
                                        :disabled="!userDetail.findFactorEnable"
                                    >
                                        범인 찾기 실행
                                    </a-button>
                                </div>
                                <div style="float: right">
                                    <span
                                        v-if="userDetail.findFactorCompletedAt"
                                        style="margin-right: 10px"
                                    >
                                        전송 시간:
                                        {{
                                            userDetail.findFactorCompletedAt !=
                                            null
                                                ? formatLocalDateTime(
                                                      userDetail.findFactorCompletedAt
                                                  )
                                                : ' '
                                        }}
                                    </span>
                                    <a-button
                                        @click="completeFindFactor"
                                        type="primary"
                                        :loading="loading"
                                        :disabled="
                                            userDetail.findFactorCompletedAt
                                        "
                                    >
                                        범인찾기 리포트 알림톡 전송 완료
                                    </a-button>
                                </div>
                            </a-space>
                        </a-form-item>
                        <a-form-item
                            label="WM 메모"
                            :help="'웰니스마스터만 확인하는 내용으로, 유저에게 공개되지 않아요.'"
                        >
                            <tiptap
                                :value="userDetail.wmMemo"
                                @update:value="onWriteWmNote"
                            />
                        </a-form-item>
                        <a-form-item label="문진표">
                            <span>
                                {{ userDetail.wellnessSurvey?.answer }}
                            </span>
                        </a-form-item>
                        <a-form-item label="검진결과 공유">
                            <span>
                                {{ userDetail.wellnessSurvey?.healthCheckup }}
                            </span>
                        </a-form-item>
                        <a-form-item label="다이어트 경험">
                            <span>
                                {{ userDetail.wellnessSurvey?.dietExperience }}
                            </span>
                        </a-form-item>
                        <a-form-item label="다이어트 목표">
                            <span>
                                {{ userDetail.wellnessSurvey?.reasonForDiet }}
                            </span>
                        </a-form-item>
                        <a-form-item label="셀프 원인 분석">
                            <span>
                                {{ userDetail.wellnessSurvey?.guessReason }}
                            </span>
                        </a-form-item>
                        <a-form-item label="노션 링크">
                            <a-input
                                :value="userDetail.notionLink"
                                @update:value="onWriteNotionLink"
                            >
                                <template #addonAfter>
                                    <a
                                        :href="userDetail.notionLink"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <LinkOutlined />
                                    </a>
                                </template>
                            </a-input>
                        </a-form-item>
                    </a-form>
                </a-card>
            </a-collapse-panel>
        </a-collapse>
        <a-form style="margin-top: 48px">
            <a-form-item>
                <a-radio-group
                    :value="filterParams.category"
                    @update:value="onUpdateCategory"
                >
                    <a-radio-button>전체</a-radio-button>
                    <a-radio-button value="체중">체중</a-radio-button>
                    <a-radio-button value="혈당">혈당</a-radio-button>
                    <a-radio-button value="식단">식단</a-radio-button>
                    <a-radio-button value="활동">활동</a-radio-button>
                    <a-radio-button value="수면">수면</a-radio-button>
                </a-radio-group>
            </a-form-item>
            <a-form-item>
                <WeekSelectInput
                    :user-id="userId"
                    v-model:value="filterParams.weekNumber"
                    @after-update="fetchData"
                />
            </a-form-item>
            <a-form-item
                label="주차별 WM 코멘트"
                :help="'유저에게 전달하는 메시지로, [메시지 전달] 버튼을 누르면 앱으로 전달돼요.'"
            >
                <tiptap v-model:value="weeklyMessage" />
            </a-form-item>
            <a-form-item>
                <div style="float: right">
                    <span style="margin-right: 10px">
                        최근 전송 시간:
                        {{ formatLocalDateTime(userDetail.wmMessageWriteAt) }}
                    </span>
                    <a-button
                        @click="
                            () =>
                                writeMessage(
                                    userId,
                                    'WEEKLY',
                                    weeklyMessage,
                                    new Date(),
                                    selectedWeek?.weekNumber
                                )
                        "
                        :loading="loading"
                        type="primary"
                        :disabled="userDetail.wmMessageWriteAt || isFuture"
                        >메세지 전송
                    </a-button>
                </div>
            </a-form-item>
        </a-form>
        <NestedResourceTable
            ref="table"
            title="미션"
            create-modal-title="커스텀 미션 추가"
            :params="filterParams"
            :uri="`/api/forme/diet/mission/${userId}`"
            :columns="columns"
            :create-spec="createSpec"
            :create-rules="rules"
            :update-spec="updateSpec"
            :update-rules="rules"
            primary-key="userMissionId"
            :scroll="{ x: 'max-content' }"
        >
            <template #toolbar>
                <a-button
                    type="primary"
                    @click="completeMission"
                    :loading="loading"
                    >미션 알림톡 전송
                </a-button>
            </template>
        </NestedResourceTable>
    </a-flex>
</template>
