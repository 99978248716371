<template>
    <ResourceTable
        title="사용자 피드백"
        uri="/api/user-feedback"
        :filter-spec="filterSpec"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        :scroll="{ x: true }"
        :columns="columns"
        exportable
        load-on-mount
    >
        <template #summary="{ search }">
            <a-card :title="summary.title">
                <a-row>
                    <a-statistic
                        v-for="stat of summary.values"
                        :key="stat.label"
                        :value="stat.value"
                        style="margin-right: 20px; overflow: scroll"
                    >
                        <template #title>
                            <a-button
                                type="link"
                                @click="
                                    () => {
                                        setProductIdFilter(stat.productId)
                                        search()
                                    }
                                "
                            >
                                ID: {{ stat.productId }}
                            </a-button>
                            {{ stat.label }}
                        </template>
                    </a-statistic>
                </a-row>
            </a-card>
        </template>
        <template #actions="{ uri, search }">
            <a-button style="color: black" :loading="loading" disabled
                >처리상태 변경</a-button
            >
            <a-button
                @click="() => updateStatus('OPEN', uri, search)"
                :disabled="any('OPEN')"
                >처리 전
            </a-button>
            <a-button
                @click="() => updateStatus('PENDING', uri, search)"
                :disabled="any('PENDING')"
                >처리 중</a-button
            >
            <a-button
                @click="() => updateStatus('CLOSED', uri, search)"
                :disabled="any('CLOSED')"
                >처리 됨</a-button
            >
            <a-button
                @click="() => updateStatus('QC', uri, search)"
                :disabled="any('QC')"
                >QC 필요</a-button
            >
            <a-button
                @click="() => updateStatus('QC_DONE', uri, search)"
                :disabled="any('QC_DONE')"
                >QC 완료</a-button
            >
            <bulk-action-modal
                @after-submit="resetSelectedRows(search)"
                :resource-ids="selectedRowKeys"
                :uri="uri"
                :input-spec="stashedActionSpec"
                label="숨김"
                :disabled="any('STASHED')"
            />
            <bulk-action-modal
                @after-submit="resetSelectedRows(search)"
                :resource-ids="selectedRowKeys"
                :uri="uri"
                :input-spec="productExistsActionSpec"
                label="상품 기등록"
                :disabled="any('PRODUCT_EXISTS')"
            />
            <bulk-action-modal
                @after-submit="resetSelectedRows(search)"
                :resource-ids="selectedRowKeys"
                :uri="uri"
                :input-spec="unresolvedActionSpec"
                label="처리 불가"
                :disabled="any('UNRESOLVED')"
            />
            <bulk-action-modal
                @after-submit="resetSelectedRows(search)"
                :resource-ids="selectedRowKeys"
                :uri="`${uri}/reply/bulk`"
                :input-spec="replyBulkSpec"
                label="단체 답변"
                :disabled="any('CLOSED')"
            />
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import TableSupport from '@/views/tableSupport'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { computed, defineComponent, ref, Ref } from 'vue'
import globalFormState from '@/components/globalFormState'
import { useUserStore } from '@/store/user'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'UserFeedback',
    mixins: [TableSupport],
    components: {
        ResourceTable,
        BulkActionModal,
    },
    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedRows: Ref<Array<Record<string, any>>> = ref([])
        const loading = ref(false)
        const userStore = useUserStore()
        const authorities = computed(() => userStore.authorities)
        const role = computed(() => userStore.role)
        const any = (status: string) => {
            return (
                selectedRows.value.filter((it) => it.status === status).length >
                    0 || selectedRowKeys.value.length == 0
            )
        }
        const resetSelectedRows = (search: () => void) => {
            selectedRowKeys.value = []
            search()
        }

        const filterSpec = ref({
            id: {
                label: 'ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            userId: {
                label: '사용자 ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            topic: {
                label: '카테고리',
                value:
                    (role.value === 'RESEARCHER' && [
                        'PRODUCT_NOT_FOUND',
                        'INGREDIENT_NOT_FOUND',
                        'ANALYSIS_IMPOSSIBLE_PRODUCTS',
                        'NUTRIENT_CORRECTION',
                        'PRODUCT_CORRECTION',
                        'FOOD_NOT_FOUND',
                        'FOOD_CORRECTION',
                    ]) ||
                    [],
                component: InputComponents.EnumSelect,
                typeName: 'user-feedback-topic',
                filter: (topic: string) => {
                    if (authorities.value.includes('ADMIN')) {
                        return true
                    } else if (authorities.value.includes('RESEARCHER')) {
                        return [
                            'PRODUCT_NOT_FOUND',
                            'INGREDIENT_NOT_FOUND',
                            'ANALYSIS_IMPOSSIBLE_PRODUCTS',
                            'NUTRIENT_CORRECTION',
                            'PRODUCT_CORRECTION',
                            'FOOD_NOT_FOUND',
                            'FOOD_CORRECTION',
                        ].includes(topic)
                    }
                    return false
                },
                mode: 'multiple',
            },
            status: {
                label: '상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'user-feedback-type',
            },
            productId: {
                label: '제품 ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            target: {
                label: 'Data',
                value: null,
            },
            assigneeNames: {
                label: '담당자',
                value: [],
                component: InputComponents.UserInput,
                mode: 'multiple',
            },
            creationTime: {
                label: '등록일',
                value: null,
                component: InputComponents.ARangePicker,
            },
            updateTime: {
                label: '수정일',
                value: null,
                component: InputComponents.ARangePicker,
            },
        })

        const setProductIdFilter = (id: any) => {
            filterSpec.value.productId.value = id
        }

        const { formSpec } = globalFormState
        const replyBulkSpec = ref<FormSpecification>({
            notificationTemplate: {
                label: '알림 유형',
                value: null,
                component: InputComponents.EnumSelect,
                select: (value: any, option: Record<string, any>) => {
                    console.log(formSpec.value)
                    formSpec.value.title.value = option.title
                    formSpec.value.body.value = option.body
                },
                typeName: 'notification-template',
            },
            title: {
                label: '제목',
                value: null,
                readonly: true,
            },
            body: {
                label: '내용',
                value: null,
                readonly: true,
                help: '`userName`과 `targetName`은 알림별로 사용자명과 대상명으로 변경됩니다.',
            },
        })

        const summary = ref<Record<string, any>>({})
        const setSummary = (data: Record<string, any>) => {
            summary.value = data
        }

        return {
            selectedRowKeys,
            selectedRows,
            loading,
            authorities,
            role,
            filterSpec,
            replyBulkSpec,
            any,
            resetSelectedRows,
            summary,
            setSummary,
            setProductIdFilter,
        }
    },
    methods: {
        onSelectChange(
            selectedRowKeys: Array<number>,
            selectedRows: Array<Record<string, any>>
        ) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        updateStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                status,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateFeedbacks(queryParams, uri, search)
            this.fetchSummary()
        },
        async updateFeedbacks(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                        console.log('search :' + search)
                    }, 1000)
                }
            }
        },
        async fetchSummary() {
            const response = await adminApi<ApiResponse<Record<string, any>>>(
                '/api/user-feedback/summary'
            )
            this.setSummary(response.result || {})
        },
    },
    data() {
        return {
            stashedActionSpec: {
                reason: {
                    label: '사유',
                    value: null,
                    type: 'textarea',
                },
                status: { label: '상태', value: 'STASHED', hidden: true },
            },
            productExistsActionSpec: {
                reason: { label: '사유', value: null, type: 'textarea' },
                status: {
                    label: '상태',
                    value: 'PRODUCT_EXISTS',
                    hidden: true,
                },
            },
            unresolvedActionSpec: {
                reason: { label: '사유', value: null, type: 'textarea' },
                status: { label: '상태', value: 'UNRESOLVED', hidden: true },
            },
            columns: [
                { title: 'ID', dataIndex: 'id', width: 100, fixed: 'left' },
                {
                    title: '사용자 ID',
                    dataIndex: 'userId',
                    width: 100,
                    fixed: 'left',
                },
                {
                    title: '사용자 명',
                    dataIndex: 'userName',
                    width: 100,
                    fixed: 'left',
                },
                {
                    title: 'data',
                    dataIndex: 'data',
                    width: 500,
                    type: 'data',
                },
                {
                    title: '답변',
                    dataIndex: 'replied',
                    type: 'userFeedbackResponse',
                    width: 120,
                },
                {
                    title: '카테고리',
                    dataIndex: 'topic',
                    customRender: formatEnum('user-feedback-topic'),
                    width: 120,
                },
                {
                    title: '상태',
                    dataIndex: 'status',
                    customRender: formatEnum('user-feedback-type'),
                    width: 100,
                },
                {
                    title: '사유',
                    dataIndex: 'reason',
                },
                {
                    title: '수행인',
                    dataIndex: 'assigneeNames',
                    type: 'assignee',
                    width: 100,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    sorter: true,
                    align: 'center',
                    width: 200,
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    width: 200,
                },
            ],
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'user-feedback-type',
            'user-feedback-topic',
            'notification-type',
            'notification-template',
        ])

        this.fetchSummary()
    },
})
</script>
