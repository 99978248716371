<template>
    <ResourceTable
        resource-name="ai-coaching-schedule"
        title="AI 코칭 스케쥴"
        uri="/api/ai-coaching-schedule"
        :columns="columns"
        :create-spec="inputSpec"
        :update-spec="inputSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        load-on-mount
    />
</template>

<script setup lang="ts">
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { formatEnumList, formatEnum } from '@/util/formmater'
import { required } from '@/util/input-validation'
import { onMounted, ref } from 'vue'

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: 'Number',
        dataIndex: 'number',
        width: 100,
        align: 'center',
    },
    {
        title: '트리거 유형',
        dataIndex: 'eventTriggerType',
        customRender: formatEnumList('event-trigger-type'),
    },
    {
        title: '반복 유형',
        dataIndex: 'eventLoopType',
        customRender: formatEnum('event-loop-type'),
    },
    {
        title: 'contentTitle',
        dataIndex: 'contentTitle',
    },
    {
        title: 'floatingMessage',
        dataIndex: 'floatingMessage',
    },
    {
        title: 'floatingButton',
        dataIndex: 'floatingButton',
    },
    {
        title: 'andyIconUrl',
        dataIndex: 'andyIconUrl',
        type: 'img',
        width: 130,
    },
    {
        title: 'surveyName',
        dataIndex: 'surveyName',
    },
]

const inputSpec: FormSpecification = {
    gdate: {
        label: 'G Date',
        value: null,
    },
    eventCode: {
        label: 'Event Code',
        value: null,
    },
    caseCode: {
        label: 'Case Code',
        value: null,
    },
    eventTriggerType: {
        label: 'Event Trigger Type',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'event-trigger-type',
        mode: SelectInputMode.MULTIPLE,
    },
    eventLoopType: {
        label: 'Event Loop Type',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'event-loop-type',
    },
    contentTitle: {
        label: 'Content Title',
        value: null,
    },
    floatingMessage: {
        label: 'Floating Message',
        value: null,
        type: InputType.TEXTAREA,
    },
    floatingButton: {
        label: 'Floating Button',
        value: null,
    },
    notificationTitle: {
        label: 'Notification Title',
        value: null,
    },
    notificationMessage: {
        label: 'Notification Message',
        value: null,
    },
    andyIconUrl: {
        label: 'Andy Icon',
        component: InputComponents.ImageInput,
        path: '/ai_coaching/icon',
        value: null,
    },
    aiCoachingType: {
        label: '코칭 방식',
        component: InputComponents.EnumSelect,
        typeName: 'ai-coaching-type',
        value: null,
    },
    timelineTitle: {
        label: 'Timeline Title',
        value: null,
    },
    timelineContent: {
        label: 'Timeline Content',
        value: null,
        type: InputType.TEXTAREA,
    },
    timelineIcon: {
        label: 'Timeline Icon',
        component: InputComponents.ImageInput,
        path: '/glucose/timeline/icon',
        value: null,
    },
    timelineImage: {
        label: 'Timeline Image',
        component: InputComponents.ImageInput,
        value: null,
    },
    timelineAnswer: {
        label: 'Timeline Answer',
        value: null,
    },
    timelineButton: {
        label: 'Timeline Button',
        value: null,
    },
    surveyName: {
        label: 'Survey',
        component: InputComponents.AutoCompleteInput,
        uri: '/api/survey/name',
        value: null,
    },
    version: {
        label: '지원 버전',
        component: InputComponents.AInputNumber,
        value: 0,
    },
    priority: {
        label: '우선순위(클수록 낮음)',
        component: InputComponents.AInputNumber,
        value: 0,
    },
}

const filterSpec = ref({
    number: {
        label: 'Number',
        value: null,
    },
    gdate: {
        label: 'G Date',
        value: null,
    },
    eventCode: {
        label: 'Event Code',
        value: null,
    },
    caseCode: {
        label: 'Case Code',
        value: null,
    },
    eventTriggerTypeStr: {
        label: 'Event Trigger Type',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'event-trigger-type',
    },
    eventLoopType: {
        label: 'Event Loop Type',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'event-loop-type',
    },
    contentTitle: {
        label: 'Content Title',
        value: null,
    },
    floatingMessage: {
        label: 'Floating Message',
        value: null,
    },
    floatingButton: {
        label: 'Floating Button',
        value: null,
    },
    notificationTitle: {
        label: 'Notification Title',
        value: null,
    },
    notificationMessage: {
        label: 'Notification Message',
        value: null,
    },
    aiCoachingType: {
        label: '코칭 방식',
        component: InputComponents.EnumSelect,
        typeName: 'ai-coaching-type',
        value: null,
    },
})

const rules = {
    aiCoachingType: [required],
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'event-trigger-type',
        'event-loop-type',
        'ai-coaching-type',
    ])
})
</script>
