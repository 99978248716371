import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#f9fefe","padding":"13px"} }

import { ref, toRef } from 'vue'
import { required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { mlApi } from '@/fetchTemplate'
import { debounce } from 'lodash'

type Message = {
    role: string
    content: string
    tokenLength?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatMessageInput',
  props: {
    rules: {
        type: Object,
        default: () => ({
            role: [required],
            content: [required],
        }),
    },
    value: {
        type: Array,
        default: () => [],
    },
},
  setup(__props) {

const roleOptions = [
    { label: '사용자', value: 'user' },
    { label: '지원자', value: 'assistant' },
    { label: '시스템', value: 'system' },
]

const props = __props

const form = ref<Message>({
    role: '',
    content: '',
    tokenLength: 0,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: Message) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}
const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            form.value = {
                role: '',
                content: '',
            }
        }
    })
}

const onChangeMessage = debounce(async (event: any, form: Record<any, any>) => {
    if (event.target.value) {
        const response = await mlApi(`/open-ai/token-length`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: event.target.value }),
        })
        form.tokenLength = response
    }
}, 500)

return (_ctx: any,_cache: any) => {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref_key: "formRule",
          ref: formRule,
          model: form.value,
          rules: __props.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "role" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: form.value.role,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.value.role) = $event)),
                  options: roleOptions
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "content",
              label: "내용"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: form.value.content,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.value.content) = $event)),
                  style: {"width":"600px !important"},
                  onChange: _cache[2] || (_cache[2] = (event) => _unref(onChangeMessage)(event, form.value))
                }, null, 8, ["value"]),
                _createVNode(_component_a_row, { style: {"justify-content":"end"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(`token 수: ${form.value.tokenLength}`), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_typography_text, null, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" 토큰의 최대수는 모델별로 3072 ~ 8192개 입니다. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" 메세지 추가 ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { style: {"margin-top":"25px"} }, {
      default: _withCtx(() => [
        _createVNode(_unref(Draggable), {
          list: valueRef.value,
          "item-key": "foodId"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: __props.rules,
              layout: "inline",
              style: {"border-color":"#6c2bf2","margin-bottom":"25px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SwapOutlined), { rotate: 90 })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "role" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: element.role,
                      "onUpdate:value": ($event: any) => ((element.role) = $event),
                      options: roleOptions
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, {
                  name: "content",
                  label: "내용"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: element.content,
                      "onUpdate:value": ($event: any) => ((element.content) = $event),
                      style: {"width":"600px !important"},
                      onChange: (event) => _unref(onChangeMessage)(event, element)
                    }, null, 8, ["value", "onUpdate:value", "onChange"]),
                    (element.tokenLength)
                      ? (_openBlock(), _createBlock(_component_a_row, {
                          key: 0,
                          style: {"justify-content":"end"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_typography_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(`token 수: ${element.tokenLength}`), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => pop(index)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_unref(DeleteOutlined))
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_space, { direction: "vertical" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" role에 따라 응답을 설정합니다. ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" '시스템': 시스템 메시지는 대화의 시작 부분에 사용되며, 대화의 컨텍스트와 기대되는 동작을 설정하는 데 도움이 됩니다. 시스템 메시지는 일반적으로 ChatGPT에게 특정 주제에 대한 정보를 제공하거나, 어떤 방식으로 대화를 진행할지 가이드합니다. ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_typography_text, { code: "" }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("시스템: \"넌 친절하고 상냥해\" ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode(" '지원자': 도우미 메시지는 이전에 생성된 ChatGPT의 응답을 포함합니다. 이전 응답을 포함함으로써, ChatGPT는 이전 대화의 문맥을 더 잘 이해하고 적절한 후속 응답을 생성할 수 있습니다. ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_typography_text, { code: "" }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode(" 지원자: \"비타민A는 아침에 먹는게 좋아요.\" ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_typography_text, null, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode(" '사용자': 사용자 메시지는 실제 사용자의 질문이나 명령을 포함합니다. ChatGPT는 이러한 사용자 메시지를 바탕으로 적절한 응답을 생성하려고 시도합니다. ")
          ])),
          _: 1
        }),
        _createVNode(_component_a_typography_text, { code: "" }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("사용자: \"비타민A는 언제먹는게 좋니.\" ")
          ])),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})