<template>
    <a-row>
        <a-select
            class="select"
            :options="options"
            :value="valueByMode"
            :mode="mode"
            @change="change"
            @select="onSelect"
        />
        <a-button @click.prevent="refreshOptions" style="margin-left: 5px">
            <reload-outlined />
        </a-button>
        <a-button @click.prevent="resetValue">
            <minus-outlined />
        </a-button>
    </a-row>
</template>

<style scoped>
.select {
    width: 20em;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { SelectOption } from '@/store/enumType'
import { AdLayout, useLayoutStore } from '@/store/advertisement'

export default defineComponent({
    name: 'AdLayoutInput',

    components: {
        ReloadOutlined,
        MinusOutlined,
    },

    props: {
        showReload: {
            type: Boolean,
            default: false,
        },
        value: {
            type: [String, Array],
        },
        mode: {
            type: String,
            default: 'multiple',
        },
    },

    setup() {
        const layoutStore = useLayoutStore()

        return {
            layoutStore,
        }
    },

    emits: ['select', 'update:value'],

    computed: {
        options(): Array<SelectOption> {
            return this.layoutStore.layout.map((it: AdLayout) => ({
                label: `[${it.id}] ${it.name}`,
                value: it.id,
                key: it.id,
            }))
        },
        valueByMode: {
            get() {
                if (this.mode === 'multiple') {
                    return this.value || []
                }
                return this.value
            },
            set(value: any) {
                this.$emit('update:value', value)
            },
        },
    },

    methods: {
        refreshOptions() {
            console.log('refresh')
            this.layoutStore.fetchAdLayout()
        },
        onSelect(value: any, option: Record<string, any>) {
            this.$emit('select', value, option)
        },
        change(value: any) {
            return this.$emit('update:value', value)
        },
        resetValue() {
            this.$emit('update:value', null)
        },
    },
})
</script>
