import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#f9fefe","padding":"13px","width":"auto"} }

import { ref, toRef } from 'vue'
import BooleanInput from '@/components/input/BooleanInput.vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'


type SurveyOption = {
    id?: number
    content: string | null
    value: number
    answer: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyOptionInput',
  props: {
    rules: {
        type: Object,
        default: () => ({
            content: [required],
            value: [required, aboveOrEqualToZero],
        }),
    },
    productId: {
        type: Number,
    },
    value: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
},
  setup(__props) {

const props = __props

const form = ref<SurveyOption>({
    content: null,
    value: 0,
    answer: false,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: SurveyOption) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            form.value = {
                content: null,
                value: 0,
                answer: false,
            }
        }
    })
}

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { style: {"padding-bottom":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref_key: "formRule",
          ref: formRule,
          model: form.value,
          rules: __props.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { name: "content" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  name: "content",
                  value: form.value.content,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.value.content) = $event)),
                  disabled: __props.disabled
                }, null, 8, ["value", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, { name: "value" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input_number, {
                  value: form.value.value,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.value.value) = $event)),
                  disabled: __props.disabled
                }, null, 8, ["value", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "isDefault",
              label: "정답"
            }, {
              default: _withCtx(() => [
                _createVNode(BooleanInput, {
                  value: form.value.answer,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.value.answer) = $event)),
                  toggle: ""
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add,
                  disabled: __props.disabled
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" 선택지 추가 ")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_unref(Draggable), {
          list: valueRef.value,
          "item-key": "value"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: __props.rules,
              layout: "inline",
              style: {"border-color":"#6c2bf2","padding-bottom":"10px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SwapOutlined), { rotate: 90 })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { name: "content" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      name: "content",
                      value: element.content,
                      "onUpdate:value": ($event: any) => ((element.content) = $event),
                      disabled: __props.disabled
                    }, null, 8, ["value", "onUpdate:value", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, { name: "value" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      value: element.value,
                      "onUpdate:value": ($event: any) => ((element.value) = $event),
                      disabled: __props.disabled
                    }, null, 8, ["value", "onUpdate:value", "disabled"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, {
                  name: "isDefault",
                  label: "정답"
                }, {
                  default: _withCtx(() => [
                    _createVNode(BooleanInput, {
                      value: element.answer,
                      "onUpdate:value": ($event: any) => ((element.answer) = $event),
                      toggle: ""
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => pop(index),
                      disabled: __props.disabled
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_unref(DeleteOutlined))
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ]))
}
}

})