export const recommendTimeWithMealCase = [
    { label: '식전', value: '식전' },
    { label: '식후', value: '식후' },
    { label: '식사 2시간후', value: '식사 2시간후' },
    { label: '없음', value: null },
]

export const recommendTimeCase = [
    { label: '아침', value: '아침' },
    { label: '점심', value: '점심' },
    { label: '저녁', value: '저녁' },
    { label: '자기전', value: '자기전' },
    { label: '언제든', value: '언제든' },
    { label: '운동전후', value: '운동전후' },
    { label: '하루 중 가장 든든한 식사', value: '하루 중 가장 든든한 식사' },
    { label: '오전중', value: '오전중' },
    { label: '추천 시간 없음', value: '추천 시간 없음' },
]

export const productRecommendTimeCase = [
    { label: '아침', value: '아침' },
    { label: '점심', value: '점심' },
    { label: '저녁', value: '저녁' },
    { label: '자기전', value: '자기전' },
    { label: '언제든', value: '언제든' },
    { label: '운동전후', value: '운동전후' },
    { label: '오전중', value: '오전중' },
    { label: '추천 시간 없음', value: '추천 시간 없음' },
]

export const sourceType = [
    { label: 'I', value: 'I' },
    { label: 'D', value: 'D' },
    { label: 'P', value: 'P' },
]

export const foodManagementCriteria = [
    { label: '브랜드', value: 'byBrand' },
    { label: '음식이름', value: 'byFoodName' },
]

export const formulation = [
    { label: '정', value: '정' },
    { label: '캡슐', value: '캡슐' },
    { label: '포', value: '포' },
    { label: '방울', value: '방울' },
    { label: '티스푼', value: '티스푼' },
    { label: '테이블스푼', value: '테이블스푼' },
    { label: '스푼', value: '스푼' },
    { label: '스쿱', value: '스쿱' },
    { label: 'ml', value: 'ml' },
    { label: 'mg', value: 'mg' },
    { label: 'g', value: 'g' },
    { label: '병', value: '병' },
    { label: '앰플', value: '앰플' },
    { label: '환', value: '환' },
    { label: '구미', value: '구미' },
    { label: '너겟', value: '너겟' },
    { label: '온즈', value: '온즈' },
    { label: '팩', value: '팩' },
    { label: '스틱', value: '스틱' },
    { label: '스프레이', value: '스프레이' },
    { label: '튜브', value: '튜브' },
    { label: '스포이드', value: '스포이드' },
    { label: '매', value: '매' },
    { label: '조각', value: '조각' },
    { label: '편', value: '편' },
    { label: '알', value: '알' },
    { label: '개', value: '개' },
]

export const BAD_CATEGORY1 = [
    '가공유지',
    '가공유크림',
    '가루/조미료/오일',
    '개량메주',
    '고추씨기름',
    '고추장',
    '고춧가루',
    '곤충가공식품',
    '과실주',
    '과자',
    '과자/초콜릿/시리얼',
    '과자류',
    '기타설탕',
    '기타장류',
    '기타잼',
    '김치',
    '김치류',
    '당류가공품',
    '당시럽류',
    '당절임',
    '된장',
    '떡류',
    '마가린',
    '마요네즈',
    '만두피',
    '면 및 만두류',
    '모조치즈',
    '물엿',
    '밀가루',
    '밀크초콜릿',
    '발효소시지',
    '벌꿀',
    '베이컨류',
    '복합조미식품',
    '비유지방아이스크림',
    '빙과',
    '빵류',
    '산분해간장',
    '샤베트',
    '설탕',
    '소스',
    '소시지',
    '쇼트닝',
    '아이스밀크',
    '아이스크림',
    '아이스크림류',
    '양념젓갈',
    '양조간장',
    '영양강화 밀가루',
    '옥수수기름(옥배유)',
    '올리고당',
    '올리고당가공품',
    '유탕면',
    '장/소스/드레싱/식초',
    '잼',
    '전.적 및 부침류',
    '전분가공품',
    '전지분유',
    '절임식품',
    '젓갈',
    '젓갈류',
    '조미건어포',
    '주류',
    '준초콜릿',
    '채종유(유채유 또는 카놀라유)',
    '초콜릿가공품',
    '춘장',
    '캔디류',
    '콩기름(대두유)',
    '탄산음료',
    '토마토케첩',
    '튀김류',
    '팜유류',
    '해바라기유',
    '향미유',
    '혼합간장',
    '혼합식용유',
    '혼합장',
    '화이트초콜릿',
    '가공유',
    '찌개 및 전골류',
    '간편조리세트',
    '건면',
    '곡류가공품',
    '기타',
    '기타식물성유지',
    '미강유(현미유)',
    '시리얼류',
    '식물성크림',
    '장아찌 및 절임류',
    '혼합소시지',
    '생면',
    '숙면',
]

export const BAD_CATEGORY2 = [
    '기타 볶음류',
    '복합조미식품',
    '분쇄가공육제품',
    '조리가공품류',
    '조미료류',
    '탄산음료류',
    '떡류',
    '떡볶이류',
    '서류가공품',
    '당류',
    '볶음밥류',
]

export const BAD_DRINK_CATEGORY1 = [
    '과/채가공품',
    '과/채음료',
    '과/채주스',
    '기타 코코아가공품',
    '기타발효음료',
    '농축과/채즙(또는 과/채분)',
    '농후발효유',
    '발효식초',
    '발효유',
    '생수/음료',
    '액상차',
    '우유',
    '유당분해우유',
    '유산균음료',
    '유제품/아이스크림',
    '음료 및 차류',
    '음료베이스',
    '인삼/홍삼음료',
    '초콜릿',
    '캔디류',
    '커피',
    '혼합음료',
]

export const BAD_DRINK_CATEGORY2 = ['우유 및 유제품류', '우유.유제품류']

export const BAD_OIL_CATEGORY1 = [
    '들기름',
    '참기름',
    '해바라기유',
    '기타식물성유지',
    '올리브유',
    '버터',
    '가공치즈',
]

export const UNKNOWN_CATEGORY = [
    '영아용 조제유',
    '영아용 조제식',
    '영/유아용 이유식',
    '영/유아용 특수조제식품',
    '성장기용 조제유',
    '성장기용 조제식',
    '혼합분유',
    '선천성 대사질환자용 식품',
]

export const DEFAULT_CATEGORY1 = ['참기름', '들기름']
