<template>
    <a-row class="scoped-container">
        <a-col :span="24">
            <span class="header">{{ header }}</span>
        </a-col>
        <a-col :span="24" v-if="instanceList?.length > 0">
            <draggable
                class="list-group"
                itemKey="id"
                :list="instanceList || []"
                :group="{ name: 'instances' }"
            >
                <template #item="{ element, index }">
                    <ad-preview
                        class="preview"
                        :index="index"
                        :deletable="true"
                        :instance="element"
                        @remove="remove"
                    />
                </template>
            </draggable>
        </a-col>
        <a-col :span="24" v-else>데이터 없음</a-col>
    </a-row>
</template>

<style scoped>
.scoped-container {
    padding: 12px;
}
.header {
    display: flex;
    margin-bottom: 6px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
}
</style>
<script lang="ts">
import { defineComponent, toRef } from 'vue'
import AdPreview from '@/views/ad/AdPreview.vue'
import draggable from 'vuedraggable'

export default defineComponent({
    name: 'AdCurrentInstance',

    components: {
        draggable,
        AdPreview,
    },

    props: {
        header: {
            type: String,
        },
        instances: {
            type: Array,
        },
    },

    emits: ['remove'],

    setup(props: any) {
        const instanceList = toRef(props, 'instances')
        const headerProps = toRef(props, 'header')
        return {
            instanceList,
            headerProps,
        }
    },

    methods: {
        remove(index: any) {
            this.$emit('remove', index)
        },
    },
})
</script>
