import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","white-space":"nowrap","width":"90%"} }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_minus_outlined = _resolveComponent("minus-outlined")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_a_select, {
      value: _ctx.valueByMode,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueByMode) = $event)),
      options: _ctx.options,
      mode: _ctx.mode,
      disabled: _ctx.disabled,
      style: _normalizeStyle({ width: _ctx.width }),
      "show-search": "",
      "option-filter-prop": "label",
      onSelect: _ctx.onSelect
    }, {
      option: _withCtx(({ label, image }) => [
        _createElementVNode("span", {
          role: "img",
          "aria-label": label
        }, _toDisplayString(image), 9, _hoisted_2),
        _createTextVNode("   " + _toDisplayString(label), 1)
      ]),
      _: 1
    }, 8, ["value", "options", "mode", "disabled", "style", "onSelect"]),
    _createVNode(_component_a_button, {
      onClick: _withModifiers(_ctx.refreshOptions, ["prevent"]),
      style: {"margin-left":"5px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_reload_outlined)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_a_button, {
      onClick: _withModifiers(_ctx.resetValue, ["prevent"])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_minus_outlined)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}