import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "50%",
    open: _ctx.isShowModal,
    "onUpdate:open": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isShowModal) = $event)),
    title: "이벤트 인증",
    onCancel: _ctx.closeModal,
    onOk: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_spin, {
            key: 0,
            style: {"position":"absolute","left":"0","right":"0","width":"100%","height":"100%"}
          }))
        : _createCommentVNode("", true),
      (!_ctx.loading)
        ? (_openBlock(), _createBlock(_component_a_row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { class: "section" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 12,
                    style: {"padding-right":"5px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_component_a_image, {
                        src: _ctx.imageUrl,
                        key: _ctx.imageUrl,
                        preview: false
                      }, null, 8, ["src"]))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, {
                    span: 12,
                    style: {"padding-left":"5px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_descriptions, {
                        title: "",
                        bordered: true,
                        layout: "vertical",
                        column: 2,
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_descriptions_item, { label: "참가자 이름" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.eventUserName || '-'), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_descriptions_item, { label: "참가자 ID" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.eventUserId), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_descriptions_item, {
                            label: "이벤트 명",
                            span: 2
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.event.title), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_descriptions_item, {
                            label: "리뷰 종료일",
                            span: 2
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.event.promotedReviewEndAt), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_descriptions_item, {
                            label: "페이백 지급일",
                            span: 2
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.event.paybackAt), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_row, { class: "section" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 24,
                    style: {"display":"flex","flex-wrap":"wrap"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonGroup, (item) => {
                        return (_openBlock(), _createBlock(_component_a_col, {
                          span: 6,
                          key: item.value,
                          style: {"padding":"5px"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              style: {"width":"100%"},
                              class: _normalizeClass({
                                status: true,
                                'status-selected':
                                    _ctx.eventUserVerificationStatus === item.value,
                            }),
                              onClick: () => _ctx.updateStatus(_ctx.id, item.value)
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["class", "onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["open", "onCancel", "onOk"]))
}