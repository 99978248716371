import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_ctx.slotId)
    ? (_openBlock(), _createBlock(_component_ResourceTable, {
        key: 0,
        ref: "table",
        title: "",
        uri: "/api/advertisements/instances",
        class: "resource-table",
        "create-spec": _ctx.createSpec,
        "create-rules": _ctx.createRules,
        "update-spec": _ctx.updateSpec,
        "update-rules": _ctx.updateRules,
        "filter-spec": _ctx.filterSpec,
        columns: _ctx.columns,
        scroll: { x: 'max-content', y: 1400 },
        "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
        deletable: "",
        "load-on-mount": ""
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[0] || (_cache[0] = () => _ctx.addBaseInstance())
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("기본 광고")
            ])),
            _: 1
          }),
          _createVNode(_component_a_button, {
            onClick: _cache[1] || (_cache[1] = () => _ctx.addInstance())
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("광고 추가")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["create-spec", "create-rules", "update-spec", "update-rules", "filter-spec", "columns", "row-selection"]))
    : _createCommentVNode("", true)
}