<template>
    <ResourceTable
        title="신규기능 알림"
        uri="/api/admin/release-notification"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        :columns="columns"
        :scroll="{ x: 2000, y: 650 }"
        load-on-mount
    />
</template>

<script lang="ts">
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import { formatLocalDateTime } from '@/util/formmater'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { required } from '@/util/input-validation'
import { defineComponent, Ref, ref } from 'vue'

export default defineComponent({
    name: 'AdminReleaseNotification',
    mixins: [TableSupport],
    components: {
        ResourceTable,
    },

    setup() {
        const columns = ref([
            { title: 'ID', dataIndex: 'id', width: 100, fixed: 'left' },
            { title: '앱 버전', dataIndex: 'minBuildNumber', width: 100 },
            { title: '캠페인', dataIndex: 'campaign' },
            {
                title: '출시 날짜',
                dataIndex: 'releaseDateTime',
                customRender: formatLocalDateTime,
                sorter: true,
                width: 165,
            },
            {
                title: 'Icon',
                width: 130,
                dataIndex: 'iconUrl',
                type: 'img',
            },
            { title: '제목', dataIndex: 'title' },
            { title: '내용', dataIndex: 'body' },
            { title: '딥링크', dataIndex: 'deeplink' },
            { title: '서브 딥링크', dataIndex: 'subDeeplink' },
            { title: '노출여부', dataIndex: 'enable' },
        ])

        const inputSpec: FormSpecification = {
            minBuildNumber: {
                label: '앱 출시 버전',
                value: null,
                component: InputComponents.AInputNumber,
            },
            campaign: {
                label: '캠페인',
                value: null,
            },
            releaseDateTime: {
                label: '출시 날짜',
                value: null,
                component: InputComponents.ADatetimePicker,
            },
            iconUrl: {
                label: 'Icon',
                value: null,
                component: InputComponents.ImageInput,
            },
            title: {
                label: '제목',
                value: null,
            },
            body: {
                label: '내용',
                value: null,
                type: InputType.TEXTAREA,
                width: '360px !important',
                height: '180px',
            },
            deeplink: {
                label: '딥링크',
                value: null,
            },
            subDeeplink: {
                label: '하위버전 딥링크',
                value: null,
            },
            enable: {
                label: '노출 여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '노출',
                falseText: '비노출',
            },
        }

        const createSpec: Ref<FormSpecification> = ref(inputSpec)

        const updateSpec: Ref<FormSpecification> = ref(inputSpec)

        const rules = ref({
            minBuildNumber: [required],
            releaseDateTime: [required],
            iconUrl: [required],
            title: [required],
            body: [required],
            deeplink: [required],
            subDeeplink: [required],
        })

        return {
            columns,
            createSpec,
            updateSpec,
            rules,
        }
    },
})
</script>
