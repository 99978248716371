import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "포인트샵 주문",
    uri: "/api/admin/point-shop/orders",
    "filter-spec": _ctx.filterSpec,
    scroll: { x: 2520, y: 650 },
    columns: _ctx.columns,
    "row-selection": {
            selectedRowKeys: _ctx.selectedRowKeys,
            onChange: _ctx.onSelectChange,
        },
    exportable: "",
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        onClick: 
                    () => _ctx.updateOrderStatus('DELIVERY_PREPARE', uri, search)
                
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" 발송준비 ")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: 
                    () => _ctx.updateOrderStatus('DELIVERY_COMPLETE', uri, search)
                
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" 발송완료 ")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => _ctx.updateOrderStatus('DELIVERY_RESEND', uri, search)
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" 재전송 ")
        ])),
        _: 2
      }, 1032, ["onClick"])
    ]),
    detail: _withCtx(({ record }) => [
      _createVNode(_component_a_descriptions, {
        title: "리뷰 상세",
        bordered: true,
        layout: "vertical"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "리뷰 ID" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.id), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["filter-spec", "columns", "row-selection"]))
}