import 'moment/locale/ko'

import { eachDayOfInterval, isAfter } from 'date-fns'

/**
 * 시작 날짜부터 끝 날짜까지의 날짜 리스트를 생성하는 함수
 * @param {Date} startDate - 시작 날짜 (예: new Date('2023-01-01'))
 * @param {Date} endDate - 끝 날짜 (예: new Date('2023-01-10'))
 * @param {string} [dateFormat='yyyy-MM-dd'] - 날짜 형식 (기본값: 'yyyy-MM-dd')
 * @returns {Array<string>} 날짜 문자열의 배열
 */
export function getDateList(startDate: Date, endDate: Date): Array<Date> {
    // 시작 날짜가 끝 날짜보다 이후인 경우 빈 배열 반환
    if (isAfter(startDate, endDate)) {
        return []
    }

    // 시작 날짜부터 끝 날짜까지의 모든 날짜를 배열로 생성
    return eachDayOfInterval({
        start: startDate,
        end: endDate,
    })
}
