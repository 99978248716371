import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NestedResourceTable = _resolveComponent("NestedResourceTable")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "영양소",
    uri: "/api/nutrient",
    columns: _ctx.columns,
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    scroll: { x: 3400, y: 650 },
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    exportable: ""
  }, {
    updateModalHeader: _withCtx(({ resourceId }) => [
      _createVNode(_component_NestedResourceTable, {
        title: "관련 섭취량표",
        uri: "/api/nutrient-allowance",
        columns: _ctx.nestedTableColumns,
        params: { nutrientId: resourceId },
        scroll: { x: 1500, y: 300 }
      }, null, 8, ["columns", "params"])
    ]),
    _: 1
  }, 8, ["columns", "filter-spec", "create-spec", "update-spec", "create-rules", "update-rules"]))
}