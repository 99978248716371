<template>
    <ResourceTable
        title="사용자 세그먼트"
        uri="/api/segments"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        primary-key="segId"
        load-on-mount
    >
        <template #detail="{ resourceId, record }">
            <a-descriptions
                title="영양소 좋은점"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="제목">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="요약">
                    {{ record.summary }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일시">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="썸네일">
                    <img
                        :key="resourceId"
                        :width="200"
                        :src="record.thumbnailUrl"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'UserSegment',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            segId: {
                label: '세그먼트 ID',
                value: null,
                help: '16자 이내',
            },
            methodType: {
                label: '세그먼트 방식',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'segment-method-type',
            },
            selectionQuery: {
                label: '쿼리 조건',
                value: null,
                help: '사용자의 id를 리턴하는 쿼리 입력 (select id from user where id < 100)',
                type: InputType.TEXTAREA,
            },
            uploadedUserIdCsvUri: {
                label: '사용자 ID CSV 파일',
                value: null,
                component: InputComponents.ContentImageInput,
            },
            adminMemo: {
                label: '설명',
                value: '',
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            filterSpec: {
                segId: {
                    label: '세그먼트 ID',
                    value: null,
                    help: '16자 이내',
                },
                enabled: {
                    label: '활성화',
                    value: null,
                    component: InputComponents.BooleanInput,
                    notNull: true,
                },
                methodType: {
                    label: '세그먼트 방식',
                    value: null,
                    component: InputComponents.EnumSelect,
                    typeName: 'segment-method-type',
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                segId: {
                    readonly: true,
                },
                uploadedUserIdCsvUri: {
                    label: '사용자 ID CSV 파일',
                    value: null,
                    readonly: true,
                },
                uploadedUserCount: {
                    label: '세그먼트 대상자 사용자수 (CSV 업로드)',
                    value: null,
                    readonly: true,
                },
                selectionQueryUserCount: {
                    label: '세그먼트 대상자 사용자수 (쿼리)',
                    value: null,
                    readonly: true,
                },
                enabled: {
                    label: '활성화 여부',
                    value: null,
                    readonly: false,
                    component: InputComponents.BooleanInput,
                    trueText: '활성화',
                    falseText: '비활성화',
                    notNull: true,
                },
                uploadResult: {
                    label: 'CSV 파일 업로드 결과',
                    value: null,
                    readonly: true,
                },
                creationTime: {
                    label: '등록일시',
                    value: null,
                    readonly: true,
                },
                updateTime: {
                    label: '수정일시',
                    value: null,
                    readonly: true,
                },
            },
            columns: [
                {
                    title: '세그먼트 ID',
                    dataIndex: 'segId',
                    width: 50,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '방식',
                    width: 20,
                    dataIndex: 'methodType',
                },
                {
                    title: '대상자수(UPLOAD_CSV)',
                    width: 100,
                    dataIndex: 'uploadedUserCount',
                },
                {
                    title: '대상자수(QUERY)',
                    width: 100,
                    dataIndex: 'selectionQueryUserCount',
                },
                {
                    title: '설명',
                    width: 100,
                    dataIndex: 'adminMemo',
                },
                {
                    title: '활성화',
                    width: 35,
                    dataIndex: 'enabled',
                    customRender: (value: any) => {
                        if (value.value) {
                            return 'True'
                        }
                        return 'False'
                    },
                },
            ],
            rules: {
                segId: [required],
                adminMemo: [required],
                queryMethod: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['segment-method-type'])
    },
})
</script>
