<template>
    <span>
        <a-input-number
            class="korean-number-input"
            :value="value"
            @change="change"
            @pressEnter="$emit('pressEnter')"
            :addonAfter="addonAfter"
            :placeholder="placeholder"
            :min="0"
            :style="{
                color: (value >= 10000 && 'red') || 'black',
                minWidth: width,
                width: width,
            }"
            :disabled="disabled"
        >
            <template v-if="hasAddonAfterSlot" #addonAfter>
                <slot name="addonAfter" />
            </template>
        </a-input-number>
        <p
            :hidden="value < 10000"
            :style="{
                color: (value >= 10000 && 'red') || 'darkgray',
                width: '90px',
                whiteSpace: 'nowrap',
                overflow: 'none',
            }"
        >
            {{ numToKorean(value) }}
        </p>
        <p :hidden="value >= 10000"></p>
    </span>
</template>

<script lang="ts">
import { FormatOptions, numToKorean } from 'num-to-korean'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'KoreanNumberInput',

    props: {
        value: {
            type: [Number],
            default: 0,
        },
        placeholder: {
            type: String,
        },
        width: {
            type: String,
            default: '8em',
        },
        addonAfter: {
            type: String,
        },
        hasAddonAfterSlot: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:value', 'pressEnter', 'input'],

    methods: {
        numToKorean(value?: number | string): string | undefined {
            if (value == null || value == '' || value?.toString().includes('.'))
                return undefined
            return numToKorean(value as number, FormatOptions.MIXED)
        },
        change(value: any) {
            this.$emit('update:value', value)
            return this.$emit('input')
        },
    },
})
</script>
