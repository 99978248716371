import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateFormModal = _resolveComponent("CreateFormModal")!
  const _component_UpdateFormModal = _resolveComponent("UpdateFormModal")!
  const _component_NestedResourceTable = _resolveComponent("NestedResourceTable")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "HIG",
    uri: "/api/hig",
    columns: _ctx.columns,
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    scroll: { x: 2500, y: 650 },
    exportable: "",
    "edit-column-width": 220,
    "primary-key": "code",
    "create-modal-title": "HIG Broad 등록",
    "update-modal-title": "HIG Broad 수정"
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_CreateFormModal, {
        title: "HIG Detail 등록",
        label: "detail 등록",
        uri: `${uri}/detail`,
        "input-spec": _ctx.detailCreateSpec,
        rules: _ctx.detailRules,
        onSearch: search
      }, null, 8, ["uri", "input-spec", "rules", "onSearch"])
    ]),
    edit: _withCtx(({ uri, record, search }) => [
      _createVNode(_component_UpdateFormModal, {
        title: "HIG Detail 수정",
        "resource-id": record.higDetailName,
        label: "detail 수정",
        uri: `${uri}/detail`,
        "input-spec": _ctx.detailUpdateSpec,
        rules: _ctx.detailRules,
        onSearch: search
      }, null, 8, ["resource-id", "uri", "input-spec", "rules", "onSearch"])
    ]),
    updateModalHeader: _withCtx(({ resourceId }) => [
      _createVNode(_component_NestedResourceTable, {
        title: "HIG detail",
        uri: `/api/hig/detail/broad/${resourceId}`,
        columns: _ctx.higDetailColumns,
        scroll: { x: 2000, y: 450 }
      }, null, 8, ["uri", "columns"])
    ]),
    _: 1
  }, 8, ["columns", "filter-spec", "create-spec", "update-spec", "create-rules", "update-rules"]))
}