<template>
    <modal-button v-bind="$props" ref="modalButtonRef" @submit="onSubmit">
        <template #icon>
            <plus-outlined />
        </template>
        <template #button> 등록 </template>
        <template #header="data">
            <slot name="header" v-bind="data" />
        </template>
        <template #footer="data">
            <slot name="footer" v-bind="data" />
        </template>
    </modal-button>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import ModalButton from '@/components/modal/ModalButton.vue'
import { defineComponent, ref } from 'vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import useModalState from '@/components/globalFormState'

export default defineComponent({
    name: 'CreateFormModal',
    mixins: [ModalButton],
    props: {
        inputSpec: {
            type: Object,
            required: true,
        },
    },
    components: {
        PlusOutlined,
        ModalButton,
    },

    setup() {
        const modalButtonRef = ref()
        return { ...useModalState, modalButtonRef }
    },

    methods: {
        async post() {
            const { toggleLoading, toggleVisible, setErrorMessage, alertFail } =
                this.modalButtonRef
            toggleLoading()

            try {
                const response = await adminApi<ApiResponse<void>>(this.uri, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.form),
                })
                toggleLoading()

                if (
                    response.success === undefined ||
                    response.success === false
                ) {
                    setErrorMessage(
                        response.errorMessage || '개발자에게 문의하세요'
                    )
                    alertFail()
                } else {
                    this.resetForm()
                    this.$emit('afterSubmit')
                    toggleVisible()
                }
            } catch (error) {
                const err = error as Error
                toggleLoading()
                setErrorMessage(err.message)
                alertFail()
                throw error
            }
        },
        onSubmit() {
            this.post().then()
        },
    },
})
</script>
