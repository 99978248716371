import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "영양제 인기 검색어",
    uri: "/api/product-keyword-rank",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns,
    deletable: "",
    "load-on-mount": ""
  }, {
    detail: _withCtx(({ record }) => [
      _createVNode(_component_a_descriptions, {
        title: "팝업 상세",
        bordered: true,
        layout: "vertical",
        column: 1,
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "이벤트명" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.name), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "시작일" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.startAt), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "종료일" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.endAt), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "모집마감일" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.recruitAt), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "추첨발표일" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.announceAt), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "메타 타이틀" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.title), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "웹뷰 타이틀" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.webviewTitle), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "메타 디스크립션" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.description), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "담당자" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.manager), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "최대 모집인원" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.maxUserCount), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "최소 모집인원" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.minUserCount), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "회차" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.serialNo), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "앱용 URL" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.eventUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "외부 공유용 URL" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.externalEventUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "(구) 앱 배너 이미지" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.bannerImageUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "(구) 앱 배너 심플 이미지" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.simpleBannerImageUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "앱 배너 이미지" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.bannerLayoutDesc), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "웹 공유용 이미지" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.shareImageUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "카톡 공유용 이미지" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.kakaoShareImageUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "웹 홈 상단 배너(PC)" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.webHomeTopBannerImageUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "웹 홈 상단 배너(MO)" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.webHomeTopBannerImageUrl2), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "웹 사이드바 배너(PC)" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.webSidebarBannerImageUrl), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "웹 사이드바 배너(MO)" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.webSidebarBannerImageUrl2), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "이벤트 타입" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.eventType), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "APP 노출" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.visible), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "WEB 노출" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.webVisible), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "등록일" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.creationTime), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "업데이트일" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.updateTime), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["create-spec", "update-spec", "columns"]))
}