<template>
    <div v-if="slotId" class="slot-tabs">
        <a-tabs
            v-model:activeKey="slotId"
            :tab-position="'left'"
            @change="changeSlot"
        >
            <a-tab-pane
                v-for="slot in slotList"
                :key="slot.id"
                :tab="slot.name"
            >
                <a-col class="slot-tabs-container" :span="24">
                    <a-row>
                        <a-col class="slot-main" :span="24">
                            <ad-header-input
                                header="섹션명"
                                :value="slotName"
                                @change="changeSectionName"
                            />
                            <ad-header-input
                                header="구분값"
                                :value="slotPosition"
                                @change="changeSlotPosition"
                            />
                            <ad-header-input
                                header="상태"
                                type="select"
                                :value="status"
                                :options="statusOptions"
                                @change="changeStatus"
                            />
                            <a-button type="primary" @click="patch">
                                설정 변경
                            </a-button>
                        </a-col>
                    </a-row>
                </a-col>
                <slot name="advertise" />
            </a-tab-pane>
        </a-tabs>
    </div>
    <div v-else>
        <span>비어있음! 혜택 탭 섹션을 추가해주세요!</span>
    </div>
</template>

<style scoped>
.slot-tabs,
.slot-tabs-container,
.slot-main {
    display: flex;
    align-items: flex-end;
}
.slot-tabs-container {
    position: absolute;
    top: -80px;
}
</style>

<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import { patchApi } from '@/fetchTemplate'
import AdHeaderInput from '@/components/input/advertisements/AdHeaderInput.vue'
import { SelectOption } from '@/store/enumType'

export default defineComponent({
    name: 'AdBenefitSlotTab',
    components: { AdHeaderInput },

    props: {
        propsSlot: {
            type: Object,
        },
        propsSlotId: {
            type: Number,
        },
        propsSlotList: {
            type: Array,
        },
        propsStatus: {
            type: Boolean,
        },
        propsMinBuildNumberList: {
            type: Array,
        },
    },

    setup(props: any) {
        const slot = toRef(props, 'propsSlot')
        const slotList = toRef(props, 'propsSlotList')
        const slotId = toRef(props, 'propsSlotId')

        const slotName = ref()
        const slotPosition = ref()

        const minBuildNumberList = toRef(props, 'propsMinBuildNumberList')
        const minBuildNumber = ref()

        const status = toRef(props, 'propsStatus')
        const statusOptions: Array<SelectOption> = [
            { label: '활성', value: true },
            { label: '비활성', value: false },
        ]

        return {
            slot,
            slotId,
            slotList,
            slotName,
            slotPosition,
            minBuildNumber,
            minBuildNumberList,
            status,
            statusOptions,
        }
    },

    emits: ['change:slot', 'fetch:slot'],

    watch: {
        slot() {
            this.slotName = this.slot?.name
            this.slotPosition = this.slot?.position
        },
    },

    methods: {
        async patch() {
            const url = '/api/advertisements/publish-groups'
            const payload = {
                slotId: this.slotId,
                slotName: this.slotName,
                slotPosition: this.slotPosition,
                status: this.status,
                minBuildNumber: 1,
            }
            await patchApi(url, payload, true, '업데이트 실패!')
        },
        getSlot(slotId: number) {
            return this.slotList.find((obj: any) => obj.id === slotId)
        },
        getPublish(slotId: number) {
            return this.minBuildNumberList.find(
                (obj: any) => obj.slotId === slotId
            )
        },
        changeSlot(slotId: number) {
            const slot: any = this.getSlot(slotId)
            const publish: any = this.getPublish(slotId)
            this.slotName = slot?.name
            this.slotPosition = slot?.position
            this.status = publish?.enable
            this.minBuildNumber = publish?.minBuildNumber
            this.$emit('change:slot', slot)
        },
        changeSectionName(event: any) {
            event.preventDefault()
            this.slotName = event.target.value
        },
        changeStatus(status: boolean) {
            this.status = status
        },
        changeSlotPosition(event: any) {
            event.preventDefault()
            this.slotPosition = event.target.value
        },
    },
})
</script>
