<template>
    <ResourceTable
        title="HIG"
        uri="/api/hig"
        :columns="columns"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 2500, y: 650 }"
        exportable
        :edit-column-width="220"
        primary-key="code"
        create-modal-title="HIG Broad 등록"
        update-modal-title="HIG Broad 수정"
    >
        <template #actions="{ uri, search }">
            <CreateFormModal
                title="HIG Detail 등록"
                label="detail 등록"
                :uri="`${uri}/detail`"
                :input-spec="detailCreateSpec"
                :rules="detailRules"
                @search="search"
            />
        </template>
        <template #edit="{ uri, record, search }">
            <UpdateFormModal
                title="HIG Detail 수정"
                :resource-id="record.higDetailName"
                label="detail 수정"
                :uri="`${uri}/detail`"
                :input-spec="detailUpdateSpec"
                :rules="detailRules"
                @search="search"
            />
        </template>
        <template #updateModalHeader="{ resourceId }">
            <NestedResourceTable
                title="HIG detail"
                :uri="`/api/hig/detail/broad/${resourceId}`"
                :columns="higDetailColumns"
                :scroll="{ x: 2000, y: 450 }"
            />
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import {
    FormSpecification,
    InputComponents,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { Column } from '.'
import { formatEnum } from '@/util/formmater'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import UpdateFormModal from '@/components/modal/UpdateFormModal.vue'
import NestedResourceTable from '@/components/NestedResourceTable.vue'
import { required } from '@/util/input-validation'
import { defineComponent } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'HigView',
    components: {
        ResourceTable,
        CreateFormModal,
        UpdateFormModal,
        NestedResourceTable,
    },

    data() {
        const filterSpec: FormSpecification = {
            code: { label: '코드', value: null },
            name: {
                label: '이름',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
            },
        }

        const createSpec: FormSpecification = {
            code: { label: '코드', value: null },
            name: { label: '이름', value: null },
            displayName: { label: 'display명', value: null },
            tip: { label: '팁', value: null },
            iconUrl: { label: 'Icon 주소', value: null },
            iconUrl2: { label: 'Icon 주소2', value: null },
            targetGender: {
                label: '성별',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'gender',
            },
            recommendedNutrients: {
                label: '추천 영양소',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
                mode: SelectInputMode.MULTIPLE,
            },
        }

        const updateSpec = { ...createSpec }

        const detailCreateSpec: FormSpecification = {
            name: { label: '이름', value: null },
            displayName: { label: 'display명', value: null },
            description: { label: '설명', value: null },
            higBroadName: {
                label: 'HIG Broad',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
            },
            mcuDiagnosis: { label: 'MCU', value: null },
            recommendedNutrients: {
                label: '추천 영양소',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
                mode: SelectInputMode.MULTIPLE,
            },
            filterOutGender: {
                label: 'filterOutGender',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'gender',
            },
            filterOutAgeRange: {
                label: 'filterOutAgeRange',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'age-range',
                mode: SelectInputMode.MULTIPLE,
            },
        }

        const detailUpdateSpec = {
            ...detailCreateSpec,
        }

        const columns: Array<Column> = [
            { title: '코드', dataIndex: 'code', fixed: 'left', width: 100 },
            {
                title: '순위',
                dataIndex: 'rankNo',
                width: 100,
                align: 'right',
                sorter: true,
            },
            { title: 'HIG_BROAD', dataIndex: 'name', width: 100 },
            { title: 'HIG_DETAIL', dataIndex: 'higDetailName', width: 200 },
            { title: '팁', dataIndex: 'tip' },
            { title: '상세 설명', dataIndex: 'detailDescription' },
            { title: 'display명', dataIndex: 'displayName', width: 100 },
            {
                title: '성별',
                dataIndex: 'targetGender',
                width: 100,
                customRender: formatEnum('gender'),
            },
            {
                title: '추천 영양소',
                dataIndex: 'recommendedNutrients',
                scopedSlots: { customRender: 'tags' },
            },
        ]

        const higDetailColumns: Array<Column> = [
            { title: '이름', dataIndex: 'name', width: 100 },
            { title: 'display명', dataIndex: 'displayName', width: 150 },
            { title: '설명', dataIndex: 'description', width: 300 },
            { title: 'HIG Broad', dataIndex: 'higBroadName', width: 100 },
            { title: 'MCU', dataIndex: 'mcuDiagnosis', width: 100 },
            {
                title: '추천 영양소',
                dataIndex: 'recommendedNutrients',
                scopedSlots: { customRender: 'tags' },
            },
            {
                title: 'filterOutGender',
                dataIndex: 'filterOutGender',
                customRender: formatEnum('gender'),
                width: 100,
            },
            {
                title: 'filterOutAgeRange',
                dataIndex: 'filterOutAgeRange',
                scopedSlots: { customRender: 'tags' },
            },
        ]

        const rules = {
            code: [required],
            name: [required],
            displayName: [required],
            tip: [required],
            iconUrl: [required],
            iconUrl2: [required],
            targetGender: [required],
        }

        const detailRules = {
            name: [required],
            description: [required],
            higBroadName: [required],
        }
        return {
            filterSpec,
            createSpec,
            updateSpec,
            detailCreateSpec,
            detailUpdateSpec,
            columns,
            higDetailColumns,
            rules,
            detailRules,
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['gender', 'hig-broad-name', 'age-range'])
    },
})
</script>
