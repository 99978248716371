import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatLocalDateTime } from '@/util/formmater'
import { onMounted, ref, Ref } from 'vue'
import { stringify } from 'qs'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import store from '@/store'
import { required } from '@/util/input-validation'
import { foodManagementCriteria } from '@/constants'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { useEnumTypeStore } from '@/store/enumType'


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodManagementByPriority',
  setup(__props) {

const filterSpec = ref({
    name: { label: '이름', value: null },
    brandName: {
        label: '브랜드명',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/brand/name',
    },
    processStatus: {
        label: '관리상태',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'process-status',
    },
    criteria: {
        label: '기준',
        value: null,
        component: InputComponents.ASelect,
        options: foodManagementCriteria,
    },
    adminUserName: {
        label: '담당자',
        value: [],
        component: InputComponents.UserInput,
        mode: 'single',
    },
})

const statusSpec = ref<FormSpecification>({
    reason: {
        label: '사유',
        value: null,
    },
    processStatus: {
        label: '상태',
        value: 'CANCEL',
        hidden: true,
    },
})

const selectedRows: Ref<Array<Record<string, any>>> = ref([])
const selectedRowKeys: Ref<Array<number>> = ref([])
const any = (status: string) => {
    return (
        selectedRows.value.filter((it) => it.processStatus === status).length >
            0 || selectedRowKeys.value.length == 0
    )
}

const loading = ref(false)

const columns = [
    {
        title: '식품명',
        dataIndex: 'foodName',
    },
    {
        title: '브랜드명',
        dataIndex: 'brandName',
    },
    {
        title: '누적등록횟수',
        dataIndex: 'count',
    },
    {
        title: '관리자이름',
        dataIndex: 'adminUserName',
    },
    {
        title: '관리상태',
        dataIndex: 'processStatusDisplayName',
    },
    {
        title: '마지막 수정 시간',
        dataIndex: 'statusUpdatedAt',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

function onSelectChange(keys: Array<number>, rows: Array<Record<string, any>>) {
    selectedRowKeys.value = keys
    selectedRows.value = rows
}

async function request(param: string, uri: string, search: () => void) {
    if (selectedRowKeys.value.length > 0 && loading.value === false) {
        loading.value = true
        try {
            await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                method: 'PATCH',
            })
        } catch (err) {
            console.error(err)
            throw err
        } finally {
            setTimeout(() => {
                loading.value = false
                selectedRowKeys.value = []
                search()
            }, 1000)
        }
    }
}

const createRules = {
    brandName: [required],
}

function updateStatus(status: string, uri: string, search: () => void) {
    const params: Record<string, any> = {
        ids: selectedRowKeys.value,
        processStatus: status,
    }
    const queryParams = stringify(params, { indices: false })
    request(queryParams, uri, search)
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['food-status', 'process-status'])
})

const resetSelectedRows = (search: () => void) => {
    selectedRowKeys.value = []
    search()
}

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "foodManagementByPriority",
    title: "직접등록 식품 통계",
    uri: "/api/food-management-by-priority",
    "row-selection": {
            selectedRowKeys: selectedRowKeys.value,
            onChange: onSelectChange,
        },
    columns: columns,
    "filter-spec": filterSpec.value,
    "create-rules": createRules,
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        style: {"color":"black","margin-left":"45px"},
        loading: loading.value,
        disabled: ""
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("관리상태")
        ])),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_button, {
        onClick: () => updateStatus('READY', uri, search)
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("처리전")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => updateStatus('PROCESSING', uri, search)
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("처리중")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: () => updateStatus('PROCESSED', uri, search)
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("처리완료")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(BulkActionModal, {
        onAfterSubmit: resetSelectedRows,
        "resource-ids": selectedRowKeys.value,
        uri: uri,
        "input-spec": statusSpec.value,
        label: "처리불가",
        disabled: any('CANCEL')
      }, null, 8, ["resource-ids", "uri", "input-spec", "disabled"])
    ]),
    _: 1
  }, 8, ["row-selection", "filter-spec"]))
}
}

})