<template>
    <ResourceTable
        title="필라이즈 팝업"
        uri="/api/v1/popup"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        load-on-mount
    >
        <template #detail="{ record }">
            <a-descriptions
                title="팝업 상세"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="이벤트명">
                    {{ record.name }}
                </a-descriptions-item>
                <a-descriptions-item label="시작일">
                    {{ record.startAt }}
                </a-descriptions-item>
                <a-descriptions-item label="종료일">
                    {{ record.endAt }}
                </a-descriptions-item>
                <a-descriptions-item label="모집마감일">
                    {{ record.recruitAt }}
                </a-descriptions-item>
                <a-descriptions-item label="추첨발표일">
                    {{ record.announceAt }}
                </a-descriptions-item>
                <a-descriptions-item label="메타 타이틀">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="웹뷰 타이틀">
                    {{ record.webviewTitle }}
                </a-descriptions-item>
                <a-descriptions-item label="메타 디스크립션">
                    {{ record.description }}
                </a-descriptions-item>
                <a-descriptions-item label="담당자">
                    {{ record.manager }}
                </a-descriptions-item>
                <a-descriptions-item label="최대 모집인원">
                    {{ record.maxUserCount }}
                </a-descriptions-item>
                <a-descriptions-item label="최소 모집인원">
                    {{ record.minUserCount }}
                </a-descriptions-item>
                <a-descriptions-item label="회차">
                    {{ record.serialNo }}
                </a-descriptions-item>
                <a-descriptions-item label="앱용 URL">
                    {{ record.eventUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="외부 공유용 URL">
                    {{ record.externalEventUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="(구) 앱 배너 이미지">
                    {{ record.bannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="(구) 앱 배너 심플 이미지">
                    {{ record.simpleBannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="앱 배너 이미지">
                    {{ record.bannerLayoutDesc }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 공유용 이미지">
                    {{ record.shareImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="카톡 공유용 이미지">
                    {{ record.kakaoShareImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 홈 상단 배너(PC)">
                    {{ record.webHomeTopBannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 홈 상단 배너(MO)">
                    {{ record.webHomeTopBannerImageUrl2 }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 사이드바 배너(PC)">
                    {{ record.webSidebarBannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 사이드바 배너(MO)">
                    {{ record.webSidebarBannerImageUrl2 }}
                </a-descriptions-item>
                <a-descriptions-item label="이벤트 타입">
                    {{ record.eventType }}
                </a-descriptions-item>
                <a-descriptions-item label="APP 노출">
                    {{ record.visible }}
                </a-descriptions-item>
                <a-descriptions-item label="WEB 노출">
                    {{ record.webVisible }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="업데이트일">
                    {{ record.updateTime }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
        <template #actions="{ uri, search }">
            <a-button
                @click="copyPopup(uri, search)"
                :disabled="selectedRowKeys.length !== 1"
            >
                복사
            </a-button>
            <a-button
                @click="setStatus(uri, false, search)"
                :disabled="selectedRowKeys.length === 0"
            >
                활성화
            </a-button>
            <a-button
                @click="setStatus(uri, true, search)"
                :disabled="selectedRowKeys.length === 0"
            >
                비활성화
            </a-button>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { formatBoolean, formatLocalDateTime } from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { message } from 'ant-design-vue'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Popup',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            campaign: {
                label: '캠패인',
                value: null,
                help: '팝업 캠페인 이름 (고객 노출 X)',
            },
            startAt: {
                label: '시작일',
                value: null,
                component: InputComponents.ADatetimePicker,
            },
            endAt: {
                label: '종료일',
                value: null,
                component: InputComponents.ADatetimePicker,
            },
            actionName: { label: '액션 버튼 이름', value: null },
            template: {
                label: '템플릿',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'popup-template',
            },
            imageUrl: {
                label: '팝업 이미지',
                value: null,
                component: InputComponents.ImageInput,
            },
            slidingImageUrl: {
                label: '슬라이딩 이미지',
                value: null,
                component: InputComponents.ImageInput,
            },
            webviewLink: { label: '웹뷰 링크', value: null },
            webviewTitle: { label: '웹뷰 제목', value: null },
            segId: {
                label: '세그먼트 ID',
                value: null,
                help: '지정하지 않으면 전체 공개',
            },
            disable: {
                label: '비활성화',
                value: false,
                component: InputComponents.BooleanInput,
                notNull: true,
                falseText: '활성화',
                trueText: '비활성화',
            },
            priority: {
                label: '우선순위',
                value: 0,
                help: '숫자가 낮을수록 우선순위가 높음',
            },
            visibleForPaidUser: {
                label: '유료 사용자 노출',
                value: false,
                component: InputComponents.BooleanInput,
                notNull: true,
                falseText: '비노출',
                trueText: '노출',
            },
            closeButtonColor: {
                label: '닫기 버튼 색상',
                value: null,
                help: '6자리 HEX 코드',
            },
            okButtonBgColor: {
                label: '확인 버튼 배경색',
                value: null,
                help: '6자리 HEX 코드',
            },
        }
        const filterSpec = {
            campaign: {
                label: '캠패인',
                value: null,
                help: '팝업 캠페인 이름 (고객 노출 X)',
            },
            actionName: { label: '액션 버튼 이름', value: null },
            webviewTitle: { label: '웹뷰 제목', value: null },
            disable: {
                label: '비활성화',
                value: false,
                component: InputComponents.BooleanInput,
                notNull: true,
                falseText: '활성화',
                trueText: '비활성화',
            },
        }
        return {
            selectedRowKeys: [] as Array<number>,
            selectedRows: [] as Array<Record<string, any>>,
            loading: false,
            createSpec,
            updateSpec: { ...createSpec },
            filterSpec: filterSpec,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                    sorter: true,
                },
                {
                    title: '템플릿',
                    dataIndex: 'template',
                    width: 150,
                    customRender: (value: any) => {
                        if (value.value === 'TYPE_1_FULL_IMAGE_TEMPLATE') {
                            return 'T1. 전면 팝업'
                        } else if (value.value === 'TYPE_2_DELIVERY_TEMPLATE') {
                            return 'T2. 배송 팝업'
                        } else if (
                            value.value === 'TYPE_3_ANNA_REVIEW_TEMPLATE'
                        ) {
                            return 'T3. 리뷰 유도 팝업'
                        } else {
                            return 'T4. 바텀 슬라이딩 팝업'
                        }
                    },
                },
                {
                    title: '캠페인명',
                    dataIndex: 'campaign',
                    width: 200,
                },
                {
                    title: '우선순위',
                    dataIndex: 'priority',
                    width: 150,
                    sorter: true,
                },
                {
                    title: '시작일',
                    dataIndex: 'startAt',
                    customRender: formatLocalDateTime,
                    sorter: true,
                    width: 150,
                },
                {
                    title: '종료일',
                    dataIndex: 'endAt',
                    customRender: formatLocalDateTime,
                    sorter: true,
                    width: 150,
                },
                {
                    title: '이미지',
                    dataIndex: 'imageUrl',
                    width: 120,
                    type: 'img',
                },
                {
                    title: '버튼동작',
                    dataIndex: 'actionType',
                    width: 120,
                },
                {
                    title: '버튼명',
                    dataIndex: 'actionName',
                    width: 120,
                },
                {
                    title: '타게팅 (세그먼트 ID)',
                    dataIndex: 'segId',
                    width: 180,
                },
                {
                    title: '비활성화',
                    dataIndex: 'disable',
                    customRender: (value: any) => {
                        if (value.value == true) {
                            return '비활성화'
                        }
                        return '활성화'
                    },
                    width: 100,
                },
                {
                    title: '웹뷰링크',
                    dataIndex: 'webviewLink',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    type: 'detail',
                },
            ],
            rules: {
                actionName: [required],
                campaign: [required],
                imageUrl: [required],
                startAt: [required],
                endAt: [required],
                disable: [required],
                template: [required],
            },
        }
    },
    methods: {
        onSelectChange(
            selectedRowKeys: Array<number>,
            selectedRows: Array<Record<string, any>>
        ) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },

        async copyPopup(uri: string, search: () => void) {
            const id =
                this.selectedRowKeys.length === 1
                    ? this.selectedRowKeys[0]
                    : null

            if (id == null) {
                return message.error('하나의 팝업을 선택해주세요')
            }

            await adminApi(`${uri}/copy/${id}`, {
                method: 'POST',
            })
            await search()
        },
        async setStatus(uri: string, disable: any, search: () => void) {
            const idList = this.selectedRowKeys
            if (idList.length === 0) {
                return message.error('팝업을 선택해주세요')
            }
            const payload = {
                idList: idList,
                status: disable,
            }
            try {
                const response = await adminApi<ApiResponse<void>>(
                    `${uri}/status`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    }
                )
                if (response.success === false) {
                    return message.error('에러 발생')
                } else {
                    search()
                    this.selectedRowKeys = []
                    this.selectedRows = []
                }
            } catch (error) {
                message.error('에러 발생')
            }
        },
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['popup-template'])
    },
})
</script>
