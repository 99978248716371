import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", null, [
      _cache[0] || (_cache[0] = _createTextVNode(" 필라이즈 어드민 입니다.")),
      _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
      _createTextVNode(" API: " + _toDisplayString(`${_ctx.apiServerAddress}`) + " 입니다. ", 1)
    ])
  ]))
}