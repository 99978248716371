<template>
    <a-form ref="formRule" :model="form">
        <a-row>
            <a-descriptions :bordered="true" :column="1">
                <a-descriptions-item label="레이아웃">
                    <a-select
                        class="select"
                        :options="options"
                        :value="form.layout.layoutId"
                        :mode="layoutMode"
                        @change="changeLayout"
                    />
                    <a-button class="btn" @click.prevent="refreshOptions">
                        <reload-outlined />
                    </a-button>
                    <a-button class="btn" @click.prevent="resetValue">
                        <minus-outlined />
                    </a-button>
                </a-descriptions-item>
                <a-descriptions-item
                    v-bind:key="key"
                    v-for="(value, key) in properties"
                    :label="key"
                >
                    <a-col v-if="value === 'IMAGE'" :span="24">
                        <image-input
                            :value="form.layout.layoutProperties[key]?.url"
                            @update:value="(path: string) => changeImage(key, path)"
                        />
                    </a-col>
                    <a-col
                        v-else-if="value === 'BUTTON'"
                        :span="24"
                        class="property button"
                    >
                        <a-col :span="12" class="button-box">
                            <label>label</label>
                            <a-input
                                :value="
                                    form.layout.layoutProperties[key]?.label
                                "
                                @change="(event: any) => changeButtonLabel(key, event)"
                            />
                        </a-col>
                        <a-col :span="12" class="button-box">
                            <label>value</label>
                            <a-input
                                :value="
                                    form.layout.layoutProperties[key]?.value
                                "
                                @change="(event: any) => changeButtonValue(key, event)"
                            />
                        </a-col>
                    </a-col>
                    <div v-else class="property text">
                        <div class="element">
                            <a-input
                                :value="form.layout.layoutProperties[key]?.text"
                                @change="(event: any) => changeText(key, event)"
                            />
                        </div>
                    </div>
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
    </a-form>
</template>

<style scoped>
.btn {
    margin-left: 5px;
}
.select {
    width: 20em;
}
.property.button {
    display: flex;
}
.button-box:first-child {
    padding-right: 6px;
}
.button-box:last-child {
    padding-left: 6px;
}
</style>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { SelectOption } from '@/store/enumType'
import { AdLayout, useLayoutStore } from '@/store/advertisement'
import { SelectInputMode } from '@/components/InputMapper.vue'
import globalFormState from '@/components/globalFormState'
import ImageInput from '@/components/input/ImageInput.vue'

export default defineComponent({
    name: 'AdLayoutInputV2',

    components: {
        ImageInput,
        ReloadOutlined,
        MinusOutlined,
    },

    setup() {
        const layoutMode = ref(SelectInputMode.DEFAULT)
        const layoutStore = useLayoutStore()
        const global = {
            ...globalFormState,
        }
        const layoutId = global.form.value?.layout?.layoutId
        const properties = ref(
            layoutStore.layout.find((it: any) => it.id === layoutId)
                ?.properties || []
        )

        return {
            form: global.form,
            properties,
            layoutMode,
            layoutStore,
        }
    },

    emits: ['select', 'update:value'],

    computed: {
        options(): Array<SelectOption> {
            return this.layoutStore.layout.map((it: AdLayout) => ({
                label: `[${it.id}] ${it.name}`,
                value: it.id,
                key: it.id,
            }))
        },
    },

    methods: {
        refreshOptions() {
            console.log('refresh - property input')
            this.layoutStore.fetchAdLayout()
        },
        changeLayout(layoutId: any) {
            this.properties =
                this.layoutStore.layout.find((it: any) => it.id === layoutId)
                    ?.properties || []
            this.form.layout.layoutId = layoutId
        },
        changeImage(key: any, value: any) {
            this.form.layout.layoutProperties[key] = {
                type: 'IMAGE',
                url: value,
            }
        },
        changeButtonLabel(key: any, event: any) {
            const result = event.target.value
            const value = this.form.layout.layoutProperties[key]?.value
            this.form.layout.layoutProperties[key] = {
                type: 'BUTTON',
                label: result,
                value: value,
            }
        },
        changeButtonValue(key: any, event: any) {
            const value = event.target.value
            const number = Number(value)
            const result = isNaN(number) ? value : Number(value)
            const label = this.form.layout.layoutProperties[key]?.label
            this.form.layout.layoutProperties[key] = {
                type: 'BUTTON',
                label: label,
                value: result,
            }
        },
        changeText(key: any, event: any) {
            this.form.layout.layoutProperties[key] = {
                type: 'TEXT',
                text: event.target.value,
            }
        },
        changeCountDown(key: any, value: any) {
            console.log('countdown : ', key, value)
        },
        resetValue() {
            this.$emit('update:value', null)
        },
    },
})
</script>
