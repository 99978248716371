<template>
    <div class="container">
        <div v-if="instance" class="instance">
            <div class="status-box">
                <span :class="isOnAir ? 'on-air' : 'off'">
                    {{ badge }}
                </span>
            </div>
            <div class="preview-layout">
                <div class="overlay">
                    <div v-if="!isOnAir" class="off">
                        <span class="off-text">OFF</span>
                    </div>
                    <div v-if="deletable" class="overlay-hover">
                        <div class="item">ID : {{ instance.id }}</div>
                        <div class="item">
                            <a-button danger @click="remove(index)">
                                <delete-outlined />
                            </a-button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="instance?.layoutType === 'benefit-layout'"
                    class="benefit-layout"
                >
                    <div>
                        <div class="benefit-top-content">
                            <a-image
                                :src="benefitImageUrl"
                                width="100%"
                                max-width="335px"
                                height="148px"
                                @error="replaceImage"
                            />
                        </div>
                        <div class="benefit-bottom-content">
                            <span class="benefit-name">
                                {{ benefitName }}
                            </span>
                            <span class="benefit-title">
                                {{ benefitTitle }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="instance?.layoutType === 'reward-text'"
                    class="reward-text-layout"
                >
                    <div class="reward-content">
                        <div class="reward-image">
                            <a-image
                                :src="imageUrl"
                                width="44px"
                                height="44px"
                            />
                        </div>
                        <div class="reward-text">
                            <span class="reward-title">
                                {{ rewardTitle }}
                            </span>
                            <span class="reward-sub-title">
                                {{ rewardSubTitle }}
                            </span>
                        </div>
                    </div>
                    <div class="reward-button">
                        <span class="reward-coin" />
                        <span class="reward-button-label">
                            {{ buttonLabel }}
                        </span>
                    </div>
                </div>
                <div
                    v-else-if="instance?.layoutType === 'reward-image'"
                    class="reward-image-layout"
                >
                    <a-image
                        :src="imageUrl"
                        width="100%"
                        max-width="335px"
                        height="84px"
                    />
                </div>
                <div
                    v-else-if="instance?.layoutType === 'da-layout'"
                    class="da-layout"
                >
                    <a-image
                        :src="imageUrl"
                        width="100%"
                        max-width="335px"
                        height="148px"
                    />
                </div>
                <div
                    v-else-if="instance?.layoutType === 'store-countdown'"
                    class="store-countdown-layout"
                >
                    <a-image
                        :src="imageUrl"
                        width="100%"
                        max-width="335px"
                        height="148px"
                    />
                </div>
                <div
                    v-else-if="instance?.layoutType === 'nutrients-countdown'"
                    class="nutrients-countdown-layout"
                >
                    <a-image
                        :src="imageUrl"
                        width="100%"
                        max-width="335px"
                        height="148px"
                    />
                </div>
                <div v-else><h1>지원하지 않는 LAYOUT</h1></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.container {
    margin-bottom: 10px;
}
.preview-layout {
    width: 100%;
    position: relative;
    max-width: 335px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 10px 0 rgba(70, 56, 147, 0.3);
}

.reward-text-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 336px;
    height: 84px;
    background-color: rgb(57, 59, 74);
    border-radius: 15px;
    padding: 20px;
}
.reward-content {
    display: flex;
    align-items: center;
}
.reward-image {
    margin-right: 10px;
}
.reward-text {
    display: flex;
    flex-direction: column;
}
.reward-title {
    display: flex;
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: bold;
    color: rgb(255, 255, 255);
}
.reward-sub-title {
    font-size: 14px;
    color: rgb(196, 200, 206);
}
.reward-button {
    width: 66px;
    height: 28px;
    background-color: rgb(108, 47, 242);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reward-coin {
    width: 16px;
    height: 16px;
    background-color: rgb(249, 220, 74);
    border-radius: 100%;
    margin-right: 6px;
    font-style: italic;
}
.reward-button-label {
    font-size: 12px;
    font-weight: bold;
    color: rgb(244, 242, 255);
}
.reward-image-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 336px;
    height: 84px;
    background-color: rgb(57, 59, 74);
    border-radius: 15px;
}
.store-countdown-layout {
    display: flex;
    align-items: center;
    width: 336px;
    height: 148px;
}
.nutrients-countdown-layout {
    display: flex;
    align-items: center;
    width: 336px;
    height: 148px;
}
.instance {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    cursor: pointer;
}
.instance:hover .overlay {
    cursor: pointer;
    opacity: 1;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
}
.status-box {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
}
.on-air {
    display: flex;
    padding: 6px 12px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    border: 1px solid #ff0000;
    border-radius: 12px;
    background: #ff0000;
    animation: flicker 1.5s infinite alternate;
}
.overlay:hover .overlay-hover {
    display: flex;
    position: absolute;
    top: 0;
}
.overlay-hover {
    width: 100%;
    height: 100%;
    background: #eaeaea;
    opacity: 0.9;
    display: none;
}
.overlay-hover .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.overlay .off {
    width: 100%;
    height: 100%;
    background: #eaeaea;
    opacity: 0.9;
    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay-hover .item,
.off-text {
    font-weight: bold;
    color: #fff;
    padding: 6px 12px;
    background: rgb(57, 59, 74);
}

/*************************
        BENEFIT
*************************/
.benefit-top-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.benefit-bottom-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 10px 0px rgba(70, 56, 147, 0.3);
}

.benefit-name {
    color: #9ba1aa;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.benefit-title {
    height: 44px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    word-break: keep-all;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #101219;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 22.4px */
}

@keyframes flicker {
    0%,
    100% {
        opacity: 1;
        text-shadow: 0 0 10px #fff, 0 0 20px #ff0000, 0 0 30px #ff0000,
            0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000,
            0 0 70px #ff0000;
    }
    50% {
        opacity: 0.8;
        text-shadow: 0 0 5px #fff, 0 0 10px #ff0000, 0 0 15px #ff0000,
            0 0 20px #ff0000, 0 0 25px #ff0000, 0 0 30px #ff0000,
            0 0 35px #ff0000;
    }
}
</style>
<script lang="ts">
import { defineComponent, toRef } from 'vue'
import { isNull } from 'lodash'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'AdPreview',
    components: { DeleteOutlined },

    props: {
        index: {
            type: Number,
        },
        deletable: {
            type: Boolean,
        },
        instance: {
            type: Object || Array || null,
        },
        isSwiperProps: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isOnAir() {
            const enable = this.value.enable
            const startTime = this.value.startTime
            const endTime = this.value.endTime
            const current = new Date().getTime()
            const start = new Date(startTime).getTime()
            const end = new Date(endTime).getTime()

            if (enable === false) return false
            else if (!isNull(startTime) && current < start) return false
            else if (!isNull(endTime) && current > end) return false
            else return true
        },
        badge() {
            return this.isOnAir ? 'ON AIR' : ''
        },
        layoutType() {
            return this.value.layoutType
        },
        imageUrl() {
            return this.value.layout?.layoutProperties?.image?.url || ''
        },
        buttonLabel() {
            return this.value.layout?.layoutProperties?.button?.label || ''
        },
        benefitImageUrl() {
            if (this.isSwiper) {
                return (
                    this.value.layout?.layoutProperties?.smallImage?.url ||
                    'https://cdn.pillyze.io/assets/2024-07-17/coming_soon_short.webp'
                )
            }
            return (
                this.value.layout?.layoutProperties?.wideImage?.url ||
                'https://cdn.pillyze.io/assets/2024-07-17/coming_soon_long.webp'
            )
        },
        benefitName() {
            return this.value.layout?.layoutProperties?.name?.text || ''
        },
        benefitTitle() {
            return this.value.layout?.layoutProperties?.title?.text || ''
        },
        rewardTitle() {
            return this.value.layout?.layoutProperties?.title?.text || ''
        },
        rewardSubTitle() {
            return this.value.layout?.layoutProperties?.subTitle?.text || ''
        },
    },

    setup(props: any) {
        const value = toRef(props, 'instance')
        const isSwiper = toRef(props, 'isSwiperProps')
        return {
            value,
            isSwiper,
        }
    },

    emits: ['remove'],

    methods: {
        remove(index: any) {
            console.log('i : ', index)
            this.$emit('remove', index)
        },
        replaceImage(e: any) {
            e.target.src =
                'https://cdn.pillyze.io/assets/img/eventbanner_error_short.webp'
        },
    },
})
</script>
