<template>
    <resource-table
        title="영양소 단위"
        uri="/api/standard-unit"
        :columns="columns"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        primary-key="code"
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'StandardUnitInput',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            code: { label: '코드', value: null },
            unitExpression: { label: '표현식', value: null },
            unitName: { label: '단위', value: null },
            synonyms: {
                label: '동의어',
                value: [],
                component: 'ASelect',
                mode: 'tags',
            },
        }
        return {
            columns: [
                {
                    title: '코드',
                    dataIndex: 'code',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '표현식',
                    dataIndex: 'unitExpression',
                },
                {
                    title: '단위',
                    dataIndex: 'unitName',
                },
                {
                    title: '동의어',
                    dataIndex: 'synonyms',
                    scopedSlots: { customRender: 'tags' },
                },
            ],
            filterSpec: {
                code: {
                    label: '코드',
                    value: null,
                    component: 'StandardUnitInput',
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
            },
        }
    },
})
</script>
