import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FilterSupport',

    props: {
        filterSpec: {
            type: Object,
        },
    },

    computed: {
        filter() {
            const { filterSpec } = this.$props

            if (filterSpec == null) return {}

            return Object.keys(filterSpec).reduce((record, key) => {
                if (filterSpec[key].value == null) {
                    return { ...record }
                }

                return { ...record, [key]: filterSpec[key].value }
            }, {})
        },
    },
})
