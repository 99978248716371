import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_image_input = _resolveComponent("image-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_banner_creator_item = _resolveComponent("banner-creator-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "overlay"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_spin)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { class: "element-group" }, {
              default: _withCtx(() => [
                _cache[24] || (_cache[24] = _createElementVNode("h3", null, "LAYOUT", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[22] || (_cache[22] = [
                        _createTextVNode("배경 색상")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.bg,
                          "onUpdate:pureColor": _cache[0] || (_cache[0] = (value) => _ctx.setColor('bg', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[23] || (_cache[23] = [
                        _createTextVNode("Circle 색상")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.circle,
                          "onUpdate:pureColor": _cache[1] || (_cache[1] = (value) => _ctx.setColor('circle', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "element-group" }, {
              default: _withCtx(() => [
                _cache[28] || (_cache[28] = _createElementVNode("h3", null, "천원필템 로고", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                        _createTextVNode("천원필템 색상")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.label,
                          "onUpdate:pureColor": _cache[2] || (_cache[2] = (value) => _ctx.setColor('label', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[26] || (_cache[26] = [
                        _createTextVNode("천원필템 top")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.logo.top,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.logo.top) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[27] || (_cache[27] = [
                        _createTextVNode("천원필템 left")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.logo.left,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.logo.left) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "element-group" }, {
              default: _withCtx(() => [
                _cache[31] || (_cache[31] = _createElementVNode("h3", null, "천원필템 문구", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[29] || (_cache[29] = [
                        _createTextVNode("천원필템 Header")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.values.text.header,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.text.header) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[30] || (_cache[30] = [
                        _createTextVNode("천원필템 Desc")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.values.text.description,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.text.description) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "element-group" }, {
              default: _withCtx(() => [
                _cache[38] || (_cache[38] = _createElementVNode("h3", null, "라벨", -1)),
                _cache[39] || (_cache[39] = _createElementVNode("h4", null, "라벨 위치", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[32] || (_cache[32] = [
                        _createTextVNode("라벨 top")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.label.top,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.label.top) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode("라벨 left")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.label.left,
                          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.label.left) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[40] || (_cache[40] = _createElementVNode("h4", null, "내일마감", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                        _createTextVNode("배경색")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.tomorrowBg,
                          "onUpdate:pureColor": _cache[9] || (_cache[9] = (value) => _ctx.setColor('tomorrowBg', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[35] || (_cache[35] = [
                        _createTextVNode("폰트색")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.tomorrowFont,
                          "onUpdate:pureColor": _cache[10] || (_cache[10] = (value) => _ctx.setColor('tomorrowFont', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[41] || (_cache[41] = _createElementVNode("h4", null, "오늘마감", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[36] || (_cache[36] = [
                        _createTextVNode("배경색")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.todayBg,
                          "onUpdate:pureColor": _cache[11] || (_cache[11] = (value) => _ctx.setColor('todayBg', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[37] || (_cache[37] = [
                        _createTextVNode("폰트색")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.todayFont,
                          "onUpdate:pureColor": _cache[12] || (_cache[12] = (value) => _ctx.setColor('todayFont', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "element-group" }, {
              default: _withCtx(() => [
                _cache[46] || (_cache[46] = _createElementVNode("h3", null, "결과 버튼", -1)),
                _cache[47] || (_cache[47] = _createElementVNode("h4", null, "위치", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[42] || (_cache[42] = [
                        _createTextVNode("Left")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.button.left,
                          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.values.button.left) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[43] || (_cache[43] = [
                        _createTextVNode("Bottom")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.button.bottom,
                          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.values.button.bottom) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[48] || (_cache[48] = _createElementVNode("h4", null, "내일마감", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[44] || (_cache[44] = [
                        _createTextVNode("배경색")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.buttonBg,
                          "onUpdate:pureColor": _cache[15] || (_cache[15] = (value) => _ctx.setColor('buttonBg', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[45] || (_cache[45] = [
                        _createTextVNode("폰트색")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_color_picker, {
                          "pure-color": _ctx.values.color.buttonFont,
                          "onUpdate:pureColor": _cache[16] || (_cache[16] = (value) => _ctx.setColor('buttonFont', value))
                        }, null, 8, ["pure-color"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_row, { class: "element-group" }, {
              default: _withCtx(() => [
                _cache[55] || (_cache[55] = _createElementVNode("h3", null, "상품", -1)),
                _cache[56] || (_cache[56] = _createElementVNode("h4", null, "상품 사이즈", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[49] || (_cache[49] = [
                        _createTextVNode("이미지 Width")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.product.width,
                          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.values.product.width) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[50] || (_cache[50] = [
                        _createTextVNode("이미지 Height")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.product.height,
                          "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.values.product.height) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[57] || (_cache[57] = _createElementVNode("h4", null, "상품 위치", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[51] || (_cache[51] = [
                        _createTextVNode("이미지 위치 Top")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.product.top,
                          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.values.product.top) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[52] || (_cache[52] = [
                        _createTextVNode("이미지 위치 Bottom")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.product.bottom,
                          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.values.product.bottom) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[53] || (_cache[53] = [
                        _createTextVNode("이미지 위치 Right")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          value: _ctx.values.product.right,
                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.values.product.right) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[58] || (_cache[58] = _createElementVNode("h4", null, "이미지 파일", -1)),
                _createVNode(_component_a_col, {
                  span: 24,
                  class: "element"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[54] || (_cache[54] = [
                        _createTextVNode("상품 이미지")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_image_input, {
                          value: _ctx.values.product?.image,
                          "onUpdate:value": _ctx.setImage
                        }, null, 8, ["value", "onUpdate:value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          span: 12,
          offset: 2
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              class: "preview-group"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, { style: {"margin-bottom":"30px"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { onClick: _ctx.download }, {
                      default: _withCtx(() => _cache[59] || (_cache[59] = [
                        _createTextVNode("이미지 다운로드")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-base",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-tomorrow",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "label-tomorrow",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-today",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "label-today",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-end",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description-end",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-end-button",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo-button",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header-button",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description-end-button",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "end-button",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-rolling-banner-start",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header-rolling-banner",
                        header: "첫번째 미션 시작",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description-rolling-banner",
                        description: "구매를 시작해주세요!",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { class: "image-row" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      id: "image-rolling-banner-end",
                      class: "image image-335x148",
                      style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                    }, [
                      _createVNode(_component_banner_creator_item, {
                        type: "logo",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "header-rolling-banner",
                        header: "마지막 미션 시작",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "description-rolling-banner",
                        description: "리뷰를 작성해주세요!",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "circle",
                        values: _ctx.values
                      }, null, 8, ["values"]),
                      _createVNode(_component_banner_creator_item, {
                        type: "product-image",
                        values: _ctx.values
                      }, null, 8, ["values"])
                    ], 4)
                  ]),
                  _: 1
                }),
                (_ctx.isOn)
                  ? (_openBlock(), _createBlock(_component_a_row, {
                      key: 0,
                      class: "image-row"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          id: "image-top-banner",
                          class: "image image-375x167",
                          style: _normalizeStyle({ backgroundColor: _ctx.values.color.bg })
                        }, [
                          _createVNode(_component_banner_creator_item, {
                            type: "logo-button",
                            values: _ctx.values
                          }, null, 8, ["values"]),
                          _createVNode(_component_banner_creator_item, {
                            type: "header-top-banner",
                            values: _ctx.values
                          }, null, 8, ["values"]),
                          _createVNode(_component_banner_creator_item, {
                            type: "description-end-button",
                            values: _ctx.values
                          }, null, 8, ["values"]),
                          _createVNode(_component_banner_creator_item, {
                            type: "circle-large",
                            values: _ctx.values
                          }, null, 8, ["values"]),
                          _createVNode(_component_banner_creator_item, {
                            type: "product-image",
                            values: _ctx.values
                          }, null, 8, ["values"]),
                          _createVNode(_component_banner_creator_item, {
                            type: "end-button",
                            values: _ctx.values
                          }, null, 8, ["values"])
                        ], 4)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}