import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { class: "remix" }
const _hoisted_3 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["menu-item", { 'is-active': _ctx.isActive ? _ctx.isActive() : null }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.action && _ctx.action(...args))),
    title: _ctx.title
  }, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("use", {
        "xlink:href": `${_ctx.iconUrl}#ri-${_ctx.icon}`
      }, null, 8, _hoisted_3)
    ]))
  ], 10, _hoisted_1))
}