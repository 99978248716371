import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationDevtool',
  setup(__props) {

const rules = {
    userName: {
        required: true,
    },
    title: {
        required: true,
    },
}

const inputSpec = ref<FormSpecification>({
    userName: {
        component: InputComponents.UserInput,
        label: '사용자',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
    },
    body: {
        label: '내용',
        value: null,
    },
    image: {
        component: InputComponents.ImageInput,
        label: '이미지',
        value: null,
    },
    description: {
        label: '설명',
        value: null,
    },
    link: {
        label: '링크',
        value: null,
    },
    type: {
        label: '타입',
        value: null,
    },
})

const loading = ref(false)

const onSubmit = async (form: Record<string, any>) => {
    loading.value = true
    await adminApi<ApiResponse<void>>(
        `/api/devtool/notification?${stringify(form)}`
    )
    loading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_a_page_header = _resolveComponent("a-page-header")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_page_header, { title: "앱 알림 개발자 도구" }),
    _createVNode(PForm, {
      title: "앱 알림 개발자 도구",
      rules: rules,
      "input-spec": inputSpec.value,
      loading: loading.value,
      onSubmit: onSubmit
    }, {
      button: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" 보내기 ")
      ])),
      _: 1
    }, 8, ["input-spec", "loading"])
  ]))
}
}

})