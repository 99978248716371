<template>
    <a-button @click="showModal">
        상세
        <a-modal
            :title="title"
            v-model:visible="visible"
            :footer="null"
            width="800px"
        >
            <slot
                name="header"
                :resource-id="resourceId"
                :record="record"
                :fetch-resource="fetchResource"
            />
            <slot
                name="body"
                :resource-id="resourceId"
                :record="record"
                :fetch-resource="fetchResource"
            />
            <slot
                name="footer"
                :resource-id="resourceId"
                :record="record"
                :fetch-resource="fetchResource"
            />
        </a-modal>
        <FailResultModal ref="failResultModal" :error-message="errorMessage" />
    </a-button>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineComponent, ref } from 'vue'
import FailResultModal from './FailResultModal.vue'

export default defineComponent({
    name: 'DetailModal',

    components: {
        FailResultModal,
    },

    props: {
        resourceId: {
            type: Number,
        },
        uri: {
            type: String,
        },
        title: {
            type: String,
        },
    },

    setup() {
        const record = ref({})
        const failResultModal = ref()
        const errorMessage = ref('')
        const visible = ref(false)

        const openFailModal = () => {
            failResultModal.value?.open()
        }
        const setRecord = (value: Record<any, any>) => {
            record.value = value
        }
        const setErrorMessage = (message: string) => {
            errorMessage.value = message
        }

        return {
            record,
            visible,
            failResultModal,
            errorMessage,
            setErrorMessage,
            setRecord,
            openFailModal,
        }
    },

    methods: {
        async fetchResource() {
            const response = await adminApi<ApiResponse<Record<any, any>>>(
                `${this.uri}/${this.resourceId}`
            )

            if (response.success === undefined || response.success === false) {
                this.setErrorMessage(
                    response.errorMessage || '예외 발생 - 개발자에게 문의하세요'
                )
                this.openFailModal()
            }

            this.setRecord(response.result || {})
            console.log(this.record)
        },

        showModal() {
            this.setRecord({})
            this.visible = !this.visible
            if (this.visible === true) {
                this.fetchResource().then()
            }
        },
    },
})
</script>
