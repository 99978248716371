<template>
    <a-row v-if="schedule">
        <a-col :span="24">
            <span
                class="day-of-week"
                v-for="(item, index) in schedule.repeatDayOfWeeks"
                :key="index"
            >
                <span class="item weekday" v-if="item === 'MONDAY'">월</span>
                <span class="item weekday" v-if="item === 'TUESDAY'">화</span>
                <span class="item weekday" v-if="item === 'WEDNESDAY'">수</span>
                <span class="item weekday" v-if="item === 'THURSDAY'">목</span>
                <span class="item weekday" v-if="item === 'FRIDAY'">금</span>
                <span class="item saturday" v-if="item === 'SATURDAY'">토</span>
                <span class="item sunday" v-if="item === 'SUNDAY'">일</span>
            </span>
        </a-col>
        <a-col v-if="schedule.repeatStartTime" :span="24">
            <span class="repeat start">{{ schedule.repeatStartTime }}</span>
            ~
            <span class="repeat end">{{ schedule.repeatEndTime }}</span>
        </a-col>
    </a-row>
</template>

<style scoped>
.item {
    font-size: 12px;
    font-weight: 700;
    padding: 4px 6px;
    margin-right: 3px;
    border-radius: 6px;
}
.day-of-week:last-child {
    margin-right: 0px;
}
.item.weekday {
    color: #767781;
    border: 1px solid #a5a6aa;
    padding: 3px 5px;
}
.item.saturday {
    color: #ffffff;
    background: #3d86f3;
}
.item.sunday {
    color: #ffffff;
    background: #ff5454;
}
.repeat {
    font-size: 12px;
    padding: 3px 6px;
    margin-top: 6px;
}
.repeat.start {
    margin-right: 6px;
}
.repeat.end {
    margin-left: 6px;
}
</style>
<script lang="ts">
import { defineComponent, toRef } from 'vue'

export default defineComponent({
    name: 'AdRepeat',

    props: {
        instance: {
            type: Object || Array || null,
        },
    },

    setup(props: any) {
        const instanceRef = toRef(props, 'instance')
        return {
            schedule: instanceRef.value.schedule,
        }
    },
})
</script>
