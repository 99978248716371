<template>
    <ResourceTable
        title="필라이즈 프레스"
        uri="/api/press"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        load-on-mount
    >
        <template #detail="{ record }">
            <a-descriptions
                title="프레스 상세"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="제목">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="발행자">
                    {{ record.publisher }}
                </a-descriptions-item>
                <a-descriptions-item label="기사 링크">
                    {{ record.url }}
                </a-descriptions-item>
                <a-descriptions-item label="발행일자">
                    {{ record.publishedAt }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일시">
                    {{ record.creationTime }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'PillyzePress',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            title: { label: '제목', value: null },
            publisher: { label: '발행자', value: null },
            url: { label: 'URL', value: null },
            publishedAt: {
                label: '발행일',
                value: null,
                component: InputComponents.ADatetimePicker,
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            filterSpec: {
                title: {
                    label: '제목',
                    value: null,
                },
            },
            createSpec,
            updateSpec: { ...createSpec },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                {
                    title: '제목',
                    dataIndex: 'title',
                },
                {
                    title: '발행자',
                    dataIndex: 'publisher',
                },
                {
                    title: '기사 링크',
                    dataIndex: 'url',
                },
                {
                    title: '발행일자',
                    dataIndex: 'publishedAt',
                    customRender: formatLocalDateTime,
                    width: 100,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    width: 100,
                },
                {
                    title: '상세',
                    dataIndex: 'detail',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    type: 'detail',
                },
            ],
            rules: {
                title: [required],
                summary: [required],
                content: [required],
                columnType: [required],
                category: [required],
                thumbnailUrl: [required],
                isActive: [required],
                isPopular: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'column-type',
            'hig-broad-name',
            'column-category',
        ])
    },
})
</script>
