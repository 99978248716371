<template>
    <a-form ref="formRule" :model="form">
        <a-row>
            <a-descriptions :bordered="true" :column="1">
                <a-descriptions-item label="시작/종료">
                    <a-range-picker
                        show-time
                        value-format="YYYY-MM-DD HH:mm:ss"
                        :minuteStep="5"
                        :secondStep="60"
                        :value="dateRange"
                        @change="changeDateRange"
                    />
                </a-descriptions-item>
                <a-descriptions-item label="반복 선택">
                    <a-row>
                        <a-col :span="24">
                            <div>
                                <a-checkbox
                                    :checked="checkAll"
                                    @change="changeCheckAll"
                                >
                                    모두 선택
                                </a-checkbox>
                            </div>
                            <div>
                                <a-checkbox-group
                                    :value="form.schedule.repeatDayOfWeeks"
                                    :options="dayOfWeekOptions"
                                    @change="changeCheck"
                                />
                            </div>
                        </a-col>
                        <a-divider style="margin: 12px 0px" />
                        <a-col :span="24">
                            <a-time-range-picker
                                :minuteStep="5"
                                :secondStep="60"
                                :value="timeRange"
                                valueFormat="HH:mm:ss"
                                @change="changeTimeRange"
                            />
                        </a-col>
                    </a-row>
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
    </a-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import globalFormState from '@/components/globalFormState'
import { isEmpty } from 'lodash'

export default defineComponent({
    name: 'AdScheduleInput',

    components: {},

    setup() {
        const dayOfWeekOptions = [
            { label: '월', value: 'MONDAY' },
            { label: '화', value: 'TUESDAY' },
            { label: '수', value: 'WEDNESDAY' },
            { label: '목', value: 'THURSDAY' },
            { label: '금', value: 'FRIDAY' },
            { label: '토', value: 'SATURDAY' },
            { label: '일', value: 'SUNDAY' },
        ]
        return {
            form: globalFormState.form,
            dayOfWeekOptions,
        }
    },

    computed: {
        checkAll() {
            return this.form.schedule.repeatDayOfWeeks.length === 7 || 0
        },
        dateRange() {
            return [this.form.schedule.startTime, this.form.schedule.endTime]
        },
        timeRange() {
            return [
                this.form.schedule.repeatStartTime,
                this.form.schedule.repeatEndTime,
            ]
        },
    },

    methods: {
        changeDateRange(date: [string, string]) {
            const size: number = date?.length || 0
            console.log('date : ', date, size)
            if (isEmpty(date)) {
                this.form.schedule.startTime = null
                this.form.schedule.endTime = null
                return
            }
            const start = date[0]
            const end = date[1]
            this.form.schedule.startTime = start
            this.form.schedule.endTime = end
        },
        changeCheckAll(e: any) {
            const checked = e.target.checked
            const all = this.dayOfWeekOptions.map((obj: any) => obj.value)
            this.form.schedule.repeatDayOfWeeks = checked ? all : []
        },
        changeCheck(values: any) {
            this.form.schedule.repeatDayOfWeeks = values
        },
        changeTimeRange(_: [string, string], time: [string, string]) {
            this.form.schedule.repeatStartTime = time[0]
            this.form.schedule.repeatEndTime = time[1]
        },
    },
})
</script>
