<template>
    <span>
        <a-form-item :name="name">
            <a-select
                :options="options"
                :value="value"
                @change="change"
                @select="(value: any) => $emit('select', value)"
                style="minwidth: 60px; width: 93px"
                show-search
            />
            <a-button
                v-if="showReload"
                @click="refreshOptions"
                style="marginleft: 5px"
                ><reload-outlined
            /></a-button>
        </a-form-item>
    </span>
</template>

<script lang="ts">
import { SelectOption } from '@/store/enumType'
import { defineComponent } from 'vue'
import { ReloadOutlined } from '@ant-design/icons-vue'
import { useStandardUnitStore } from '@/store/standardUnit'

type StandardUnit = {
    code: string
    unitExpression: string
    unitName: string
}

export default defineComponent({
    name: 'StandardUnitInput',
    data() {
        return {
            test: null,
        }
    },
    components: {
        ReloadOutlined,
    },

    props: {
        showReload: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
        name: {
            type: String,
        },
    },

    setup() {
        const standardUnitStore = useStandardUnitStore()

        return {
            standardUnitStore,
        }
    },

    emits: ['select', 'update:value'],

    computed: {
        options(): Array<SelectOption> {
            return this.standardUnitStore.standardUnit.map(
                (it: StandardUnit) => ({
                    label: it.unitExpression,
                    value: it.code,
                    key: it.code,
                })
            )
        },
    },

    methods: {
        refreshOptions() {
            this.standardUnitStore.fetchStandardUnit()
        },
        change(value: any) {
            return this.$emit('update:value', value)
        },
    },
})
</script>
