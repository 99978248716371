import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_replace_modal = _resolveComponent("replace-modal")!
  const _component_resource_table = _resolveComponent("resource-table")!

  return (_openBlock(), _createBlock(_component_resource_table, {
    title: "신호등 Flag 관리",
    uri: "/api/intake-advisor",
    columns: _ctx.columns,
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    scroll: { x: 1800, y: 650 },
    exportable: "",
    deletable: ""
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_replace_modal, {
        title: "사유 변경",
        label: "사유 변경",
        uri: "/api/intake-advisor/reason",
        "input-spec": _ctx.reasonReplaceSpec
      }, null, 8, ["input-spec"])
    ]),
    _: 1
  }, 8, ["columns", "filter-spec", "create-spec", "update-spec", "create-rules", "update-rules"]))
}