import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "slot-tabs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ad_header_input = _resolveComponent("ad-header-input")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tabs, {
      activeKey: _ctx.slotIdProps,
      "onUpdate:activeKey": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.slotIdProps) = $event)),
      onChange: _ctx.changeSlot
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slotList, (slot) => {
          return (_openBlock(), _createBlock(_component_a_tab_pane, {
            key: slot.id,
            tab: slot.name
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                class: "slot-tabs-container",
                span: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        class: "slot-main",
                        span: 24
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ad_header_input, {
                            header: "최소빌드넘버",
                            type: "select",
                            value: _ctx.minBuildNumber,
                            options: _ctx.minBuildNumberOptions,
                            onChange: _cache[0] || (_cache[0] = (value) => _ctx.changeInput('change:minBuildNumber', value))
                          }, null, 8, ["value", "options"]),
                          (slot.page === 'reward')
                            ? (_openBlock(), _createBlock(_component_ad_header_input, {
                                key: 0,
                                header: "(개발Only)미션정보",
                                type: "select",
                                value: _ctx.dailyMissionType,
                                options: _ctx.dailyMissionTypeOptions,
                                onChange: _cache[1] || (_cache[1] = (value) => _ctx.changeInput('change:dailyMissionType', value))
                              }, null, 8, ["value", "options"]))
                            : _createCommentVNode("", true),
                          (slot.page === 'reward')
                            ? (_openBlock(), _createBlock(_component_ad_header_input, {
                                key: 1,
                                header: "(개발Only)응답정보",
                                type: "select",
                                value: _ctx.voType,
                                options: _ctx.voTypeOptions,
                                onChange: _cache[2] || (_cache[2] = (value) => _ctx.changeInput('change:voType', value))
                              }, null, 8, ["value", "options"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, {
                        class: "slot-sub",
                        span: 24
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ad_header_input, {
                            header: "상태",
                            type: "select",
                            value: _ctx.status,
                            options: _ctx.statusOptions,
                            onChange: _ctx.changeStatus
                          }, null, 8, ["value", "options", "onChange"]),
                          (slot.page === 'reward')
                            ? (_openBlock(), _createBlock(_component_ad_header_input, {
                                key: 0,
                                header: "리워드 수",
                                type: "number",
                                value: _ctx.rewardCount,
                                onChange: _ctx.changeRewardCount
                              }, null, 8, ["value", "onChange"]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_a_button, {
                            type: "primary",
                            onClick: _ctx.patchStatus
                          }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" 설정 변경 ")
                            ])),
                            _: 1
                          }, 8, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["tab"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["activeKey", "onChange"])
  ]))
}