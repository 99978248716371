<template>
    <ResourceTable
        title=""
        class="benefit-screen-table"
        uri="/api/advertisements/benefit"
        :columns="columns"
        :scroll="{ x: 'max-content', y: 1400 }"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        load-on-mount
    >
        <template #actions>
            <a-button @click="() => add()">광고 추가</a-button>
        </template>
    </ResourceTable>
</template>

<style scoped>
.benefit-screen-table {
    padding: 15px;
}
</style>
<script lang="ts">
import { defineComponent, Ref, ref, toRef } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatLocalDateTime } from '@/util/formmater'

export default defineComponent({
    name: 'AdBenefitTable',

    components: {
        ResourceTable,
    },

    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedRows: Ref<Array<any>> = ref([])
        const columns = [
            {
                title: 'ID',
                dataIndex: 'publishId',
                align: 'center',
                width: '64px',
                fixed: 'left',
            },
            {
                title: '이름',
                dataIndex: 'slotName',
                width: '256px',
            },
            {
                title: '광고 수',
                dataIndex: 'instanceCount',
                width: '64px',
                align: 'center',
            },
            {
                title: '기본 광고 수',
                dataIndex: 'baseInstanceCount',
                width: '64px',
                align: 'center',
            },
            {
                title: '시작',
                dataIndex: 'startTime',
                align: 'center',
                width: '128px',
                customRender: formatLocalDateTime,
            },
            {
                title: '종료',
                dataIndex: 'endTime',
                align: 'center',
                width: '128px',
                customRender: formatLocalDateTime,
            },
        ]

        return {
            columns,
            selectedRowKeys,
            selectedRows,
        }
    },

    emits: ['update:add'],

    methods: {
        onSelectChange(
            selectedRowKeys: Array<number>,
            selectedRows: Array<any>
        ) {
            console.log('sc : ', selectedRowKeys, selectedRows)
            this.selectedRows = selectedRows
            this.selectedRowKeys = selectedRowKeys
        },
        add() {
            this.$emit('update:add', this.selectedRows)
            this.selectedRows = []
            this.selectedRowKeys = []
        },
    },
})
</script>
