<template>
    <ResourceTable
        title="필라이즈 팁"
        uri="/api/product-consumer-guide"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :scroll="{ x: true }"
        :columns="columns"
        load-on-mount
    >
    </ResourceTable>
</template>

<script lang="ts">
import { FormSpecification } from '@/components/InputMapper.vue'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ConsumerGuide',
    mixins: [TableSupport],
    components: {
        ResourceTable,
    },

    setup() {
        const selectedRowKeys: Array<number> = []
        const loading = false

        return {
            selectedRowKeys,
            loading,
        }
    },

    data() {
        const createSpec: FormSpecification = {
            name: { label: '분류 이름', value: null },
        }

        const updateSpec = { ...this.createSpec }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 10,
            },
            {
                title: '분류 이름',
                dataIndex: 'name',
                width: 80,
            },
            {
                title: '팁 개수',
                dataIndex: 'countDocs',
                width: 20,
            },
            {
                title: '연결 제품수',
                dataIndex: 'countProducts',
                width: 20,
            },
        ]

        return { createSpec, updateSpec, columns }
    },
})
</script>
