<template>
    <a-card title="사용자/제품 관련 영양소 분석 요약">
        <div id="nutrient-analyze-report">
            <a-card
                class="nutrient-analyze-report-item"
                v-for="re of report"
                :key="re.nutrientAllowanceGroupId"
                :title="re.nutrientAllowanceGroupName"
            >
                <template #extra
                    >{{ (re.recommended && '추천') || '비추천' }}
                </template>
                <a-list item-layout="vertical">
                    <a-list-item>
                        <a-list-item-meta title="섭취량" />
                        <a-typography-text
                            >{{ `${re.udi} ${re.unit}` }}
                        </a-typography-text>
                    </a-list-item>
                    <a-list-item>
                        <a-list-item-meta title="권장섭취량" />
                        <a-slider
                            :value="re.udi"
                            :marks="{
                                [re.udi]: `${re.udi}${re.unit}`,
                                [re.rda || re.higMin]: {
                                    style: { color: 'orange' },
                                    label: `${re.rda || re.higMin}${re.unit}`,
                                },
                                [maxByMin(
                                    re.rda || re.higMin,
                                    re.udi,
                                    re.ul || re.higMax
                                )]: {
                                    style: { color: '#f50' },
                                    label: `${parseMax(re.ul || re.higMax)}${
                                        re.unit
                                    }`,
                                },
                            }"
                            :max="
                                maxByMin(
                                    re.rda || re.higMin,
                                    re.udi,
                                    re.ul || re.higMax
                                )
                            "
                            :step="0.1"
                        />
                    </a-list-item>
                    <a-list-item>
                        <a-list-item-meta title="사유" />
                        <a-typography-text>{{ re.reason }}</a-typography-text>
                    </a-list-item>
                </a-list>
            </a-card>
        </div>
        <a-button style="float: right" @click="fetchReport"
            >분석 요약 보기
        </a-button>
    </a-card>
</template>

<style lang="scss" scoped>
#nutrient-analyze-report {
    max-height: 50em;
    overflow: scroll;
}

.nutrient-analyze-report-item {
    width: 50em;
}

.ant-card {
    margin: auto auto 2em auto;
}
</style>

<script setup lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { ref, defineProps, defineExpose } from 'vue'

const props = defineProps({
    userId: {
        type: Number,
        required: true,
    },
    productIds: {
        type: Array,
        required: true,
    },
})

const report = ref<Record<any, any>>({})

const fetchReport = async () => {
    const response = await adminApi<ApiResponse<Record<any, any>>>(
        `/api/v1/questions/nutrient-analyze-report/${props.userId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productIds: props.productIds }),
        }
    )
    report.value = response.result || {}
}

const maxByMin = (min: number, udi: number, max: number) => {
    if (max >= 9999999999999) {
        const minM2 = (udi || min) * 1.2
        return minM2
    }
    return max
}

const parseMax = (max: number) => {
    if (max >= 9999999999999) {
        return '∞'
    }
    return max
}

defineExpose({
    fetchReport,
})
</script>
