import * as constants from '@/constants'

export const stoplightFormChangeHook = (
    form: Record<any, any>,
    formSpec: any
) => {
    const servingSize = form.servingSize || 0
    const calorie = form.calorie || 0
    const carbohydrate =
        form.nutrients?.filter((it: any) => it.nutrientName == '탄수화물').pop()
            ?.amount || 0
    const protein =
        form.nutrients?.filter((it: any) => it.nutrientName == '단백질').pop()
            ?.amount || 0
    const fat =
        form.nutrients?.filter((it: any) => it.nutrientName == '지방').pop()
            ?.amount || 0
    const saturatedFat =
        form.nutrients
            ?.filter((it: any) => it.nutrientName == '포화지방산')
            .pop()?.amount || 0
    const sugar =
        form.nutrients?.filter((it: any) => it.nutrientName == '당류').pop()
            ?.amount || 0
    const natrum =
        form.nutrients?.filter((it: any) => it.nutrientName == '나트륨').pop()
            ?.amount || 0
    const alchol =
        form.nutrients?.filter((it: any) => it.nutrientName == '알코올').pop()
            ?.amount || 0

    const pillyzeFoodType = form.pillyzeFoodType
    const categoryBias = form.categoryBias

    const cpfSum = carbohydrate + protein + fat
    const carbohydrateRatio = carbohydrate / cpfSum
    const proteinRatio = protein / cpfSum
    const dataQuality = form.dataQuality
    const status = form.status || 'NORMAL'
    const countOfNutrients = form.nutrients?.length || 0
    const carbohydrateExists =
        form.nutrients?.filter((it: any) => it.nutrientName == '탄수화물')
            .length > 0
    const proteinExists =
        form.nutrients?.filter((it: any) => it.nutrientName == '단백질')
            .length > 0
    const fatExists =
        form.nutrients?.filter((it: any) => it.nutrientName == '지방').length >
        0

    if (formSpec.stoplight.value != null) {
        return
    }

    if (categoryBias == 'DEFAULT') {
        formSpec.stoplight.value = 'DEFAULT'
        formSpec.stoplightReason.value = '참기름 혹은 들기름'
    } else if (pillyzeFoodType == 'NUTRIENT') {
        formSpec.stoplight.value = 'UNKNOWN'
        formSpec.stoplightReason.value = '식품 유형 영양제'
    } else if (
        ['SEVERE', 'BAD'].includes(dataQuality) ||
        categoryBias == 'UNKNOWN'
    ) {
        formSpec.stoplight.value = 'UNKNOWN'
        formSpec.stoplightReason.value = '데이터 품질이 나쁨'
    } else if (status != 'NORMAL') {
        formSpec.stoplight.value = 'UNKNOWN'
        formSpec.stoplightReason.value = '상태가 비정상'
    } else if (countOfNutrients <= 4) {
        formSpec.stoplight.value = 'UNKNOWN'
        formSpec.stoplightReason.value = '영양소가 4개 이하'
    } else if (!(carbohydrateExists && proteinExists && fatExists)) {
        formSpec.stoplight.value = 'UNKNOWN'
        formSpec.stoplightReason.value = '탄단지가 모두 존재하지 않음'
    } else if (categoryBias == 'NOT_RECOMMEND') {
        formSpec.stoplight.value = 'NOT_RECOMMEND'
        formSpec.stoplightReason.value = '카테고리가 나쁜 카테고리'
    } else if (saturatedFat >= 7 && saturatedFat / fat >= 0.7) {
        formSpec.stoplight.value = 'NOT_RECOMMEND'
        formSpec.stoplightReason.value = '포화지방이 지방의 70% 이상'
    } else if (sugar >= 30) {
        formSpec.stoplight.value = 'NOT_RECOMMEND'
        formSpec.stoplightReason.value = '당류가 30g 이상'
    } else if (natrum >= 1500) {
        formSpec.stoplight.value = 'NOT_RECOMMEND'
        formSpec.stoplightReason.value = '나트륨이 1.5g 이상'
    } else if (
        (pillyzeFoodType == 'DRINK' ||
            categoryBias == 'NOT_RECOMMEND_IF_HIGH_SUGAR') &&
        sugar > 10
    ) {
        formSpec.stoplight.value = 'NOT_RECOMMEND'
        formSpec.stoplightReason.value = '음료 카테고리가 나쁜 카테고리'
    } else if (pillyzeFoodType == 'ALCOHOLIC_DRINK' || alchol > 0) {
        formSpec.stoplight.value = 'NOT_RECOMMEND'
        formSpec.stoplightReason.value = '알코올이 0g 이상'
    } else if (calorie / servingSize <= 1) {
        formSpec.stoplight.value = 'RECOMMEND'
        formSpec.stoplightReason.value = '1회 제공량 대비 열량이 적음'
    } else if (
        1 < calorie / servingSize &&
        calorie / servingSize <= 1.5 &&
        carbohydrateRatio < 0.5
    ) {
        formSpec.stoplight.value = 'RECOMMEND'
        formSpec.stoplightReason.value = '1회 제공량 대비 열량이 적음'
    } else if (proteinRatio >= 0.5) {
        formSpec.stoplight.value = 'RECOMMEND'
        formSpec.stoplightReason.value = '단백질이 50% 이상'
    } else if (pillyzeFoodType == 'PROTEIN_PRODUCT' && sugar <= 10) {
        formSpec.stoplight.value = 'RECOMMEND'
        formSpec.stoplightReason.value = '단백질 제품이면서 당류가 10g 이하'
    } else {
        formSpec.stoplight.value = 'DEFAULT'
    }
}
