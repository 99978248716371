<template>
    <a-modal
        width="50%"
        v-model:open="isShowModal"
        title="이벤트 인증"
        @cancel="closeModal"
        @ok="closeModal"
    >
        <a-spin
            v-if="loading"
            style="
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            "
        />
        <a-row v-if="!loading">
            <a-row class="section">
                <a-col :span="12" style="padding-right: 5px">
                    <a-image :src="imageUrl" :key="imageUrl" :preview="false" />
                </a-col>
                <a-col :span="12" style="padding-left: 5px">
                    <a-descriptions
                        title=""
                        :bordered="true"
                        layout="vertical"
                        :column="2"
                        size="small"
                    >
                        <a-descriptions-item label="참가자 이름">
                            {{ eventUserName || '-' }}
                        </a-descriptions-item>
                        <a-descriptions-item label="참가자 ID">
                            {{ eventUserId }}
                        </a-descriptions-item>
                        <a-descriptions-item label="이벤트 명" :span="2">
                            {{ event.title }}
                        </a-descriptions-item>
                        <a-descriptions-item label="리뷰 종료일" :span="2">
                            {{ event.promotedReviewEndAt }}
                        </a-descriptions-item>
                        <a-descriptions-item label="페이백 지급일" :span="2">
                            {{ event.paybackAt }}
                        </a-descriptions-item>
                    </a-descriptions>
                </a-col>
            </a-row>
            <a-row class="section">
                <a-col :span="24" style="display: flex; flex-wrap: wrap">
                    <a-col
                        :span="6"
                        v-for="item in buttonGroup"
                        :key="item.value"
                        style="padding: 5px"
                    >
                        <a-button
                            style="width: 100%"
                            :class="{
                                status: true,
                                'status-selected':
                                    eventUserVerificationStatus === item.value,
                            }"
                            @click="() => updateStatus(id, item.value)"
                        >
                            {{ item.label }}
                        </a-button>
                    </a-col>
                </a-col>
            </a-row>
        </a-row>
    </a-modal>
</template>

<style scoped>
.section {
    width: 100%;
    padding-bottom: 12px;
}
.status {
    font-size: 12px;
    font-weight: 500;
}
.status-selected {
    color: white;
    background-color: #6dafcc;
}
</style>
<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import { getApi, patchApi } from '@/fetchTemplate'

export default defineComponent({
    name: 'EventUserVerificationModal',

    props: {
        isShowModalProps: {
            type: Boolean,
        },
        imageUrlProps: {
            type: String,
        },
        idProps: {
            type: Number,
        },
        eventIdProps: {
            type: Number,
        },
        eventNameProps: {
            type: String,
        },
        eventUserIdProps: {
            type: Number,
        },
        eventUserNameProps: {
            type: String,
        },
        eventUserVerificationStatusProps: {
            type: String,
        },
    },

    setup(props: any) {
        const isShowModal = toRef(props, 'isShowModalProps')
        const imageUrl = toRef(props, 'imageUrlProps')
        const id = toRef(props, 'idProps')
        const event = ref()
        const eventId = toRef(props, 'eventIdProps')
        const eventName = toRef(props, 'eventNameProps')
        const eventUserId = toRef(props, 'eventUserIdProps')
        const eventUserName = toRef(props, 'eventUserNameProps')
        const eventUserVerificationStatus = toRef(
            props,
            'eventUserVerificationStatusProps'
        )
        const eventVerificationStatus = ref()

        const loading = ref(false)

        const buttonGroup = ref([
            { label: '당첨', value: 'WINNER' },
            { label: '미당첨', value: 'NON_WINNER' },
            { label: '미구매자', value: 'PURCHASE_REQUIRED' },
            { label: '구매완료', value: 'PURCHASE_COMPLETE' },
            { label: '리뷰미작성', value: 'REVIEW_REQUIRED' },
            { label: '리뷰완료', value: 'REVIEW_COMPLETE' },
            { label: '구매보완요청', value: 'REQUEST_PURCHASE_COMPLETE' },
            { label: '리뷰보완요청', value: 'REQUEST_REVIEW_COMPLETE' },
            { label: '페이백완료', value: 'PAYBACK_COMPLETE' },
        ])

        return {
            isShowModal,
            imageUrl,
            id,
            event,
            eventId,
            eventName,
            eventUserId,
            eventUserName,
            eventUserVerificationStatus,
            eventVerificationStatus,
            loading,
            buttonGroup,
        }
    },

    watch: {
        eventId(newValue: any, oldValue: any) {
            this.getEvent()
        },
    },

    emits: ['modal:close'],

    methods: {
        closeModal() {
            this.$emit('modal:close')
        },
        updateStatus(id: number, status: any) {
            this.patchVerification(id, status)
        },
        async getEvent() {
            if (this.eventId === undefined) {
                return
            }
            this.loading = true
            const url = `/api/v1/events/${this.eventId}`
            const response = await getApi(url)
            this.event = response?.result
            this.eventVerificationStatus = this.event?.verificationStatus
            this.loading = false
        },
        async patchVerification(id: number, status: any) {
            const url = `/api/v1/event-users/verification-status`
            const payload = {
                idList: [id],
                status: status,
            }
            this.loading = true
            await patchApi(url, payload, true, '상태값 변경 실패')
            this.loading = false
        },
    },

    mounted() {
        this.getEvent()
    },
})
</script>
