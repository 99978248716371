<template>
    <a-row class="overlay" v-if="loading">
        <a-spin />
    </a-row>
    <a-row>
        <a-col :span="6" class="preview">
            <div class="user-event">
                <a-button type="primary" class="btn-save" @click="save">
                    저장
                </a-button>
            </div>
            <ad-benefit-screen-current :props-list="list" @remove="remove" />
        </a-col>
        <a-col :span="18">
            <ad-benefit-screen-table @update:add="updatePublishGroups" />
        </a-col>
    </a-row>
</template>

<style scoped>
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9999;
}
.preview {
    border-right: 1px solid #e8e8e8;
}
</style>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import AdBenefitScreenCurrent from '@/views/ad/benefit/AdBenefitScreenCurrent.vue'
import AdBenefitScreenTable from '@/views/ad/benefit/AdBenefitScreenTable.vue'
import { getApi, postApi } from '@/fetchTemplate'

export default defineComponent({
    name: 'AdBenefitScreen',

    components: {
        AdBenefitScreenCurrent,
        AdBenefitScreenTable,
    },

    setup() {
        const list = ref([] as any[])
        const loading = ref(false)
        return {
            list,
            loading,
        }
    },

    methods: {
        updatePublishGroups(list: any[]) {
            this.addBenefit(list)
        },
        remove(index: number) {
            this.list.splice(index, 1)
        },
        async addBenefit(ids: any[]) {
            if (!(ids && ids.length > 0)) {
                return
            }
            const params = new URLSearchParams()
            ids.forEach((obj: any) => params.append('ids', obj.id))
            const url = `/api/advertisements/benefit/add?${params.toString()}`
            const response = await getApi(url)
            const result = response?.result || []
            this.list.push(...result)
        },
        async fetchBenefits() {
            const url = `/api/advertisements/benefit/current`
            const response = await getApi(url)
            const result = response?.result || []
            this.list.push(...result)
        },
        async save() {
            if (this.list.length === 0) {
                alert('설정된 값이 없습니다!')
                return
            }
            const orders = this.list.map((it: any, index: number) => {
                return {
                    id: it.publish.id,
                    orderNo: index,
                }
            })
            const payload = {
                screen: 'benefit',
                orders: orders,
            }
            this.loading = true
            const url = '/api/advertisements/publish-groups'
            await postApi(url, payload, true, '업데이트 실패!')
            this.loading = false
        },
    },

    mounted() {
        console.log('mounted')
        this.fetchBenefits()
    },
})
</script>
