import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ApiResponse, adminApi, mlApi } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import { ref, toRef } from 'vue'
import { FormOutlined } from '@ant-design/icons-vue'
import { ChatCompletionRequest, ChatMessage } from './inputComplete'
import { debounce } from 'lodash'


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCompleteInput',
  props: {
    name: {
        type: String,
    },
    value: {
        type: String,
    },
    chatMessage: {
        type: Array,
    },
},
  emits: ['update:value', 'change'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const messages = toRef(props, 'chatMessage')

const request = ref<ChatCompletionRequest>({
    model: 'gpt-3.5-turbo',
    messages: [],
    topP: 1,
})

const loading = ref(false)
const tokenLength = ref(0)

const onRequest = async () => {
    try {
        loading.value = true
        if (messages.value != null && messages.value.length > 0) {
            const prompts = messages.value as ChatMessage[]
            request.value.messages = [
                ...prompts,
                { role: 'user', content: props.value || '' },
            ]
        }
        const response = await adminApi<ApiResponse<any>>(
            `/api/openai/chat-completion`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request.value),
            }
        )
        emits('change', JSON.parse(response.result.choices[0].message.content))
    } catch (e) {
        const error = e as Error
        message.error(error.message)
    } finally {
        loading.value = false
    }
}

const onChange = (e: any) => {
    emits('update:value', e.target.value)
    onChangeMessage(e)
}

const onChangeMessage = debounce(async (event: any) => {
    if (event.target.value) {
        const response = await mlApi(`/open-ai/token-length`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: event.target.value }),
        })
        tokenLength.value = response
    }
}, 500)

return (_ctx: any,_cache: any) => {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createBlock(_component_a_space, { direction: "vertical" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_radio_group, {
        value: request.value.model,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((request.value.model) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_radio, { value: "gpt-3.5-turbo" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("gpt-3")
            ])),
            _: 1
          }),
          _createVNode(_component_a_radio, { value: "gpt-4" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("gpt-4")
            ])),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value"]),
      _createVNode(_component_a_textarea, {
        value: __props.value,
        onChange: onChange,
        rows: 8
      }, null, 8, ["value"]),
      _createVNode(_component_a_row, { style: {"justify-content":"end"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_typography_text, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`token 수: ${tokenLength.value}`), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, {
        onClick: onRequest,
        style: {"float":"right"},
        loading: loading.value
      }, {
        icon: _withCtx(() => [
          _createVNode(_unref(FormOutlined))
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _: 1
  }))
}
}

})