import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_button = _resolveComponent("modal-button")!

  return (_openBlock(), _createBlock(_component_modal_button, _mergeProps({ ref: "modalButtonRef" }, _ctx.$props, {
    rules: _ctx.formRules,
    onSubmit: _ctx.onSubmit,
    onAfterShow: _ctx.initForm
  }), {
    button: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" 답변 ")
    ])),
    _: 1
  }, 16, ["rules", "onSubmit", "onAfterShow"]))
}