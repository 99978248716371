import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"float":"right"} }

import UpdateForm from '../UpdateForm.vue'
import { ref } from 'vue'
import {
    FullscreenOutlined,
    FullscreenExitOutlined,
} from '@ant-design/icons-vue'
import router from '@/router'



export default /*@__PURE__*/_defineComponent({
  __name: 'FormModal',
  props: {
    uri: {
        type: String,
        required: true,
    },
    resourceIds: {
        type: Array,
    },
    resourceId: {
        type: [Number, String],
    },
    record: {
        type: Object,
    },
    title: {
        type: String,
        default: '',
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    rules: {
        type: Object,
    },
    icon: {
        type: String,
    },
    inputSpec: {
        type: Object,
        default: () => ({}),
    },
},
  emits: ['submit', 'afterShow', 'afterSubmit', 'resetForm'],
  setup(__props, { expose: __expose }) {



const visible = ref(true)
const expand = ref(false)
const form = ref(null)

__expose({ form })

const toggleExpand = () => {
    expand.value = !expand.value
}



const onClose = () => {
    visible.value = !visible.value
    router.back()
}

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    "wrap-class-name": (expand.value && 'full-modal') || null,
    title: __props.title,
    visible: visible.value,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((visible).value = $event)),
    footer: null,
    width: (expand.value && '100%') || '80em',
    onCancel: onClose
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header", {
        record: __props.record,
        resourceIds: __props.resourceIds,
        resourceId: __props.resourceId
      }),
      _createElementVNode("span", _hoisted_1, [
        (!expand.value)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              onClick: toggleExpand
            }, {
              icon: _withCtx(() => [
                _createVNode(_unref(FullscreenOutlined))
              ]),
              _: 1
            }))
          : (expand.value)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                onClick: toggleExpand
              }, {
                icon: _withCtx(() => [
                  _createVNode(_unref(FullscreenExitOutlined))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
      ]),
      _createVNode(UpdateForm, {
        ref_key: "form",
        ref: form,
        uri: __props.uri,
        "resource-id": __props.resourceId,
        "input-spec": __props.inputSpec,
        rules: __props.rules
      }, null, 8, ["uri", "resource-id", "input-spec", "rules"]),
      _renderSlot(_ctx.$slots, "footer", {
        record: __props.record,
        resourceIds: __props.resourceIds,
        resourceId: __props.resourceId
      })
    ]),
    _: 3
  }, 8, ["wrap-class-name", "title", "visible", "width"]))
}
}

})