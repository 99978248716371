<template>
    <a-popconfirm
        :title="title"
        ok-text="실행"
        cancel-text="취소"
        @confirm="confirm"
        @cancel="cancel"
        :disabled="disabled"
    >
        <a-button v-if="blinded" :disabled="disabled">
            <eye-invisible-outlined />
        </a-button>
        <a-button v-else type="primary" :disabled="disabled">
            <eye-outlined />
        </a-button>
    </a-popconfirm>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineComponent } from 'vue'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'BlindPopconfirm',

    components: {
        EyeInvisibleOutlined,
        EyeOutlined,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        uri: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        blinded: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['afterBlind'],

    methods: {
        async confirm() {
            const response = await adminApi<ApiResponse<void>>(this.uri, {
                method: 'PATCH',
            })

            if (response.success === false) {
                this.$message.error('블라인드에 실패했습니다.')
            } else {
                this.$emit('afterBlind')
            }
        },

        cancel() {
            this.$message.error('블라인드를 취소했습니다.')
        },
    },
})
</script>
