import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "instance"
}
const _hoisted_3 = { class: "status-box" }
const _hoisted_4 = { class: "preview-layout" }
const _hoisted_5 = { class: "overlay" }
const _hoisted_6 = {
  key: 0,
  class: "off"
}
const _hoisted_7 = {
  key: 1,
  class: "overlay-hover"
}
const _hoisted_8 = { class: "item" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = {
  key: 0,
  class: "benefit-layout"
}
const _hoisted_11 = { class: "benefit-top-content" }
const _hoisted_12 = { class: "benefit-bottom-content" }
const _hoisted_13 = { class: "benefit-name" }
const _hoisted_14 = { class: "benefit-title" }
const _hoisted_15 = {
  key: 1,
  class: "reward-text-layout"
}
const _hoisted_16 = { class: "reward-content" }
const _hoisted_17 = { class: "reward-image" }
const _hoisted_18 = { class: "reward-text" }
const _hoisted_19 = { class: "reward-title" }
const _hoisted_20 = { class: "reward-sub-title" }
const _hoisted_21 = { class: "reward-button" }
const _hoisted_22 = { class: "reward-button-label" }
const _hoisted_23 = {
  key: 2,
  class: "reward-image-layout"
}
const _hoisted_24 = {
  key: 3,
  class: "da-layout"
}
const _hoisted_25 = {
  key: 4,
  class: "store-countdown-layout"
}
const _hoisted_26 = {
  key: 5,
  class: "nutrients-countdown-layout"
}
const _hoisted_27 = { key: 6 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_image = _resolveComponent("a-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.instance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass(_ctx.isOnAir ? 'on-air' : 'off')
            }, _toDisplayString(_ctx.badge), 3)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (!_ctx.isOnAir)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                    _createElementVNode("span", { class: "off-text" }, "OFF", -1)
                  ])))
                : _createCommentVNode("", true),
              (_ctx.deletable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, "ID : " + _toDisplayString(_ctx.instance.id), 1),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_a_button, {
                        danger: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.remove(_ctx.index)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_delete_outlined)
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.instance?.layoutType === 'benefit-layout')
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_a_image, {
                        src: _ctx.benefitImageUrl,
                        width: "100%",
                        "max-width": "335px",
                        height: "148px",
                        onError: _ctx.replaceImage
                      }, null, 8, ["src", "onError"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.benefitName), 1),
                      _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.benefitTitle), 1)
                    ])
                  ])
                ]))
              : (_ctx.instance?.layoutType === 'reward-text')
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_a_image, {
                          src: _ctx.imageUrl,
                          width: "44px",
                          height: "44px"
                        }, null, 8, ["src"])
                      ]),
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.rewardTitle), 1),
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.rewardSubTitle), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "reward-coin" }, null, -1)),
                      _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.buttonLabel), 1)
                    ])
                  ]))
                : (_ctx.instance?.layoutType === 'reward-image')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _createVNode(_component_a_image, {
                        src: _ctx.imageUrl,
                        width: "100%",
                        "max-width": "335px",
                        height: "84px"
                      }, null, 8, ["src"])
                    ]))
                  : (_ctx.instance?.layoutType === 'da-layout')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                        _createVNode(_component_a_image, {
                          src: _ctx.imageUrl,
                          width: "100%",
                          "max-width": "335px",
                          height: "148px"
                        }, null, 8, ["src"])
                      ]))
                    : (_ctx.instance?.layoutType === 'store-countdown')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                          _createVNode(_component_a_image, {
                            src: _ctx.imageUrl,
                            width: "100%",
                            "max-width": "335px",
                            height: "148px"
                          }, null, 8, ["src"])
                        ]))
                      : (_ctx.instance?.layoutType === 'nutrients-countdown')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                            _createVNode(_component_a_image, {
                              src: _ctx.imageUrl,
                              width: "100%",
                              "max-width": "335px",
                              height: "148px"
                            }, null, 8, ["src"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_27, _cache[3] || (_cache[3] = [
                            _createElementVNode("h1", null, "지원하지 않는 LAYOUT", -1)
                          ])))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}