<template>
    <resource-table
        title="식단 관리"
        uri="/api/diet"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1000, y: 650 }"
        :columns="columns"
        load-on-mount
        deletable
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FoodIntakeAdvisor',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            name: {
                label: '이름',
                value: null,
            },
            displayName: {
                label: 'display 이름',
                value: null,
            },
            healthStatusList: {
                label: '건강 상태',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/name',
                mode: SelectInputMode.MULTIPLE,
            },
            higBroadList: {
                label: 'HIG Broad',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
                mode: SelectInputMode.MULTIPLE,
            },
            relatedNAGroupList: {
                label: '관련 영양소 섭취그룹',
                value: null,
                component: 'AutoCompleteInput',
                uri: '/api/nutrient-allowance-group/name',
                mode: SelectInputMode.MULTIPLE,
            },
            gender: {
                label: '성별',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'gender',
            },
            ageMin: {
                label: '최소 나이(min)',
                value: null,
                component: 'AInputNumber',
            },
            ageMax: {
                label: '최대 나이(max)',
                value: null,
                component: 'AInputNumber',
            },
            isIncrease: {
                label: '증량 여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '증량(1kg 이상 증량)',
                falseText: '적용 안함',
            },
            isDecrease: {
                label: '증량 여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '감량(1kg 이상 감량)',
                falseText: '적용 안함',
            },
            description: {
                label: '한줄설명',
                value: null,
            },
            fullTip: {
                label: '전체영양소설명',
                type: InputType.TEXTAREA,
                value: null,
            },
            cpfTip: {
                label: '탄단지설명',
                type: InputType.TEXTAREA,
                value: null,
            },
            increaseTip: {
                label: '늘렸어요',
                type: InputType.TEXTAREA,
                value: null,
            },
            decreaseTip: {
                label: '줄였어요',
                type: InputType.TEXTAREA,
                value: null,
            },
            iconUrl: {
                label: '아이콘 URL',
                value: null,
            },
            priority: {
                label: '중요도',
                value: null,
                component: 'AInputNumber',
            },
        }
        const updateSpec = { ...createSpec }
        return {
            selectedRowKeys: [],
            loading: false,
            createSpec,
            updateSpec,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                { title: '이름', dataIndex: 'name', width: 50 },
                { title: 'display 이름', dataIndex: 'displayName', width: 50 },
                {
                    title: '건강 상태',
                    dataIndex: 'healthStatusList',
                    customRender: (value: any) => {
                        return value.value
                            .map((a: { name: any }) => a.name)
                            .join(', ')
                    },
                    width: 50,
                },
                {
                    title: 'HIG Broad',
                    dataIndex: 'higBroadList',
                    customRender: (value: any) => {
                        return value.value
                            .map((a: { name: any }) => a.name)
                            .join(', ')
                    },
                    width: 50,
                },
                {
                    title: '대상 성별',
                    dataIndex: 'gender',
                    width: 50,
                },
                {
                    title: '최소 나이(min)',
                    dataIndex: 'ageMin',
                    width: 50,
                },
                {
                    title: '최대 나이(max)',
                    dataIndex: 'ageMax',
                    width: 50,
                },
                {
                    title: '증량/감량',
                    dataIndex: 'isIncrease',
                    customRender: (value: any) => {
                        if (value.value) {
                            return '증량'
                        }
                        return '감량'
                    },
                    width: 50,
                },
                {
                    title: '중요도',
                    dataIndex: 'priority',
                    width: 50,
                },
            ],
            rules: {
                name: [required],
                displayName: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['gender', 'hig-broad-name'])
    },
})
</script>
