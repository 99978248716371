import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineStore } from 'pinia'

export type StandardUnit = {
    code: string
    unitExpression: string
    unitName: string
}

type StandardUnitState = Array<StandardUnit>

type State = {
    standardUnit: StandardUnitState
}

const defaultState: State = {
    standardUnit: [],
}

export const useStandardUnitStore = defineStore('standardUnit', {
    persist: true,
    state: () => ({ ...defaultState }),
    actions: {
        async fetchStandardUnit() {
            try {
                const unitList = this.standardUnit
                if (unitList.length == 0) {
                    const response = await adminApi<
                        ApiResponse<{ content: StandardUnitState }>
                    >('/api/standard-unit')

                    if (response.success === false)
                        throw new Error(response.errorMessage)

                    if (response.result?.content != null) {
                        console.log(this.standardUnit)
                        this.standardUnit.push(...response.result.content)
                    }
                }
            } catch (error) {
                if (this.standardUnit != null) {
                    this.standardUnit.push(...[])
                }
                console.error(error)
            }
        },
    },
})
