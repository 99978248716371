<template>
    <a-popconfirm
        :title="title"
        ok-text="삭제"
        cancel-text="취소"
        @confirm="confirm"
        @cancel="cancel"
        :disabled="disabled"
    >
        <a-button v-if="showButton" danger :disabled="disabled">
            <delete-outlined />
        </a-button>
        <delete-outlined v-else />
    </a-popconfirm>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineComponent } from 'vue'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'DeletePopconfirm',

    components: {
        DeleteOutlined,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        uri: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showButton: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['afterDelete'],

    methods: {
        async confirm() {
            try {
                const response = await adminApi<ApiResponse<void>>(this.uri, {
                    method: 'DELETE',
                })

                if (response.success === false) {
                    this.$message.error('삭제 실패했습니다.')
                } else {
                    this.$emit('afterDelete')
                }
            } catch (error) {
                this.$message.error('삭제 실패했습니다.')
            }
        },

        cancel() {
            this.$message.error('삭제를 취소했습니다.')
        },
    },
})
</script>
