import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 2 }
const _hoisted_3 = ["src"]

import { ApiResponse, adminApi, baseUrl } from '@/fetchTemplate'
import { computed, ref } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { fileToWebp } from '@/util/image'
import { OptionProps } from 'ant-design-vue/es/select'
import { stringify } from 'qs'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageInput',
  props: {
    value: {
        type: String,
        default: '',
    },
    path: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    form: {
        type: Object,
    },
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const props = __props
const loading = ref(false)

const uploadUrl = computed(() => {
    const params = {
        path: props.path,
        pillyzeAssetType: props.form?.pillyzeAssetType,
    }
    return `${baseUrl}/api/file?${stringify(params)}`
})

const options = ref<Array<OptionProps>>([])

const emits = __emit

const handleInput = (event: any) => {
    emits('update:value', event.target.value)
}

const handleSelect = (value: any) => {
    emits('update:value', value)
}

const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
        loading.value = true
        return
    }
    if (info.file.status === 'done') {
        const allDone = !info.fileList.some((f: any) => f.status !== 'done')
        if (allDone) {
            emits('update:value', info.file.response.result)
            loading.value = false
        }
    }
}

const onSearch = async (value: string) => {
    if (value) {
        const response = await adminApi<ApiResponse<Array<OptionProps>>>(
            `/api/pillyze-image-asset/name/${value}`
        )
        if (response.result) {
            options.value = [...response.result]
        }
    }
}

const webp = ref(true)

const beforeUpload = (file: any) => {
    if (
        webp.value === true &&
        (file.type === 'image/jpeg' || file.type === 'image/png')
    ) {
        return fileToWebp(file)
    }
    return file
}

return (_ctx: any,_cache: any) => {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_upload, {
      name: "file",
      "list-type": "picture-card",
      class: "avatar-uploader",
      "show-upload-list": false,
      action: uploadUrl.value,
      onChange: handleChange,
      "before-upload": beforeUpload,
      "with-credentials": ""
    }, {
      default: _withCtx(() => [
        (__props.value && !__props.value.endsWith('.json'))
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: __props.value,
              alt: "avatar",
              style: {"max-width":"100px","max-height":"100px"}
            }, null, 8, _hoisted_1))
          : (__props.value && __props.value.endsWith('.json'))
            ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                key: 1,
                animationLink: __props.value,
                width: 100,
                height: 100
              }, null, 8, ["animationLink"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (loading.value)
                  ? (_openBlock(), _createBlock(_unref(LoadingOutlined), { key: 0 }))
                  : (_openBlock(), _createBlock(_unref(PlusOutlined), { key: 1 })),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ant-upload-text" }, "Upload", -1))
              ]))
      ]),
      _: 1
    }, 8, ["action"]),
    _createVNode(_component_a_form_item, { label: "이미지 주소" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input, {
          value: __props.value,
          onChange: handleInput,
          style: {"width":"500px"}
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_form_item, { label: "이미지 검색" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select, {
          options: options.value,
          disabled: __props.disabled,
          "show-search": "",
          style: {"width":"500px"},
          "option-filter-prop": "label",
          onChange: handleSelect,
          onSearch: onSearch
        }, {
          option: _withCtx(({ label, image }) => [
            _createElementVNode("img", {
              src: image,
              width: 64
            }, null, 8, _hoisted_3),
            _createTextVNode("   " + _toDisplayString(label), 1)
          ]),
          _: 1
        }, 8, ["options", "disabled"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_form_item, {
      label: "webp 변환",
      help: "체크시 이후 등록된 이미지들은 webp로 변환됩니다. (jpeg, png만) webp로 변환된 이미지는 크기가 줄어듭니다."
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_switch, {
          checked: webp.value,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((webp).value = $event))
        }, null, 8, ["checked"])
      ]),
      _: 1
    })
  ], 64))
}
}

})