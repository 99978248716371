import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resource_table = _resolveComponent("resource-table")!

  return (_openBlock(), _createBlock(_component_resource_table, {
    title: "영양소 단위",
    uri: "/api/standard-unit",
    columns: _ctx.columns,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    "primary-key": "code"
  }, null, 8, ["columns", "create-spec", "update-spec", "filter-spec"]))
}