<template>
    <div>
        <div class="tag-space" v-if="!editable" @click="onClickTag">
            <a-tag v-for="tag in userNames" :key="tag" color="blue">{{
                tag
            }}</a-tag>
        </div>
        <div v-else style="display: inline-block; white-space: nowrap">
            <a-mentions
                v-model:value="mentionText"
                style="max-width: 70%; margin-right: 10px"
            >
                <a-mentions-option
                    v-for="{ id, userName } in companion"
                    :key="id"
                    :value="userName"
                >
                    {{ userName }}
                </a-mentions-option>
            </a-mentions>
            <a-button @click="onClickAllocate">할당</a-button>
        </div>
    </div>
</template>

<style lang="scss">
.tag-space {
    border: solid;
    border-color: #e8e8e8;
    border-width: thin;
    padding: 5px;
}
</style>

<script lang="ts">
import { Companion, useCompanionStore } from '@/store/companion'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { defineComponent, Ref, ref, watch } from 'vue'

export default defineComponent({
    name: 'UserMention',

    props: {
        resourceId: {
            type: Number,
            required: true,
        },
        names: {
            type: Array,
            required: true,
        },
    },

    setup(props: any) {
        const mentionText: Ref<string> = ref('')
        const editable = ref(false)
        const userNames = ref([...props.names])
        const setName = () => {
            userNames.value = mentionText.value
                .replaceAll('@', '')
                .split(' ')
                .filter((name: string) => name !== '')
        }
        watch(mentionText, () => {
            setName()
        })
        return { mentionText, editable, userNames }
    },

    computed: {
        companion() {
            const companionStore = useCompanionStore()
            const companion = companionStore.companion
            const userNameList = companion.map((it: Companion) => it.userName)

            return companion?.filter(
                (it: Companion, index: number) =>
                    it.userName != null &&
                    userNameList.indexOf(it.userName) === index
            )
        },
        userIds(): Array<number> {
            return this.companion
                .filter(
                    (it: Companion) => this.userNames.indexOf(it.userName) != -1
                )
                .map((it: Companion) => it.id)
        },
    },
    methods: {
        async onClickAllocate() {
            const queryParams = {
                assignees: this.userIds,
            }
            await adminApi<ApiResponse<void>>(
                `/api/user-feedback/assign/${this.resourceId}?${stringify(
                    queryParams,
                    { indices: false }
                )}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            this.editable = false
        },

        onClickTag() {
            this.editable = true
            this.mentionText = this.userNames
                .reduce((acc: string, it: string) => `${acc} @${it}`, '')
                .trim()
        },
    },
})
</script>
