import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineStore } from 'pinia'

export type AdLayout = {
    id: number
    name: string
    layoutType: string
    properties: any
}

export type AdSlot = {
    id: number
    name: any
    page: any
    position: any
}

type AdLayoutState = Array<AdLayout>

type AdSlotState = Array<AdSlot>

type LayoutState = {
    layout: AdLayoutState
}

type SlotState = {
    slot: AdSlotState
}

const layoutState: LayoutState = {
    layout: [],
}

const slotState: SlotState = {
    slot: [],
}

export const useSlotStore = defineStore('ad-slot', {
    persist: true,
    state: () => ({ ...slotState }),
    actions: {
        async fetchAdSlot() {
            try {
                const slotList = this.slot
                const response = await adminApi<
                    ApiResponse<{ content: AdSlotState }>
                >('/api/advertisements/slots?size=100')

                if (response.success === false) {
                    throw new Error(response.errorMessage)
                }

                if (response.result?.content != null) {
                    this.slot.push(...response.result.content)
                }
            } catch (error) {
                if (this.slot != null) {
                    this.slot.push(...[])
                }
            }
        },
    },
})

export const useLayoutStore = defineStore('ad-layout', {
    persist: true,
    state: () => ({ ...layoutState }),
    actions: {
        async fetchAdLayout() {
            try {
                const response = await adminApi<
                    ApiResponse<{ content: AdLayoutState }>
                >('/api/advertisements/layouts?size=100')

                if (response.success === false) {
                    throw new Error(response.errorMessage)
                }

                if (response.result?.content != null) {
                    this.layout = response.result.content
                }
            } catch (error) {
                if (this.layout != null) {
                    this.layout.push(...[])
                }
                console.error(error)
            }
        },
    },
})
