<template style="display: flex; align-items: center">
    <a-col>
        <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :multiple="true"
            :action="uploadUrl"
            @change="handleChange"
            :before-upload="beforeUpload"
            with-credentials
            style="width: unset"
        >
            <div>
                <loading-outlined v-if="loading" />
                <plus-outlined v-else />
                <div class="ant-upload-text">Upload</div>
            </div>
        </a-upload>
        <a-row>
            <a-col
                v-for="(imageValue, index) in imageUrls"
                :key="index"
                span="6"
            >
                <img
                    v-if="
                        imageValue &&
                        !imageValue.endsWith('.json') &&
                        !imageValue.endsWith('.csv')
                    "
                    :src="imageValue"
                    alt="avatar"
                    style="max-width: 100px; max-height: 100px"
                />
            </a-col>
        </a-row>
    </a-col>
</template>

<style>
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}

.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>

<script setup lang="ts">
import { baseUrl } from '@/fetchTemplate'
import { computed, ref, defineProps, defineEmits } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { fileToWebp } from '@/util/image'

const props = defineProps({
    values: {
        type: Array<string>,
        default: () => [],
    },
    path: {
        type: String,
        default: '',
    },
})
const loading = ref(false)

const uploadUrl = computed(() => {
    return `${baseUrl}/api/file?path=${props.path}`
})

const imageUrls = computed(() => {
    return props.values
})

const emits = defineEmits(['update:value'])

const handleInput = (event: any) => {
    emits('update:value', event.target.value)
}

const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
        loading.value = true
        return
    }
    if (info.file.status === 'done') {
        const allDone = !info.fileList.some((f: any) => f.status !== 'done')
        if (allDone) {
            const result = info.fileList.map((f: any) => {
                return f.response.result
            })
            emits('update:value', result)
            loading.value = false
        }
    }
}

const beforeUpload = (file: any) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return fileToWebp(file)
    }
    return file
}
</script>
