<template>
    <a-row class="overlay" v-if="loading">
        <a-spin />
    </a-row>
    <a-row class="slot">
        <ad-slot-tab
            :slot-id="slotId"
            :min-build-number="minBuildNumber"
            :vo-type="voType"
            :daily-mission-type="dailyMissionType"
            @change:slot="changeSlot"
            @change:min-build-number="changeMinBuildNumber"
            @change:vo-type="changeVoType"
            @change:daily-mission-type="changeDailyMissionType"
        />
    </a-row>
    <a-row class="advertise" v-if="minBuildNumber">
        <a-col class="advertise-on" :span="6">
            <div class="user-event">
                <a-button type="primary" class="btn-save" @click="save">
                    광고 저장
                </a-button>
            </div>
            <ad-current-instance
                :header="'기본광고'"
                :instances="baseInstances"
                @remove="removeBaseInstance"
            />
            <ad-current-instance
                :header="'광고'"
                :instances="instances"
                @remove="removeInstances"
            />
        </a-col>
        <a-col class="advertise-table" :span="18">
            <ad-instance
                :slot-id="slotId"
                :min-build-number="minBuildNumber"
                @update:instances="updateInstances"
                @update:base-instance="updateBaseInstance"
            />
        </a-col>
    </a-row>
</template>

<style scoped>
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9999;
}
.slot {
    padding-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
}
.advertise-on {
    z-index: 9;
    overflow: scroll;
    padding-right: 12px;
    border-right: 1px solid #e8e8e8;
}
.advertise-table {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.advertise-table::-webkit-scrollbar {
    display: none;
}
.user-event {
    padding: 24px 12px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import AdSlotTab from '@/views/ad/AdSlotTab.vue'
import AdInstance from '@/views/ad/AdInstance.vue'
import AdCurrentInstance from '@/views/ad/AdCurrentInstance.vue'
import { adminApi, ApiResponse, postApi } from '@/fetchTemplate'
import { isEmpty, isNil } from 'lodash'

export default defineComponent({
    name: 'AdMain',

    components: {
        AdSlotTab,
        AdInstance,
        AdCurrentInstance,
    },

    setup() {
        const slotId = ref(0)
        const minBuildNumber = ref()
        const dailyMissionType = ref()
        const voType = ref()
        const baseInstances = ref([] as any[])
        const instances = ref([] as any[])

        const loading = ref(false)

        return {
            slotId,
            minBuildNumber,
            baseInstances,
            instances,
            dailyMissionType,
            voType,
            loading,
        }
    },

    watch: {
        slotId() {
            this.minBuildNumber = null
            this.baseInstances = []
            this.instances = []
        },
        minBuildNumber() {
            this.fetchInstances()
        },
    },

    methods: {
        click() {
            this.slotId++
        },
        initReward(isReward: boolean) {
            if (!isReward) {
                this.voType = null
                this.dailyMissionType = null
                return
            }
            this.voType = 'REWARD'
            this.dailyMissionType = 'ADVERTISEMENT_DAILY_REWARD'
        },
        changeSlot(slot: any) {
            this.slotId = slot.id
            this.initReward(slot.page === 'reward')
        },
        changeMinBuildNumber(minBuildNumber: number) {
            this.minBuildNumber = minBuildNumber
        },
        changeVoType(voType: string) {
            this.voType = voType
        },
        changeDailyMissionType(dailyMissionType: string) {
            this.dailyMissionType = dailyMissionType
        },
        updateBaseInstance(baseInstances: any[]) {
            this.baseInstances.push(...baseInstances)
        },
        updateInstances(instances: any[]) {
            this.instances.push(...instances)
        },
        removeBaseInstance(index: any) {
            this.baseInstances.splice(index, 1)
        },
        removeInstances(index: any) {
            this.instances.splice(index, 1)
        },
        checkForm() {
            let invalid = false
            let message = ''
            if (isNil(this.slotId)) {
                invalid = true
                message = '구좌를 선택하세요'
            }
            if (isNil(this.minBuildNumber)) {
                invalid = true
                message = '빌드넘버를 선택하세요'
            }
            if (invalid) {
                window.alert(message)
            }
            return invalid
        },
        async fetchInstances() {
            if (isNil(this.slotId) || isNil(this.minBuildNumber)) {
                return
            }
            try {
                const url = `/api/advertisements/current?slotId=${this.slotId}&minBuildNumber=${this.minBuildNumber}`
                const response = await adminApi<ApiResponse<any>>(url)
                if (!response.success) {
                    console.log('error : ", response')
                    return null
                }
                this.baseInstances = response.result.baseInstances || []
                this.instances = response.result.instances || []
            } catch (error: any) {
                console.log('e : ', error)
            }
        },
        async save() {
            if (this.checkForm()) {
                return
            }
            const url = '/api/advertisements'
            const orders = this.instances.map((it: any, index: number) => {
                return {
                    instanceId: it.id,
                    layoutId: it.layout.layoutId,
                    order: index,
                }
            })
            const baseOrders = this.baseInstances.map(
                (it: any, index: number) => {
                    return {
                        instanceId: it.id,
                        layoutId: it.layout.layoutId,
                        order: index,
                    }
                }
            )
            const payload = {
                enable: true,
                slotId: this.slotId,
                baseOrders: baseOrders,
                orders: orders,
                minBuildNumber: this.minBuildNumber,
                voType: this.voType,
                dailyMissionType: this.dailyMissionType,
            }
            this.loading = true
            await postApi(url, payload, false, '')
            this.loading = false
        },
    },
})
</script>
