import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "활성 사용자 목록",
    uri: "/api/user-management",
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns
  }, {
    detail: _withCtx(({ record }) => [
      _createVNode(_component_a_descriptions, {
        title: "팝업 상세",
        bordered: true,
        layout: "vertical",
        column: 1,
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "이벤트명" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.name), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["update-spec", "filter-spec", "columns"]))
}