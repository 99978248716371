import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#f9fefe","padding":"13px"} }

import { ref, toRef } from 'vue'
import FoodInput from '@/components/input/FoodInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { SelectOption } from '@/store/enumType'

type Amounts = {
    id?: number
    foodName?: string
    foodId?: number
    noServing?: number
    amountPerNoServing: number
    intakeUnitName?: string
    unitOptions?: Array<SelectOption>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodIntakeInput',
  props: {
    rules: {
        type: Object,
        default: () => ({
            nutrientName: [required],
            amount: [required, aboveOrEqualToZero],
            unit: [required],
            isCalculated: [required],
            isImportant: [required],
        }),
    },
    value: {
        type: Array,
        default: () => [],
    },
},
  setup(__props) {

const props = __props

const form = ref<Amounts>({
    amountPerNoServing: 0,
    noServing: 0,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: Amounts) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}
const add = () => {
    return formRule.value.validate().then(() => {
        if (form.value.intakeUnitName == undefined) {
            alert('섭취 단위는 필수값입니다.')
            return
        }
        if (props.value != null) {
            push(form.value)
            form.value = {
                amountPerNoServing: 0,
                noServing: 0,
            }
        }
    })
}

const onSelectFood = async (intakeUnit: any, event: any) => {
    intakeUnit.foodName = event.label
    intakeUnit.foodId = event.value

    const response = await adminApi<ApiResponse<Array<SelectOption>>>(
        `/api/food/food-intake-unit/${event.value}`
    )
    intakeUnit.unitOptions = response.result
}

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref_key: "formRule",
          ref: formRule,
          model: form.value,
          rules: __props.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, { label: "식품명" }, {
              default: _withCtx(() => [
                _createVNode(FoodInput, {
                  value: form.value.foodName,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.value.foodName) = $event)),
                  name: "foodName",
                  onSelect: _cache[1] || (_cache[1] = (event) => onSelectFood(form.value, event)),
                  width: "280px",
                  mode: "default"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "noServing",
              label: "제공수"
            }, {
              default: _withCtx(() => [
                _createVNode(KoreanNumberInput, {
                  value: form.value.noServing,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((form.value.noServing) = $event)),
                  "has-addon-after-slot": "",
                  width: "180px"
                }, {
                  addonAfter: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: form.value.intakeUnitName,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.value.intakeUnitName) = $event)),
                      options: form.value.unitOptions
                    }, null, 8, ["value", "options"])
                  ]),
                  _: 1
                }, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              name: "amountPerNoServing",
              label: "1회 제공량"
            }, {
              default: _withCtx(() => [
                _createVNode(KoreanNumberInput, {
                  value: form.value.amountPerNoServing,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((form.value.amountPerNoServing) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" 식품 추가")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_unref(Draggable), {
          list: valueRef.value,
          "item-key": "foodId"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: __props.rules,
              layout: "inline",
              style: {"border-color":"#6c2bf2"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SwapOutlined), { rotate: 90 })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "식품명" }, {
                  default: _withCtx(() => [
                    _createVNode(FoodInput, {
                      value: element.foodName,
                      "onUpdate:value": ($event: any) => ((element.foodName) = $event),
                      label: "식품명",
                      name: `foodName${index}`,
                      onSelect: 
                                    (event) => onSelectFood(element, event)
                                ,
                      width: "280px",
                      mode: "default"
                    }, null, 8, ["value", "onUpdate:value", "name", "onSelect"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, {
                  name: "noServing",
                  label: "제공수"
                }, {
                  default: _withCtx(() => [
                    _createVNode(KoreanNumberInput, {
                      value: element.noServing,
                      "onUpdate:value": ($event: any) => ((element.noServing) = $event),
                      "has-addon-after-slot": "",
                      width: "180px"
                    }, {
                      addonAfter: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          value: element.intakeUnitName,
                          "onUpdate:value": ($event: any) => ((element.intakeUnitName) = $event),
                          options: element.unitOptions
                        }, null, 8, ["value", "onUpdate:value", "options"])
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, {
                  name: `amountPerNoServing${index}`,
                  label: "1회 제공량"
                }, {
                  default: _withCtx(() => [
                    _createVNode(KoreanNumberInput, {
                      value: element.amountPerNoServing,
                      "onUpdate:value": ($event: any) => ((element.amountPerNoServing) = $event)
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1032, ["name"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => pop(index)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_unref(DeleteOutlined))
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ]))
}
}

})