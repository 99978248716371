import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!

  return (_openBlock(), _createBlock(_component_a_descriptions, {
    style: {"padding-left":"10px","padding-right":"10px"},
    column: 1
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_descriptions_item, { label: "Job 실행 상태" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.execution.status), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "시작시간" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.execution.startTime), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_descriptions_item, { label: "종료시간" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.execution.endTime), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}