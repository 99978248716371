<template>
    <a-button @click="openModal">
        <span>{{ label }}</span>
    </a-button>
    <a-modal
        width="50%"
        v-model:open="isOpen"
        title="이벤트 CRM"
        cancel-text="취소"
        ok-text="발송"
        :ok-button-props="{ disabled: !isTestDone }"
        @cancel="closeModal"
        @ok="clickSend"
    >
        <a-spin v-if="loading" />
        <a-row v-if="!loading">
            <a-col :span="24">
                <a-descriptions
                    layout="horizontal"
                    :column="1"
                    :bordered="true"
                >
                    <a-descriptions-item label="이벤트 제목">
                        {{ value.eventName }}
                    </a-descriptions-item>
                    <a-descriptions-item label="SegId">
                        {{ value.segId }}
                    </a-descriptions-item>
                    <a-descriptions-item label="발송 수단">
                        {{ type }}
                    </a-descriptions-item>
                    <a-descriptions-item label="템플릿">
                        <a-row>
                            <a-col :span="16">
                                <a-select
                                    style="width: 100%"
                                    :options="options"
                                    @select="selectTemplate"
                                />
                            </a-col>
                            <a-col :span="8">
                                <a-button
                                    @click.prevent="refreshOptions"
                                    style="margin-left: 5px"
                                >
                                    <reload-outlined />
                                </a-button>
                                <a-button @click.prevent="resetValue">
                                    <minus-outlined />
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="type === 'KAKAO_TALK'"
                        label="비즈엠 템플릿 Id"
                    >
                        <a-input disabled v-model:value="bizmTemplateId" />
                    </a-descriptions-item>
                    <a-descriptions-item v-if="type === 'SMS'" label="제목">
                        <a-input v-model:value="smsTitle" />
                    </a-descriptions-item>
                    <a-descriptions-item label="본문">
                        <a-row>
                            <a-col :span="24">
                                <a-textarea
                                    style="
                                        width: 100% !important;
                                        min-height: 150px;
                                    "
                                    :max-length="10"
                                    v-model:value="smsTemplate"
                                />
                            </a-col>
                        </a-row>
                    </a-descriptions-item>
                    <a-descriptions-item label="테스트 발송(userId)">
                        <a-row style="padding-bottom: 5px">
                            <a-col :span="24">
                                <a-input-number
                                    class="input-number"
                                    v-model:value="testUserId"
                                />
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12" style="padding-right: 5px">
                                <a-button
                                    warning
                                    class="btn btn-send-test"
                                    @click.prevent="clickSendToMe"
                                >
                                    테스트 발송
                                </a-button>
                            </a-col>
                            <a-col :span="12" style="padding-left: 5px">
                                <a-button
                                    type="primary"
                                    class="btn btn-check-test"
                                    @click.prevent="checkSendTest"
                                >
                                    발송 확인
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-descriptions-item>
                </a-descriptions>
            </a-col>
        </a-row>
    </a-modal>
</template>

<style scoped>
.input-number,
.btn {
    width: 100%;
}
</style>
<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import { SelectOption } from '@/store/enumType'
import {
    CrmTemplateType,
    useCrmSmsTemplateStore,
    useCrmBizmTemplateStore,
} from '@/store/crm'
import { MinusOutlined, ReloadOutlined } from '@ant-design/icons-vue'
import { getApi, postApi } from '@/fetchTemplate'

export default defineComponent({
    name: 'SendCrmSegModal',
    components: {
        ReloadOutlined,
        MinusOutlined,
    },

    props: {
        valueProps: {
            type: String,
        },
        typeProps: {
            type: String,
        },
        labelProps: {
            type: String,
        },
    },

    setup(props: any) {
        const value = toRef(props, 'valueProps')
        const type = toRef(props, 'typeProps')
        const label = toRef(props, 'labelProps')
        const isOpen = ref(false)
        const loading = ref(false)
        const crmSmsTemplateStore = useCrmSmsTemplateStore()
        const crmBizmTemplateStore = useCrmBizmTemplateStore()
        const smsTitle = ref()
        const smsTemplate = ref()
        const bizmTemplateId = ref()
        const testUserId = ref()
        const isTestDone = ref(false)
        return {
            isOpen,
            loading,
            value,
            type,
            label,
            crmSmsTemplateStore,
            crmBizmTemplateStore,
            bizmTemplateId,
            smsTitle,
            smsTemplate,
            testUserId,
            isTestDone,
        }
    },

    computed: {
        options(): Array<SelectOption> {
            if (this.type === 'SMS') {
                return this.crmSmsTemplateStore.smsTemplates.map(
                    (it: CrmTemplateType) => ({
                        label: `${it.label}`,
                        value: it.value,
                        key: it.value,
                    })
                )
            }
            return this.crmBizmTemplateStore.bizmTemplates.map(
                (it: CrmTemplateType) => ({
                    label: `${it.label}`,
                    value: it.value,
                    key: it.value,
                })
            )
        },
    },

    emits: ['modal:close'],

    methods: {
        openModal() {
            this.isOpen = true
        },
        closeModal() {
            this.isOpen = false
        },
        selectTemplate(templateId: number) {
            this.getTemplate(templateId)
        },
        clickSend() {
            const payload = {
                title: this.smsTitle,
                template: this.smsTemplate,
            }
            this.isOpen = false
        },
        clickSendToMe() {
            const payload = {
                type: this.type,
                title: this.smsTitle,
                content: this.smsTemplate,
                userId: this.testUserId,
                eventId: this.value.eventId,
                segId: this.value.segId,
                bizmTemplateId: this.bizmTemplateId,
            }
            this.sendToMe(payload)
        },
        checkSendTest() {
            this.isTestDone = true
        },
        async getTemplate(templateId: number) {
            const url = `/api/crm-templates/${templateId}`
            const response = await getApi(url)
            const result = response?.result || []
            this.smsTemplate = result.template
            this.smsTitle = result.title
            this.bizmTemplateId = result.bizmTemplateId
        },
        async sendToMe(payload: any) {
            const url = '/api/crm-templates/send-to-one'
            await postApi(url, payload, true, '업데이트 실패!')
        },
        refreshOptions() {
            this.crmSmsTemplateStore.fetchCrmSmsTemplates()
            this.crmBizmTemplateStore.fetchCrmBizmTemplates()
        },
        resetValue() {
            this.smsTemplate = null
            this.smsTitle = null
            this.bizmTemplateId = null
        },
    },
})
</script>
