<template>
    <resource-table
        title="영양소 그룹"
        uri="/api/nutrient-group"
        :columns="columns"
        :scroll="{ x: 3000, y: 650 }"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        exportable
    />
</template>

<script lang="ts">
import { formatLocaleNumber } from '@/util/formmater'
import { required } from '@/util/input-validation'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { InputComponents, SelectInputMode } from '@/components/InputMapper.vue'

export default defineComponent({
    name: 'NutrientGroup',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            groupName: {
                label: '영양소 그룹명',
                value: null,
            },
            searchTag: {
                label: '검색태그',
                value: null,
            },
            webSearchTag: {
                label: 'WEB 검색태그',
                value: null,
            },
            mainExplain: {
                label: '설명',
                value: null,
                type: 'textarea',
            },
            intakeTitle: {
                label: '섭취제목',
                value: null,
                type: 'textarea',
            },
            intakeGuide: {
                label: '섭취가이드',
                value: null,
                type: 'textarea',
            },
            foodTitle: {
                label: '식품제목',
                value: null,
                type: 'textarea',
            },
            foodSub: {
                label: '식품목록',
                value: null,
                type: 'textarea',
            },
            foodGuide: {
                label: '식품가이드',
                value: null,
                type: 'textarea',
            },
            relatedNutrient: {
                label: '관련 영양소',
                value: null,
                component: 'ASelect',
                mode: 'tags',
            },
            relatedGuide: {
                label: '관련 영양소 가이드',
                value: null,
                type: 'textarea',
            },
            overdoseExpl: {
                label: '과용량표현',
                value: null,
                type: 'textarea',
            },
            lackExpl: {
                label: '결핍표현',
                value: null,
                type: 'textarea',
            },
            unitExpression: {
                label: '단위',
                value: null,
            },
            goodWith: {
                label: 'goodWith',
                value: null,
                component: 'ASelect',
                mode: 'tags',
            },
            badWith: {
                label: 'badWith',
                value: null,
                component: 'ASelect',
                mode: 'tags',
            },
            rda: {
                label: 'rda',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            podi: {
                label: 'podi',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            ul: {
                label: 'ul',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            higMin: {
                label: 'higMin',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            higMax: {
                label: 'higMax',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            rankNumber: {
                label: 'rankNumber',
                value: null,
            },
            allowanceGroupName: {
                label: '섭취 그룹명',
                value: null,
                component: 'AutoCompleteInput',
                uri: '/api/nutrient-allowance-group/name',
            },
        }
        return {
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '그룹명',
                    dataIndex: 'groupName',
                },
                {
                    title: '좋아요 수',
                    dataIndex: 'favedCount',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '설명',
                    dataIndex: 'mainExplain',
                },
                {
                    title: '섭취제목',
                    dataIndex: 'intakeTitle',
                },
                {
                    title: '섭취가이드',
                    dataIndex: 'intakeGuide',
                },
                {
                    title: '식품제목',
                    dataIndex: 'foodTitle',
                },
                {
                    title: '식품가이드',
                    dataIndex: 'foodGuide',
                },
                {
                    title: '관련 영양소',
                    dataIndex: 'relatedNutrient',
                },
                {
                    title: '관련 영양소 가이드',
                    dataIndex: 'relatedGuide',
                },
                {
                    title: '과용량표현',
                    dataIndex: 'overdoseExpl',
                },
                {
                    title: '부족표현',
                    dataIndex: 'lackExpl',
                },
                {
                    title: '단위',
                    dataIndex: 'unitExpression',
                },
                {
                    title: '검색태그',
                    dataIndex: 'searchTag',
                },
                {
                    title: 'goodWith',
                    dataIndex: 'goodWith',
                },
                {
                    title: 'badWith',
                    dataIndex: 'badWith',
                },
                {
                    title: 'rda',
                    dataIndex: 'rda',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: 'podi',
                    dataIndex: 'podi',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: 'ul',
                    dataIndex: 'ul',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: 'higMin',
                    dataIndex: 'higMin',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: 'higMax',
                    dataIndex: 'higMax',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: 'rankNumber',
                    dataIndex: 'rankNumber',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                    sorter: true,
                },
                {
                    title: '연결 영양소 수',
                    dataIndex: 'countOfChildren',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
            ],
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInputNumber' },
                groupName: {
                    label: '그룹명',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'nutrient-group-name',
                    help: '검색에 사용되는 이름',
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                nutrientName: {
                    label: '관련 영양소',
                    component: InputComponents.NutrientInput,
                    value: [],
                    mode: SelectInputMode.MULTIPLE,
                    readonly: true,
                },
            },
            rules: {
                groupName: [required],
            },
        }
    },
})
</script>
