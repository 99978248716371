import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "property text"
}
const _hoisted_2 = { class: "element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_image_input = _resolveComponent("image-input")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRule",
    model: _ctx.form
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions, {
            bordered: true,
            column: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, { label: "레이아웃" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    class: "select",
                    options: _ctx.options,
                    value: _ctx.form.layout.layoutId,
                    mode: _ctx.layoutMode,
                    onChange: _ctx.changeLayout
                  }, null, 8, ["options", "value", "mode", "onChange"]),
                  _createVNode(_component_a_button, {
                    class: "btn",
                    onClick: _withModifiers(_ctx.refreshOptions, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_reload_outlined)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    class: "btn",
                    onClick: _withModifiers(_ctx.resetValue, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_minus_outlined)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (value, key) => {
                return (_openBlock(), _createBlock(_component_a_descriptions_item, {
                  key: key,
                  label: key
                }, {
                  default: _withCtx(() => [
                    (value === 'IMAGE')
                      ? (_openBlock(), _createBlock(_component_a_col, {
                          key: 0,
                          span: 24
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_image_input, {
                              value: _ctx.form.layout.layoutProperties[key]?.url,
                              "onUpdate:value": (path) => _ctx.changeImage(key, path)
                            }, null, 8, ["value", "onUpdate:value"])
                          ]),
                          _: 2
                        }, 1024))
                      : (value === 'BUTTON')
                        ? (_openBlock(), _createBlock(_component_a_col, {
                            key: 1,
                            span: 24,
                            class: "property button"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, {
                                span: 12,
                                class: "button-box"
                              }, {
                                default: _withCtx(() => [
                                  _cache[0] || (_cache[0] = _createElementVNode("label", null, "label", -1)),
                                  _createVNode(_component_a_input, {
                                    value: 
                                    _ctx.form.layout.layoutProperties[key]?.label
                                ,
                                    onChange: (event) => _ctx.changeButtonLabel(key, event)
                                  }, null, 8, ["value", "onChange"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_a_col, {
                                span: 12,
                                class: "button-box"
                              }, {
                                default: _withCtx(() => [
                                  _cache[1] || (_cache[1] = _createElementVNode("label", null, "value", -1)),
                                  _createVNode(_component_a_input, {
                                    value: 
                                    _ctx.form.layout.layoutProperties[key]?.value
                                ,
                                    onChange: (event) => _ctx.changeButtonValue(key, event)
                                  }, null, 8, ["value", "onChange"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024))
                        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_a_input, {
                                value: _ctx.form.layout.layoutProperties[key]?.text,
                                onChange: (event) => _ctx.changeText(key, event)
                              }, null, 8, ["value", "onChange"])
                            ])
                          ]))
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}