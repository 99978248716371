import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { formatEnum } from '@/util/formmater'
import { required } from '@/util/input-validation'
import { onMounted, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkoutManager',
  setup(__props) {

const columns = [
    {
        title: 'icon URL',
        dataIndex: 'workoutIconUrl',
        fixed: 'left',
        type: 'img',
    },
    {
        title: 'ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '운동 ID',
        dataIndex: 'workoutId',
    },
    {
        title: '카테고리',
        dataIndex: 'workoutCategory',
    },
    {
        title: '이름',
        dataIndex: 'workoutName',
    },
    {
        title: '대분류',
        dataIndex: 'workoutWorkoutCategory',
        customRender: formatEnum('workout-category'),
    },
    {
        title: '상태',
        dataIndex: 'workoutStatus',
        customRender: formatEnum('workout-status'),
    },
    {
        title: '강도',
        dataIndex: 'workoutIntensity',
        customRender: formatEnum('workout-intensity'),
    },
    {
        title: 'MET',
        dataIndex: 'met',
    },
]

const filterSpec = ref({
    id: { label: 'ID', value: null, component: 'AInputNumber' },
    workoutId: { label: '운동 ID', value: null },
    workoutCategory: { label: '카테고리', value: null },
    workoutName: { label: '이름', value: null },
    workoutIconUrl: { label: 'icon URL', value: null },
    workoutStatus: {
        label: '상태',
        value: 'NORMAL',
        component: InputComponents.EnumSelect,
        typeName: 'workout-status',
    },
})

const createSpec = ref({
    workoutIconUrl: {
        label: 'icon URL',
        value: null,
        component: InputComponents.ImageInput,
    },
    workoutName: {
        label: '이름',
        value: null,
    },
    workoutCategory: {
        label: '카테고리',
        value: null,
    },
    workoutWorkoutCategory: {
        label: '대분류',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'workout-category',
    },
    workoutStatus: {
        label: '상태',
        value: 'NORMAL',
        component: InputComponents.EnumSelect,
        typeName: 'workout-status',
    },
    workoutDefaultTime: {
        label: '기본시간 (초)',
        value: 1800,
    },
    lowPaceTip: {
        label: '저강도 페이스 팁',
        type: 'textarea',
        value: null,
    },
    lowMet: {
        label: '저강도 MET',
        value: null,
    },
    mediumPaceTip: {
        label: '중강도 페이스 팁',
        type: 'textarea',
        value: null,
    },
    mediumMet: {
        label: '중강도 MET',
        value: null,
    },
    highPaceTip: {
        label: '고강도 페이스 팁',
        type: 'textarea',
        value: null,
    },
    highMet: {
        label: '고강도 MET',
        value: null,
    },
})

const inputSpec = {
    workoutIconUrl: {
        label: 'icon URL',
        value: null,
        component: InputComponents.ImageInput,
    },
    workoutName: {
        label: '이름',
        value: null,
    },
    workoutCategory: {
        label: '카테고리',
        value: null,
    },
    workoutWorkoutCategory: {
        label: '대분류',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'workout-category',
    },
    paceTip: {
        label: '페이스 팁',
        type: 'textarea',
        value: null,
    },
    met: {
        label: 'MET',
        value: null,
    },
    workoutIntensity: {
        label: '강도',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'workout-intensity',
    },
    workoutStatus: {
        label: '상태',
        value: 'NORMAL',
        component: InputComponents.EnumSelect,
        typeName: 'workout-status',
    },
    workoutDefaultTime: {
        label: '기본시간 (초)',
        value: 1800,
    },
    keyword: {
        label: '연관 검색 키워드',
        value: null,
        help: '',
    },
}

const createRules = {
    workoutIconUrl: [required],
    workoutName: [required],
    workoutCategory: [required],
    met: [required],
    workoutIntensity: [required],
    workoutStatus: [required],
    workoutDefaultTime: [required],
    lowPaceTip: [required],
    lowMet: [required],
    mediumPaceTip: [required],
    mediumMet: [required],
    highPaceTip: [required],
    highMet: [required],
}

const inputRules = {
    workoutIconUrl: [required],
    workoutName: [required],
    wokroutCategory: [required],
    met: [required],
    workoutIntensity: [required],
    workoutStatus: [required],
    workoutDefaultTime: [required],
}

const updateSpec = ref({ ...inputSpec })

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'workout-category',
        'workout-status',
        'workout-intensity',
        'tracker-activity-type',
    ])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "workout",
    title: "운동 관리",
    uri: "/api/workout",
    columns: columns,
    "filter-spec": filterSpec.value,
    "create-spec": createSpec.value,
    "create-rules": createRules,
    "update-spec": updateSpec.value,
    "update-rules": inputRules,
    "load-on-mount": "",
    deletable: "",
    exportable: ""
  }, null, 8, ["filter-spec", "create-spec", "update-spec"]))
}
}

})