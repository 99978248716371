<template>
    <a-popconfirm
        :title="title"
        ok-text="복사"
        cancel-text="취소"
        @confirm="confirm"
        @cancel="cancel"
        :disabled="disabled"
    >
        <a-button v-if="showButton" warn :disabled="disabled">
            <copy-outlined />
        </a-button>
        <copy-outlined v-else />
    </a-popconfirm>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineComponent } from 'vue'
import { CopyOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'CopyPopConfirm',

    components: {
        CopyOutlined,
    },

    props: {
        title: {
            type: String,
            required: true,
        },
        uri: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showButton: {
            type: Boolean,
            default: true,
        },
        record: {
            type: Object,
            required: true,
        },
    },

    emits: ['afterCopy'],

    methods: {
        async confirm() {
            try {
                const response = await adminApi<ApiResponse<void>>(this.uri, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...this.record,
                    }),
                })
                if (response.success === false) {
                    this.$message.error('복사 실패했습니다.')
                } else {
                    this.$emit('afterCopy')
                }
            } catch (error) {
                this.$message.error('복사 실패했습니다.')
            }
        },

        cancel() {
            this.$message.error('복사 취소했습니다.')
        },
    },
})
</script>
