<template>
    <ResourceTable
        title="포인트샵 상품"
        uri="/api/admin/point-shop/products"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="createRules"
        :update-rules="updateRules"
        :scroll="{ x: 'max-content' }"
        :columns="columns"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <a-button @click="() => updateProductStatus('SALE', uri, search)">
                판매중
            </a-button>
            <a-button
                @click="() => updateProductStatus('SOLD_OUT', uri, search)"
            >
                품절
            </a-button>
            <a-button
                @click="() => updateProductStatus('SALE_STOP', uri, search)"
            >
                판매중지
            </a-button>
        </template>
        <template #detail="{ record }">
            <a-descriptions
                title="리뷰 상세"
                :bordered="true"
                layout="vertical"
            >
                <a-descriptions-item label="리뷰 ID">
                    {{ record.id }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'

export default defineComponent({
    name: 'PointShopProducts',

    mixins: [TableSupport],

    components: {
        ResourceTable,
    },

    setup() {
        const loading = ref(false)
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: '48px',
                align: 'center',
                fixed: 'left',
            },
            {
                title: '썸네일',
                dataIndex: 'thumbnailUrl',
                align: 'center',
                type: 'img',
            },
            {
                title: '상세이미지',
                dataIndex: 'mainImageUrl',
                align: 'center',
                type: 'img',
            },
            {
                title: '이름',
                dataIndex: 'name',
                width: '256px',
            },
            {
                title: '카테고리',
                dataIndex: 'category',
                width: '128px',
                align: 'center',
            },
            {
                title: '상태',
                dataIndex: 'status',
                width: '64px',
                align: 'center',
                customRender: (record: any) => {
                    if (record.value === 'SALE') return '판매중'
                    if (record.value === 'SALE_STOP') return '판매중지'
                    if (record.value === 'SOLD_OUT') return '품절'
                    return '-'
                },
            },
            {
                title: '구매처 이름',
                dataIndex: 'giftShopName',
                width: '128px',
                align: 'center',
            },
            {
                title: '상품 가격',
                dataIndex: 'originalPrice',
                width: '128px',
                align: 'center',
            },
            {
                title: '포인트 가격',
                dataIndex: 'pointPrice',
                width: '128px',
                align: 'center',
            },
            {
                title: '노출 가중치',
                dataIndex: 'displayWeight',
                align: 'center',
            },
            {
                title: '설명',
                dataIndex: 'descriptionShort',
                type: 'text-ellipsis',
                width: '256px',
            },
            {
                title: '상세 설명',
                width: '256px',
                dataIndex: 'descriptionLong',
                type: 'text-ellipsis',
            },
            {
                title: '유의 사항',
                dataIndex: 'caution',
                type: 'text-ellipsis',
                width: '256px',
            },
        ]

        const createSpec: FormSpecification = {
            name: {
                label: '상품명',
                value: null,
            },
            category: {
                label: '카테고리',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-category',
            },
            giftShopName: {
                label: '구매처',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-giftshop',
            },
            originalPrice: {
                label: '가격',
                value: null,
                component: InputComponents.AInputNumber,
            },
            thumbnailUrl: {
                label: '썸네일',
                value: null,
                component: InputComponents.ImageInput,
            },
            mainImageUrl: {
                label: '상세 이미지',
                value: null,
                component: InputComponents.ImageInput,
            },
            descriptionShort: {
                label: '상품 설명',
                value: null,
            },
            descriptionLong: {
                label: '상품 상세 설명',
                value: null,
            },
            caution: {
                label: '유의 사항',
                value: null,
            },
            displayWeight: {
                label: '노출 가중치',
                value: null,
                component: InputComponents.AInputNumber,
            },
        }

        const createRules = {
            name: [required],
        }

        const updateSpec: FormSpecification = {
            name: {
                label: '상품명',
                value: null,
            },
            category: {
                label: '카테고리',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-category',
            },
            giftShopName: {
                label: '구매처',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-giftshop',
            },
            originalPrice: {
                label: '가격',
                value: null,
                component: InputComponents.AInputNumber,
            },
            thumbnailUrl: {
                label: '썸네일',
                value: null,
                component: InputComponents.ImageInput,
            },
            mainImageUrl: {
                label: '상세 이미지',
                value: null,
                component: InputComponents.ImageInput,
            },
            descriptionShort: {
                label: '상품 설명',
                value: null,
            },
            descriptionLong: {
                label: '상품 상세 설명',
                value: null,
            },
            caution: {
                label: '유의 사항',
                value: null,
            },
            displayWeight: {
                label: '노출 가중치',
                value: null,
                component: InputComponents.AInputNumber,
            },
            status: {
                label: '상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-status',
            },
        }

        const updateRules = {
            name: [required],
        }

        const filterSpec = ref({
            id: {
                label: 'ID',
                value: null,
                component: 'AInput',
            },
            name: {
                label: '상품명',
                value: null,
                component: 'AInput',
            },
            originPrice: {
                label: '상품 가격',
                value: null,
                component: 'AInputNumber',
            },
            pointPrice: {
                label: '포인트 가격',
                value: null,
                component: 'AInputNumber',
            },
            displayWeight: {
                label: '노출 가중치',
                value: null,
                component: 'AInputNumber',
            },
            status: {
                label: '상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'point-shop-product-status',
            },
        })

        const rowSelection = ref({
            selectedRowKeys: [],
            selectedRows: [],
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                rowSelection.value.selectedRowKeys = selectedRowKeys
                rowSelection.value.selectedRows = selectedRows
            },
        })

        return {
            loading,
            selectedRowKeys,
            columns,
            createSpec,
            createRules,
            updateSpec,
            updateRules,
            filterSpec,
            rowSelection,
        }
    },

    methods: {
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        updateProductStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                status: status,
            }
            const callUri = `${uri}/status`
            const queryParams = stringify(params, { indices: false })
            this.patchStatus(queryParams, callUri, search)
        },
        async patchStatus(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'point-shop-product-status',
            'point-shop-product-category',
            'point-shop-product-giftshop',
        ])
    },
})
</script>
