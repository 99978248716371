import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!

  return (_openBlock(), _createBlock(_component_a_popconfirm, {
    title: _ctx.title,
    "ok-text": "삭제",
    "cancel-text": "취소",
    onConfirm: _ctx.confirm,
    onCancel: _ctx.cancel,
    disabled: _ctx.disabled
  }, {
    default: _withCtx(() => [
      (_ctx.showButton)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            danger: "",
            disabled: _ctx.disabled
          }, {
            default: _withCtx(() => [
              _createVNode(_component_delete_outlined)
            ]),
            _: 1
          }, 8, ["disabled"]))
        : (_openBlock(), _createBlock(_component_delete_outlined, { key: 1 }))
    ]),
    _: 1
  }, 8, ["title", "onConfirm", "onCancel", "disabled"]))
}