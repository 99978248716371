<template>
    <span>
        <a-form-item v-if="mode" :name="name">
            <a-select
                :options="options"
                :value="valueByMode"
                :mode="mode"
                @change="change"
                @select="select"
                @search="fetchNutrient"
                :default-active-first-option="false"
                :not-found-content="null"
                :filter-option="false"
                :style="`width: ${width}`"
                show-search
                :disabled="disabled"
            />
        </a-form-item>
        <a-form-item v-else :name="name">
            <a-auto-complete
                :data-source="options"
                :value="value"
                @change="change"
                @select="select"
                @search="fetchNutrient"
                :style="`width: ${width}`"
                :disabled="disabled"
                :dropdown-match-select-width="700"
            />
        </a-form-item>
    </span>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash-es'
import { defineComponent, ref, Ref } from 'vue'
import { allowedChars } from '@/util/input-validation'

type NutrientName = {
    id: number
    name: string
    synonym: string
    unitExpression: string
    unitCode: string
    generalMax?: number
    generalMin?: number
    generalPodi?: number
    goodPoint?: string
}

export default defineComponent({
    name: 'NutrientInput',

    props: {
        prop: {
            type: String,
        },
        value: {
            type: [String, Array],
            default: '',
        },
        mode: {
            type: String,
        },
        width: {
            type: String,
            default: '20em',
        },
        name: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const options: Ref<Array<any>> = ref([])
        const fetchNutrient = debounce(async (name?: string) => {
            allowedChars.check(name!)
            if (name != null && name != '') {
                const response = await adminApi<
                    ApiResponse<Array<NutrientName>>
                >(`/api/nutrient/name/${name}`)

                if (response.success === false)
                    throw new Error(response.errorMessage)

                const nameOptions = response.result?.map(
                    (it: NutrientName, idx: number) => ({
                        label: it.synonym || it.name,
                        text: it.synonym || it.name,
                        value: it.synonym || it.name,
                        key: idx,
                        origin: it.name,
                        unit: it.unitCode,
                        generalMax: it.generalMax,
                        generalMin: it.generalMin,
                        generalPodi: it.generalPodi,
                        goodPoint: it.goodPoint,
                    })
                )
                const valueList = nameOptions?.map((it) => it.value)

                if (nameOptions != null) {
                    options.value.splice(
                        0,
                        options.value.length,
                        ...nameOptions
                            .filter(
                                (it, idx) =>
                                    valueList?.indexOf(it.value) === idx
                            )
                            .sort((x, y) => x.value.length - y.value.length)
                    )
                }
            }
        }, 100)

        return { options, fetchNutrient }
    },

    computed: {
        valueByMode: {
            get() {
                if (this.mode === 'tags' || this.mode === 'multiple') {
                    return this.value || []
                }
                return this.value
            },
            set(value: any) {
                this.$emit('update:value', value)
            },
        },
    },

    emits: ['input', 'select', 'update:value'],

    methods: {
        select(name: string) {
            const opt = this.options.filter((it) => it.text === name)[0]
            this.$emit('select', {
                name: opt?.origin,
                unit: opt?.unit,
                generalMax: opt?.generalMax,
                generalMin: opt?.generalMin,
                generalPodi: opt?.generalPodi,
                goodPoint: opt?.goodPoint,
            })
        },
        change(value: any) {
            return this.$emit('update:value', value)
        },
    },
})
</script>
