import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "benefit-section"
}
const _hoisted_2 = { class: "benefit-section-header" }
const _hoisted_3 = { class: "preview-layout" }
const _hoisted_4 = {
  key: 0,
  class: "multiple"
}
const _hoisted_5 = {
  key: 1,
  class: "single"
}
const _hoisted_6 = {
  key: 1,
  class: "benefit-section"
}
const _hoisted_7 = { class: "benefit-section-header" }
const _hoisted_8 = { class: "preview-layout" }
const _hoisted_9 = {
  key: 0,
  class: "multiple"
}
const _hoisted_10 = {
  key: 1,
  class: "single"
}
const _hoisted_11 = {
  key: 2,
  class: "benefit-section"
}
const _hoisted_12 = { class: "benefit-section-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ad_preview = _resolveComponent("ad-preview")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "scoped-container" }, {
    default: _withCtx(() => [
      (_ctx.list?.length > 0)
        ? (_openBlock(), _createBlock(_component_a_col, {
            key: 0,
            span: 24
          }, {
            default: _withCtx(() => [
              _createVNode(_component_draggable, {
                class: "list-group",
                itemKey: "id",
                list: _ctx.list || [],
                group: { name: 'instances' }
              }, {
                item: _withCtx(({ element, index }) => [
                  (element.instances.length >= 1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("span", null, _toDisplayString(element.slot.name), 1),
                          _createVNode(_component_a_button, {
                            danger: "",
                            onClick: ($event: any) => (_ctx.remove(index))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_delete_outlined)
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _createElementVNode("div", _hoisted_3, [
                          (element.instances.length > 1)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                _createVNode(_component_swiper, {
                                  "slides-per-view": 'auto',
                                  "space-between": 15
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.instances, (instance) => {
                                      return (_openBlock(), _createBlock(_component_swiper_slide, {
                                        class: "pillyze-swiper-slide",
                                        key: instance.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ad_preview, {
                                            class: "preview",
                                            index: index,
                                            instance: instance,
                                            "is-swiper-props": true
                                          }, null, 8, ["index", "instance"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.instances, (instance) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: instance.id
                                  }, [
                                    _createVNode(_component_ad_preview, {
                                      class: "preview",
                                      index: index,
                                      instance: instance,
                                      "is-swiper-props": false
                                    }, null, 8, ["index", "instance"])
                                  ]))
                                }), 128))
                              ]))
                        ])
                      ]))
                    : (element.baseInstances.length >= 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("span", null, _toDisplayString(element.slot.name), 1),
                            _createVNode(_component_a_button, {
                              danger: "",
                              onClick: ($event: any) => (_ctx.remove(index))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete_outlined)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            (element.baseInstances.length > 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                  _createVNode(_component_swiper, {
                                    "slides-per-view": 'auto',
                                    "space-between": 15
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.baseInstances, (instance) => {
                                        return (_openBlock(), _createBlock(_component_swiper_slide, {
                                          class: "pillyze-swiper-slide",
                                          key: instance.id
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ad_preview, {
                                              class: "preview",
                                              index: index,
                                              instance: instance,
                                              "is-swiper-props": true
                                            }, null, 8, ["index", "instance"])
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(element.baseInstances, (instance) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: instance.id
                                    }, [
                                      _createVNode(_component_ad_preview, {
                                        class: "preview",
                                        index: index,
                                        instance: instance,
                                        "is-swiper-props": false
                                      }, null, 8, ["index", "instance"])
                                    ]))
                                  }), 128))
                                ]))
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("span", null, _toDisplayString(element.slot.name), 1),
                            _createVNode(_component_a_button, {
                              danger: "",
                              onClick: ($event: any) => (_ctx.remove(index))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete_outlined)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _cache[0] || (_cache[0] = _createElementVNode("div", null, "데이터 없음", -1))
                        ]))
                ]),
                _: 1
              }, 8, ["list"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_a_col, {
            key: 1,
            span: 24
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("데이터 없음")
            ])),
            _: 1
          }))
    ]),
    _: 1
  }))
}