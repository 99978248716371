import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import AutoCompleteInput from '@/components/input/AutoCompleteInput.vue'
import router from '@/router'
import { MinusOutlined } from '@ant-design/icons-vue'



export default /*@__PURE__*/_defineComponent({
  __name: 'FoodInput',
  props: {
    value: {
        type: [Number, String],
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '100%',
    },
    mode: {
        type: String,
        default: 'default',
    },
},
  emits: ['update:value', 'select'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const showFood = (id: number | undefined) => {
    router.push({
        path: `/userFeedback/food/edit/${id}?visible=true`,
    })
}

const change = (value: any) => {
    emit('update:value', value)
}

const select = (value: any) => {
    emit('select', value)
}

const resetValue = () => {
    emit('update:value', null)
}

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(AutoCompleteInput, {
      label: __props.label,
      uri: "/api/food/food-name",
      width: __props.width,
      value: __props.value,
      "onUpdate:value": change,
      onSelect: select,
      mode: __props.mode,
      disabled: __props.disabled,
      style: {"display":"inline-block"}
    }, null, 8, ["label", "width", "value", "mode", "disabled"]),
    _createVNode(_component_a_button, {
      onClick: _withModifiers(resetValue, ["prevent"]),
      style: {"margin-left":"5px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(MinusOutlined))
      ]),
      _: 1
    })
  ]))
}
}

})