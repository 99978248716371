<template>
    <div>
        <a-card title="인기질문">
            <a-list
                item-layout="horizontal"
                :data-source="featureList"
                :loading="listLoading"
            >
                <template #renderItem="{ item }">
                    <a-list-item>
                        <a-list-item-meta :description="item.questionContent">
                            <template #title>
                                {{ `ID ${item.id}. ${item.title}` }}
                            </template>

                            <template #avatar>
                                <a-avatar :src="item.ownerProfileImageUrl" />
                            </template>
                        </a-list-item-meta>
                    </a-list-item>
                </template>
            </a-list>
        </a-card>
        <ResourceTable
            title="질문"
            uri="/api/v1/questions"
            :filter-spec="filterSpec"
            :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
            }"
            :scroll="{ x: 2000 }"
            :columns="columns"
            :update-spec="updateSpec"
            :action-spec="{}"
            action-modal-title="알림"
            action-uri="/api/v1/questions/reply"
            :actionModalDisabled="actionModalDisabled"
            :create-rules="rules"
            :update-rules="rules"
            exportable
            load-on-mount
            @after-search="fetchFeatureList"
        >
            <template #updateModalHeader="{ form }">
                <NutrientAnalyzeReport
                    v-if="form.userId && form.productIds"
                    ref="report"
                    :user-id="form.userId"
                    :product-ids="form.productIds"
                />
            </template>
            <template #actionModalHeader="{ record }">
                <a-descriptions :column="1">
                    <a-descriptions-item label="ID">{{
                        `${record.id}를 처리하시겠습니까?`
                    }}</a-descriptions-item>
                    <a-descriptions-item label="제목">{{
                        record.title
                    }}</a-descriptions-item>
                    <a-descriptions-item label="내용">{{
                        record.questionContent
                    }}</a-descriptions-item>
                    <a-descriptions-item label="답변">{{
                        record.answerContentText
                    }}</a-descriptions-item>
                </a-descriptions>
            </template>
            <template #actions="{ uri, search }">
                <a-button style="color: black" :loading="loading" disabled
                    >질문 상태 변경</a-button
                >
                <a-button @click="() => updateStatus('NORMAL', uri, search)"
                    >정상</a-button
                >
                <a-button
                    @click="() => updateStatus('ADMIN_BLIND', uri, search)"
                    >블라인드</a-button
                >
                <a-button @click="() => toggleFeatured(uri, search)"
                    >인기 선정/비선정</a-button
                >
                <a-button
                    @click="
                        () =>
                            updateResponseStatus(
                                'IN_PROGRESS',
                                `${uri}/response`,
                                search
                            )
                    "
                    >처리중</a-button
                >
                <a-button
                    @click="
                        () =>
                            updateResponseStatus(
                                'IGNORE',
                                `${uri}/response`,
                                search
                            )
                    "
                    >무시</a-button
                >
                <BulkActionModal
                    @after-submit="search"
                    :resource-ids="selectedRowKeys"
                    :uri="`${uri}/priority`"
                    :input-spec="prioritySpec"
                    label="우선순위 변경"
                />
            </template>
            <template #detail="{ record }">
                <a-descriptions
                    title="질문 상세"
                    bordered="true"
                    layout="vertical"
                    column="1"
                    size="small"
                >
                    <a-descriptions-item label="사용자 ID">
                        {{ record.userId }}
                    </a-descriptions-item>
                    <a-descriptions-item label="제목">
                        {{ record.title }}
                    </a-descriptions-item>
                    <a-descriptions-item label="사용자 정보">
                        {{ record.userAgeGenderCondition }}
                    </a-descriptions-item>
                    <a-descriptions-item label="내용" :span="3">
                        {{ record.questionContent }}
                    </a-descriptions-item>
                    <a-descriptions-item label="건강목표">
                        {{ record.userHigBroadSet }}
                    </a-descriptions-item>
                    <a-descriptions-item label="복용약물">
                        {{ record.userDrugSet }}
                    </a-descriptions-item>
                    <a-descriptions-item label="기저질병">
                        {{ record.userDiseaseSet }}
                    </a-descriptions-item>
                    <a-descriptions-item label="건강특수상태">
                        {{ record.userConditionSet }}
                    </a-descriptions-item>
                    <a-descriptions-item label="건강검진">
                        {{ record.userMcuDiagnosisSet }}
                    </a-descriptions-item>
                    <a-descriptions-item label="이미지">
                        <a-image-preview-group>
                            <a-image
                                v-for="(imageUrl, index) in record.images"
                                :key="index"
                                :width="200"
                                :src="imageUrl"
                            />
                        </a-image-preview-group>
                    </a-descriptions-item>
                    <a-descriptions-item label="질문 제품">
                        <ul>
                            <li v-for="p in record.targetProducts" :key="p.id">
                                <a :href="getProductUrl(p.productId)">
                                    {{ p.productName }}
                                </a>
                            </li>
                        </ul>
                    </a-descriptions-item>
                    <a-descriptions-item label="섭취제품">
                        <ul>
                            <li
                                v-for="p in getParsed(
                                    record.userIntakeProductSnapshot
                                )"
                                :key="p.id"
                            >
                                <a :href="getProductUrl(p.productId)">
                                    {{ p.productName }}
                                </a>
                            </li>
                        </ul>
                    </a-descriptions-item>
                </a-descriptions>
            </template>
            <template #customAction="{ record }">
                <a-button
                    type="primary"
                    :disabled="!record.userSnapshotId"
                    :loading="mockLoading"
                    @click="mockUser(record.userSnapshotId)"
                >
                    복사
                </a-button>
            </template>
            <template #actionModalIcon>
                <NotificationOutlined />
            </template>
        </ResourceTable>
    </div>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import {
    formatBoolean,
    formatEnum,
    formatLocalDateTime,
} from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import tableSupport from './tableSupport'
import { InputComponents, SelectInputMode } from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import { NotificationOutlined } from '@ant-design/icons-vue'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import NutrientAnalyzeReport from './NutrientAnalyzeReport.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'QuestionView',
    extends: tableSupport,
    components: {
        ResourceTable,
        NotificationOutlined,
        BulkActionModal,
        NutrientAnalyzeReport,
    },
    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const loading = ref(false)
        const listLoading = ref(false)
        const featureList = ref<Array<any>>([])
        const onSelectChange = (keys: Array<number>) => {
            selectedRowKeys.value = keys
        }
        const fetchFeatureList = async () => {
            listLoading.value = true
            const response = await adminApi<ApiResponse<Array<any>>>(
                `/api/v1/questions/feature`
            )
            listLoading.value = false
            featureList.value = response.result || []
        }

        return {
            selectedRowKeys,
            loading,
            onSelectChange,
            fetchFeatureList,
            featureList,
            listLoading,
        }
    },

    methods: {
        updateStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                questionStatus: status,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateQuestion(queryParams, uri, search)
        },

        updateResponseStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                questionResponseStatus: status,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateQuestion(queryParams, uri, search)
        },

        getParsed(jsonText: string) {
            if (jsonText == undefined) {
                return []
            }
            return JSON.parse(jsonText)
        },

        getProductUrl(id: number) {
            return `https://www.pillyze.com/products/${id}/관리자`
        },

        async updateQuestion(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },

        async toggleFeatured(uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
            }
            this.loading = true
            await adminApi<ApiResponse<void>>(
                `${uri}/toggle-featured?${stringify(params, {
                    indices: false,
                })}`,
                {
                    method: 'PATCH',
                }
            )
            this.loading = false
            await search()
        },

        actionModalDisabled(record: Record<string, any>) {
            return record.notifiedAt != null
        },

        async mockUser(snapshotId: string) {
            try {
                this.mockLoading = true
                await adminApi<ApiResponse<boolean>>(
                    `/api/v1/questions/mock/${snapshotId}`,
                    {
                        method: 'GET',
                    }
                )
                this.mockLoading = false
            } catch (error) {
                console.error(error)
            }
        },
    },

    data() {
        return {
            mockLoading: false,
            updateSpec: {
                userId: {
                    label: '유저 ID',
                    value: null,
                    readonly: true,
                },
                title: {
                    label: '질문 제목',
                    value: null,
                    type: 'textarea',
                    readonly: true,
                },
                titleForSeo: {
                    label: '질문 제목 (SEO용)',
                    value: null,
                    type: 'textarea',
                    readonly: false,
                },
                questionContent: {
                    label: '질문 내용',
                    value: null,
                    type: 'textarea',
                    readonly: true,
                },
                answerContent: {
                    label: '답변(HTML)',
                    value: null,
                    component: 'Tiptap',
                    help: '답변불가인 경우 내용에 "답변불가"를 적어주세요. 자동으로 알림 발송되지 않습니다.',
                },
                answerContentText: {
                    label: '답변(Text)',
                    value: null,
                    type: 'textarea',
                    help: '공백으로 두면 HTML 에서 자동으로 생성 됩니다.',
                },
                priority: {
                    label: '순서번호',
                    value: null,
                    component: 'AInputNumber',
                },
                isUnanswerable: {
                    label: '답변불가',
                    value: 'false',
                    component: 'BooleanInput',
                    trueText: '답변불가',
                    falseText: '답변가능',
                    notNull: true,
                },
                productIds: {
                    label: '관련제품',
                    value: null,
                    component: 'ProductInput',
                    mode: 'multiple',
                },
                nutrientGroupIds: {
                    label: '관련영양소',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/nutrient-group/search',
                    mode: 'multiple',
                },
                etcKeywords: {
                    label: '기타 키워드',
                    value: [],
                    component: 'ASelect',
                    mode: SelectInputMode.TAGS,
                },
                userDiseaseSet: {
                    label: '사용자 기저질환',
                    value: [],
                    readonly: true,
                },
                userHigBroadSet: {
                    label: '사용자 건강목표',
                    value: [],
                    readonly: true,
                },
                userMcuDiagnosisSet: {
                    label: '사용자 건강검진',
                    value: [],
                    readonly: true,
                },
                userAllergySet: {
                    label: '사용자 알러지',
                    value: [],
                    readonly: true,
                },
                userConditionSet: {
                    label: '사용자 건강특수상태',
                    value: [],
                    readonly: true,
                },
                diseaseSet: {
                    label: '기저질환',
                    value: [],
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/health-status/disease/name',
                    mode: SelectInputMode.MULTIPLE,
                },
                drugSet: {
                    label: '복용약물',
                    value: [],
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/drug/name2',
                    mode: SelectInputMode.MULTIPLE,
                },
                higSet: {
                    label: '건강목표',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/hig/name',
                    mode: 'multiple',
                },
                dietSet: {
                    label: '식단',
                    value: [],
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/diet/name',
                    mode: SelectInputMode.MULTIPLE,
                },
                allergySet: {
                    label: '알러지',
                    value: [],
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/health-status/allergy/name',
                    mode: SelectInputMode.MULTIPLE,
                },
                conditionSet: {
                    label: '건강특수상태',
                    value: [],
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/health-status/condition/name',
                    mode: SelectInputMode.MULTIPLE,
                },
                questionType: {
                    label: '질문유형',
                    value: null,
                    component: InputComponents.EnumSelect,
                    typeName: 'question-type',
                },
                questionImage: {
                    label: 'QnA 이미지',
                    value: null,
                    component: InputComponents.QuestionImageInput,
                },
            },
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInputNumber' },
                userId: {
                    label: '사용자 ID',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/user/id',
                    mode: 'multiple',
                },
                title: {
                    label: '질문 제목',
                    value: null,
                },
                questionContent: {
                    label: '질문 내용',
                    value: null,
                },
                answerContent: {
                    label: '답변 내용',
                    value: null,
                },
                reportedCount: {
                    label: '신고 수',
                    value: null,
                    component: 'AInputNumber',
                },
                questionStatus: {
                    label: '질문상태',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'question-status',
                    input: () => {
                        this.reload()
                    },
                },
                answerStatus: {
                    label: '답변상태',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'answer-status',
                    input: () => {
                        this.reload()
                    },
                },
                isFeatured: {
                    label: '인기여부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '인기',
                    falseText: '비인기',
                },
                creationTime: {
                    label: '등록일',
                    value: null,
                    component: 'ARangePicker',
                },
                questionContentClusterId: {
                    label: '질문 클러스터 ID',
                    value: null,
                    component: 'AInputNumber',
                    help: '질문 클러스터 ID를 입력하면 유사한 질문을 검색합니다.',
                    labelCol: { span: 24 },
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 30,
                },
                {
                    title: '사용자 ID',
                    dataIndex: 'userId',
                    width: 100,
                },
                {
                    title: '질문 수',
                    dataIndex: 'questionCount',
                    align: 'center',
                    width: 50,
                },
                {
                    title: '제목',
                    dataIndex: 'title',
                    width: 200,
                },
                {
                    title: '답변',
                    dataIndex: 'answerStatus',
                    width: 70,
                },
                {
                    title: '신고 수',
                    dataIndex: 'reportedCount',
                    align: 'center',
                    width: 50,
                },
                {
                    title: '질문 클러스터 ID',
                    dataIndex: 'questionContentClusterId',
                    width: 120,
                },
                {
                    title: '상태',
                    dataIndex: 'questionStatus',
                    customRender: formatEnum('question-status'),
                    width: 50,
                },
                {
                    title: '처리 상태',
                    dataIndex: 'questionResponseStatus',
                    customRender: formatEnum('question-response-status'),
                    width: 50,
                },
                {
                    title: '담당자',
                    dataIndex: 'owner',
                    width: 60,
                    align: 'center',
                    type: 'owner',
                },
                {
                    title: '인기질문',
                    dataIndex: 'isFeatured',
                    customRender: formatBoolean,
                    width: 50,
                },
                {
                    title: '답변자 ID',
                    dataIndex: 'answererId',
                    width: 50,
                    align: 'center',
                },
                {
                    title: '답변시간',
                    dataIndex: 'answerTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    sorter: true,
                    width: 165,
                },
                {
                    title: '수정자',
                    dataIndex: 'updatedBy',
                    width: 60,
                    align: 'center',
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    sorter: true,
                    width: 165,
                },
                {
                    title: '좋아요(총계)',
                    dataIndex: 'answerFavedCount',
                    width: 100,
                    align: 'center',
                    fixed: 'right',
                    sorter: true,
                },
                {
                    title: '스냅샷복사',
                    type: 'customAction',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                },
                {
                    title: '상세',
                    dataIndex: 'detail',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    type: 'detail',
                },
            ],
            rules: {
                answerContent: [required],
            },
            prioritySpec: {
                priority: {
                    label: '우선순위',
                    value: null,
                    component: 'AInputNumber',
                },
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'question-status',
            'question-response-status',
            'answer-status',
            'question-type',
        ])
    },
})
</script>
