import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resource_table = _resolveComponent("resource-table")!

  return (_openBlock(), _createBlock(_component_resource_table, {
    title: "식단 영양소 목표",
    uri: "/api/food-nutrient-goal",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    scroll: { x: 1000, y: 650 },
    columns: _ctx.columns,
    "load-on-mount": "",
    deletable: ""
  }, null, 8, ["create-spec", "update-spec", "filter-spec", "create-rules", "update-rules", "columns"]))
}