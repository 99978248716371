import { adminApiWithResponse, baseUrl } from '@/fetchTemplate'
import router from '@/router'
import { defineStore } from 'pinia'

export type User = {
    id: number | null
    userName: string | null
    profileName: string | null
    profileImageUrl: string | null
    profiles: Array<any>
    contactLink: Record<any, any> | null
    role: string | null
    kakaoToken: string | null
    loginType: 'KAKAO' | 'AWS_COGNITO' | null
    bindUserId: string | null | number
    cognitoUsername: string | null
}

const defaultState: User = {
    id: null,
    userName: null,
    profileName: null,
    profileImageUrl: null,
    profiles: [],
    contactLink: null,
    role: null,
    kakaoToken: null,
    loginType: null,
    bindUserId: null,
    cognitoUsername: null,
}

export enum Role {
    ADMIN = 'ADMIN',
    RESEARCHER = 'RESEARCHER',
    PHARMACIST = 'PHARMACIST',
}

export const useUserStore = defineStore('user', {
    persist: true,
    state: () => ({ ...defaultState }),
    getters: {
        authorities: (state: User): Array<string> => {
            if (state.role === Role.ADMIN) {
                return [Role.ADMIN, Role.RESEARCHER, Role.PHARMACIST]
            } else if (state.role === Role.RESEARCHER) {
                return [Role.RESEARCHER]
            } else if (state.role === Role.PHARMACIST) {
                return [Role.PHARMACIST]
            } else {
                return []
            }
        },
    },
    actions: {
        async fetchUser() {
            try {
                const jsonBody: any = await adminApiWithResponse(
                    '/api/user'
                ).then((resp) => {
                    if (resp.redirected) {
                        window.location.href = `${baseUrl}/api/auth/frontend`
                    }
                    if (resp.status === 403) {
                        router.push('/login')
                    }
                    return resp.json()
                })
                const user = jsonBody.result
                this.id = user.id
                this.userName = user.userName
                this.profileName = user.profileName
                this.profileImageUrl = user.profileImageUrl
                this.role = user.role
                this.profiles = user.profiles
                this.contactLink = user.contactLink
                this.kakaoToken = user.kakaoToken
                this.loginType = user.loginType
                this.cognitoUsername = user.cognitoUsername
                this.bindUserId = user.bindUserId

                if (this.userName == '') {
                    this.userName = '탈퇴한 사용자'
                }
            } catch (error) {
                this.id = null
                this.userName = null
                this.role = null
                console.error(error)
            }
        },

        setToken(kakaoToken: string) {
            this.kakaoToken = kakaoToken
        },
    },
})
