import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = { id: "pipeline-tag" }
const _hoisted_2 = { class: "resource--page" }

import ResourceTable from '@/components/ResourceTable.vue'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import Draggable from 'vuedraggable'
import { ArrowRightOutlined } from '@ant-design/icons-vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { computed, onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import UpdateFormModal from '@/components/modal/UpdateFormModal.vue'
import { required } from '@/util/input-validation'
import DeletePopconfirm from '@/components/DeletePopconfirm.vue'
import FilterForm from '@/components/FilterForm.vue'
import { useEnumTypeStore } from '@/store/enumType'

enum TriggerMessageType {
    ACTION = 'ACTION',
    EMOTION = 'EMOTION',
    AD_HOC = 'AD_HOC',
}

type Rule = {
    id: number
    ruleName: string
    otInterest: string
    triggerMessageType: TriggerMessageType
    createdBy: string
    creationTime: string
    updatedBy: string
    updateTime: string
    priority: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerBox',
  setup(__props) {

const resourceTable = ref()

const rulePipeline = ref<Record<string, Array<Rule>>>({})
const initPipeline = ref<Record<string, Array<Rule>>>({})

const tagColorSet = {
    [TriggerMessageType.ACTION]: 'orange',
    [TriggerMessageType.EMOTION]: 'green',
    [TriggerMessageType.AD_HOC]: 'purple',
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '이름',
        dataIndex: 'ruleName',
    },
    {
        title: 'OT 선호',
        dataIndex: 'otInterest',
        customRender: formatEnum('ot-interest'),
    },
    {
        title: '메세지 유형',
        dataIndex: 'triggerMessageType',
        customRender: formatEnum('trigger-message-type'),
    },
    {
        title: '등록자',
        dataIndex: 'createdBy',
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const afterSearch = async () => {
    const response = await adminApi<ApiResponse<Array<Rule>>>(
        '/api/trigger-box/queue'
    )
    const ruleList = response.result || []
    rulePipeline.value = ruleList.reduce((acc, cur) => {
        if (acc[cur.otInterest] == null) {
            acc[cur.otInterest] = []
        }
        acc[cur.otInterest].push(cur)
        acc[cur.otInterest].sort((a, b) => a.id - b.id)
        acc[cur.otInterest].sort((a, b) => a.priority - b.priority)
        return acc
    }, {} as Record<string, Array<Rule>>)
    initPipeline.value = { ...rulePipeline.value }
}

const orderNode = async (event: any, otInterest: string) => {
    const oldOrder = initPipeline.value[otInterest].map((it) => it.id)
    const newOrder = rulePipeline.value[otInterest].map((it) => it.id)

    await adminApi(`/api/trigger-box/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            otInterest,
            oldOrder,
            newOrder,
        }),
    })
    resourceTable.value.onSearch()
}

const filterSpec = ref<FormSpecification>({
    triggerBoxRuleName: {
        label: '룰 이름',
        value: null,
    },
    otInterest: {
        label: 'OT 선호',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'ot-interest',
    },
    triggerBoxRuleTriggerBoxType: {
        label: '메세지 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-message-type',
    },
    triggerBoxRuleSchedule: {
        label: '스케줄',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-schedule',
    },
    triggerBoxRuleTriggerCount: {
        label: '트리거 횟수',
        component: InputComponents.AInputNumber,
        value: null,
        help: '트리거 횟수를 입력해주세요.(0이면 무제한)',
    },
})

const createSpec = ref<FormSpecification>({
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    otInterest: {
        label: 'OT 선호',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'ot-interest',
    },
    triggerMessageType: {
        label: '메세지 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-message-type',
    },
    viewTypes: {
        label: '뷰 타입',
        value: [],
        component: InputComponents.EnumSelect,
        typeName: 'trigger-box-view-type',
        mode: SelectInputMode.MULTIPLE,
    },
    schedule: {
        label: '스케줄',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-schedule',
    },
    triggerCount: {
        label: '트리거 횟수',
        component: InputComponents.AInputNumber,
        value: null,
        help: '트리거 횟수를 입력해주세요.(0이면 무제한)',
    },
})

const createRules = {
    ruleName: [required],
    otInterest: [required],
    triggerMessageType: [required],
    schedule: [required],
    triggerCount: [required],
    osType: [required],
}

const contentRules = {
    ruleName: [required],
    design: [required],
    osType: [required],
    showCount: [required],
    minVersion: [required],
}

const createContentSpec = ref<FormSpecification>({
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    iconUrl: {
        label: '아이콘 URL',
        component: InputComponents.ImageInput,
        path: '/trigger_box/icon',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    description: {
        label: '설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    appendix: {
        label: '부가 설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    button: {
        label: '버튼 라벨',
        value: null,
    },
    link: {
        label: 'link',
        value: null,
    },
    deepLink: {
        label: 'deepLink',
        value: null,
    },
    design: {
        label: '디자인',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-box-design',
    },
    minVersion: {
        label: '최소 버전',
        value: null,
        component: InputComponents.AInputNumber,
    },
    osType: {
        label: 'OS 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'os-type',
    },
    startAt: {
        label: '시작일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 만료일만 영향을 줍니다.',
    },
    expiredAt: {
        label: '만료일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 시작일만 영향을 줍니다. 둘다 없다면 일자제한이 없습니다.',
    },
    showCount: {
        label: '노출 횟수',
        value: null,
        component: InputComponents.AInputNumber,
        min: 0,
        help: '입력값이 0이면 무제한입니다.',
    },
    repeatIcon: {
        label: 'icon 반복',
        value: false,
        component: InputComponents.BooleanInput,
    },
})

const triggerBoxContent = ref<Record<any, any>>({})
const filteredContent = computed(() => {
    const filterRuleName = filterContentSpec.value.ruleName.value
    if (filterRuleName) {
        return Object.entries(triggerBoxContent.value).reduce(
            (acc, [ruleName, values]) => {
                if (ruleName.includes(filterRuleName)) {
                    return { ...acc, [ruleName]: values }
                }
                return acc
            },
            {} as Record<any, any>
        )
    }
    return triggerBoxContent.value
})

const fetchContentList = async () => {
    const response = await adminApi<ApiResponse<Array<any>>>(
        '/api/trigger-box/content'
    )
    triggerBoxContent.value = response?.result?.reduce((acc, cur) => {
        acc[cur.ruleName] = [cur, ...(acc[cur.ruleName] || [])]
        return acc
    }, {} as Record<string, any>)
}

const filterContentSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        value: null,
    },
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
    },
})

const updateContentSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        value: null,
        readonly: true,
    },
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    iconUrl: {
        label: '아이콘 URL',
        component: InputComponents.ImageInput,
        path: '/trigger_box/icon/',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    description: {
        label: '설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    appendix: {
        label: '부가 설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    button: {
        label: '버튼 라벨',
        value: null,
    },
    link: {
        label: 'link',
        value: null,
    },
    deepLink: {
        label: 'deepLink',
        value: null,
    },
    design: {
        label: '디자인',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-box-design',
    },
    minVersion: {
        label: '최소 버전',
        value: null,
        component: InputComponents.AInputNumber,
    },
    osType: {
        label: 'OS 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'os-type',
    },
    startAt: {
        label: '시작일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 만료일만 영향을 줍니다.',
    },
    expiredAt: {
        label: '만료일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 시작일만 영향을 줍니다. 둘다 없다면 일자제한이 없습니다.',
    },
    showCount: {
        label: '노출 횟수',
        value: null,
        component: InputComponents.AInputNumber,
        min: 0,
        help: '0일시 노출횟수 제한이 없습니다.',
    },
    repeatIcon: {
        label: 'icon 반복',
        value: false,
        component: InputComponents.BooleanInput,
    },
})

onMounted(async () => {
    const enumTypeStore = useEnumTypeStore()
    await enumTypeStore.dispatchEnums([
        'os-type',
        'ot-interest',
        'trigger-box-content-format-args',
        'trigger-schedule',
        'trigger-box-design',
        'trigger-box-view-type',
    ])
    await fetchContentList()
})

return (_ctx: any,_cache: any) => {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_a_card_grid = _resolveComponent("a-card-grid")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_card, {
      title: "트리거박스 파이프라인",
      style: {"padding":"50px"}
    }, {
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("p", null, "드래그하여 순서를 조정할 수 있습니다.", -1)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(rulePipeline.value), (otInterest) => {
          return (_openBlock(), _createElementBlock("div", {
            key: otInterest,
            style: {"margin-bottom":"2em"}
          }, [
            (otInterest == 'DIET')
              ? (_openBlock(), _createBlock(_component_a_typography_title, {
                  key: 0,
                  type: "secondary",
                  level: 4
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("식단 선호 파이프라인")
                  ])),
                  _: 1
                }))
              : (otInterest == 'NUTRIENT')
                ? (_openBlock(), _createBlock(_component_a_typography_title, {
                    key: 1,
                    type: "secondary",
                    level: 4
                  }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" 영양제 선호 파이프라인 ")
                    ])),
                    _: 1
                  }))
                : (otInterest == 'GLUCOSE')
                  ? (_openBlock(), _createBlock(_component_a_typography_title, {
                      key: 2,
                      type: "secondary",
                      level: 4
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" 혈당 선호 파이프라인 ")
                      ])),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
            _createVNode(_unref(Draggable), {
              modelValue: rulePipeline.value[otInterest],
              "onUpdate:modelValue": ($event: any) => ((rulePipeline.value[otInterest]) = $event),
              onChange: (event) => orderNode(event, otInterest),
              "item-key": "id"
            }, {
              item: _withCtx(({ element }) => [
                _createElementVNode("span", _hoisted_1, [
                  _createVNode(_component_a_tag, {
                    color: tagColorSet[element.triggerMessageType],
                    style: {"display":"inline-block\n                                padding: 5px\n                                margin-bottom: 10px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(element.ruleName), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"]),
                  (
                                rulePipeline.value[otInterest][
                                    rulePipeline.value[otInterest].length - 1
                                ].id !== element.id
                            )
                    ? (_openBlock(), _createBlock(_unref(ArrowRightOutlined), {
                        key: 0,
                        style: {"padding-right":"10px"}
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
          ]))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(ResourceTable, {
      ref_key: "resourceTable",
      ref: resourceTable,
      "resource-name": "trigger-box",
      title: "트리거 박스",
      uri: "/api/trigger-box",
      columns: columns,
      "filter-spec": filterSpec.value,
      "create-spec": createSpec.value,
      "create-rules": createRules,
      "update-spec": createSpec.value,
      "update-rules": createRules,
      onAfterSearch: afterSearch,
      "load-on-mount": "",
      deletable: ""
    }, null, 8, ["filter-spec", "create-spec", "update-spec"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_page_header, { title: "트리거 박스 메시지폼" }),
      _createVNode(FilterForm, {
        ref: "filterForm",
        "filter-spec": filterContentSpec.value,
        "input-only": ""
      }, null, 8, ["filter-spec"]),
      _createVNode(_component_a_space, { style: {"margin-bottom":"40px"} }, {
        default: _withCtx(() => [
          _createVNode(CreateFormModal, {
            title: "메시지폼 등록",
            label: "메시지폼 등록",
            uri: "/api/trigger-box/content",
            rules: contentRules,
            "input-spec": createContentSpec.value,
            onAfterSubmit: fetchContentList
          }, null, 8, ["input-spec"]),
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: fetchContentList
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("조회")
            ])),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(filteredContent.value), (ruleName) => {
        return (_openBlock(), _createElementBlock("div", { key: ruleName }, [
          _createVNode(_component_a_typography_title, {
            type: "secondary",
            level: 3
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(ruleName), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_list, {
            grid: { gutter: 16, column: 3 },
            "data-source": filteredContent.value[ruleName]
          }, {
            renderItem: _withCtx(({ item }) => [
              _createVNode(_component_a_list_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_card, {
                    title: `${item.title}#${item.id}`,
                    hoverable: ""
                  }, {
                    actions: _withCtx(() => [
                      _createVNode(UpdateFormModal, {
                        title: "메세지폼 수정",
                        label: "메세지폼 수정",
                        "resource-id": item.id,
                        uri: "/api/trigger-box/content",
                        rules: contentRules,
                        "input-spec": updateContentSpec.value,
                        onAfterSubmit: fetchContentList,
                        "show-button": false
                      }, null, 8, ["resource-id", "input-spec"]),
                      _createVNode(DeletePopconfirm, {
                        title: `${item.id}를 삭제하시겠습니까?`,
                        uri: `/api/trigger-box/content/${item.id}`,
                        onAfterDelete: fetchContentList,
                        "show-button": false
                      }, null, 8, ["title", "uri"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_a_card_grid, {
                        style: {"width":"25%","text-align":"center","box-shadow":"none"},
                        hoverable: false
                      }, {
                        default: _withCtx(() => [
                          (
                                        item.iconUrl == null ||
                                        !item.iconUrl.endsWith('.json')
                                    )
                            ? (_openBlock(), _createBlock(_component_a_avatar, {
                                key: 0,
                                src: item.iconUrl,
                                shape: "square",
                                size: 64
                              }, null, 8, ["src"]))
                            : (
                                        item.iconUrl != null &&
                                        item.iconUrl.endsWith('.json')
                                    )
                              ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                                  key: 1,
                                  animationLink: item.iconUrl,
                                  width: 63,
                                  height: 63
                                }, null, 8, ["animationLink"]))
                              : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_a_card_grid, {
                        style: {"width":"75%","box-shadow":"none"},
                        hoverable: false
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("p", null, _toDisplayString(item.description), 1),
                          _createVNode(_component_a_button, { style: {"background-color":"#6c2ff2","color":"white"} }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.button), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["title"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["data-source"])
        ]))
      }), 128))
    ])
  ], 64))
}
}

})