import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-event" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ad_benefit_screen_current = _resolveComponent("ad-benefit-screen-current")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_ad_benefit_screen_table = _resolveComponent("ad-benefit-screen-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "overlay"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_spin)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 6,
          class: "preview"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_a_button, {
                type: "primary",
                class: "btn-save",
                onClick: _ctx.save
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" 저장 ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createVNode(_component_ad_benefit_screen_current, {
              "props-list": _ctx.list,
              onRemove: _ctx.remove
            }, null, 8, ["props-list", "onRemove"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 18 }, {
          default: _withCtx(() => [
            _createVNode(_component_ad_benefit_screen_table, { "onUpdate:add": _ctx.updatePublishGroups }, null, 8, ["onUpdate:add"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}