import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ResourceTable from '@/components/ResourceTable.vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LibrePractice',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
]

const inputSpec = ref({
    email: {
        label: 'Email',
        rules: { required: true },
    },
    password: {
        label: 'Password',
        rules: { required: true },
    },
    deviceToken: {
        label: 'Device token',
        rules: { required: true },
    },
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "ai-coaching-schedule",
    title: "리브레 병원 계정 관리",
    uri: "/api/libre-practice",
    columns: columns,
    "create-spec": inputSpec.value,
    "update-spec": inputSpec.value,
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec"]))
}
}

})