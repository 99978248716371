<template>
    <resource-table
        title="식단 신호등"
        uri="/api/food-intake-advisor"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1800, y: 650 }"
        :columns="columns"
        load-on-mount
        exportable
    />
</template>

<script lang="ts">
import { formatKoreanNumber } from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FoodIntakeAdvisor',
    components: {
        ResourceTable,
    },
    data() {
        const filterSpec: FormSpecification = {
            advisorType: {
                label: '신호등 타입',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'food-intake-advisor-type',
            },
            nutrientAllowanceGroupName: {
                label: '영양소 섭취그룹명',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
                width: '200px',
                labelCol: 8,
            },
            isGood: {
                label: '추천여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '추천',
                falseText: '비추천',
            },
        }

        const createSpec = {
            isGood: {
                label: '추천여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '추천',
                falseText: '비추천',
            },
            advisorTarget: {
                label: '신호등 적용대상',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'intake-advisor-target',
            },
            advisorType: {
                label: '신호등 타입',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'food-intake-advisor-type',
            },
            dietName: {
                label: '식단 종류(Diet)',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'diet-name',
            },
            healthStatusCode: {
                label: 'Health Status',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/name',
                mode: true,
            },
            higBroadName: {
                label: 'HIG broad',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
            },
            higDetailName: {
                label: 'HIG detail',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-detail-name',
            },
            nutrientAllowanceGroupName: {
                label: '영양소 섭취그룹명',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
            },
            gender: {
                label: '성별',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'gender',
            },
            ageMin: {
                label: '최소 나이(min)',
                value: null,
                component: 'AInputNumber',
            },
            ageMax: {
                label: '최대 나이(max)',
                value: null,
                component: 'AInputNumber',
            },
            ratioMin: {
                label: 'ratioMin',
                value: null,
                component: 'AInputNumber',
            },
            ratioPodi: {
                label: 'ratioPodi',
                value: null,
                component: 'AInputNumber',
            },
            ratioMax: {
                label: 'ratioMax',
                value: null,
                component: 'AInputNumber',
            },
            min: {
                label: 'min',
                value: null,
                component: 'AInputNumber',
            },
            podi: {
                label: 'podi',
                value: null,
                component: InputComponents.AInputNumber,
            },
            max: {
                label: 'max',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            description: {
                label: '설명',
                value: null,
            },
            descriptionShort: {
                label: '설명 (Short)',
                value: null,
            },
            memo: {
                label: '메모',
                value: null,
            },
            priority: {
                label: '중요도',
                value: null,
                component: 'AInputNumber',
            },
            recommend: {
                label: '관리영양소 노출여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '노출',
                falseText: '비노출',
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            createSpec,
            updateSpec: { ...createSpec },
            filterSpec,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                },
                {
                    title: '추천',
                    dataIndex: 'isGood',
                    customRender: (value: any) => {
                        if (value.value) {
                            return '추천'
                        }
                        return '비추천'
                    },
                    width: 100,
                },
                {
                    title: '성분명',
                    width: 100,
                    dataIndex: 'nutrientAllowanceGroupName',
                },
                {
                    title: '신호등 타겟',
                    width: 100,
                    dataIndex: 'advisorTarget',
                },
                {
                    title: '신호등 타입',
                    width: 100,
                    dataIndex: 'advisorType',
                },
                {
                    title: '식단 종류',
                    width: 100,
                    dataIndex: 'dietName',
                },
                {
                    title: 'HIG broad',
                    width: 100,
                    dataIndex: 'higBroadName',
                },
                {
                    title: 'HIG detail',
                    width: 100,
                    dataIndex: 'higDetailName',
                },
                {
                    title: '건강상태 코드',
                    width: 100,
                    dataIndex: 'healthStatusCode',
                },
                {
                    title: 'ratioMin',
                    dataIndex: 'ratioMin',
                    customRender: formatKoreanNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'ratioPodi',
                    dataIndex: 'ratioPodi',
                    customRender: formatKoreanNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'ratioMax',
                    dataIndex: 'ratioMax',
                    customRender: formatKoreanNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'min',
                    dataIndex: 'min',
                    customRender: formatKoreanNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'podi',
                    dataIndex: 'podi',
                    customRender: formatKoreanNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'max',
                    dataIndex: 'max',
                    customRender: formatKoreanNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '대상 성별',
                    dataIndex: 'gender',
                    width: 300,
                },
                {
                    title: '최소 나이(min)',
                    dataIndex: 'ageMin',
                    width: 300,
                },
                {
                    title: '최대 나이(max)',
                    dataIndex: 'ageMax',
                    width: 300,
                },
                {
                    title: '설명',
                    dataIndex: 'description',
                    width: 300,
                },
                {
                    title: '설명 요약',
                    dataIndex: 'descriptionShort',
                    width: 100,
                },
            ],
            rules: {
                advisorTarget: [required],
                advisorType: [required],
                nutrientAllowanceGroupName: [required],
                isGood: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'food-intake-advisor-type',
            'hig-broad-name',
            'hig-detail-name',
            'diet-name',
            'health-status-category',
            'gender',
            'intake-advisor-target',
        ])
    },
})
</script>
