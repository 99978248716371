<template>
    <a-row>
        <a-form ref="formRule" :model="form">
            <a-col :span="24" class="content">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>버튼명</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.name" />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>버튼타입</span>
                    </a-col>
                    <a-col :span="16">
                        <enum-select
                            v-model:value="form.type"
                            type-name="crm-bizm-button"
                        />
                    </a-col>
                </a-col>
            </a-col>
            <a-col :span="24" class="content">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>android</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.schema_android" />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>ios</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.schema_ios" />
                    </a-col>
                </a-col>
            </a-col>
            <a-col :span="24" class="content border">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>mobile</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.url_mobile" />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>pc</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.url_pc" />
                    </a-col>
                </a-col>
                <a-col :span="4">
                    <a-button @click="add">
                        <template #icon>
                            <plus-outlined />
                        </template>
                    </a-button>
                </a-col>
            </a-col>
        </a-form>
    </a-row>
    <a-row style="margin-top: 5px">
        <a-row :key="index" v-for="(item, index) in value">
            <a-col :span="24" class="content">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>버튼명</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.name"
                            @change="(e: any) => update(index, 'name', e)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>버튼타입</span>
                    </a-col>
                    <a-col :span="16">
                        <enum-select
                            :value="item?.type"
                            type-name="crm-bizm-button"
                            @select="(e: any) => updateEnum(index, e)"
                        />
                    </a-col>
                </a-col>
            </a-col>
            <a-col :span="24" class="content">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>android</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.schema_android"
                            @change="(e: any) => update(index, 'schema_android', e)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>ios</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.schema_ios"
                            @change="(e: any) => update(index, 'schema_ios', e)"
                        />
                    </a-col>
                </a-col>
            </a-col>
            <a-col :span="24" class="content border">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>mobile</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.url_mobile"
                            @change="(e: any) => update(index, 'url_mobile', e)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>pc</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.url_pc"
                            @change="(e: any) => update(index, 'url_pc', e)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="4">
                    <a-button @click="() => remove(index)">
                        <template #icon>
                            <delete-outlined />
                        </template>
                    </a-button>
                </a-col>
            </a-col>
        </a-row>
    </a-row>
</template>

<style scoped>
.content {
    display: flex;
    align-items: end;
    padding-bottom: 5px;
}
.border {
    border-bottom: 1px solid #dedede;
}
.input-box {
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.input-box.key {
    margin-right: 10px;
}
.input-box span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<script lang="ts">
import { defineComponent, ref, Ref, toRef } from 'vue'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue'
import EnumSelect from '@/components/input/EnumSelect.vue'

type Value = {
    name: string | null
    type: string | undefined
    schema_android: string | null
    schema_ios: string | null
    url_mobile: string | null
    url_pc: string | null
}
export default defineComponent({
    name: 'CrmBizmButtonInput',

    components: {
        EnumSelect,
        DeleteOutlined,
        PlusOutlined,
    },

    props: {
        value: {
            type: Array<Value>,
            default: () => [],
        },
    },

    setup(props: any) {
        const formValue = toRef(props, 'value')
        const form: Ref<any> = ref({
            name: null,
            type: null,
            schema_android: null,
            schema_ios: null,
            url_mobile: null,
            url_pc: null,
        })

        return {
            formValue,
            form,
        }
    },

    methods: {
        add() {
            this.formValue.push(this.form)
            this.form = {
                name: null,
                type: null,
                schema_android: null,
                schema_ios: null,
                url_mobile: null,
                url_pc: null,
            }
        },
        remove(index: number) {
            this.formValue?.splice(index, 1)
        },
        update(index: number, key: string, e: any) {
            this.formValue[index][key] = e.target.value
        },
        updateEnum(index: number, e: any) {
            this.formValue[index]['type'] = e
        },
    },
})
</script>
