import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "제품 태그",
    uri: "/api/product-tag",
    columns: _ctx.columns,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec
  }, null, 8, ["columns", "create-spec", "update-spec"]))
}