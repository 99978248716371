import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "연구소 팁 (세부)",
    uri: "/api/product-consumer-guide/docs",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    scroll: { x: true },
    columns: _ctx.columns,
    deletable: "",
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec", "filter-spec", "columns"]))
}