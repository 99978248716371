<template>
    <a-modal :visible="visible" :closable="false" :footer="null" centered>
        <a-result status="error" :title="title" :sub-title="errorMessage">
            <template #extra>
                <a-button type="primary" @click="close"> 닫기 </a-button>
            </template>
        </a-result>
    </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'FailResultModal',

    setup() {
        const visible = ref(false)
        return { visible }
    },

    props: {
        errorMessage: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '등록 실패',
        },
    },

    methods: {
        open() {
            this.visible = true
        },
        close() {
            this.visible = false
        },
    },
})
</script>
