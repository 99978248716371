import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, unref as _unref, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'IntakeUnitInput',
  props: {
    value: {
        type: [String, Array],
        default: '',
    },
    width: {
        type: String,
        default: '100px',
    },
    name: {
        type: String,
    },
    label: {
        type: String,
    },
},
  emits: ['update:value', 'select'],
  setup(__props, { emit: __emit }) {



const options = ref<Array<any>>([])

const fetchIntakeUnit = debounce(async (name?: string) => {
    try {
        const response = await adminApi<ApiResponse<Array<string>>>(
            `/api/food/intake-unit/${name}`
        )
        options.value =
            response.result?.map((it) => ({ label: it, value: it })) || []
    } catch (error) {
        throw error as Error
    }
})

const emit = __emit

const change = (value: any) => {
    emit('update:value', value)
}

return (_ctx: any,_cache: any) => {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_a_form_item, {
      name: __props.name,
      label: __props.label
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select, {
          options: options.value,
          value: __props.value || [],
          onChange: change,
          onSelect: _cache[0] || (_cache[0] = (value) => emit('select', value)),
          onSearch: _unref(fetchIntakeUnit),
          "default-active-first-option": false,
          "not-found-content": null,
          "filter-option": false,
          style: _normalizeStyle({ width: __props.width }),
          "dropdown-match-select-width": 500,
          "show-search": ""
        }, null, 8, ["options", "value", "onSearch", "style"])
      ]),
      _: 1
    }, 8, ["name", "label"])
  ]))
}
}

})