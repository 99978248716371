import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode } from "vue"

import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import store from '@/store'
import { useEnumTypeStore } from '@/store/enumType'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import { DeliveredProcedureOutlined } from '@ant-design/icons-vue'
import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'AndyChance',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '사용자ID',
        dataIndex: 'userId',
    },
    {
        title: '사용자명',
        dataIndex: 'userName',
    },
    {
        title: '섭취시간',
        dataIndex: 'intakeDateTime',
        customRender: formatLocalDateTime,
    },
    {
        title: '식사유형',
        dataIndex: 'intakeType',
        customRender: formatEnum('food-intake-type'),
    },
    {
        title: '상태',
        dataIndex: 'status',
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const actionSpec = ref<FormSpecification>({
    intakeType: {
        label: '식사유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-intake-type',
    },
    intakeDateTime: {
        label: '섭취시간',
        component: InputComponents.ADatetimePicker,
        value: null,
    },
    memo: { label: '메모', type: InputType.TEXTAREA, value: null },
    intakeList: {
        label: '식품',
        component: InputComponents.FoodIntakeInput,
        value: [],
    },
})

const filterSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        component: InputComponents.AInputNumber,
    },
    userId: {
        label: '사용자ID',
        component: InputComponents.AInputNumber,
    },
    intakeDate: {
        label: '섭취일',
        component: InputComponents.ADatePicker,
    },
    status: {
        label: '상태',
        component: InputComponents.EnumSelect,
        typeName: 'andy-chance-status',
    },
})

const showActionModal = async ({
    setFormValues,
    resourceId,
}: Record<string, any>) => {
    const response = await adminApi<ApiResponse<Record<string, any>>>(
        `/api/andy-chance/chance-result/${resourceId}`
    )
    setFormValues(response.result)
}

const waitBackup = ref(false)

const onBackup = async (data: any) => {
    waitBackup.value = true
    adminApi<ApiResponse<Record<string, any>>>(
        `/api/andy-chance/keep-chance/${data.resourceId}`,

        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...data.form,
            }),
        }
    )
    waitBackup.value = false
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['food-intake-type', 'andy-chance-status'])
})

return (_ctx: any,_cache: any) => {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_image_preview_group = _resolveComponent("a-image-preview-group")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "andy_chance",
    title: "앤디 찬스",
    uri: "/api/admin-andy-chance",
    columns: columns,
    "load-on-mount": "",
    "action-uri": "/api/andy-chance/take-chance",
    "action-modal-title": "입력",
    "action-spec": actionSpec.value,
    "filter-spec": filterSpec.value,
    onShowActionModal: showActionModal
  }, {
    actionModalHeader: _withCtx(({ record }) => [
      _createVNode(_component_a_image_preview_group, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.images, (image, index) => {
            return (_openBlock(), _createBlock(_component_a_image, {
              width: "200",
              src: image.url,
              key: index
            }, null, 8, ["src"]))
          }), 128))
        ]),
        _: 2
      }, 1024)
    ]),
    actionModalIcon: _withCtx(() => [
      _createVNode(_unref(DeliveredProcedureOutlined))
    ]),
    actionModalBeforeButton: _withCtx((data) => [
      _createVNode(_component_a_button, {
        onClick: () => onBackup(data),
        loading: waitBackup.value
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" 임시 저장 ")
        ])),
        _: 2
      }, 1032, ["onClick", "loading"])
    ]),
    _: 1
  }, 8, ["action-spec", "filter-spec"]))
}
}

})