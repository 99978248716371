import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRule",
          model: _ctx.form
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              class: "content"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box key"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createElementVNode("span", null, "버튼명", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.name,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createElementVNode("span", null, "버튼타입", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_enum_select, {
                          value: _ctx.form.type,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.type) = $event)),
                          "type-name": "crm-bizm-button"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 24,
              class: "content"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box key"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createElementVNode("span", null, "android", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.schema_android,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.schema_android) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createElementVNode("span", null, "ios", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.schema_ios,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.schema_ios) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              span: 24,
              class: "content border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box key"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createElementVNode("span", null, "mobile", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.url_mobile,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.url_mobile) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createElementVNode("span", null, "pc", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.url_pc,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.url_pc) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { onClick: _ctx.add }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_plus_outlined)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { style: {"margin-top":"5px"} }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_row, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                span: 24,
                class: "content"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box key"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createElementVNode("span", null, "버튼명", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.name,
                            onChange: (e) => _ctx.update(index, 'name', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[13] || (_cache[13] = [
                          _createElementVNode("span", null, "버튼타입", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_enum_select, {
                            value: item?.type,
                            "type-name": "crm-bizm-button",
                            onSelect: (e) => _ctx.updateEnum(index, e)
                          }, null, 8, ["value", "onSelect"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_a_col, {
                span: 24,
                class: "content"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box key"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createElementVNode("span", null, "android", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.schema_android,
                            onChange: (e) => _ctx.update(index, 'schema_android', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[15] || (_cache[15] = [
                          _createElementVNode("span", null, "ios", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.schema_ios,
                            onChange: (e) => _ctx.update(index, 'schema_ios', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_a_col, {
                span: 24,
                class: "content border"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box key"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[16] || (_cache[16] = [
                          _createElementVNode("span", null, "mobile", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.url_mobile,
                            onChange: (e) => _ctx.update(index, 'url_mobile', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createElementVNode("span", null, "pc", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.url_pc,
                            onChange: (e) => _ctx.update(index, 'url_pc', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, { span: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        onClick: () => _ctx.remove(index)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_delete_outlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}