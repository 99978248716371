import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRule",
    model: _ctx.form
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions, {
            bordered: true,
            column: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, { label: "이벤트 타입" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { class: "pillyze-form-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_enum_select, {
                        value: _ctx.form['eventHandler']?.type,
                        "type-name": "ad-event-handler-type",
                        onSelect: _ctx.changeType
                      }, null, 8, ["value", "onSelect"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "랜딩 URL" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { class: "pillyze-form-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        disabled: _ctx.isCoupang,
                        "default-value": _ctx.form['eventHandler']?.url,
                        onChange: _ctx.changeUrl
                      }, null, 8, ["disabled", "default-value", "onChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "쿠팡 키워드" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { class: "pillyze-form-item" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input, {
                        disabled: !_ctx.isCoupang,
                        "default-value": _ctx.form['eventHandler']?.keyword,
                        onChange: _ctx.changeKeyword
                      }, null, 8, ["disabled", "default-value", "onChange"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}