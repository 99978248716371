<template>
    <div>
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">입력 preset</div>
        <a-radio-group
            v-if="radio"
            v-model:value="checked"
            :options="options"
            @change="check"
        />
        <a-checkbox-group
            v-else
            v-model:value="checked"
            :options="options"
            @change="check"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'

export default defineComponent({
    name: 'PresetInput',

    props: {
        value: {
            type: Array,
            default: () => [],
        },
        options: {
            type: Array,
            default: () => [],
        },
        radio: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const checked = ref([])
        return { checked }
    },

    emits: ['check'],

    methods: {
        check(event: any) {
            if (this.radio) {
                this.checked = event.target.value
                return this.$emit('check', event.target.value)
            }
            this.checked = event
            return this.$emit('check', event)
        },
    },
})
</script>
