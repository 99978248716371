import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "admin-login" }
const _hoisted_2 = { class: "login-container" }
const _hoisted_3 = { class: "login-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    tip: "Loading...",
    spinning: _ctx.loading
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "company-name" }, "P A", -1)),
          _createElementVNode("form", _hoisted_3, [
            _createVNode(_component_a_button, {
              class: "login-button",
              size: "large",
              onClick: _withModifiers(_ctx.loginWithCognito, ["prevent"]),
              style: {"background-color":"#6c2bf2","color":"whitesmoke"}
            }, {
              icon: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("img", {
                  width: 25,
                  src: "/img/icons/apple-icon-72x72.png",
                  style: {"float":"left"}
                }, null, -1)
              ])),
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("span", null, "Pillyze 로그인", -1))
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.loginType)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  class: "logout-button",
                  type: "primary",
                  onClick: _ctx.logout,
                  danger: ""
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 로그아웃 ")
                  ])),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["spinning"]))
}