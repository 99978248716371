import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineStore } from 'pinia'

export type SelectOption = {
    label: string
    value: any
    key?: any
    bias?: any
}

type EnumType = {
    [typeName: string]: Array<SelectOption>
}

type State = {
    enumType: EnumType
}

const defaultState: State = { enumType: {} }

export const useEnumTypeStore = defineStore('enumType', {
    persist: true,
    state: () => ({ ...defaultState }),
    getters: {
        getEnumType: (state: State) => (typeName: string) =>
            state.enumType[typeName],
    },
    actions: {
        async fetchEnum(typeName: string) {
            try {
                const jsonBody = await adminApi<
                    ApiResponse<Array<SelectOption>>
                >(`/api/constant/${typeName}`)

                if (jsonBody.result != null) {
                    this.enumType[typeName] = jsonBody.result
                }
            } catch (e) {
                console.log(e)
            }
        },
        async dispatchEnums(typeNames: Array<string>) {
            for (const name of typeNames) {
                await this.fetchEnum(name)
            }
        },
    },
})
