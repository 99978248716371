import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import { onMounted, ref, Ref } from 'vue'
import { stringify } from 'qs'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { required } from '@/util/input-validation'
import globalFormState from '@/components/globalFormState'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { stoplightFormChangeHook } from './food'
import { useEnumTypeStore } from '@/store/enumType'



export default /*@__PURE__*/_defineComponent({
  __name: 'FoodManagement',
  props: {
    foodName: {
        type: String,
        default: '',
    },
},
  setup(__props) {

const { formSpec } = globalFormState

const props = __props

const rowSelection = ref({
    selectedRowKeys: [],
    selectedRows: [],
    onChange: (selectedRowKeys: any, selectedRows: any) => {
        rowSelection.value.selectedRowKeys = selectedRowKeys
        rowSelection.value.selectedRows = selectedRows
    },
})

const consumerGuideSpec = ref({
    consumerGuideName: {
        label: '연구소 팁',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'consumer-guide',
    },
})

const filterSpec = ref({
    id: { label: 'ID', value: null, component: InputComponents.AInputNumber },
    stoplight: {
        label: '신호등',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-stoplight',
    },
    name: { label: '이름', value: props.foodName },
    brandName: {
        label: '브랜드명',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/brand/name',
    },
    dataQuality: {
        label: '데이터품질',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'data-quality-code',
    },
    foodSourceType: {
        label: '데이터 출처',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-source-type',
    },
    isBrandNameEmpty: {
        label: '無브랜드만',
        value: null,
        component: InputComponents.BooleanInput,
        toggle: true,
        labelCol: 6,
    },
    isRepresentative: {
        label: '대표식품 여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
    pillyzeFoodType: {
        label: '식품 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'pillyze-food-type',
    },
    status: {
        label: '상태',
        value: 'NORMAL',
        component: InputComponents.EnumSelect,
        typeName: 'food-status',
    },
    categoryCategory1: {
        label: '카테고리1',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/food/category1',
    },
    categoryCategory2: {
        label: '카테고리2',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/food/category2',
    },
    clusterId: {
        label: '클러스터 ID',
        value: null,
        component: InputComponents.AInputNumber,
        help: '유사도 검색을 위한 ID입니다. 같은 클러스터 ID를 갖는 정보는 유사도가 높습니다.',
    },
    linkId: {
        label: '링크 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    consumerGuideName: {
        label: '연구소 팁',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'consumer-guide',
    },
})
const selectedRowKeys: Ref<Array<number>> = ref([])
const loading = ref(false)

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '신호등',
        dataIndex: 'stoplight',
        customRender: formatEnum('food-stoplight'),
    },
    {
        title: '식품명',
        width: 130,
        dataIndex: 'name',
    },
    {
        title: '브랜드명',
        dataIndex: 'brandName',
    },
    {
        title: '식품유형',
        dataIndex: 'pillyzeFoodType',
    },
    {
        title: '1회제공량',
        dataIndex: 'servingSize',
    },
    {
        title: '칼로리',
        dataIndex: 'calorie',
    },
    {
        title: '섭취단위',
        dataIndex: 'unit',
    },
    {
        title: '품질',
        dataIndex: 'dataQuality',
    },
    {
        title: '출시년도',
        dataIndex: 'year',
    },
    {
        title: '상태',
        dataIndex: 'status',
        customRender: formatEnum('food-status'),
    },
    {
        title: '오류',
        dataIndex: 'debugLog',
    },
    {
        title: '카테고리1',
        dataIndex: 'category1',
    },
    {
        title: '카테고리2',
        dataIndex: 'category2',
    },
    {
        title: 'Cluster ID',
        dataIndex: 'clusterId',
    },
    {
        title: '원본 ID',
        dataIndex: 'linkId',
    },
    {
        title: '연구소 팁',
        dataIndex: 'consumerGuide',
        customRender: formatEnum('consumer-guide'),
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const createSpec: FormSpecification = {
    name: {
        label: '식품명',
        value: null,
    },
    brandName: {
        label: '브랜드명',
        value: null,
        component: InputComponents.AutoCompleteInput,
        help: '주의: 브랜드 미입력시 대표음식으로 자동 설정됩니다.',
        uri: '/api/brand/name',
    },
    searchTags: {
        label: '제품서치태그',
        value: null,
        component: InputComponents.ASelect,
        mode: SelectInputMode.TAGS,
    },
    pillyzeFoodType: {
        label: '식품 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'pillyze-food-type',
    },
    foodSourceType: {
        label: '데이터 출처',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-source-type',
    },
    year: {
        label: '출시년도',
        value: '2023',
        help: '입력하지 않으면 현재 연도로 자동 설정',
        component: InputComponents.AInputNumber,
    },
    servingSizeNotFound: {
        label: '1회 제공량 없음 😞',
        value: false,
        component: InputComponents.BooleanInput,
        help: '1회 제공량이 없는 경우 단위는 1회분으로 통일됩니다.',
        disabled: (form: any) => form?.ingredients.length > 0,
    },
    unitCode: {
        label: '제공량 단위',
        value: 'g',
        component: InputComponents.EnumSelect,
        typeName: 'food-unit-code',
    },
    servingSize: {
        label: '1회 제공량 (g, ml)',
        help: '1회 제공량이 없는 경우 "1회 제공량 없음" 체크박스를 체크해주세요.',
        value: null,
        component: InputComponents.AInputNumber,
        disabled: (form: any) => {
            return (
                form?.ingredients.length > 0 ||
                form?.servingSizeNotFound == 'true' ||
                form?.servingSizeNotFound == true
            )
        },
        formChangeHook: (form: Record<any, any>) => {
            stoplightFormChangeHook(form, formSpec.value)
        },
    },
    totalAmount: {
        label: '총 제공량 (g, ml)',
        value: null,
        component: InputComponents.AInputNumber,
    },
    calorie: {
        label: '열량 (kcal)',
        value: null,
        component: InputComponents.AInputNumber,
        disabled: (form: any) => form?.ingredients.length > 0,
    },
    nutrients: {
        label: '영양소',
        value: [],
        component: InputComponents.FoodNutrientInput,
        disabled: (form: any) => form?.ingredients.length > 0,
    },
    intakeUnits: {
        label: '섭취단위',
        value: [],
        component: InputComponents.FoodIntakeUnitInput,
    },
    ingredients: {
        label: '재료',
        value: [],
        help: '식품 구성 재료',
        component: InputComponents.FoodIngredientInput,
        input: async (value: any) => {
            const response = await adminApi<ApiResponse<Record<any, any>>>(
                `/api/food/ingredient-summary`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(value),
                }
            )
            const result = response.result
            formSpec.value.servingSize.value = result?.gramAmount
            formSpec.value.calorie.value = result?.calorie
            formSpec.value.nutrients.value =
                result?.ingredientSummaryAggregate.map((it: any) => ({
                    nutrientName: it.nutrientName,
                    amount: it.nutrientGramAmount,
                    unit: it.unit,
                }))
        },
    },
    category: {
        label: '카테고리',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/food/category-name',
        select: (value) => {
            formSpec.value.categoryBias.value = value?.bias
        },
    },
    categoryBias: {
        label: '카테고리 Bias',
        value: null,
        readonly: true,
    },
    adminMemo: {
        label: '관리자 메모',
        value: null,
        type: InputType.TEXTAREA,
        help: '',
    },
    inMenu: {
        label: '메뉴에 포함',
        value: null,
        help: '드링크 메뉴에 포함 여부',
        component: InputComponents.BooleanInput,
        trueText: '포함',
        falseText: '미포함',
    },
    customCoupangQuery: {
        label: '쿠팡 파트너스 검색 쿼리',
        value: null,
        help: '기본적인 조합 (제목 + 브랜드)로 검색이 되지 않을 경우 사용 (만약 항상 비노출을 원한다면 "Gold box" 를 입력)',
    },
    buyNowInCoupangAllowed: {
        label: '쿠팡 파트너스 바로구매 허용',
        value: null,
        component: InputComponents.BooleanInput,
        trueText: '노출',
        falseText: '비노출',
    },
    consumerGuide: {
        label: '연구소 팁',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'consumer-guide',
    },
    stoplight: {
        label: '신호등',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'food-stoplight',
    },
    stoplightReason: {
        label: '신호등 이유',
        value: null,
        readonly: true,
    },
    recipeLink: {
        label: '레시피 출처',
        value: null,
    },
    recipeLinkType: {
        label: '레시피 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'contact-link-type',
    },
}

function onSelectChange(keys: Array<number>) {
    selectedRowKeys.value = keys
}

async function request(param: string, uri: string, search: () => void) {
    if (selectedRowKeys.value.length > 0 && loading.value === false) {
        loading.value = true
        try {
            await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                method: 'PATCH',
            })
        } catch (err) {
            console.error(err)
            throw err
        } finally {
            setTimeout(() => {
                loading.value = false
                selectedRowKeys.value = []
                search()
            }, 1000)
        }
    }
}

const createRules = {
    name: [required],
    pillyzeFoodType: [required],
    unitCode: [required],
    calorie: [required],
}

function updateStatus(status: string, uri: string, search: () => void) {
    const params: Record<string, any> = {
        ids: selectedRowKeys.value,
        foodStatus: status,
    }
    const queryParams = stringify(params, { indices: false })
    request(queryParams, uri, search)
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums([
        'pillyze-food-type',
        'food-source-type',
        'food-unit-code',
        'food-status',
        'data-quality-code',
        'food-source-type',
        'consumer-guide',
        'food-stoplight',
        'contact-link-type',
    ])
})

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "food",
    title: "식품 관리",
    uri: "/api/food",
    "row-selection": {
            selectedRowKeys: selectedRowKeys.value,
            onChange: onSelectChange,
        },
    columns: columns,
    "filter-spec": filterSpec.value,
    "create-spec": createSpec,
    "create-rules": createRules,
    scroll: { x: 2000 },
    hasEditPage: "",
    exportable: "",
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(_component_a_button, {
        style: {"color":"black","margin-left":"45px"},
        loading: loading.value,
        disabled: ""
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("음식 상태 변경 ")
        ])),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_a_tooltip, null, {
        title: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("기존 식품을 수정하지 않고 복제하여 새로운 식품을 만든 후 일반 공개한다. ")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: () => updateStatus('NORMAL', uri, search)
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("개인용 → 일반 (공개) ")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_component_a_button, {
        onClick: () => updateStatus('DELETED', uri, search)
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("삭제 ")
        ])),
        _: 2
      }, 1032, ["onClick"]),
      _createVNode(_component_a_tooltip, null, {
        title: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("기존 식품의 상태만 변경하고 검색엔진에 등록한다. ")
        ])),
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: () => updateStatus('NORMAL', uri, search)
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("삭제 → 일반 ")
            ])),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 2
      }, 1024),
      _createVNode(BulkActionModal, {
        "resource-ids": selectedRowKeys.value,
        uri: `${uri}/consumer-guide`,
        "input-spec": consumerGuideSpec.value,
        title: "연구소 팁 입력",
        label: "연구소 팁 입력"
      }, null, 8, ["resource-ids", "uri", "input-spec"])
    ]),
    _: 1
  }, 8, ["row-selection", "filter-spec"]))
}
}

})