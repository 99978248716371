<template>
    <div>
        <a-row>
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <product-input
                    v-model:value="form.productId"
                    name="productId"
                    @select="(args) => setProduct(args, form)"
                    width="340px"
                />
                <a-form-item name="idx">
                    <korean-number-input
                        v-model:value="form.idx"
                        placeholder="광고 노출 순서"
                    />
                </a-form-item>
                <a-textarea
                    placeholder="추천 이유"
                    v-model:value="form.recommendReason"
                    style="margin-right: 10px; width: 450px"
                />
                <a-form-item>
                    <a-button type="primary" @click="add">
                        <template #icon>
                            <plus-outlined />
                        </template>
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row>
            <a-divider style="height: 1px; background-color: #cdcdcd" />
        </a-row>
        <a-row>
            <draggable :list="valueRef" item-key="productId">
                <template #item="{ element, index }">
                    <a-form :model="element" :rules="rules" layout="inline">
                        <product-input
                            v-model:value="element.productId"
                            :name="`productId${index}`"
                            @select="(args) => setProduct(args, element)"
                            width="340px"
                            mode="default"
                        />
                        <a-form-item :name="`idx${index}`">
                            <korean-number-input v-model:value="element.idx" />
                        </a-form-item>
                        <a-textarea
                            v-model:value="element.recommendReason"
                            style="margin-right: 10px; width: 450px"
                            placeholder="추천이유"
                        />
                        <a-form-item>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                        <a-divider
                            style="height: 1px; background-color: #eeeeee"
                            dashed
                        />
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<script lang="ts">
import StandardUnitInput from '@/components/input/StandardUnitInput.vue'
import NutrientInput from '@/components/input/NutrientInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import { required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { defineComponent, Ref, ref, toRef } from 'vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import AutoCompleteInput from './AutoCompleteInput.vue'
import ProductInput from '@/components/input/ProductInput.vue'

type Product = {
    id?: number
    productId: number | undefined
    idx: number
    recommendReason?: string
}

export default defineComponent({
    name: 'SearchAdProductInput',

    components: {
        ProductInput,
        KoreanNumberInput,
        Draggable,
        PlusOutlined,
        DeleteOutlined,
    },

    setup(props: any) {
        const form: Ref<Product> = ref({
            productId: 0,
            idx: 0,
            recommendReason: '',
        })
        const formRule = ref()
        const valueRef = toRef(props, 'value')

        const push = (value: Product) => {
            valueRef.value.push(value)
        }
        const pop = (index: number) => {
            valueRef.value?.splice(index, 1)
        }

        return { form, formRule, valueRef, push, pop }
    },

    props: {
        rules: {
            type: Object,
            default: () => ({
                productId: [required],
                idx: [required],
            }),
        },
        productId: {
            type: Number,
        },
        value: {
            type: Array,
            default: () => [],
        },
    },

    computed: {},

    emits: ['update:value'],

    methods: {
        add() {
            return this.formRule.validate().then(() => {
                if (this.value != null) {
                    this.push(this.form)
                    this.form = {
                        productId: 0,
                        idx: 0,
                        recommendReason: '',
                    }
                }
            })
        },

        setProduct(
            {
                name,
                recommendReason,
            }: {
                name: number | undefined
                recommendReason?: string
            },
            form: Product
        ) {
            form = {
                productId: name,
                idx: form.idx,
                recommendReason: recommendReason,
            }
        },
    },
})
</script>
