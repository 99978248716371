<template>
    <a-col class="container">
        <span class="header">{{ header }}</span>
        <a-select
            v-if="type === 'select'"
            :value="value"
            :options="options"
            @change="change"
        />
        <a-input-number
            v-else-if="type === 'number'"
            :value="value"
            @change="change"
        />
        <a-input v-else :value="value" @change="change" />
    </a-col>
</template>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
}
.header {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 6px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AdAdvertiseInput',

    props: {
        type: null,
        value: null,
        header: null,
        options: [] as any[],
    },

    emits: ['change'],

    methods: {
        change(value: any) {
            this.$emit('change', value)
        },
    },
})
</script>
