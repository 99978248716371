<template>
    <a-descriptions style="padding-left: 10px; padding-right: 10px" :column="1">
        <a-descriptions-item label="Job 실행 상태">
            {{ execution.status }}
        </a-descriptions-item>
        <a-descriptions-item label="시작시간">
            {{ execution.startTime }}
        </a-descriptions-item>
        <a-descriptions-item label="종료시간">
            {{ execution.endTime }}
        </a-descriptions-item>
    </a-descriptions>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { timer } from 'rxjs'
import { formatLocalDateTime } from '@/util/formmater'
import { defineComponent, ref } from 'vue'

type BatchJobExecution = {
    status: string
    startTime: string
    endTime: string
}

export default defineComponent({
    name: 'JobMonitor',

    props: {
        jobName: {
            type: String,
            required: true,
        },
    },

    setup(props: any) {
        const execution = ref({ status: '', startTime: '', endTime: '' })

        const fetchJobStatus = async () => {
            const response = await adminApi<ApiResponse<BatchJobExecution>>(
                `/api/job/status/${props.jobName}`
            )
            execution.value = response.result || execution.value
            execution.value.startTime = formatLocalDateTime({
                text: execution.value.startTime,
            })
            execution.value.endTime = formatLocalDateTime({
                text: execution.value.endTime,
            })
        }

        const every5Seconds = timer(0, 5000).subscribe(() => {
            fetchJobStatus().then()
        })

        return { execution, every5Seconds }
    },

    beforeUnmount() {
        this.every5Seconds.unsubscribe()
    },
})
</script>
