import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash'

export type FormeMission = {
    id: string
    category: string
    name: string
    type: string
    value: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FormeMissionInput',
  props: {
    value: {
        type: String,
    },
    mode: {
        type: String,
        default: 'default',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
},
  emits: ['update:value', 'afterSelect'],
  setup(__props, { emit: __emit }) {



const emits = __emit

const state = ref<any>({ options: [] })

const fetchOptions = debounce(async (keyword: string) => {
    const response = await adminApi<ApiResponse<Array<FormeMission>>>(
        `/api/forme/diet/search/mission/${keyword}`
    )

    state.value.options =
        response.result?.map((it) => ({
            ...it,
            variable: it.value,
            label: it.name,
            value: it.id,
        })) || []
}, 200)

const onSearch = async (keyword: string) => {
    await fetchOptions(keyword)
}

const onSelect = (value: any) => {
    emits('update:value', value)
    emits(
        'afterSelect',
        state.value.options.filter((it) => it.value === value)[0]
    )
}

return (_ctx: any,_cache: any) => {
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createBlock(_component_a_auto_complete, {
    value: __props.value,
    options: state.value.options,
    mode: __props.mode,
    disabled: __props.disabled,
    "show-search": "",
    onSearch: onSearch,
    onSelect: onSelect
  }, null, 8, ["value", "options", "mode", "disabled"]))
}
}

})