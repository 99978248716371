export const fileToWebp = (file: File) =>
    new Promise((resolve) => {
        if (!file) return null

        const reader = new FileReader()

        reader.onload = async (e: any) => {
            const img = new Image()
            img.onload = async () => {
                const canvas = document.createElement('canvas')
                canvas.width = img.width
                canvas.height = img.height

                const ctx = canvas.getContext('2d')
                ctx?.drawImage(img, 0, 0)

                const webpDataUrl = canvas.toDataURL('image/webp')
                const webpFile = webpUrlToFile(
                    webpDataUrl,
                    file.name.replace(/\.[^/.]+$/, '') + '.' + 'webp'
                )
                resolve(webpFile)
            }

            img.src = reader.result as string
        }

        reader.readAsDataURL(file)
    })

export const webpUrlToFile = (url: string, filename: string) => {
    // Data URL에서 Base64 부분을 추출
    const base64Data = url.split(',')[1]

    // Base64 문자열을 바이너리 데이터로 변환
    const byteString = atob(base64Data)
    const byteArray = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i)
    }

    // Blob 객체 생성
    const blob = new Blob([byteArray], { type: 'image/webp' })

    // Blob 객체를 File 객체로 변환
    const file = new File([blob], filename, { type: 'image/webp' })

    return file
}
