<template>
    <a-popconfirm
        :title="title"
        ok-text="실행"
        cancel-text="취소"
        @confirm="confirm"
        @cancel="cancel"
        :disabled="disabled"
    >
        <a-button type="primary">수정</a-button>
    </a-popconfirm>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'UpdatePopConfirm',

    props: {
        title: {
            type: String,
            required: true,
        },
        uri: {
            type: String,
            required: true,
        },
        payload: {
            type: Object,
            required: true,
        },
    },

    emits: ['afterBlind'],

    methods: {
        async confirm() {
            const response = await adminApi<ApiResponse<void>>(this.uri, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.payload),
            })

            if (response.success === false) {
                this.$message.error('수정 실패했습니다.')
            } else {
                this.$emit('afterBlind')
            }
        },

        cancel() {
            this.$message.error('수정을 취소했습니다.')
        },
    },
})
</script>
