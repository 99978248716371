<template>
    <a-row class="overlay" v-if="loading">
        <a-spin />
    </a-row>
    <a-row>
        <a-col :span="10">
            <a-row class="element-group">
                <h3>LAYOUT</h3>
                <a-col :span="24" class="element">
                    <a-col :span="8">배경 색상</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.bg"
                            @update:pureColor="(value: any) => setColor('bg', value)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">Circle 색상</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.circle"
                            @update:pureColor="(value: any) => setColor('circle', value)"
                        />
                    </a-col>
                </a-col>
            </a-row>
            <a-row class="element-group">
                <h3>천원필템 로고</h3>
                <a-col :span="24" class="element">
                    <a-col :span="8">천원필템 색상</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.label"
                            @update:pureColor="(value: any) => setColor('label', value)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">천원필템 top</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.logo.top" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">천원필템 left</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.logo.left" />
                    </a-col>
                </a-col>
            </a-row>
            <a-row class="element-group">
                <h3>천원필템 문구</h3>
                <a-col :span="24" class="element">
                    <a-col :span="8">천원필템 Header</a-col>
                    <a-col :span="16">
                        <a-input v-model:value="values.text.header" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">천원필템 Desc</a-col>
                    <a-col :span="16">
                        <a-input v-model:value="values.text.description" />
                    </a-col>
                </a-col>
            </a-row>
            <a-row class="element-group">
                <h3>라벨</h3>
                <h4>라벨 위치</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">라벨 top</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.label.top" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">라벨 left</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.label.left" />
                    </a-col>
                </a-col>
                <h4>내일마감</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">배경색</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.tomorrowBg"
                            @update:pureColor="(value: any) => setColor('tomorrowBg', value)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">폰트색</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.tomorrowFont"
                            @update:pureColor="(value: any) => setColor('tomorrowFont', value)"
                        />
                    </a-col>
                </a-col>
                <h4>오늘마감</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">배경색</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.todayBg"
                            @update:pureColor="(value: any) => setColor('todayBg', value)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">폰트색</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.todayFont"
                            @update:pureColor="(value: any) => setColor('todayFont', value)"
                        />
                    </a-col>
                </a-col>
            </a-row>
            <a-row class="element-group">
                <h3>결과 버튼</h3>
                <h4>위치</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">Left</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.button.left" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">Bottom</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.button.bottom" />
                    </a-col>
                </a-col>
                <h4>내일마감</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">배경색</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.buttonBg"
                            @update:pureColor="(value: any) => setColor('buttonBg', value)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">폰트색</a-col>
                    <a-col :span="16">
                        <color-picker
                            :pure-color="values.color.buttonFont"
                            @update:pureColor="(value: any) => setColor('buttonFont', value)"
                        />
                    </a-col>
                </a-col>
            </a-row>
            <a-row class="element-group">
                <h3>상품</h3>
                <h4>상품 사이즈</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">이미지 Width</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.product.width" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">이미지 Height</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.product.height" />
                    </a-col>
                </a-col>

                <h4>상품 위치</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">이미지 위치 Top</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.product.top" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">이미지 위치 Bottom</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.product.bottom" />
                    </a-col>
                </a-col>
                <a-col :span="24" class="element">
                    <a-col :span="8">이미지 위치 Right</a-col>
                    <a-col :span="16">
                        <a-input-number v-model:value="values.product.right" />
                    </a-col>
                </a-col>

                <h4>이미지 파일</h4>
                <a-col :span="24" class="element">
                    <a-col :span="8">상품 이미지</a-col>
                    <a-col :span="16">
                        <image-input
                            :value="values.product?.image"
                            @update:value="setImage"
                        />
                    </a-col>
                </a-col>
            </a-row>
        </a-col>
        <a-col :span="12" :offset="2">
            <a-col :span="24" class="preview-group">
                <a-row style="margin-bottom: 30px">
                    <a-button @click="download">이미지 다운로드</a-button>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-base"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item type="logo" :values="values" />
                        <banner-creator-item type="header" :values="values" />
                        <banner-creator-item
                            type="description"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-tomorrow"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item type="logo" :values="values" />
                        <banner-creator-item
                            type="label-tomorrow"
                            :values="values"
                        />
                        <banner-creator-item type="header" :values="values" />
                        <banner-creator-item
                            type="description"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-today"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item type="logo" :values="values" />
                        <banner-creator-item
                            type="label-today"
                            :values="values"
                        />
                        <banner-creator-item type="header" :values="values" />
                        <banner-creator-item
                            type="description"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-end"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item type="logo" :values="values" />
                        <banner-creator-item type="header" :values="values" />
                        <banner-creator-item
                            type="description-end"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-end-button"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item
                            type="logo-button"
                            :values="values"
                        />
                        <banner-creator-item
                            type="header-button"
                            :values="values"
                        />
                        <banner-creator-item
                            type="description-end-button"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                        <banner-creator-item
                            type="end-button"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-rolling-banner-start"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item type="logo" :values="values" />
                        <banner-creator-item
                            type="header-rolling-banner"
                            header="첫번째 미션 시작"
                            :values="values"
                        />
                        <banner-creator-item
                            type="description-rolling-banner"
                            description="구매를 시작해주세요!"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row class="image-row">
                    <div
                        id="image-rolling-banner-end"
                        class="image image-335x148"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item type="logo" :values="values" />
                        <banner-creator-item
                            type="header-rolling-banner"
                            header="마지막 미션 시작"
                            :values="values"
                        />
                        <banner-creator-item
                            type="description-rolling-banner"
                            description="리뷰를 작성해주세요!"
                            :values="values"
                        />
                        <banner-creator-item type="circle" :values="values" />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                    </div>
                </a-row>
                <a-row v-if="isOn" class="image-row">
                    <div
                        id="image-top-banner"
                        class="image image-375x167"
                        :style="{ backgroundColor: values.color.bg }"
                    >
                        <banner-creator-item
                            type="logo-button"
                            :values="values"
                        />
                        <banner-creator-item
                            type="header-top-banner"
                            :values="values"
                        />
                        <banner-creator-item
                            type="description-end-button"
                            :values="values"
                        />
                        <banner-creator-item
                            type="circle-large"
                            :values="values"
                        />
                        <banner-creator-item
                            type="product-image"
                            :values="values"
                        />
                        <banner-creator-item
                            type="end-button"
                            :values="values"
                        />
                    </div>
                </a-row>
            </a-col>
        </a-col>
    </a-row>
</template>

<style>
h3,
h4,
h5 {
    width: 100%;
    display: flex;
}
.preview-group {
    position: sticky;
    top: 0;
}
.element-group {
    padding-bottom: 10px;
    border-bottom: 1px solid #dedede;
}
.element {
    padding-bottom: 10px;
    display: flex;
}
.image-row {
    padding-bottom: 10px;
}
.image {
    position: relative;
}
.image-335x148 {
    width: 335px;
    height: 148px;
}
.image-375x167 {
    width: 375px;
    height: 167px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import { ColorPicker } from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'
import { toSvg } from 'html-to-image'
import ImageInput from '@/components/input/ImageInput.vue'
import BannerCreatorItem from '@/views/BannerCreatorItem.vue'
import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

interface KeyAny {
    [key: string]: any
}
export default defineComponent({
    name: 'BannerCreator',

    components: {
        BannerCreatorItem,
        ImageInput,
        ColorPicker,
    },

    setup() {
        const loading = ref(false)
        const color = ref({
            bg: '#FF416D',
            circle: '#0BDBDB',
            label: '#0BDBDB',
            tomorrowBg: '#000000',
            tomorrowFont: '#ffffff',
            todayBg: '#FF416D',
            todayFont: '#FFFFFF',
            buttonBg: '#4F33F4',
            buttonFont: '#FFFFFF',
        } as KeyAny)
        const product = ref({
            image: '',
            top: 0,
            bottom: 0,
            right: 0,
            width: 160,
            height: 120,
        })
        const label = ref({
            top: 29,
            left: 97,
        })
        const logo = ref({
            top: 32,
            left: 20,
        })
        const text = ref({
            header: '',
            description: '',
        })
        const button = ref({
            left: 0,
            bottom: 20,
        })
        const values = ref({
            color,
            product,
            label,
            logo,
            text,
            button,
        })

        const isOn = ref(false)

        return {
            loading,
            values,
            isOn,
        }
    },

    methods: {
        async download() {
            this.loading = true
            const ids = [
                'image-base',
                'image-tomorrow',
                'image-today',
                'image-end',
                'image-end-button',
            ] as string[]

            for (const id of ids) {
                const imageDocument = document.getElementById(id)
                const prefixRandom = this.prefixRandom()
                const filePrefix = this.prefix()
                const fileName = `${filePrefix}-${id}.${prefixRandom}`
                if (imageDocument && id === 'image-base') {
                    try {
                        const canvas = await html2canvas(imageDocument, {
                            scale: 30,
                        })
                        canvas.toBlob((blob) => {
                            if (blob !== null) {
                                saveAs(blob, `${fileName}.png`)
                            }
                        })
                    } catch (error) {
                        console.error('Error converting div to image:', error)
                    }
                }
            }
            this.loading = false
        },
        setColor(key: string, color: any) {
            this.values.color[key] = color
        },
        setImage(path: string) {
            this.values.product.image = path
        },
        prefix() {
            const today = new Date()
            const year = today.getFullYear()
            const month = ('0' + (today.getMonth() + 1)).slice(-2)
            const day = ('0' + today.getDate()).slice(-2)
            const hours = ('0' + today.getHours()).slice(-2)
            const minutes = ('0' + today.getMinutes()).slice(-2)
            const seconds = ('0' + today.getSeconds()).slice(-2)
            const yyyyMMdd = `${year}${month}${day}`
            const hhmmss = `${hours}${minutes}${seconds}`
            return `${yyyyMMdd}-${hhmmss}`
        },
        prefixRandom() {
            let result = ''
            const characters = 'abcdefghijklmnopqrstuvwxyz'
            const charactersLength = characters.length
            for (let i = 0; i < 8; i++) {
                const word = characters.charAt(
                    Math.floor(Math.random() * charactersLength)
                )
                result += word
            }
            return result
        },
    },
})
</script>
