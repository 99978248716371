import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_input = _resolveComponent("product-input")!
  const _component_korean_number_input = _resolveComponent("korean-number-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRule",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_product_input, {
              value: _ctx.form.productId,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.productId) = $event)),
              name: "productId",
              onSelect: _cache[1] || (_cache[1] = (args) => _ctx.setProduct(args, _ctx.form)),
              width: "340px"
            }, null, 8, ["value"]),
            _createVNode(_component_a_form_item, { name: "idx" }, {
              default: _withCtx(() => [
                _createVNode(_component_korean_number_input, {
                  value: _ctx.form.idx,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.idx) = $event)),
                  placeholder: "광고 노출 순서"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_textarea, {
              placeholder: "추천 이유",
              value: _ctx.form.recommendReason,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.recommendReason) = $event)),
              style: {"margin-right":"10px","width":"450px"}
            }, null, 8, ["value"]),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.add
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_plus_outlined)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_divider, { style: {"height":"1px","background-color":"#cdcdcd"} })
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_draggable, {
          list: _ctx.valueRef,
          "item-key": "productId"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: _ctx.rules,
              layout: "inline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_product_input, {
                  value: element.productId,
                  "onUpdate:value": ($event: any) => ((element.productId) = $event),
                  name: `productId${index}`,
                  onSelect: (args) => _ctx.setProduct(args, element),
                  width: "340px",
                  mode: "default"
                }, null, 8, ["value", "onUpdate:value", "name", "onSelect"]),
                _createVNode(_component_a_form_item, {
                  name: `idx${index}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_korean_number_input, {
                      value: element.idx,
                      "onUpdate:value": ($event: any) => ((element.idx) = $event)
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1032, ["name"]),
                _createVNode(_component_a_textarea, {
                  value: element.recommendReason,
                  "onUpdate:value": ($event: any) => ((element.recommendReason) = $event),
                  style: {"margin-right":"10px","width":"450px"},
                  placeholder: "추천이유"
                }, null, 8, ["value", "onUpdate:value"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => _ctx.pop(index)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_delete_outlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_divider, {
                  style: {"height":"1px","background-color":"#eeeeee"},
                  dashed: ""
                })
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ]))
}