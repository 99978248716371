<template>
    <ResourceTable
        title="필라이즈 칼럼"
        uri="/api/column"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        load-on-mount
    >
        <template #detail="{ resourceId, record }">
            <a-descriptions
                title="칼럼 상세"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="제목">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="요약">
                    {{ record.summary }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일시">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="썸네일">
                    <img
                        :key="resourceId"
                        :width="200"
                        :src="record.thumbnailUrl"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'PillyzeColumn',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            title: { label: '제목', value: null },
            subTitle1: { label: '부제목1', value: null },
            subTitle2: { label: '부제목2', value: null },
            pointProvider: {
                label: '포인트 제공 여부',
                value: null,
                component: InputComponents.BooleanInput,
                notNull: true,
                trueText: '제공',
                falseText: '미제공',
            },
            pointQuantity: {
                label: '포인트 금액',
                value: null,
                component: InputComponents.AInputNumber,
            },
            pointDurationTime: {
                label: '포인트 조건(체류시간(s))',
                value: null,
                component: InputComponents.AInputNumber,
            },
            pointPeriod: {
                label: '포인트 부여 기간',
                value: null,
                component: InputComponents.ARangeDatePicker,
            },
            columnType: {
                label: '타입',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'column-type',
            },
            category: {
                label: '카테고리',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'column-category',
            },
            category2: {
                label: '카테고리2',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'column-category2',
            },
            tags: {
                label: '태그',
                value: null,
                component: InputComponents.ASelect,
                mode: SelectInputMode.TAGS,
            },
            summary: { label: '요약', value: null },
            keyword: { label: '키워드', value: null },
            seoContent: { label: 'SEO 컨텐츠', value: null },
            thumbnailUrl: {
                label: '썸네일',
                value: null,
                component: InputComponents.ImageInput,
            },
            content: {
                label: 'HTML',
                value: null,
                type: InputType.TEXTAREA,
            },
            snippet: {
                label: '스니펫',
                value: null,
                type: InputType.TEXTAREA,
            },
            relatedProductList: {
                label: '관련 제품아이디',
                value: [],
                component: InputComponents.MultipleProductInput,
            },
            relatedHigBroadList: {
                label: '기능메인태그',
                value: [],
                component: InputComponents.ProductTagInput,
                help: true,
                mode: SelectInputMode.MULTIPLE,
            },
            isActive: {
                label: '노출여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '노출',
                falseText: '비노출',
            },
            isPopular: {
                label: '인기컨텐츠여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '인기',
                falseText: '비인기',
                labelCol: 8,
            },
            relatedNutrientList: {
                label: '관련 영양성분',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-group/name',
                mode: SelectInputMode.MULTIPLE,
            },
            diseaseSet: {
                label: '기저질환',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/disease/name',
                mode: SelectInputMode.MULTIPLE,
            },
            drugSet: {
                label: '복용약물',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/drug/name2',
                mode: SelectInputMode.MULTIPLE,
            },
            higSet: {
                label: '건강목표',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/hig/name',
                mode: SelectInputMode.MULTIPLE,
            },
            dietSet: {
                label: '식단',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/diet/name',
                mode: SelectInputMode.MULTIPLE,
            },
            allergySet: {
                label: '알러지',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/allergy/name',
                mode: SelectInputMode.MULTIPLE,
            },
            conditionSet: {
                label: '건강특수상태',
                value: [],
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/condition/name',
                mode: SelectInputMode.MULTIPLE,
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            filterSpec: {
                columnType: {
                    label: '칼럼 타입',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'column-type',
                },
                title: {
                    label: '제목',
                    value: null,
                },
                summary: {
                    label: '요약',
                    value: null,
                },
                keyword: {
                    label: '키워드',
                    value: null,
                },
                isPopular: {
                    label: '인기컨텐츠여부',
                    value: null,
                    component: InputComponents.BooleanInput,
                    labelCol: 8,
                },
            },
            createSpec,
            updateSpec: { ...createSpec },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                {
                    title: '제목',
                    dataIndex: 'title',
                },
                {
                    title: '요약',
                    dataIndex: 'summary',
                },
                {
                    title: '키워드',
                    dataIndex: 'keyword',
                },
                {
                    title: 'SEO 컨텐츠',
                    dataIndex: 'seoContent',
                },
                {
                    title: '태그',
                    dataIndex: 'tags',
                    scopedSlots: { customRender: 'tags' },
                    width: 100,
                },
                {
                    title: '썸네일',
                    dataIndex: 'thumbnailUrl',
                    type: 'img',
                    width: 120,
                },
                {
                    title: '타입',
                    dataIndex: 'columnType',
                    customRender: formatEnum('column-type'),
                    width: 100,
                },
                {
                    title: '관련 제품아이디',
                    dataIndex: 'relatedProductList',
                    customRender: (value: Array<number>) => {
                        return value
                    },
                    width: 100,
                },
                {
                    title: '기능메인태그',
                    dataIndex: 'relatedHigBroadList',
                    scopedSlots: { customRender: 'tags' },
                    width: 100,
                },
                {
                    title: '관련 영양성분',
                    dataIndex: 'relatedNutrientList',
                    scopedSlots: { customRender: 'tags' },
                    width: 100,
                },
                {
                    title: '노출',
                    dataIndex: 'isActive',
                    customRender: (value: any) => {
                        if (value.value) {
                            return 'O'
                        }
                        return 'X'
                    },
                    width: 100,
                },
                {
                    title: '인기 컨텐츠',
                    dataIndex: 'isPopular',
                    customRender: (value: any) => {
                        if (value.value) {
                            return 'O'
                        }
                        return 'X'
                    },
                    width: 100,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    width: 100,
                },
                {
                    title: '상세',
                    dataIndex: 'detail',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    type: 'detail',
                },
            ],
            rules: {
                title: [required],
                pointProvider: [required],
                summary: [required],
                content: [required],
                columnType: [required],
                category: [required],
                category2: [required],
                thumbnailUrl: [required],
                isActive: [required],
                isPopular: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'column-type',
            'hig-broad-name',
            'column-category',
            'column-category2',
        ])
    },
})
</script>
