import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { baseUrl } from '@/fetchTemplate'
import { computed, ref } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { fileToWebp } from '@/util/image'


export default /*@__PURE__*/_defineComponent({
  __name: 'ImageMultipleUploader',
  props: {
    values: {
        type: Array<string>,
        default: () => [],
    },
    path: {
        type: String,
        default: '',
    },
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const props = __props
const loading = ref(false)

const uploadUrl = computed(() => {
    return `${baseUrl}/api/file?path=${props.path}`
})

const imageUrls = computed(() => {
    return props.values
})

const emits = __emit

const handleInput = (event: any) => {
    emits('update:value', event.target.value)
}

const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
        loading.value = true
        return
    }
    if (info.file.status === 'done') {
        const allDone = !info.fileList.some((f: any) => f.status !== 'done')
        if (allDone) {
            const result = info.fileList.map((f: any) => {
                return f.response.result
            })
            emits('update:value', result)
            loading.value = false
        }
    }
}

const beforeUpload = (file: any) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return fileToWebp(file)
    }
    return file
}

return (_ctx: any,_cache: any) => {
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_col, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_upload, {
        name: "file",
        "list-type": "picture-card",
        class: "avatar-uploader",
        "show-upload-list": false,
        multiple: true,
        action: uploadUrl.value,
        onChange: handleChange,
        "before-upload": beforeUpload,
        "with-credentials": "",
        style: {"width":"unset"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (loading.value)
              ? (_openBlock(), _createBlock(_unref(LoadingOutlined), { key: 0 }))
              : (_openBlock(), _createBlock(_unref(PlusOutlined), { key: 1 })),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "ant-upload-text" }, "Upload", -1))
          ])
        ]),
        _: 1
      }, 8, ["action"]),
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imageUrls.value, (imageValue, index) => {
            return (_openBlock(), _createBlock(_component_a_col, {
              key: index,
              span: "6"
            }, {
              default: _withCtx(() => [
                (
                        imageValue &&
                        !imageValue.endsWith('.json') &&
                        !imageValue.endsWith('.csv')
                    )
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: imageValue,
                      alt: "avatar",
                      style: {"max-width":"100px","max-height":"100px"}
                    }, null, 8, _hoisted_1))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})