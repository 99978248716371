<template>
    <ResourceTable
        title="포인트샵 상품"
        uri="/api/admin/point-shop/products/category"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="createRules"
        :update-rules="updateRules"
        :columns="columns"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <a-button @click="() => updateStatus(true, uri, search)">
                활성화
            </a-button>
            <a-button @click="() => updateStatus(false, uri, search)">
                비활성화
            </a-button>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import { formatBoolean } from '@/util/formmater'

export default defineComponent({
    name: 'PointShopProductsCategory',

    mixins: [TableSupport],

    components: {
        ResourceTable,
    },

    setup() {
        const loading = ref(false)
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                align: 'center',
                width: '64px',
            },
            {
                title: '이름',
                dataIndex: 'name',
            },
            {
                title: '가중치',
                dataIndex: 'weight',
                align: 'center',
                width: '128px',
            },
            {
                title: '활성화',
                dataIndex: 'forTab',
                align: 'center',
                width: '128px',
                customRender: formatBoolean,
            },
        ]

        const createSpec: FormSpecification = {
            name: {
                label: '카테고리 명',
                value: null,
            },
            weight: {
                label: '가중치',
                value: null,
                component: InputComponents.AInputNumber,
            },
            forTab: {
                label: '활성화',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '활성화',
                falseText: '비활성화',
            },
        }

        const createRules = {
            name: [required],
        }

        const updateSpec: FormSpecification = {
            name: {
                label: '카테고리 명',
                value: null,
            },
            weight: {
                label: '가중치',
                value: null,
                component: InputComponents.AInputNumber,
            },
            forTab: {
                label: '활성화',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '활성화',
                falseText: '비활성화',
            },
        }

        const updateRules = {
            name: [required],
        }

        const rowSelection = ref({
            selectedRowKeys: [],
            selectedRows: [],
            onChange: (selectedRowKeys: any, selectedRows: any) => {
                rowSelection.value.selectedRowKeys = selectedRowKeys
                rowSelection.value.selectedRows = selectedRows
            },
        })

        return {
            loading,
            selectedRowKeys,
            columns,
            createSpec,
            createRules,
            updateSpec,
            updateRules,
            rowSelection,
        }
    },

    methods: {
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        updateStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                status: status,
            }
            const callUri = `${uri}/status`
            const queryParams = stringify(params, { indices: false })
            this.patchStatus(queryParams, callUri, search)
        },
        async patchStatus(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
    },
})
</script>
