import { createStore } from 'vuex'

import tagStore from '@/store/tag'
import unitConversionStore from '@/store/unitConversion'
import companionStore from '@/store/companion'

const store = createStore({
    modules: {
        tag: tagStore,
        unitConversion: unitConversionStore,
        companion: companionStore,
    },
})

export default store
