<template>
    <resource-table
        title="음식 사전 관리"
        uri="/api/food/dictionary"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1000, y: 650 }"
        :columns="columns"
        load-on-mount
        deletable
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FoodDictionary',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            word: {
                label: '음식이름',
                value: null,
            },
        }
        const updateSpec = { ...createSpec }
        return {
            filterSpec: {
                word: { label: '음식이름', value: null },
            },
            selectedRowKeys: [],
            loading: false,
            createSpec,
            updateSpec,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                { title: '이름', dataIndex: 'word', width: 50 },
            ],
            rules: {
                word: [required],
            },
        }
    },

    mounted() {},
})
</script>
