<template>
    <a-form ref="formRule" :model="form">
        <a-row>
            <a-descriptions :bordered="true" :column="1">
                <a-descriptions-item label="이벤트 타입">
                    <a-form-item class="pillyze-form-item">
                        <enum-select
                            :value="form['eventHandler']?.type"
                            type-name="ad-event-handler-type"
                            @select="changeType"
                        />
                    </a-form-item>
                </a-descriptions-item>
                <a-descriptions-item label="랜딩 URL">
                    <a-form-item class="pillyze-form-item">
                        <a-input
                            :disabled="isCoupang"
                            :default-value="form['eventHandler']?.url"
                            @change="changeUrl"
                        />
                    </a-form-item>
                </a-descriptions-item>
                <a-descriptions-item label="쿠팡 키워드">
                    <a-form-item class="pillyze-form-item">
                        <a-input
                            :disabled="!isCoupang"
                            :default-value="form['eventHandler']?.keyword"
                            @change="changeKeyword"
                        />
                    </a-form-item>
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
    </a-form>
</template>

<style scoped>
.pillyze-form-item {
    margin-bottom: 0px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import EnumSelect from '@/components/input/EnumSelect.vue'
import globalFormState from '@/components/globalFormState'

export default defineComponent({
    name: 'AdEventHandlerInput',

    components: {
        EnumSelect,
    },

    setup() {
        return {
            ...globalFormState,
        }
    },

    emits: ['update:value', 'select'],

    computed: {
        isCoupang() {
            return this.form['eventHandler']?.type === 'COUPANG_AFFILIATE'
        },
    },

    methods: {
        changeType(value: any, _: Record<string, any>) {
            this.form['eventHandler'].type = value
        },
        changeUrl(event: any) {
            this.form['eventHandler'].url = event.target.value
        },
        changeKeyword(event: any) {
            this.form['eventHandler'].keyword = event.target.value
        },
    },
})
</script>
