<template>
    <a-flex justify="center" style="margin: 10px 0 10px 0">
        <a-image
            v-if="value && !value.endsWith('.json')"
            :src="value"
            :width="128"
        />
    </a-flex>
    <span style="display: inline-block; white-space: nowrap; width: 90%">
        <a-select
            :value="value"
            :options="options"
            :disabled="disabled"
            :style="{ width: '400px' }"
            show-search
            option-filter-prop="label"
            @select="onSelect"
            @change="onChange"
            @search="onSearch"
        >
            <template #option="{ label, image }">
                <img :src="image" :width="64" />
                &nbsp;&nbsp;{{ label }}
            </template>
        </a-select>
        <a-button @click.prevent="refreshOptions" style="margin-left: 5px">
            <reload-outlined />
        </a-button>
        <a-button @click.prevent="resetValue">
            <minus-outlined />
        </a-button>
    </span>
</template>

<script lang="ts" setup>
import { ApiResponse, adminApi } from '@/fetchTemplate'
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { OptionProps } from 'ant-design-vue/es/select'
import { onMounted, ref, defineProps, defineEmits } from 'vue'

const props = defineProps({
    value: {
        type: String,
    },
    typeName: {
        type: String,
        required: true,
    },
    mode: {
        type: String,
        default: 'default',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '15em',
    },
    filter: {
        type: Function,
    },
    resourceId: {
        type: Number,
    },
})

const options = ref<Array<OptionProps>>([])
const questionImages = ref<Array<OptionProps>>([])

const emits = defineEmits(['input', 'update:value', 'select'])

const onSelect = (value: any, option: Record<string, any>) => {
    emits('select', value, option)
}

const onChange = (value: any) => {
    emits('update:value', value)
}

const resetValue = () => {
    emits('update:value', null)
}

const refreshOptions = () => {
    fetchOptions()
}

const fetchOptions = async () => {
    if (props.resourceId != null) {
        const response = await adminApi<ApiResponse<Array<OptionProps>>>(
            `/api/v1/questions/image/${props.resourceId}`
        )
        options.value = response.result ?? []
        questionImages.value = response.result ?? []
    }
}

const onSearch = async (value: string) => {
    if (value) {
        const response = await adminApi<ApiResponse<Array<OptionProps>>>(
            `/api/pillyze-image-asset/name/${value}`
        )
        if (response.result) {
            console.log('result ', response.result)
            console.log('options.value ', options.value)
            options.value = [...response.result, ...questionImages.value]
        }
    }
}

onMounted(() => {
    fetchOptions()
})
</script>
