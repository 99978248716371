import { ActionContext } from 'vuex'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineStore } from 'pinia'

export type Companion = {
    id: number
    userName: string
    role: string
}

const SET_COMPANION = 'SET_COMPANION',
    CLEAN_COMPANION = 'CLEAN_COMPANION'

type State = {
    companion: Array<Companion>
}

const defaultState: State = {
    companion: [],
}

export const useCompanionStore = defineStore('companion', {
    persist: true,
    state: () => ({ ...defaultState }),
    actions: {
        async fetchCompanion() {
            try {
                const jsonBody = await adminApi<ApiResponse<Array<Companion>>>(
                    '/api/user/companion'
                )
                const companion = jsonBody.result || []
                this.companion.push(...companion)
            } catch (error) {
                this.companion.push(...[])
                console.error(error)
            }
        },

        cleanCompanion() {
            this.companion = []
        },
    },
})

export default {
    state: [],
    getters: {
        companion: (state: Array<Companion>) => state,
    },
    mutations: {
        [SET_COMPANION]: (
            state: Array<Companion>,
            payload: Array<Companion>
        ) => {
            state.push(...payload)
        },
        [CLEAN_COMPANION]: (state: Array<Companion>) => {
            state = []
            return state
        },
    },
    actions: {
        async fetchCompanion({
            commit,
            state,
        }: ActionContext<Array<Companion>, any>) {
            try {
                const companionList = state as Array<Companion>
                if (companionList.length == 0) {
                    const jsonBody = await adminApi<
                        ApiResponse<Array<Companion>>
                    >('/api/user/companion')
                    const companion = jsonBody.result
                    commit(SET_COMPANION, companion)
                }
            } catch (error) {
                commit(SET_COMPANION, [])
                console.error(error)
            }
        },

        cleanCompanion({ commit }: ActionContext<Array<Companion>, any>) {
            commit(CLEAN_COMPANION)
        },
    },
}
