<template>
    <resource-table
        title="섭취량 단위"
        uri="/api/intake-unit"
        :columns="columns"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        primary-key="name"
        load-on-mount
    />
</template>

<script lang="ts" setup>
import {
    InputComponents,
    FormSpecification,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { ref } from 'vue'

const createSpec = ref<FormSpecification>({
    name: { label: '이름', value: null },
    step: {
        label: '단계',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const updateSpec = ref<FormSpecification>({
    step: {
        label: '단계',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const filterSpec = ref<FormSpecification>({
    name: { label: '이름', value: null },
    step: {
        label: '단계',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const columns = [
    {
        title: '이름',
        dataIndex: 'name',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '단계',
        dataIndex: 'step',
        width: 100,
    },
]
</script>
