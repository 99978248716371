<template>
    <ResourceTable
        title="댓글"
        uri="/api/v1/reviews"
        :filter-spec="filterSpec"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        :scroll="{ x: 2000, y: 650 }"
        :columns="columns"
        exportable
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <a-button style="color: black" :loading="loading" disabled
                >리뷰 상태 변경</a-button
            >
            <a-button @click="() => updateStatus('NORMAL', uri, search)"
                >노멀</a-button
            >
            <a-button @click="() => updateStatus('ADMIN_BLIND', uri, search)"
                >블라인드</a-button
            >
            <a-button @click="() => updateStatus('ADMIN_DELETED', uri, search)"
                >운영자 삭제</a-button
            >
            <a-button style="color: black" :loading="loading" disabled
                >베스트 지정</a-button
            >
            <a-button @click="() => updateBest('true', uri, search)"
                >Best</a-button
            >
            <a-button @click="() => updateBest('false', uri, search)"
                >Unbest</a-button
            >
        </template>
        <template #detail="{ record, search, fetchResource }">
            <a-descriptions title="리뷰 상세" bordered="true" layout="vertical">
                <a-descriptions-item label="리뷰 ID">{{
                    record.id
                }}</a-descriptions-item>
                <a-descriptions-item label="사용자 ID">{{
                    record.userId
                }}</a-descriptions-item>
                <a-descriptions-item label="제품 ID">{{
                    record.productId
                }}</a-descriptions-item>
                <a-descriptions-item label="상태">{{
                    record.status
                }}</a-descriptions-item>
                <a-descriptions-item label="베스트 여부">{{
                    record.isBest
                }}</a-descriptions-item>
                <a-descriptions-item label="장점">{{
                    record.goodText
                }}</a-descriptions-item>
                <a-descriptions-item label="단점">{{
                    record.badText
                }}</a-descriptions-item>
                <a-descriptions-item label="이미지">
                    <a-image-preview-group>
                        <span
                            v-for="(image, index) in record.reviewImage"
                            :key="index"
                            style="display: inline-grid"
                        >
                            <a-image :width="200" :src="image.url" />
                            <BlindPopconfirm
                                :title="`이미지를 ${
                                    (image.blinded && '노출') || '블라인드'
                                } 하시겠습니까?`"
                                :uri="`/api/v1/reviews/blind/image/${image.id}`"
                                :blinded="image.blinded"
                                @after-blind="
                                    () => {
                                        search()
                                        fetchResource()
                                    }
                                "
                            />
                        </span>
                    </a-image-preview-group>
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import {
    formatBoolean,
    formatEnum,
    formatLocalDateTime,
} from '@/util/formmater'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import BlindPopconfirm from '@/components/BlindPopconfirm.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'ReviewView',
    mixins: [TableSupport],
    components: {
        ResourceTable,
        BlindPopconfirm,
    },
    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const selectedImageUrl = ref('')
        const showImage = ref(false)
        const loading = ref(false)

        return {
            selectedRowKeys,
            selectedImageUrl,
            showImage,
            loading,
        }
    },

    methods: {
        onClickImage(event: any) {
            this.selectedImageUrl = event.target.src
            this.showImage = true
        },
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        updateStatus(status: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                reviewStatus: status,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateReview(queryParams, uri, search)
        },
        updateBest(best: string, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                isBest: best,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateReview(queryParams, uri + '/best', search)
        },
        async updateReview(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
        async deleteImage(imageId: number) {
            await adminApi<ApiResponse<void>>(
                `/api/v1/reviews/image/${imageId}`,
                {
                    method: 'DELETE',
                }
            )
        },
    },

    data() {
        return {
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInputNumber' },
                productId: {
                    label: '제품 ID',
                    value: null,
                    component: 'AInputNumber',
                },
                userId: {
                    label: '사용자 ID',
                    value: [],
                    component: 'AutoCompleteInput',
                    uri: '/api/user/id',
                    mode: 'multiple',
                },
                reportedCount: {
                    label: '신고 수',
                    value: null,
                    component: 'AInputNumber',
                },
                status: {
                    label: '상태',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'review-status',
                    input: () => {
                        this.reload()
                    },
                },
                isBest: {
                    label: '베스트여부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '베스트',
                    falseText: '일반',
                },
                reviewerType: {
                    label: '포미테스터 여부',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'reviewer-type',
                },
                hasPhoto: {
                    label: '사진첨부',
                    value: null,
                    component: 'BooleanInput',
                    trueText: '사진첨부',
                    falseText: '사진미첨부',
                },
                creationTime: {
                    label: '등록일',
                    value: null,
                    component: 'ARangePicker',
                },
                updateTime: {
                    label: '수정일',
                    value: null,
                    component: 'ARangePicker',
                },
                goodClusterId: {
                    label: '장점 클러스터 ID',
                    value: null,
                    component: 'AInputNumber',
                    help: 'ID가 같은 리뷰는 장점 내용이 유사합니다.',
                    labelCol: { span: 24 },
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '이미지',
                    width: 130,
                    dataIndex: 'productImageUrl',
                    type: 'img',
                },
                {
                    title: '사용자 ID',
                    dataIndex: 'userId',
                    width: 100,
                },
                {
                    title: '제품 ID',
                    dataIndex: 'productId',
                    width: 100,
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                },
                {
                    title: '장점',
                    dataIndex: 'goodText',
                    width: 200,
                },
                {
                    title: '단점',
                    dataIndex: 'badText',
                    width: 200,
                },
                {
                    title: '베스트',
                    dataIndex: 'isBest',
                    customRender: formatBoolean,
                    width: 60,
                },
                {
                    title: '신고 수',
                    dataIndex: 'reportedCount',
                    width: 50,
                },
                {
                    title: '작성 단계',
                    dataIndex: 'step',
                    customRender: formatEnum('review-step'),
                    width: 70,
                },
                {
                    title: '상태',
                    dataIndex: 'status',
                    customRender: formatEnum('review-status'),
                    width: 70,
                },
                {
                    title: '블라인드 처리자',
                    dataIndex: 'blindedBy',
                    customRender: (value: any, record: any) => {
                        const status = ''
                        if (value.record.blindedBy === 0) {
                            return status + '(시스템 필터링)'
                        } else if (value.record.blindedBy !== null) {
                            // make the code short
                            return (
                                status +
                                '관리자 (' +
                                value.record.blindedBy +
                                ')'
                            )
                        } else {
                            return status
                        }
                    },
                },
                {
                    title: '포미테스터 여부',
                    dataIndex: 'reviewerType',
                    customRender: formatEnum('reviewer-type'),
                },
                {
                    title: '장점 클러스터 ID',
                    dataIndex: 'goodClusterId',
                    width: 120,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    sorter: true,
                    width: 165,
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                },
                {
                    title: '이미지 포함',
                    dataIndex: 'hasImage',
                    fixed: 'right',
                    align: 'center',
                    width: 100,
                    type: 'hasImage',
                },
                {
                    title: '상세',
                    dataIndex: 'detail',
                    width: 100,
                    fixed: 'right',
                    align: 'center',
                    type: 'detail',
                    scopedSlots: { customRender: 'detail' },
                },
            ],
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'review-status',
            'review-step',
            'reviewer-type',
        ])
    },
})
</script>
