<template>
    <div>
        <a-page-header title="앱 알림 개발자 도구" />
        <p-form
            title="앱 알림 개발자 도구"
            :rules="rules"
            :input-spec="inputSpec"
            :loading="loading"
            @submit="onSubmit"
        >
            <template #button> 보내기 </template>
        </p-form>
    </div>
</template>

<script setup lang="ts">
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { ref } from 'vue'

const rules = {
    userName: {
        required: true,
    },
    title: {
        required: true,
    },
}

const inputSpec = ref<FormSpecification>({
    userName: {
        component: InputComponents.UserInput,
        label: '사용자',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
    },
    body: {
        label: '내용',
        value: null,
    },
    image: {
        component: InputComponents.ImageInput,
        label: '이미지',
        value: null,
    },
    description: {
        label: '설명',
        value: null,
    },
    link: {
        label: '링크',
        value: null,
    },
    type: {
        label: '타입',
        value: null,
    },
})

const loading = ref(false)

const onSubmit = async (form: Record<string, any>) => {
    loading.value = true
    await adminApi<ApiResponse<void>>(
        `/api/devtool/notification?${stringify(form)}`
    )
    loading.value = false
}
</script>
