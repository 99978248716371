<template>
    <div style="background-color: #f9fefe; padding: 13px; width: auto">
        <a-row>
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <intake-unit-input
                    label="단위"
                    v-model:value="form.intakeUnitName"
                    name="intakeUnitName"
                />
                <a-form-item name="amount" label="섭취량">
                    <korean-number-input v-model:value="form.amount" />
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" @click="add">
                        섭취 단위 추가
                    </a-button>
                </a-form-item>
            </a-form>
        </a-row>
        <a-row>
            <draggable :list="valueRef" item-key="intakeUnitName">
                <template #item="{ element, index }">
                    <a-form :model="element" :rules="rules" layout="inline">
                        <a-form-item>
                            <swap-outlined :rotate="90" />
                        </a-form-item>
                        <intake-unit-input
                            label="단위"
                            v-model:value="element.intakeUnitName"
                            :name="`intakeUnitName${index}`"
                        />
                        <a-form-item :name="`amount${index}`" label="섭취량">
                            <korean-number-input
                                v-model:value="element.amount"
                            />
                        </a-form-item>
                        <a-form-item name="isDefault" label="기본">
                            <boolean-input
                                v-model:value="element.isDefault"
                                @select="
                                    (value) => selectIsDefault(value, element)
                                "
                                toggle
                            />
                        </a-form-item>
                        <a-form-item>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<script setup lang="ts">
import { ref, toRef, defineProps } from 'vue'
import IntakeUnitInput from './IntakeUnitInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import BooleanInput from './BooleanInput.vue'
import { aboveZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { DeleteOutlined, SwapOutlined } from '@ant-design/icons-vue'

type IntakeUnit = {
    id?: number
    intakeUnitName?: string
    amount: number
    isDefault?: boolean
}

const props = defineProps({
    rules: {
        type: Object,
        default: () => ({
            intakeUnitName: [required],
            amount: [required, aboveZero],
        }),
    },
    productId: {
        type: Number,
    },
    value: {
        type: Array,
        default: () => [],
    },
})

const form = ref<IntakeUnit>({
    amount: 0,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: IntakeUnit) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}
const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            form.value = {
                amount: 0,
            }
        }
    })
}

const selectIsDefault = (value: boolean, record: Record<string, any>) => {
    console.log(value, record)
    if (value) {
        valueRef.value.forEach((it: any) => {
            it.isDefault = false
        })
        record.isDefault = true
    }
}
</script>
