import DataSourceLoader, { Pagination } from './dataSourceLoader'
import FilterSupport from './filterSupport'
import { baseUrl } from '@/fetchTemplate'
import { stringify } from 'qs'
import { sanitizeUrl } from '@braintree/sanitize-url'
import { defineComponent } from 'vue'

export type Scroll = {
    x?: boolean | number
    y?: number
}

type TableProps = {
    rowKey: string
    size: 'small' | 'middle' | 'large'
    scroll: Scroll
    pagination: Pagination
    loading: boolean
    bordered: boolean
    dataSource: Array<Record<string, any>>
}

export default defineComponent({
    name: 'TableSupport',
    mixins: [FilterSupport, DataSourceLoader],

    data() {
        return {
            sorter: { order: false },
        }
    },

    computed: {
        defaultTableProps(): TableProps {
            return {
                rowKey: 'id',
                size: 'small',
                scroll: { y: 650 },
                pagination: this.tablePagination,
                loading: this.loadingDataSource,
                dataSource: this.tableData,
                bordered: true,
            }
        },
        csvExportUrl() {
            return sanitizeUrl(
                `${baseUrl}${this.uri}/csv?${stringify(this.filter, {
                    indices: false,
                })}`
            )
        },
    },

    methods: {
        async onSearch(afterSearch?: () => void) {
            await this.fetchData(
                { ...this.tablePagination, ...this.sorter },
                this.filter
            )

            if (afterSearch) await afterSearch()
        },
        async reload() {
            await this.fetchData(
                { ...this.tablePagination, ...this.sorter },
                this.filter
            )
        },
        handlePage(pagination: Pagination, filter: any, sorter: any) {
            this.sorter = { ...sorter, sorterName: sorter?.column?.sorterName }
            this.fetchData(
                { ...pagination, ...this.sorter },
                this.filter
            ).then()
        },
    },
})
