<template>
    <ResourceTable
        title="광고 매출 관리"
        uri="/api/ad-revenue"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :columns="columns"
        :create-rules="rule"
        :update-rules="rule"
        :scroll="{ x: true }"
        load-on-mount
        deletable
    />
</template>

<script lang="ts" setup>
import ResourceTable from '@/components/ResourceTable.vue'
import {
    formatLocaleNumber,
    formatLocalDate,
    formatLocalDateTime,
} from '@/util/formmater'
import { InputComponents } from '@/components/InputMapper.vue'
import { ref } from 'vue'
import { required } from '@/util/input-validation'

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        fixed: 'left',
    },
    {
        title: '이름',
        dataIndex: 'name',
        width: 80,
    },
    {
        title: '매출액',
        dataIndex: 'revenue',
        customRender: formatLocaleNumber,
        width: 100,
    },
    {
        title: '매출일',
        dataIndex: 'targetDate',
        customRender: formatLocalDate,
        width: 80,
    },
    {
        title: '설명',
        dataIndex: 'description',
        width: 150,
    },
    {
        title: '생성자',
        dataIndex: 'createdBy',
        width: 50,
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 80,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        width: 50,
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 80,
        sorter: true,
        align: 'center',
    },
]

const filterSpec = ref({
    name: {
        label: '이름',
        value: null,
    },
    description: {
        label: '설명',
        value: null,
    },
    targetDate: {
        label: '매출일',
        value: null,
        component: InputComponents.ARangeDatePicker,
    },
})

const createSpec = ref({
    name: {
        label: '이름',
        value: null,
    },
    revenue: {
        label: '매출액',
        value: null,
        component: InputComponents.AInputNumber,
    },
    description: {
        label: '설명',
        value: null,
    },
    targetDate: {
        label: '매출일',
        value: null,
        component: InputComponents.ADatePicker,
    },
})

const updateSpec = ref({
    name: {
        label: '이름',
        value: null,
    },
    revenue: {
        label: '매출액',
        value: null,
        component: InputComponents.AInputNumber,
    },
    description: {
        label: '설명',
        value: null,
    },
    targetDate: {
        label: '매출일',
        value: null,
        component: InputComponents.ADatePicker,
    },
})

const rule = {
    name: [required],
    revenue: [required],
    targetDate: [required],
}
</script>
