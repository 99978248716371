import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "row" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_select, {
        value: _ctx.value,
        options: _ctx.options,
        onChange: _ctx.change
      }, null, 8, ["value", "options", "onChange"])
    ]),
    _: 1
  }))
}