<template>
    <ResourceTable
        title="영양소 좋은점"
        uri="/api/nutrient-good-point"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        load-on-mount
    >
        <template #detail="{ resourceId, record }">
            <a-descriptions
                title="영양소 좋은점"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="제목">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="요약">
                    {{ record.summary }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일시">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="썸네일">
                    <img
                        :key="resourceId"
                        :width="200"
                        :src="record.thumbnailUrl"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'NutrientGoodPoint',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            id: { label: 'ID', value: null },
            name: {
                label: '이름(키)',
                value: null,
            },
            categoryName: {
                label: '카테고리',
                value: null,
            },
            goodReason: {
                label: '좋은점',
                value: null,
            },
            memo: {
                label: '관리자메모',
                value: null,
                type: InputType.TEXTAREA,
            },
            nutrientNameList: {
                label: '영양소',
                value: [],
                component: InputComponents.NutrientInput,
                mode: SelectInputMode.MULTIPLE,
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            filterSpec: {
                id: {
                    label: 'ID',
                    value: null,
                    component: InputComponents.AInputNumber,
                },
                name: {
                    label: '이름(키)',
                    value: null,
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                name: {
                    label: '이름(키)',
                    value: null,
                    readonly: true,
                },
                id: {
                    label: 'ID',
                    value: null,
                    readonly: true,
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                {
                    title: '이름(키)',
                    width: 150,
                    dataIndex: 'name',
                },
                {
                    title: '카테고리',
                    width: 80,
                    dataIndex: 'categoryName',
                },
                {
                    title: '좋은이유',
                    dataIndex: 'goodReason',
                },
            ],
            rules: {
                name: [required],
                categoryName: [required],
                goodReason: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'column-type',
            'hig-broad-name',
            'column-category',
        ])
    },
})
</script>
