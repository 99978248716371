import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"color":"red"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nutrient_input = _resolveComponent("nutrient-input")!
  const _component_korean_number_input = _resolveComponent("korean-number-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_standard_unit_input = _resolveComponent("standard-unit-input")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_auto_complete_input = _resolveComponent("auto-complete-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRule",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_nutrient_input, {
              value: _ctx.nutrientName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.nutrientName) = $event)),
              name: "nutrientName",
              onSelect: _cache[1] || (_cache[1] = (args) => _ctx.setNutrient(args, _ctx.form)),
              width: "340px"
            }, null, 8, ["value"]),
            _createVNode(_component_a_form_item, { name: "amount" }, {
              default: _withCtx(() => [
                _createVNode(_component_korean_number_input, {
                  value: _ctx.form.amount,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.amount) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_standard_unit_input, {
              value: _ctx.form.unit,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.unit) = $event)),
              name: "unit"
            }, null, 8, ["value"]),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.add
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_plus_outlined)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_input, {
              placeholder: "부가 설명 (e.g., 혼합물 설명, 추가적인 구성 설명)",
              value: _ctx.form.description,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.description) = $event)),
              style: {"margin-right":"10px","width":"450px"}
            }, null, 8, ["value"]),
            _createVNode(_component_auto_complete_input, {
              value: _ctx.form.goodPoints,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.goodPoints) = $event)),
              placeholder: "좋은 점",
              name: "goodPoints",
              uri: "/api/nutrient-good-point/name",
              mode: "multiple"
            }, null, 8, ["value"])
          ]),
          _: 1
        }, 8, ["model", "rules"]),
        _createElementVNode("b", _hoisted_1, _toDisplayString(_ctx.quantityCriteria), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_divider, { style: {"height":"1px","background-color":"#cdcdcd"} })
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_draggable, {
          list: _ctx.valueRef,
          "item-key": "nutrientName"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: _ctx.rules,
              layout: "inline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_nutrient_input, {
                  value: element.nutrientName,
                  "onUpdate:value": ($event: any) => ((element.nutrientName) = $event),
                  name: `nutrientName${index}`,
                  onSelect: (args) => _ctx.setNutrient(args, element),
                  width: "340px",
                  mode: "default"
                }, null, 8, ["value", "onUpdate:value", "name", "onSelect"]),
                _createVNode(_component_a_form_item, {
                  name: `amount${index}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_korean_number_input, {
                      value: element.amount,
                      "onUpdate:value": ($event: any) => ((element.amount) = $event)
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1032, ["name"]),
                _createVNode(_component_standard_unit_input, {
                  value: element.unit,
                  "onUpdate:value": ($event: any) => ((element.unit) = $event),
                  name: `unit${index}`
                }, null, 8, ["value", "onUpdate:value", "name"]),
                _createVNode(_component_a_textarea, {
                  value: element.description,
                  "onUpdate:value": ($event: any) => ((element.description) = $event),
                  style: {"margin-right":"10px","width":"450px"},
                  placeholder: "제품 내 영양소에 대한 메모 (e.g., 혼합물 목록..)"
                }, null, 8, ["value", "onUpdate:value"]),
                _createVNode(_component_auto_complete_input, {
                  value: element.goodPoints,
                  "onUpdate:value": ($event: any) => ((element.goodPoints) = $event),
                  placeholder: "좋은 점",
                  name: `goodPoints${index}`,
                  uri: "/api/nutrient-good-point/name",
                  mode: "multiple"
                }, null, 8, ["value", "onUpdate:value", "name"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => _ctx.pop(index)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_delete_outlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_divider, {
                  style: {"height":"1px","background-color":"#eeeeee"},
                  dashed: ""
                })
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ]))
}