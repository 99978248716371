<template>
    <a-card title="트리거박스 파이프라인" style="padding: 50px">
        <p>드래그하여 순서를 조정할 수 있습니다.</p>
        <div
            v-for="otInterest in Object.keys(rulePipeline)"
            :key="otInterest"
            style="margin-bottom: 2em"
        >
            <a-typography-title
                v-if="otInterest == 'DIET'"
                type="secondary"
                :level="4"
                >식단 선호 파이프라인</a-typography-title
            >
            <a-typography-title
                v-else-if="otInterest == 'NUTRIENT'"
                type="secondary"
                :level="4"
            >
                영양제 선호 파이프라인
            </a-typography-title>
            <a-typography-title
                v-else-if="otInterest == 'GLUCOSE'"
                type="secondary"
                :level="4"
            >
                혈당 선호 파이프라인
            </a-typography-title>
            <draggable
                v-model="rulePipeline[otInterest]"
                @change="(event) => orderNode(event, otInterest)"
                item-key="id"
            >
                <template #item="{ element }">
                    <span id="pipeline-tag">
                        <a-tag
                            :color="tagColorSet[element.triggerMessageType]"
                            style="
                                display: inline-block
                                padding: 5px
                                margin-bottom: 10px
                            "
                        >
                            {{ element.ruleName }}
                        </a-tag>
                        <ArrowRightOutlined
                            v-if="
                                rulePipeline[otInterest][
                                    rulePipeline[otInterest].length - 1
                                ].id !== element.id
                            "
                            style="padding-right: 10px"
                        />
                    </span>
                </template>
            </draggable>
        </div>
    </a-card>
    <ResourceTable
        ref="resourceTable"
        resource-name="trigger-box"
        title="트리거 박스"
        uri="/api/trigger-box"
        :columns="columns"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :create-rules="createRules"
        :update-spec="createSpec"
        :update-rules="createRules"
        @after-search="afterSearch"
        load-on-mount
        deletable
    />
    <div class="resource--page">
        <a-page-header title="트리거 박스 메시지폼" />
        <FilterForm
            ref="filterForm"
            :filter-spec="filterContentSpec"
            input-only
        />
        <a-space style="margin-bottom: 40px">
            <CreateFormModal
                title="메시지폼 등록"
                label="메시지폼 등록"
                uri="/api/trigger-box/content"
                :rules="contentRules"
                :input-spec="createContentSpec"
                @after-submit="fetchContentList"
            />
            <a-button type="primary" @click="fetchContentList">조회</a-button>
        </a-space>
        <div v-for="ruleName in Object.keys(filteredContent)" :key="ruleName">
            <a-typography-title type="secondary" :level="3">{{
                ruleName
            }}</a-typography-title>
            <a-list
                :grid="{ gutter: 16, column: 3 }"
                :data-source="filteredContent[ruleName]"
            >
                <template #renderItem="{ item }">
                    <a-list-item>
                        <a-card :title="`${item.title}#${item.id}`" hoverable>
                            <a-card-grid
                                style="
                                    width: 25%;
                                    text-align: center;
                                    box-shadow: none;
                                "
                                :hoverable="false"
                            >
                                <a-avatar
                                    v-if="
                                        item.iconUrl == null ||
                                        !item.iconUrl.endsWith('.json')
                                    "
                                    :src="item.iconUrl"
                                    shape="square"
                                    :size="64"
                                />
                                <Vue3Lottie
                                    v-else-if="
                                        item.iconUrl != null &&
                                        item.iconUrl.endsWith('.json')
                                    "
                                    :animationLink="item.iconUrl"
                                    :width="63"
                                    :height="63"
                                />
                            </a-card-grid>
                            <a-card-grid
                                style="width: 75%; box-shadow: none"
                                :hoverable="false"
                            >
                                <p>{{ item.description }}</p>
                                <a-button
                                    style="
                                        background-color: #6c2ff2;
                                        color: white;
                                    "
                                    >{{ item.button }}</a-button
                                >
                            </a-card-grid>
                            <template #actions>
                                <UpdateFormModal
                                    title="메세지폼 수정"
                                    label="메세지폼 수정"
                                    :resource-id="item.id"
                                    uri="/api/trigger-box/content"
                                    :rules="contentRules"
                                    :input-spec="updateContentSpec"
                                    @after-submit="fetchContentList"
                                    :show-button="false"
                                />
                                <delete-popconfirm
                                    :title="`${item.id}를 삭제하시겠습니까?`"
                                    :uri="`/api/trigger-box/content/${item.id}`"
                                    @after-delete="fetchContentList"
                                    :show-button="false"
                                />
                            </template>
                        </a-card>
                    </a-list-item>
                </template>
            </a-list>
        </div>
    </div>
</template>

<style scoped>
#pipeline-tag :hover {
    cursor: move;
    border: 2px solid #1890ff;
}
</style>

<script setup lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import Draggable from 'vuedraggable'
import { ArrowRightOutlined } from '@ant-design/icons-vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { computed, onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import UpdateFormModal from '@/components/modal/UpdateFormModal.vue'
import { required } from '@/util/input-validation'
import DeletePopconfirm from '@/components/DeletePopconfirm.vue'
import FilterForm from '@/components/FilterForm.vue'
import { useEnumTypeStore } from '@/store/enumType'

const resourceTable = ref()

enum TriggerMessageType {
    ACTION = 'ACTION',
    EMOTION = 'EMOTION',
    AD_HOC = 'AD_HOC',
}

type Rule = {
    id: number
    ruleName: string
    otInterest: string
    triggerMessageType: TriggerMessageType
    createdBy: string
    creationTime: string
    updatedBy: string
    updateTime: string
    priority: number
}

const rulePipeline = ref<Record<string, Array<Rule>>>({})
const initPipeline = ref<Record<string, Array<Rule>>>({})

const tagColorSet = {
    [TriggerMessageType.ACTION]: 'orange',
    [TriggerMessageType.EMOTION]: 'green',
    [TriggerMessageType.AD_HOC]: 'purple',
}

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '이름',
        dataIndex: 'ruleName',
    },
    {
        title: 'OT 선호',
        dataIndex: 'otInterest',
        customRender: formatEnum('ot-interest'),
    },
    {
        title: '메세지 유형',
        dataIndex: 'triggerMessageType',
        customRender: formatEnum('trigger-message-type'),
    },
    {
        title: '등록자',
        dataIndex: 'createdBy',
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const afterSearch = async () => {
    const response = await adminApi<ApiResponse<Array<Rule>>>(
        '/api/trigger-box/queue'
    )
    const ruleList = response.result || []
    rulePipeline.value = ruleList.reduce((acc, cur) => {
        if (acc[cur.otInterest] == null) {
            acc[cur.otInterest] = []
        }
        acc[cur.otInterest].push(cur)
        acc[cur.otInterest].sort((a, b) => a.id - b.id)
        acc[cur.otInterest].sort((a, b) => a.priority - b.priority)
        return acc
    }, {} as Record<string, Array<Rule>>)
    initPipeline.value = { ...rulePipeline.value }
}

const orderNode = async (event: any, otInterest: string) => {
    const oldOrder = initPipeline.value[otInterest].map((it) => it.id)
    const newOrder = rulePipeline.value[otInterest].map((it) => it.id)

    await adminApi(`/api/trigger-box/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            otInterest,
            oldOrder,
            newOrder,
        }),
    })
    resourceTable.value.onSearch()
}

const filterSpec = ref<FormSpecification>({
    triggerBoxRuleName: {
        label: '룰 이름',
        value: null,
    },
    otInterest: {
        label: 'OT 선호',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'ot-interest',
    },
    triggerBoxRuleTriggerBoxType: {
        label: '메세지 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-message-type',
    },
    triggerBoxRuleSchedule: {
        label: '스케줄',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-schedule',
    },
    triggerBoxRuleTriggerCount: {
        label: '트리거 횟수',
        component: InputComponents.AInputNumber,
        value: null,
        help: '트리거 횟수를 입력해주세요.(0이면 무제한)',
    },
})

const createSpec = ref<FormSpecification>({
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    otInterest: {
        label: 'OT 선호',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'ot-interest',
    },
    triggerMessageType: {
        label: '메세지 유형',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-message-type',
    },
    viewTypes: {
        label: '뷰 타입',
        value: [],
        component: InputComponents.EnumSelect,
        typeName: 'trigger-box-view-type',
        mode: SelectInputMode.MULTIPLE,
    },
    schedule: {
        label: '스케줄',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-schedule',
    },
    triggerCount: {
        label: '트리거 횟수',
        component: InputComponents.AInputNumber,
        value: null,
        help: '트리거 횟수를 입력해주세요.(0이면 무제한)',
    },
})

const createRules = {
    ruleName: [required],
    otInterest: [required],
    triggerMessageType: [required],
    schedule: [required],
    triggerCount: [required],
    osType: [required],
}

const contentRules = {
    ruleName: [required],
    design: [required],
    osType: [required],
    showCount: [required],
    minVersion: [required],
}

const createContentSpec = ref<FormSpecification>({
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    iconUrl: {
        label: '아이콘 URL',
        component: InputComponents.ImageInput,
        path: '/trigger_box/icon',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    description: {
        label: '설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    appendix: {
        label: '부가 설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    button: {
        label: '버튼 라벨',
        value: null,
    },
    link: {
        label: 'link',
        value: null,
    },
    deepLink: {
        label: 'deepLink',
        value: null,
    },
    design: {
        label: '디자인',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-box-design',
    },
    minVersion: {
        label: '최소 버전',
        value: null,
        component: InputComponents.AInputNumber,
    },
    osType: {
        label: 'OS 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'os-type',
    },
    startAt: {
        label: '시작일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 만료일만 영향을 줍니다.',
    },
    expiredAt: {
        label: '만료일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 시작일만 영향을 줍니다. 둘다 없다면 일자제한이 없습니다.',
    },
    showCount: {
        label: '노출 횟수',
        value: null,
        component: InputComponents.AInputNumber,
        min: 0,
        help: '입력값이 0이면 무제한입니다.',
    },
    repeatIcon: {
        label: 'icon 반복',
        value: false,
        component: InputComponents.BooleanInput,
    },
})

const triggerBoxContent = ref<Record<any, any>>({})
const filteredContent = computed(() => {
    const filterRuleName = filterContentSpec.value.ruleName.value
    if (filterRuleName) {
        return Object.entries(triggerBoxContent.value).reduce(
            (acc, [ruleName, values]) => {
                if (ruleName.includes(filterRuleName)) {
                    return { ...acc, [ruleName]: values }
                }
                return acc
            },
            {} as Record<any, any>
        )
    }
    return triggerBoxContent.value
})

const fetchContentList = async () => {
    const response = await adminApi<ApiResponse<Array<any>>>(
        '/api/trigger-box/content'
    )
    triggerBoxContent.value = response?.result?.reduce((acc, cur) => {
        acc[cur.ruleName] = [cur, ...(acc[cur.ruleName] || [])]
        return acc
    }, {} as Record<string, any>)
}

const filterContentSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        value: null,
    },
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
    },
})

const updateContentSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        value: null,
        readonly: true,
    },
    ruleName: {
        label: '룰 이름',
        value: null,
    },
    iconUrl: {
        label: '아이콘 URL',
        component: InputComponents.ImageInput,
        path: '/trigger_box/icon/',
        value: null,
    },
    title: {
        label: '제목',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    description: {
        label: '설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    appendix: {
        label: '부가 설명',
        value: null,
        component: InputComponents.TemplateInput,
        typeName: 'trigger-box-content-format-args',
    },
    button: {
        label: '버튼 라벨',
        value: null,
    },
    link: {
        label: 'link',
        value: null,
    },
    deepLink: {
        label: 'deepLink',
        value: null,
    },
    design: {
        label: '디자인',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'trigger-box-design',
    },
    minVersion: {
        label: '최소 버전',
        value: null,
        component: InputComponents.AInputNumber,
    },
    osType: {
        label: 'OS 타입',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'os-type',
    },
    startAt: {
        label: '시작일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 만료일만 영향을 줍니다.',
    },
    expiredAt: {
        label: '만료일',
        value: null,
        component: InputComponents.ADatetimePicker,
        help: '입력값이 없다면 시작일만 영향을 줍니다. 둘다 없다면 일자제한이 없습니다.',
    },
    showCount: {
        label: '노출 횟수',
        value: null,
        component: InputComponents.AInputNumber,
        min: 0,
        help: '0일시 노출횟수 제한이 없습니다.',
    },
    repeatIcon: {
        label: 'icon 반복',
        value: false,
        component: InputComponents.BooleanInput,
    },
})

onMounted(async () => {
    const enumTypeStore = useEnumTypeStore()
    await enumTypeStore.dispatchEnums([
        'os-type',
        'ot-interest',
        'trigger-box-content-format-args',
        'trigger-schedule',
        'trigger-box-design',
        'trigger-box-view-type',
    ])
    await fetchContentList()
})
</script>
