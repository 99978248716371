import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRule",
    model: _ctx.form
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions, {
            bordered: true,
            column: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, { label: "타입" }, {
                default: _withCtx(() => [
                  _createVNode(_component_enum_select, {
                    value: _ctx.form['requirement']?.type,
                    "type-name": "ad-requirement-type",
                    onSelect: _ctx.changeFormType,
                    "onUpdate:value": _ctx.resetValue
                  }, null, 8, ["value", "onSelect", "onUpdate:value"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_descriptions_item, { label: "칼럼 ID" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    value: _ctx.form['requirement']?.columnId,
                    onChange: _ctx.changeColumnId
                  }, null, 8, ["value", "onChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}