<template>
    <div>
        <a-form :model="filter">
            <a-row :gutter="24">
                <template
                    v-for="(inputItems, rowIndex) in gridKeys"
                    :key="rowIndex"
                >
                    <a-col
                        v-for="(filterKey, columnIndex) in inputItems"
                        :key="columnIndex"
                        :span="24 / countOfColumn"
                        v-show="rowIndex < showInputCount"
                        style="overflow: auto"
                    >
                        <InputMapper
                            :input-spec="filterSpec[filterKey]"
                            @press-enter="$emit('search')"
                        />
                    </a-col>
                </template>
            </a-row>
        </a-form>
        <a-row>
            <a-col
                :span="24"
                :style="{ textAlign: 'right', paddingBottom: '45px' }"
            >
                <a-button
                    v-if="inputOnly === false"
                    type="primary"
                    html-type="submit"
                    @click="$emit('search')"
                >
                    조회
                </a-button>
                <a-button :style="{ marginLeft: '8px' }" @click="resetFilter">
                    초기화
                </a-button>
                <span v-if="inputCount > 6">
                    <a
                        v-if="expand == true"
                        :style="{ marginLeft: '8px', fontSize: '12px' }"
                        @click="toggleExpand"
                    >
                        숨기기 <up-outlined />
                    </a>
                    <a
                        v-else
                        :style="{ marginLeft: '8px', fontSize: '12px' }"
                        @click="toggleExpand"
                    >
                        더보기 <down-outlined />
                    </a>
                </span>
            </a-col>
        </a-row>
    </div>
</template>

<script lang="ts">
import InputMapper from '@/components/InputMapper.vue'
import { defineComponent, ref, toRef } from 'vue'
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import filterSupport from '@/views/filterSupport'
import { cloneDeep } from 'lodash-es'

export default defineComponent({
    name: 'FilterForm',
    mixins: [filterSupport],
    components: {
        InputMapper,
        UpOutlined,
        DownOutlined,
    },

    setup(props: any) {
        const expand = ref(false)
        const defaultSpec = cloneDeep(props.filterSpec)
        const filterSpecRef = toRef(props, 'filterSpec')
        return { expand, filterSpecRef, defaultSpec }
    },

    props: {
        filterSpec: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        countOfColumn: {
            type: Number,
            default: 3,
        },
        inputOnly: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        filterKeys() {
            return Object.keys(this.filterSpec)
        },

        gridKeys() {
            const sliceCount = Math.ceil(this.inputCount / this.countOfColumn)
            const sliced = []

            for (let i = 0; i < sliceCount; i++) {
                sliced.push(
                    this.filterKeys.slice(
                        i * this.countOfColumn,
                        (i + 1) * this.countOfColumn
                    )
                )
            }
            return sliced
        },

        inputCount() {
            return this.filterKeys.length
        },

        showInputCount() {
            return this.expand === true
                ? Math.ceil(this.inputCount / this.countOfColumn)
                : 3
        },
    },

    methods: {
        resetFilter() {
            this.filterKeys.forEach(
                (it) =>
                    (this.filterSpecRef[it].value = this.defaultSpec[it].value)
            )
            this.$emit('search')
        },
        toggleExpand() {
            this.expand = !this.expand
        },
    },

    emits: ['search'],
})
</script>
