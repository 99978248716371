import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderSlot as _renderSlot, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "slot-tabs"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ad_header_input = _resolveComponent("ad-header-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_ctx.slotId)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_tabs, {
          activeKey: _ctx.slotId,
          "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.slotId) = $event)),
          "tab-position": 'left',
          onChange: _ctx.changeSlot
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slotList, (slot) => {
              return (_openBlock(), _createBlock(_component_a_tab_pane, {
                key: slot.id,
                tab: slot.name
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    class: "slot-tabs-container",
                    span: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_row, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, {
                            class: "slot-main",
                            span: 24
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ad_header_input, {
                                header: "섹션명",
                                value: _ctx.slotName,
                                onChange: _ctx.changeSectionName
                              }, null, 8, ["value", "onChange"]),
                              _createVNode(_component_ad_header_input, {
                                header: "구분값",
                                value: _ctx.slotPosition,
                                onChange: _ctx.changeSlotPosition
                              }, null, 8, ["value", "onChange"]),
                              _createVNode(_component_ad_header_input, {
                                header: "상태",
                                type: "select",
                                value: _ctx.status,
                                options: _ctx.statusOptions,
                                onChange: _ctx.changeStatus
                              }, null, 8, ["value", "options", "onChange"]),
                              _createVNode(_component_a_button, {
                                type: "primary",
                                onClick: _ctx.patch
                              }, {
                                default: _withCtx(() => _cache[1] || (_cache[1] = [
                                  _createTextVNode(" 설정 변경 ")
                                ])),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _renderSlot(_ctx.$slots, "advertise", {}, undefined, true)
                ]),
                _: 2
              }, 1032, ["tab"]))
            }), 128))
          ]),
          _: 3
        }, 8, ["activeKey", "onChange"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[2] || (_cache[2] = [
        _createElementVNode("span", null, "비어있음! 혜택 탭 섹션을 추가해주세요!", -1)
      ])))
}