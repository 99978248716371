<template>
    <span>
        <a-switch
            v-if="toggle"
            :checked="value"
            @change="change"
            @click="(event: any) => $emit('select', event)"
        />
        <a-select
            v-else
            :value="valueText"
            :options="options"
            :disabled="disabled"
            @change="change"
            @select="(event: any) => $emit('select', event)"
            @press-enter="$emit('pressEnter')"
        />
    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'BooleanInput',

    props: {
        value: {
            type: [Boolean, String],
            default: false,
        },
        trueText: {
            type: String,
        },
        falseText: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        notNull: {
            type: Boolean,
            default: false,
        },
        toggle: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['update:value', 'pressEnter', 'select'],
    computed: {
        options() {
            const { trueText, falseText, notNull, toggle } = this.$props

            if (notNull || toggle) {
                return [
                    { label: trueText || 'True', value: 'true' },
                    { label: falseText || 'False', value: 'false' },
                ]
            }
            return [
                { label: trueText || 'True', value: 'true' },
                { label: falseText || 'False', value: 'false' },
                { label: '없음', value: null },
            ]
        },
        valueText() {
            return this.value?.toString()
        },
    },
    methods: {
        change(value: any) {
            this.$emit('update:value', value)
        },
    },
})
</script>
