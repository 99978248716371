import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import ResourceTable from '@/components/ResourceTable.vue'
import { Column } from '.'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { onMounted, ref } from 'vue'
import { required } from '@/util/input-validation'
import { useEnumTypeStore } from '@/store/enumType'
import globalFormState from '@/components/globalFormState'


export default /*@__PURE__*/_defineComponent({
  __name: 'PillyzeImageAsset',
  setup(__props) {

const columns: Array<Column> = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '이름',
        dataIndex: 'name',
        width: 100,
    },
    {
        title: '자산 타입',
        dataIndex: 'pillyzeAssetType',
        customRender: formatEnum('pillyze-asset-type'),
        width: 80,
    },
    {
        title: '이미지',
        dataIndex: 'imageUrl',
        type: 'img',
        width: 100,
    },
    {
        title: '등록자',
        dataIndex: 'createdBy',
        width: 80,
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정자',
        dataIndex: 'updatedBy',
        width: 80,
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const inputSpec = ref<FormSpecification>({
    name: {
        label: '이름',
    },
    pillyzeAssetType: {
        label: '자산 타입',
        component: InputComponents.EnumSelect,
        typeName: 'pillyze-asset-type',
    },
    imageUrl: {
        label: '이미지',
        component: InputComponents.ImageInput,
        readonlyForm: globalFormState.form,
    },
})

const filterSpec = ref<FormSpecification>({
    id: {
        label: 'ID',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '이름',
    },
    pillyzeAssetType: {
        label: '자산 타입',
        component: InputComponents.EnumSelect,
        typeName: 'pillyze-asset-type',
    },
})

const rules = {
    name: [required],
    ImageUrl: [required],
}

onMounted(() => {
    useEnumTypeStore().fetchEnum('pillyze-asset-type')
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "pillyze-image-asset",
    title: "파일 관리",
    uri: "/api/pillyze-image-asset",
    columns: columns,
    "create-spec": inputSpec.value,
    "update-spec": inputSpec.value,
    "filter-spec": filterSpec.value,
    "create-rules": rules,
    "update-rules": rules,
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec", "filter-spec"]))
}
}

})