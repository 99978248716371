import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#f9fefe","padding":"13px","width":"auto"} }

import { ref, toRef } from 'vue'
import IntakeUnitInput from './IntakeUnitInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import BooleanInput from './BooleanInput.vue'
import { aboveZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { DeleteOutlined, SwapOutlined } from '@ant-design/icons-vue'

type IntakeUnit = {
    id?: number
    intakeUnitName?: string
    amount: number
    isDefault?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodIntakeUnitInput',
  props: {
    rules: {
        type: Object,
        default: () => ({
            intakeUnitName: [required],
            amount: [required, aboveZero],
        }),
    },
    productId: {
        type: Number,
    },
    value: {
        type: Array,
        default: () => [],
    },
},
  setup(__props) {

const props = __props

const form = ref<IntakeUnit>({
    amount: 0,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: IntakeUnit) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}
const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            form.value = {
                amount: 0,
            }
        }
    })
}

const selectIsDefault = (value: boolean, record: Record<string, any>) => {
    console.log(value, record)
    if (value) {
        valueRef.value.forEach((it: any) => {
            it.isDefault = false
        })
        record.isDefault = true
    }
}

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref_key: "formRule",
          ref: formRule,
          model: form.value,
          rules: __props.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(IntakeUnitInput, {
              label: "단위",
              value: form.value.intakeUnitName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.value.intakeUnitName) = $event)),
              name: "intakeUnitName"
            }, null, 8, ["value"]),
            _createVNode(_component_a_form_item, {
              name: "amount",
              label: "섭취량"
            }, {
              default: _withCtx(() => [
                _createVNode(KoreanNumberInput, {
                  value: form.value.amount,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((form.value.amount) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 섭취 단위 추가 ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_unref(Draggable), {
          list: valueRef.value,
          "item-key": "intakeUnitName"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: __props.rules,
              layout: "inline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SwapOutlined), { rotate: 90 })
                  ]),
                  _: 1
                }),
                _createVNode(IntakeUnitInput, {
                  label: "단위",
                  value: element.intakeUnitName,
                  "onUpdate:value": ($event: any) => ((element.intakeUnitName) = $event),
                  name: `intakeUnitName${index}`
                }, null, 8, ["value", "onUpdate:value", "name"]),
                _createVNode(_component_a_form_item, {
                  name: `amount${index}`,
                  label: "섭취량"
                }, {
                  default: _withCtx(() => [
                    _createVNode(KoreanNumberInput, {
                      value: element.amount,
                      "onUpdate:value": ($event: any) => ((element.amount) = $event)
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1032, ["name"]),
                _createVNode(_component_a_form_item, {
                  name: "isDefault",
                  label: "기본"
                }, {
                  default: _withCtx(() => [
                    _createVNode(BooleanInput, {
                      value: element.isDefault,
                      "onUpdate:value": ($event: any) => ((element.isDefault) = $event),
                      onSelect: 
                                    (value) => selectIsDefault(value, element)
                                ,
                      toggle: ""
                    }, null, 8, ["value", "onUpdate:value", "onSelect"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => pop(index)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_unref(DeleteOutlined))
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ]))
}
}

})