import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRule",
          model: _ctx.form
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 24,
              class: "content border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box key"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createElementVNode("span", null, "구분 값", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.key,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.key) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 10,
                  class: "input-box"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createElementVNode("span", null, "설정 값", -1)
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 16 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          value: _ctx.form.value,
                          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.value) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { onClick: _ctx.add }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_plus_outlined)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { style: {"margin-top":"5px"} }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.value, (item, index) => {
          return (_openBlock(), _createBlock(_component_a_row, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, {
                class: "content",
                span: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box key"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createElementVNode("span", null, "구분 값", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.key,
                            onChange: (e) => _ctx.updateButton(index, 'key', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, {
                    span: 10,
                    class: "input-box key"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 8 }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createElementVNode("span", null, "설정 값", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_a_col, { span: 16 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: item?.value,
                            onChange: (e) => _ctx.updateButton(index, 'value', e)
                          }, null, 8, ["value", "onChange"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_col, { span: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        onClick: () => _ctx.remove(index)
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_delete_outlined)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ], 64))
}