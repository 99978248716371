<template>
    <div>
        <a-row>
            <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
                <nutrient-input
                    v-model:value="nutrientName"
                    name="nutrientName"
                    @select="(args) => setNutrient(args, form)"
                    width="340px"
                />
                <a-form-item name="amount">
                    <korean-number-input v-model:value="form.amount" />
                </a-form-item>
                <standard-unit-input v-model:value="form.unit" name="unit" />
                <a-form-item>
                    <a-button type="primary" @click="add">
                        <template #icon>
                            <plus-outlined />
                        </template>
                    </a-button>
                </a-form-item>
                <a-input
                    placeholder="부가 설명 (e.g., 혼합물 설명, 추가적인 구성 설명)"
                    v-model:value="form.description"
                    style="margin-right: 10px; width: 450px"
                />
                <auto-complete-input
                    v-model:value="form.goodPoints"
                    placeholder="좋은 점"
                    name="goodPoints"
                    uri="/api/nutrient-good-point/name"
                    mode="multiple"
                />
            </a-form>
            <b style="color: red"> {{ quantityCriteria }} </b>
        </a-row>
        <a-row>
            <a-divider style="height: 1px; background-color: #cdcdcd" />
        </a-row>
        <a-row>
            <draggable :list="valueRef" item-key="nutrientName">
                <template #item="{ element, index }">
                    <a-form :model="element" :rules="rules" layout="inline">
                        <nutrient-input
                            v-model:value="element.nutrientName"
                            :name="`nutrientName${index}`"
                            @select="(args) => setNutrient(args, element)"
                            width="340px"
                            mode="default"
                        />
                        <a-form-item :name="`amount${index}`">
                            <korean-number-input
                                v-model:value="element.amount"
                            />
                        </a-form-item>
                        <standard-unit-input
                            v-model:value="element.unit"
                            :name="`unit${index}`"
                        />
                        <a-textarea
                            v-model:value="element.description"
                            style="margin-right: 10px; width: 450px"
                            placeholder="제품 내 영양소에 대한 메모 (e.g., 혼합물 목록..)"
                        />
                        <auto-complete-input
                            v-model:value="element.goodPoints"
                            placeholder="좋은 점"
                            :name="`goodPoints${index}`"
                            uri="/api/nutrient-good-point/name"
                            mode="multiple"
                        />
                        <a-form-item>
                            <a-button type="danger" @click="() => pop(index)">
                                <template #icon>
                                    <delete-outlined />
                                </template>
                            </a-button>
                        </a-form-item>
                        <a-divider
                            style="height: 1px; background-color: #eeeeee"
                            dashed
                        />
                    </a-form>
                </template>
            </draggable>
        </a-row>
    </div>
</template>

<script lang="ts">
import StandardUnitInput from '@/components/input/StandardUnitInput.vue'
import NutrientInput from '@/components/input/NutrientInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import { required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { defineComponent, Ref, ref, toRef } from 'vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import AutoCompleteInput from './AutoCompleteInput.vue'

type Amounts = {
    id?: number
    nutrientName: string | null
    amount: number
    toUnit: string | null
    unit: string
    description: string
    goodPoints?: Array<string>
    normedNutrientName?: string
    normedAmount?: number
    normedUnit?: string
    generalMax?: number
    generalMin?: number
    generalPodi?: number
}

export default defineComponent({
    name: 'ProductNutrientInput',

    components: {
        StandardUnitInput,
        NutrientInput,
        KoreanNumberInput,
        Draggable,
        PlusOutlined,
        DeleteOutlined,
        AutoCompleteInput,
    },

    setup(props: any) {
        const form: Ref<Amounts> = ref({
            nutrientName: null,
            amount: 0,
            toUnit: null,
            unit: '',
            description: '',
            goodPoints: [],
        })
        const formRule = ref()
        const valueRef = toRef(props, 'value')

        const push = (value: Amounts) => {
            valueRef.value.push(value)
        }
        const pop = (index: number) => {
            valueRef.value?.splice(index, 1)
        }

        return { form, formRule, valueRef, push, pop }
    },

    props: {
        rules: {
            type: Object,
            default: () => ({
                nutrientName: [required],
                amount: [required],
                unit: [required],
            }),
        },
        productId: {
            type: Number,
        },
        value: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        nutrientName: {
            get() {
                return this.form.nutrientName || ''
            },
            set(value: any) {
                this.form.nutrientName = value
            },
        },

        formula() {
            const { toUnit, unit } = this.form
            return this.$store.getters['formula'](unit, toUnit)
        },

        quantityCriteria() {
            const { amount, unit, toUnit, generalMax } = this.form
            let targetAmount = amount || 0

            if (unit != null && this.formula != null && toUnit != unit) {
                targetAmount = this.formula * targetAmount
            }

            if (generalMax != null && generalMax < targetAmount) {
                return `권장 섭취되는 최대량은 ${generalMax}${toUnit} 입니다.`
            }
            return ''
        },
    },

    emits: ['update:value'],

    methods: {
        add() {
            return this.formRule.validate().then(() => {
                if (this.value != null) {
                    this.push(this.form)
                    this.form = {
                        nutrientName: null,
                        amount: 0,
                        unit: '',
                        toUnit: null,
                        description: '',
                    }
                }
            })
        },

        setNutrient(
            {
                name,
                unit,
                generalMax,
                generalMin,
                generalPodi,
                goodPoint,
            }: {
                name: string
                unit: string
                generalMax?: number
                generalMin?: number
                generalPodi?: number
                goodPoint?: string
            },
            form: Amounts
        ) {
            form.nutrientName = name
            form.unit = unit
            form.toUnit = unit
            form.generalMax = generalMax
            form.generalMin = generalMin
            form.generalPodi = generalPodi
            form.goodPoints = goodPoint ? [goodPoint] : []
        },
    },
})
</script>
