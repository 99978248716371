import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "이벤트 토스트",
    uri: "/api/event-toast",
    columns: _ctx.columns,
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules
  }, null, 8, ["columns", "filter-spec", "create-spec", "update-spec", "create-rules", "update-rules"]))
}