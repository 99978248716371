import { cloneDeep } from 'lodash'
import { computed, ref } from 'vue'
import { FormSpecification, InputSpecification } from './InputMapper.vue'

export default () => {
    const formSpec = ref<FormSpecification>({})

    const defaultSpec = ref<FormSpecification>({})

    const resetForm = () => {
        formKeys.value.forEach((key) => {
            formSpec.value[key].value = defaultSpec.value[key]?.value
        })
    }

    const setFormValues = (resource: Record<any, any>) => {
        Object.keys(resource).forEach((key: any) => {
            if (formSpec.value[key]) {
                formSpec.value[key].value = resource[key]
            }
        })
    }

    const setFormValue = (key: string, value: any) => {
        formSpec.value[key].value = value
    }

    const setInputSpec = (key: string, value: InputSpecification) => {
        Object.keys(value).forEach((inputName) => {
            //@ts-ignore
            formSpec.value[key][inputName] = value[inputName]
        })
    }

    const formKeys = computed<Array<string>>(() => {
        if (formSpec.value == null) return []
        return [...new Set([...Object.keys(formSpec.value)])]
    })

    const form: Record<string, any> = computed(() => {
        if (formSpec.value == null) return {}

        return Object.keys(formSpec.value).reduce((record, key) => {
            if (formSpec.value[key].value == null) {
                return { ...record }
            }

            return { ...record, [key]: formSpec.value[key].value }
        }, {})
    })

    const overwriteFormSpec = (spec: FormSpecification) => {
        if (Object.keys(spec).length === 0) {
            formSpec.value = {}
        } else {
            formSpec.value = ref(cloneDeep(spec)).value
            defaultSpec.value = ref(cloneDeep(spec)).value
        }
    }

    return {
        formSpec,
        defaultSpec,
        formKeys,
        form,
        resetForm,
        setFormValues,
        setFormValue,
        setInputSpec,
        overwriteFormSpec,
    }
}
