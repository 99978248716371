<template>
    <ResourceTable
        title="건강 상태"
        uri="/api/health-status"
        :columns="columns"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        primary-key="code"
        exportable
    />
</template>

<script lang="ts">
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatEnum } from '@/util/formmater'
import { defineComponent } from 'vue'
import { Column } from '.'
import { required } from '@/util/input-validation'

export default defineComponent({
    name: 'HealthStatus',
    components: {
        ResourceTable,
    },
    data() {
        const columns: Array<Column> = [
            {
                title: '항목',
                dataIndex: 'category',
                customRender: formatEnum('health-status-category'),
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
            },
            {
                title: '이름',
                dataIndex: 'name',
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
            },
            {
                title: '코드',
                dataIndex: 'code',
                sorter: true,
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
            },
            {
                title: '이름(유저 선택지)',
                dataIndex: 'displayName',
            },
            {
                title: '추천 태그',
                dataIndex: 'recommendTag',
            },
            {
                title: '추천팁',
                dataIndex: 'recommendTip',
            },
            {
                title: '추천탭 타이틀',
                dataIndex: 'recommendDetailTitle',
            },
            {
                title: '진단명',
                dataIndex: 'mcuDiagnosis',
            },
            {
                title: '순서',
                dataIndex: 'rank',
                sorter: true,
            },
        ]

        const filterSpec: FormSpecification = {
            code: {
                label: '이름',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/name',
                mode: true,
            },
            category: {
                label: '항목',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'health-status-category',
            },
        }

        const createSpec: FormSpecification = {
            code: { label: '코드', value: null },
            name: { label: '이름', value: null },
            iconUrl: { label: 'Icon image 주소', value: null },
            displayName: { label: '유저 선택지', value: null },
            higBroadName: {
                label: 'HIG Broad',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
            },
            mcuDiagnosis: {
                label: '진단명',
                value: null,
            },
            recommendTag: {
                label: '추천 태그',
                value: null,
            },
            recommendTip: {
                label: '추천팁',
                value: null,
            },
            recommendDetailTitle: {
                label: '추천탭 타이틀',
                value: null,
            },
            rank: {
                label: '순서',
                value: null,
                help: '숫자가 높을 수록 상위에 노출됨',
            },
            category: {
                label: '항목',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'health-status-category',
            },
            memo: {
                label: '관리자 메모',
                value: null,
                type: InputType.TEXTAREA,
            },
        }

        const updateSpec = {
            ...createSpec,
            code: { label: '코드', value: null, disabled: true },
        }

        const rules = {
            category: [required],
            name: [required],
            code: [required],
            iconUrl: [required],
            displayName: [required],
            rank: [required],
        }

        return {
            columns,
            filterSpec,
            createSpec,
            updateSpec,
            rules,
        }
    },

    mounted() {
        this.$store.dispatch('dispatchEnums', [
            'health-status-category',
            'hig-broad-name',
        ])
    },
})
</script>
