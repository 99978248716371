<template>
    <ResourceTable
        title="영양제 인기 검색어"
        uri="/api/product-keyword-rank"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        deletable
        load-on-mount
    >
        <template #detail="{ record }">
            <a-descriptions
                title="팝업 상세"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="이벤트명">
                    {{ record.name }}
                </a-descriptions-item>
                <a-descriptions-item label="시작일">
                    {{ record.startAt }}
                </a-descriptions-item>
                <a-descriptions-item label="종료일">
                    {{ record.endAt }}
                </a-descriptions-item>
                <a-descriptions-item label="모집마감일">
                    {{ record.recruitAt }}
                </a-descriptions-item>
                <a-descriptions-item label="추첨발표일">
                    {{ record.announceAt }}
                </a-descriptions-item>
                <a-descriptions-item label="메타 타이틀">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="웹뷰 타이틀">
                    {{ record.webviewTitle }}
                </a-descriptions-item>
                <a-descriptions-item label="메타 디스크립션">
                    {{ record.description }}
                </a-descriptions-item>
                <a-descriptions-item label="담당자">
                    {{ record.manager }}
                </a-descriptions-item>
                <a-descriptions-item label="최대 모집인원">
                    {{ record.maxUserCount }}
                </a-descriptions-item>
                <a-descriptions-item label="최소 모집인원">
                    {{ record.minUserCount }}
                </a-descriptions-item>
                <a-descriptions-item label="회차">
                    {{ record.serialNo }}
                </a-descriptions-item>
                <a-descriptions-item label="앱용 URL">
                    {{ record.eventUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="외부 공유용 URL">
                    {{ record.externalEventUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="(구) 앱 배너 이미지">
                    {{ record.bannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="(구) 앱 배너 심플 이미지">
                    {{ record.simpleBannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="앱 배너 이미지">
                    {{ record.bannerLayoutDesc }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 공유용 이미지">
                    {{ record.shareImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="카톡 공유용 이미지">
                    {{ record.kakaoShareImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 홈 상단 배너(PC)">
                    {{ record.webHomeTopBannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 홈 상단 배너(MO)">
                    {{ record.webHomeTopBannerImageUrl2 }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 사이드바 배너(PC)">
                    {{ record.webSidebarBannerImageUrl }}
                </a-descriptions-item>
                <a-descriptions-item label="웹 사이드바 배너(MO)">
                    {{ record.webSidebarBannerImageUrl2 }}
                </a-descriptions-item>
                <a-descriptions-item label="이벤트 타입">
                    {{ record.eventType }}
                </a-descriptions-item>
                <a-descriptions-item label="APP 노출">
                    {{ record.visible }}
                </a-descriptions-item>
                <a-descriptions-item label="WEB 노출">
                    {{ record.webVisible }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="업데이트일">
                    {{ record.updateTime }}
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import {
    formatEnum,
    formatLocalDate,
    formatLocalDateTime,
} from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'ProductSearchKeywordRank',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            keyword: {
                label: '키워드',
                value: null,
            },
            prio: {
                label: '순위',
                value: null,
                component: InputComponents.AInputNumber,
                help: '숫자가 높을 수록 우선순위가 높음',
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            createSpec,
            updateSpec: { ...createSpec },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                {
                    title: '키워드',
                    dataIndex: 'keyword',
                    width: 100,
                },
                {
                    title: '우선순위',
                    dataIndex: 'prio',
                    width: 100,
                },
            ],
            rules: {
                startAt: [required],
                endAt: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['event-type'])
    },
})
</script>
