<template>
    <ResourceTable
        title="연구소 팁 (세부)"
        uri="/api/product-consumer-guide/docs"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :scroll="{ x: true }"
        :columns="columns"
        deletable
        load-on-mount
    >
    </ResourceTable>
</template>

<script lang="ts">
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, ref, Ref } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'ConsumerGuideDoc',
    mixins: [TableSupport],
    components: {
        ResourceTable,
    },

    setup() {
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const loading = ref(false)
        return { selectedRowKeys, loading }
    },

    data() {
        const filterSpec: FormSpecification = {
            guideName: {
                label: '분류 이름',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'consumer-guide',
            },
        }

        const createSpec: FormSpecification = {
            title: { label: '제목', value: null },
            content: { label: '내용', value: null, type: InputType.TEXTAREA },
            priority: {
                label: '우선 순위',
                value: null,
                component: InputComponents.AInputNumber,
            },
            guideName: {
                label: '분류 이름',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'consumer-guide',
            },
        }

        const updateSpec: FormSpecification = {
            title: { label: '제목', value: null },
            content: { label: '내용', value: null, type: InputType.TEXTAREA },
            priority: {
                label: '우선 순위',
                value: null,
                component: InputComponents.AInputNumber,
            },
        }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 10,
            },
            {
                title: '분류',
                dataIndex: 'guideName',
                width: 35,
            },
            {
                title: '제목',
                dataIndex: 'title',
                width: 120,
            },
            {
                title: '우선순위',
                dataIndex: 'priority',
                sorter: true,
                width: 20,
            },
        ]
        return {
            filterSpec,
            createSpec,
            updateSpec,
            columns,
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['consumer-guide'])
    },
})
</script>
