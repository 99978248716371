import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    FormSpecification,
    InputComponents,
    InputSpecification,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import UpdateForm from '@/components/UpdateForm.vue'
import {
    formulation,
    recommendTimeCase,
    recommendTimeWithMealCase,
    sourceType,
} from '@/constants'
import router from '@/router'
import { ProductTag } from '@/store/tag'
import { required } from '@/util/input-validation'
import { cloneDeep } from 'lodash'
import { computed, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsEdit',
  props: {
    resourceId: {
        type: [Number, String],
    },
},
  setup(__props) {

const form = ref()

const intakeMethodRegex = /\d{1,2}월|\d{1,3}일|\d주|\d{1,4}년|\d{1,3}회/



const visible = ref(true)
const setFormValue = computed(() => form.value.setFormValue)
const formSpec = computed(() => form.value.formSpec)

const listenSpec = (
    currentSpec: FormSpecification,
    setInputSpec: (key: string, record: Record<any, any>) => void
) => {
    if (
        currentSpec.dailyIntakeMin.value != currentSpec.dailyIntakeMax.value ||
        currentSpec.onetimeIntakeMin.value != currentSpec.onetimeIntakeMax.value
    ) {
        setInputSpec('intakeNote2', {
            value: '처음에는 최소량만 섭취하시다가 눈에 띄는 부작용이 없다면 최대량까지 천천히 늘려서 드시는 걸 추천드려요',
            disabled: true,
            help: '하루 취소 섭취량과 최대 섭취량, 1회 최소 섭취량과 최대 섭취량이 같으면 변경할 수 없습니다.',
        })
    } else {
        setInputSpec('intakeNote2', {
            disabled: false,
            help: null,
        })
    }
}

const toggleVisible = () => {
    visible.value = !visible.value
    router.back()
}

const preset: InputSpecification = {
    component: InputComponents.PresetInput,
    options: [
        {
            label: '멀티비타민',
            value: {
                nutrients: [
                    { nutrientName: '비타민A', unit: 'ug_RAE' },
                    { nutrientName: '비타민D', unit: 'ug' },
                    { nutrientName: '비타민E', unit: 'mg_aTE' },
                    { nutrientName: '비타민K', unit: 'ug' },
                    { nutrientName: '비타민C', unit: 'mg' },
                    { nutrientName: '비타민B1', unit: 'mg' },
                    { nutrientName: '비타민B2', unit: 'mg' },
                    { nutrientName: '비타민B6', unit: 'mg' },
                    { nutrientName: '엽산', unit: 'ug' },
                    { nutrientName: '비오틴', unit: 'ug' },
                    { nutrientName: '판토텐산', unit: 'mg' },
                    { nutrientName: '칼슘', unit: 'mg' },
                    { nutrientName: '마그네슘', unit: 'mg' },
                    { nutrientName: '아연', unit: 'mg' },
                    { nutrientName: '셀레늄', unit: 'ug' },
                    { nutrientName: '구리', unit: 'ug' },
                    { nutrientName: '망간', unit: 'mg' },
                    { nutrientName: '크롬', unit: 'ug' },
                ],
            },
        },
    ],
}

const inputSpec: FormSpecification = {
    preset: {
        ...cloneDeep(preset),
        value: null,
        input: (value: any) => {
            const nutrients = value[0]?.nutrients
            if (nutrients != null) {
                setFormValue.value('nutrients', nutrients)
            } else {
                setFormValue.value('nutrients', [])
            }
        },
    },
    productName: {
        label: '제품명',
        value: null,
        description: true,
    },
    status: {
        label: '제품 상태',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'product-status',
    },
    productNameEn: { label: '제품명(영어)', value: null },
    regNum: {
        label: '식품이력추적번호',
        value: null,
        component: InputComponents.AInputNumber,
        description: true,
    },
    brandName: {
        label: '브랜드명',
        value: null,
        component: InputComponents.AutoCompleteInput,
        uri: '/api/brand/name',
    },
    brandNameEn: {
        label: '브랜드명(영어)',
        value: null,
        readonly: true,
    },
    sourceInfoUrl: {
        label: '제품 사이트 주소',
        value: null,
        input: (event: any) => {
            const inputUrl: string = event?.target?.value

            if (inputUrl.includes('danawa.com')) {
                setFormValue.value('sourceType', 'D')
                const match = inputUrl.match('[?&]pcode=([^&]+)')
                const productId = match ? match[1] : null
                setFormValue.value('sourceId', productId)
            } else if (inputUrl.includes('iherb.com')) {
                setFormValue.value('sourceType', 'I')
                const splitedUrl = inputUrl.split('/')
                const productId = splitedUrl[splitedUrl.length - 1]
                setFormValue.value('sourceId', 'i' + productId)
            } else {
                setFormValue.value('sourceType', 'P')
                setFormValue.value('sourceId', null)
            }
        },
    },
    sourceImageUrl: {
        label: '제품 이미지 주소',
        value: null,
    },
    certification: {
        label: '인증사항',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'certification',
    },
    isGmp: {
        label: 'GMP 여부',
        value: null,
        component: InputComponents.BooleanInput,
        trueText: '인증받음',
        falseText: '알수없음',
        notNull: true,
        disabled: (record?: Record<string, any>) =>
            record?.certification === 'OTC_DRUG',
    },
    tags: {
        label: '기능메인태그',
        value: null,
        component: InputComponents.ProductTagInput,
        description: true,
        select: (value: ProductTag) => {
            if (formSpec.value.searchTags.value == null) {
                setFormValue.value('searchTags', [])
            }
            formSpec.value.searchTags.value.push(...value.keywords)
            setFormValue.value('searchTags', [
                ...new Set(formSpec.value.searchTags.value),
            ])
        },
        deselect: (value: ProductTag) => {
            value.keywords.forEach((keyword) => {
                const index = formSpec.value.searchTags.value.indexOf(keyword)
                formSpec.value.searchTags.value.splice(index, 1)
            })
        },
        mode: SelectInputMode.MULTIPLE,
    },
    searchTags: {
        label: '제품서치태그',
        value: null,
        component: InputComponents.ASelect,
        mode: SelectInputMode.TAGS,
        help: '수정 불가',
    },
    customSearchTags: {
        label: '커스텀 서치태그',
        value: null,
        type: InputType.TEXTAREA,
        help: '운영자가 특정 제품에 추가적인 태그를 추가하고 싶은 경우 (마더브랜드(상위브랜드), 판매원, 제조사, 수입사, 브랜드 모델 등)',
    },
    nutrients: {
        label: '영양성분',
        value: [],
        component: InputComponents.ProductNutrientInput,
    },
    auxiliaryMaterial: {
        label: '부원료',
        value: null,
        type: InputType.TEXTAREA,
        help: '예시: 빌베리추출물 [Indena], 프랑스해안송껍질추출물 [피크노제놀]',
    },
    memo1: {
        label: '메모(영양성분)',
        value: null,
        type: InputType.TEXTAREA,
        help: '제품정보에 나와있는 영양성분 이름이 검색되지 않으면, 여기에 써주세요',
    },
    intakeCount: {
        label: '서빙사이즈',
        value: null,
        component: InputComponents.AInputNumber,
    },
    formulation: {
        label: '제형',
        value: null,
        component: InputComponents.ASelect,
        options: formulation,
        description: true,
    },
    dailyIntakeMin: {
        label: '1일 최소 섭취 횟수',
        value: null,
        component: InputComponents.AInputNumber,
    },
    dailyIntakeMax: {
        label: '1일 최대 섭취 횟수',
        value: null,
        component: InputComponents.AInputNumber,
        help: '1 ~ 4',
        max: 4,
        input: (value: number) => {
            if (value === 1) {
                setFormValue.value('recommendTime1', '언제든')
            } else if (value === 2) {
                setFormValue.value('recommendTime1', '아침')
                setFormValue.value('recommendTime2', '저녁')
            } else if (value === 3) {
                setFormValue.value('recommendTime1', '아침')
                setFormValue.value('recommendTime2', '점심')
                setFormValue.value('recommendTime3', '저녁')
            } else if (value === 4) {
                setFormValue.value('recommendTime1', '아침')
                setFormValue.value('recommendTime2', '점심')
                setFormValue.value('recommendTime3', '저녁')
                setFormValue.value('recommendTime4', '자기전')
            }
        },
    },
    onetimeIntakeMin: {
        label: '1회 최소 복용량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    onetimeIntakeMax: {
        label: '1회 최대 복용량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    intakeMethod: {
        label: '섭취방법',
        value: null,
        description: true,
    },
    intakeGuide: {
        label: '섭취안내문',
        value: null,
        formChangeHook: (form: Record<string, any>) => {
            const {
                dailyIntakeMin,
                dailyIntakeMax,
                onetimeIntakeMin,
                onetimeIntakeMax,
                formulation,
                intakeMethod,
            } = form
            const dailyIntakeMaxTemplate =
                (dailyIntakeMax != null &&
                    dailyIntakeMax != '' &&
                    `- 최대 ${dailyIntakeMax}회까지`) ||
                '이상'

            if (
                dailyIntakeMin != dailyIntakeMax &&
                onetimeIntakeMin != onetimeIntakeMax
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `
              하루에 최소 ${dailyIntakeMin || ''}회 ${dailyIntakeMaxTemplate}
              1회당 최소${onetimeIntakeMin || ''}${formulation || ''} - 최대 ${
                        onetimeIntakeMax || ''
                    }${formulation || ''}까지 ${intakeMethod || ''} 드세요
            `
                )
            } else if (
                dailyIntakeMin == dailyIntakeMax &&
                onetimeIntakeMin == onetimeIntakeMax &&
                intakeMethod?.match(intakeMethodRegex)
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `1회당 ${onetimeIntakeMin || ''}${formulation || ''}씩 ${
                        intakeMethod || ''
                    } 드세요`
                )
            } else if (
                dailyIntakeMin == null &&
                dailyIntakeMax == null &&
                onetimeIntakeMin != onetimeIntakeMax
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `1회당 최소 ${onetimeIntakeMin || ''}${
                        formulation || ''
                    } - 최대 ${onetimeIntakeMax || ''}${
                        formulation || ''
                    }까지 ${intakeMethod || ''} 드세요`
                )
            } else if (
                dailyIntakeMin == dailyIntakeMax &&
                onetimeIntakeMin == onetimeIntakeMax
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `하루에 ${dailyIntakeMin || ''}회, 1회당 ${
                        onetimeIntakeMin || ''
                    }${formulation || ''}씩 ${intakeMethod || ''} 드세요`
                )
            } else if (
                dailyIntakeMin == dailyIntakeMax &&
                onetimeIntakeMin != onetimeIntakeMax
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `하루에 ${dailyIntakeMin || ''}회, 1회당 최소 ${
                        onetimeIntakeMin || ''
                    }${formulation || ''} - 최대 ${onetimeIntakeMax || ''}${
                        formulation || ''
                    }까지 ${intakeMethod || ''} 드세요`
                )
            } else if (
                dailyIntakeMin != dailyIntakeMax &&
                onetimeIntakeMin == null &&
                onetimeIntakeMax != null
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `하루에 최소 ${
                        dailyIntakeMin || ''
                    }회 ${dailyIntakeMaxTemplate} 1회당 최대 ${
                        onetimeIntakeMax || ''
                    }${formulation || ''}까지 ${intakeMethod || ''} 드세요`
                )
            } else if (
                dailyIntakeMin != dailyIntakeMax &&
                onetimeIntakeMin == onetimeIntakeMax
            ) {
                setFormValue.value(
                    'intakeGuide',
                    `하루에 최소 ${
                        dailyIntakeMin || ''
                    }회 ${dailyIntakeMaxTemplate} ${onetimeIntakeMin || ''}${
                        formulation || ''
                    }씩 ${intakeMethod || ''} 드세요`
                )
            }
        },
        readonly: true,
    },
    recommendTime1: {
        label: '추천 섭취시간1',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeCase,
        description: true,
        disabled: (record?: Record<string, any>) => {
            if (record?.dailyIntakeMin >= 1) {
                return false
            }
            setFormValue.value('recommendTime1', null)
            return true
        },
    },
    recommendTimeWithMeal1: {
        label: '식전/식후1',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeWithMealCase,
    },
    recommendTime2: {
        label: '추천 섭취시간2',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeCase,
        disabled: (record?: Record<string, any>) => {
            if (record?.dailyIntakeMin >= 2) {
                return false
            }
            setFormValue.value('recommendTime2', null)
            return true
        },
    },
    recommendTimeWithMeal2: {
        label: '식전/식후2',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeWithMealCase,
    },
    recommendTime3: {
        label: '추천 섭취시간3',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeCase,
        disabled: (record?: Record<string, any>) => {
            if (record?.dailyIntakeMin >= 3) {
                return false
            }
            setFormValue.value('recommendTime3', null)
            return true
        },
    },
    recommendTimeWithMeal3: {
        label: '식전/식후3',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeWithMealCase,
    },
    recommendTime4: {
        label: '추천 섭취시간4',
        value: null,
        component: InputComponents.ASelect,
        options: recommendTimeCase,
        disabled: (record?: Record<string, any>) => {
            if (record?.dailyIntakeMin === 4) {
                return false
            }
            setFormValue.value('recommendTime4', null)
            return true
        },
    },
    intakeNote1: {
        label: '섭취노트1',
        value: '부작용이 느껴지면 섭취량을 줄이거나 섭취를 중단하세요',
        description: true,
    },
    intakeNote2: { label: '각주', value: null },
    intakeNote3: { label: '섭취노트3', value: null },
    memo2: {
        label: '메모(추가정보)',
        value: null,
        type: InputType.TEXTAREA,
        help: '검색되지 않거나 상세설명이 필요한 경우, 여기에 써주세요',
    },
    gmoStatus: {
        label: 'GMO',
        value: 'UNKNOWN',
        component: InputComponents.EnumSelect,
        typeName: 'additive-status',
        disabled: true,
        hidden: true,
    },
    artificialDyeStatus: {
        label: '인공색소',
        value: 'UNKNOWN',
        component: InputComponents.EnumSelect,
        typeName: 'additive-status',
        disabled: true,
        hidden: true,
    },
    artificialSpiceStatus: {
        label: '인공향료',
        value: 'UNKNOWN',
        component: InputComponents.EnumSelect,
        typeName: 'additive-status',
        disabled: true,
        hidden: true,
    },
    memo3: {
        label: '메모(식품이력추적)',
        value: null,
        type: InputType.TEXTAREA,
        help: 'GMO, 색소, 향료에 관한 내용을 남겨주세요:)',
    },
    targetGender: {
        label: '타겟 성별',
        value: 'UNISEX',
        component: InputComponents.EnumSelect,
        typeName: 'gender',
    },
    targetAge: {
        label: '타겟 나이대',
        value: 'AGE_ALL',
        component: InputComponents.EnumSelect,
        typeName: 'age-range',
    },
    targetHealthStatus: {
        label: '타겟 건강상태',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'health-condition',
        mode: SelectInputMode.TAGS,
    },
    consumerGuides: {
        label: '연구소 팁',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'consumer-guide',
    },
    sourceType: {
        label: '원본유형',
        value: null,
        component: InputComponents.ASelect,
        options: sourceType,
        description: true,
        input: (value: string) => {
            if (value === 'P') {
                setFormValue.value('sourceType', 'P')
                setFormValue.value('sourceId', null)
            }
        },
    },
    sourceId: {
        label: '원본 ID',
        value: null,
        description: true,
        disabled: (record?: Record<string, any>) => {
            if (record?.sourceType === 'P') {
                setFormValue.value('sourceId', null)
                return true
            }
            return false
        },
    },
    renewedID: {
        label: '갱신 ID',
        value: null,
        description: true,
    },
}

const rules = {
    productName: [required],
    brandName: [required],
    sourceImageUrl: [required],
    sourceId: [
        {
            validator: (rule: string, value: string) => {
                if (formSpec.value.sourceType.value === 'I') {
                    if (!value.startsWith('i')) {
                        return Promise.reject(
                            new Error(
                                "원본 타입이 I일 경우 원본ID는 'i'로 시작해야합니다."
                            )
                        )
                    }
                }
                if (formSpec.value.sourceType.value === 'D') {
                    if (!value) {
                        return Promise.reject(
                            new Error(
                                '원본 타입이 D일 경우 원본ID를 입력해야합니다.'
                            )
                        )
                    }
                }
                return Promise.resolve()
            },
            trigger: 'blur',
        },
    ],
    nutrients: [
        {
            validator: (rule: string, value: any) => {
                if (value instanceof Array) {
                    for (const nutrient of value) {
                        if (nutrient.amount == null) {
                            return Promise.reject(
                                new Error('영양소 수치 미입력')
                            )
                        }

                        if (
                            value.filter(
                                (it: any) =>
                                    it.nutrientName === nutrient.nutrientName
                            ).length > 1
                        ) {
                            return Promise.reject(
                                new Error(
                                    `${nutrient.nutrientName} 영양소가 중복되었습니다. 중복된 영양소는 등록할 수 없습니다.`
                                )
                            )
                        }
                    }
                }
                return Promise.resolve()
            },
        },
    ],
}

return (_ctx: any,_cache: any) => {
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    title: "제품 수정",
    width: "800px",
    visible: visible.value,
    onClose: toggleVisible
  }, {
    default: _withCtx(() => [
      _createVNode(UpdateForm, {
        ref_key: "form",
        ref: form,
        uri: "/api/products",
        "resource-id": __props.resourceId,
        "input-spec": inputSpec,
        rules: rules,
        onListenSpec: listenSpec
      }, null, 8, ["resource-id"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})