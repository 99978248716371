import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_job_monitor = _resolveComponent("job-monitor")!
  const _component_NotificationOutlined = _resolveComponent("NotificationOutlined")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "공지사항 및 이벤트 알림",
    uri: "/api/admin-notification",
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "action-spec": _ctx.actionSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    "action-uri": "/api/admin-notification/notice",
    "action-modal-title": "알림",
    "action-modal-disabled": _ctx.actionModalDisabled,
    columns: _ctx.columns,
    scroll: { x: 2000, y: 650 },
    "load-on-mount": "",
    deletable: ""
  }, {
    actionModalHeader: _withCtx(({ record }) => [
      _createElementVNode("span", null, _toDisplayString(`ID: ${record.id}를 처리하시겠습니까?`), 1)
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_job_monitor, { "job-name": "noticeJob" })
    ]),
    actionModalIcon: _withCtx(() => [
      _createVNode(_component_NotificationOutlined)
    ]),
    _: 1
  }, 8, ["filter-spec", "create-spec", "update-spec", "action-spec", "create-rules", "update-rules", "action-modal-disabled", "columns"]))
}