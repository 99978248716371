import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.toggle)
      ? (_openBlock(), _createBlock(_component_a_switch, {
          key: 0,
          checked: _ctx.value,
          onChange: _ctx.change,
          onClick: _cache[0] || (_cache[0] = (event) => _ctx.$emit('select', event))
        }, null, 8, ["checked", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          value: _ctx.valueText,
          options: _ctx.options,
          disabled: _ctx.disabled,
          onChange: _ctx.change,
          onSelect: _cache[1] || (_cache[1] = (event) => _ctx.$emit('select', event)),
          onPressEnter: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('pressEnter')))
        }, null, 8, ["value", "options", "disabled", "onChange"]))
  ]))
}