<template>
    <ResourceTable
        title="EventUserSegment"
        uri="/api/event-user-segment"
        :create-spec="createSpec"
        :create-rules="createRules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        load-on-mount
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import { FormSpecification } from '@/components/InputMapper.vue'
import { formatBoolean } from '@/util/formmater'

export default defineComponent({
    name: 'EventUserSegment',
    components: {
        ResourceTable,
    },
    setup() {
        const createSpec: FormSpecification = {
            tagId: {
                label: '이벤트 ID',
                value: null,
            },
            segId: {
                label: '세그먼트 ID',
                value: null,
            },
            query: {
                label: '쿼리 조건',
                value: null,
            },
            description: {
                label: '설명',
                value: null,
            },
        }
        const createRules = {
            tagId: [required],
            segId: [required],
            query: [required],
        }
        const filterSpec: FormSpecification = {
            segId: {
                label: '세그먼트 ID',
                value: null,
            },
            enable: {
                label: '활성화',
                value: null,
            },
            tagId: {
                label: '이벤트 ID',
                value: null,
            },
        }
        const columns = [
            {
                title: '세그먼트 ID',
                dataIndex: 'segId',
                fixed: 'left',
                width: 156,
            },
            {
                title: '이벤트 ID',
                dataIndex: 'eventId',
                width: 96,
            },
            {
                title: '이벤트 제목',
                dataIndex: 'eventName',
            },
            {
                title: '구매 종료일',
                dataIndex: 'endAt',
            },
            {
                title: '리뷰 종료일',
                dataIndex: 'eventPromotedReviewEndAt',
            },
            {
                title: '페이백 종료일',
                dataIndex: 'eventPaybackAt',
            },
            {
                title: '대상인원',
                dataIndex: 'count',
            },
            {
                title: '활성화',
                dataIndex: 'enable',
                customRender: formatBoolean,
                width: 60,
                align: 'center',
            },
            {
                title: '문자발송',
                align: 'center',
                fixed: 'right',
                width: 96,
                type: 'send-crm-seg-sms',
            },
            {
                title: '알림톡발송',
                type: 'send-crm-seg-bizm',
                align: 'center',
                fixed: 'right',
                width: 96,
            },
        ]
        return {
            createSpec,
            createRules,
            filterSpec,
            columns,
        }
    },

    methods: {
        sendSms(record: any) {
            console.log('send sms : ', record)
        },
    },
})
</script>
