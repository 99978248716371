import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "EventUserSegment",
    uri: "/api/event-user-segment",
    "create-spec": _ctx.createSpec,
    "create-rules": _ctx.createRules,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns,
    "load-on-mount": ""
  }, null, 8, ["create-spec", "create-rules", "columns"]))
}