<template>
    <ResourceTable
        resource-name="ai-coaching-schedule"
        title="리브레 병원 계정 관리"
        uri="/api/libre-practice"
        :columns="columns"
        :create-spec="inputSpec"
        :update-spec="inputSpec"
        load-on-mount
    />
</template>

<script setup lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { ref } from 'vue'

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
]

const inputSpec = ref({
    email: {
        label: 'Email',
        rules: { required: true },
    },
    password: {
        label: 'Password',
        rules: { required: true },
    },
    deviceToken: {
        label: 'Device token',
        rules: { required: true },
    },
})
</script>
