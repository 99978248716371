import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import router from '@/router'
import { formatLocalDateTime } from '@/util/formmater'
import { onMounted, ref } from 'vue'
import FoodUserRequestEditVue from './FoodUserRequestEdit.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodUserRequest',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '사용자 ID',
        dataIndex: 'userId',
    },
    {
        title: '요청 유형',
        dataIndex: 'type',
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const filterSpec = ref({
    id: { label: 'ID', value: null, component: InputComponents.AInputNumber },
    userId: {
        label: '사용자 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

onMounted(() => {
    router.addRoute('FoodUserRequest', {
        path: '/edit/:resourceId',
        component: FoodUserRequestEditVue,
    })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    title: "사용자 식품 등록/수정 요청",
    uri: "/api/food-user-request",
    columns: columns,
    "filter-spec": filterSpec.value,
    hasEditPage: ""
  }, null, 8, ["filter-spec"]))
}
}

})