<template>
    <div class="slot-tabs">
        <a-tabs v-model:activeKey="slotIdProps" @change="changeSlot">
            <a-tab-pane
                v-for="slot in slotList"
                :key="slot.id"
                :tab="slot.name"
            >
                <a-col class="slot-tabs-container" :span="24">
                    <a-row>
                        <a-col class="slot-main" :span="24">
                            <ad-header-input
                                header="최소빌드넘버"
                                type="select"
                                :value="minBuildNumber"
                                :options="minBuildNumberOptions"
                                @change="(value: any) => changeInput('change:minBuildNumber', value)"
                            />
                            <ad-header-input
                                v-if="slot.page === 'reward'"
                                header="(개발Only)미션정보"
                                type="select"
                                :value="dailyMissionType"
                                :options="dailyMissionTypeOptions"
                                @change="(value: any) => changeInput('change:dailyMissionType', value)"
                            />
                            <ad-header-input
                                v-if="slot.page === 'reward'"
                                header="(개발Only)응답정보"
                                type="select"
                                :value="voType"
                                :options="voTypeOptions"
                                @change="(value: any) => changeInput('change:voType', value)"
                            />
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col class="slot-sub" :span="24">
                            <ad-header-input
                                header="상태"
                                type="select"
                                :value="status"
                                :options="statusOptions"
                                @change="changeStatus"
                            />
                            <ad-header-input
                                v-if="slot.page === 'reward'"
                                header="리워드 수"
                                type="number"
                                :value="rewardCount"
                                @change="changeRewardCount"
                            />
                            <a-button type="primary" @click="patchStatus">
                                설정 변경
                            </a-button>
                        </a-col>
                    </a-row>
                </a-col>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<style scoped>
.slot-tabs,
.slot-tabs-container,
.slot-main,
.slot-sub {
    display: flex;
}
.slot-main {
    margin-right: 6px;
    border-right: 1px solid #e8e8e8;
}
.slot-sub {
    margin-left: 18px;
    align-items: flex-end;
}
</style>

<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import { adminApi, ApiResponse, patchApi } from '@/fetchTemplate'
import AdHeaderInput from '@/components/input/advertisements/AdHeaderInput.vue'
import { SelectOption } from '@/store/enumType'
import { isEmpty } from 'lodash'

export default defineComponent({
    name: 'AdSlotTab',
    components: { AdHeaderInput },

    props: {
        id: {
            type: String,
        },
        slotId: {
            type: Number,
        },
        minBuildNumber: {
            type: Number,
        },
        dailyMissionType: {
            type: String,
        },
        voType: {
            type: String,
        },
    },

    setup(props: any) {
        const slotIdProps = toRef(props, 'slotId')
        const slotList = ref([])
        const minBuildNumberList = ref([])
        const dailyMissionTypeOptions: Array<SelectOption> = [
            { label: '리워드', value: 'ADVERTISEMENT_DAILY_REWARD' },
            { label: '없음', value: null },
        ]
        const voTypeOptions: Array<SelectOption> = [
            { label: '리워드', value: 'REWARD' },
            { label: '없음', value: null },
        ]
        const status = ref()
        const statusOptions: Array<SelectOption> = [
            { label: '활성', value: true },
            { label: '비활성', value: false },
        ]
        const filter = ref(['reward', 'nutrients', 'store'])
        const hasReward = ref(false)
        const rewardCount = ref()

        return {
            filter,
            slotIdProps,
            slotList,
            minBuildNumberList,
            dailyMissionTypeOptions,
            voTypeOptions,
            status,
            statusOptions,
            hasReward,
            rewardCount,
        }
    },

    emits: [
        'change:slot',
        'change:minBuildNumber',
        'change:voType',
        'change:dailyMissionType',
    ],

    computed: {
        minBuildNumberOptions(): Array<SelectOption> {
            return this.minBuildNumberList
                .filter((it: any) => it.slotId === this.slotId)
                .map((it: any) => ({
                    label: it.minBuildNumber,
                    value: it.minBuildNumber,
                    key: it.id,
                }))
        },
    },

    watch: {
        minBuildNumber(newVal: any, _: any) {
            const list = this.minBuildNumberList
                .filter((it: any) => it.slotId === this.slotId)
                .filter((it: any) => it.minBuildNumber === newVal)
            const publish: any = list[0]
            this.status = publish?.enable
            this.rewardCount = publish?.maxRewardCount || 0
        },
    },

    methods: {
        async fetch(url: string) {
            try {
                const response = await adminApi<ApiResponse<any>>(url)
                if (!response.success) {
                    console.log('error : ", response')
                    return null
                }
                return response
            } catch (error: any) {
                console.log('e : ', error)
            }
        },
        async fetchSlots() {
            const url = '/api/advertisements/slots'
            const response = await this.fetch(url)
            const slotList = (response?.result.content || []).filter(
                (it: any) => this.filter.includes(it.page)
            )
            const slot = slotList[0]
            this.slotList = slotList
            this.$emit('change:slot', slot)
        },
        async fetchMinBuildNumbers() {
            if (!isEmpty(this.minBuildNumberList)) {
                return
            }
            const url = '/api/advertisements/min-build-numbers'
            const response = await this.fetch(url)
            this.minBuildNumberList = response?.result || []
        },
        async patchStatus() {
            const strStatus = this.status ? '활성' : '비활성'
            const count = this.rewardCount === undefined ? 0 : this.rewardCount
            const confirmMessage = `상태: ${strStatus}, 리워드 수: ${count} 변경하시겠습니까?`
            if (!window.confirm(confirmMessage)) {
                return
            }
            const url = '/api/advertisements'
            const payload = {
                status: this.status,
                slotId: this.slotId,
                minBuildNumber: this.minBuildNumber,
                rewardCount: this.rewardCount,
            }
            await patchApi(url, payload, true, '상태값 변경 실패')
        },
        changeSlot(slotId: number) {
            const slot: any = this.slotList.find(
                (obj: any) => obj.id === slotId
            )
            this.$emit('change:slot', slot)
        },
        changeInput(emitKey: any, value: any) {
            this.$emit(emitKey, value)
        },
        changeStatus(enable: boolean) {
            this.status = enable
        },
        changeRewardCount(count: number) {
            this.rewardCount = count
        },
    },

    mounted() {
        this.fetchSlots()
        this.fetchMinBuildNumbers()
    },
})
</script>
