<template>
    <a-form ref="formRule" :model="form">
        <a-row>
            <a-descriptions :bordered="true" :column="1">
                <a-descriptions-item label="세그먼트">
                    <enum-select
                        :value="form.targetUser.segId"
                        typeName="segments"
                        @update:value="updateValue"
                    ></enum-select>
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
    </a-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import globalFormState from '@/components/globalFormState'
import EnumSelect from '@/components/input/EnumSelect.vue'

export default defineComponent({
    name: 'AdTargetUserInput',

    components: {
        EnumSelect,
    },

    setup() {
        return {
            form: globalFormState.form,
        }
    },

    methods: {
        updateValue(segId: string) {
            this.form.targetUser.segId = segId
        },
    },
})
</script>
