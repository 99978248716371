import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-event" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_ad_slot_tab = _resolveComponent("ad-slot-tab")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ad_current_instance = _resolveComponent("ad-current-instance")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_ad_instance = _resolveComponent("ad-instance")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          class: "overlay"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_spin)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_a_row, { class: "slot" }, {
      default: _withCtx(() => [
        _createVNode(_component_ad_slot_tab, {
          "slot-id": _ctx.slotId,
          "min-build-number": _ctx.minBuildNumber,
          "vo-type": _ctx.voType,
          "daily-mission-type": _ctx.dailyMissionType,
          "onChange:slot": _ctx.changeSlot,
          "onChange:minBuildNumber": _ctx.changeMinBuildNumber,
          "onChange:voType": _ctx.changeVoType,
          "onChange:dailyMissionType": _ctx.changeDailyMissionType
        }, null, 8, ["slot-id", "min-build-number", "vo-type", "daily-mission-type", "onChange:slot", "onChange:minBuildNumber", "onChange:voType", "onChange:dailyMissionType"])
      ]),
      _: 1
    }),
    (_ctx.minBuildNumber)
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 1,
          class: "advertise"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              class: "advertise-on",
              span: 6
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    class: "btn-save",
                    onClick: _ctx.save
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" 광고 저장 ")
                    ])),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _createVNode(_component_ad_current_instance, {
                  header: '기본광고',
                  instances: _ctx.baseInstances,
                  onRemove: _ctx.removeBaseInstance
                }, null, 8, ["instances", "onRemove"]),
                _createVNode(_component_ad_current_instance, {
                  header: '광고',
                  instances: _ctx.instances,
                  onRemove: _ctx.removeInstances
                }, null, 8, ["instances", "onRemove"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, {
              class: "advertise-table",
              span: 18
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ad_instance, {
                  "slot-id": _ctx.slotId,
                  "min-build-number": _ctx.minBuildNumber,
                  "onUpdate:instances": _ctx.updateInstances,
                  "onUpdate:baseInstance": _ctx.updateBaseInstance
                }, null, 8, ["slot-id", "min-build-number", "onUpdate:instances", "onUpdate:baseInstance"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}