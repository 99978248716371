export const required = {
    required: true,
    message: '빈 값을 입력 할 수 없습니다.',
    trigger: 'blur',
}

export const requiredIf = (condition: boolean) => {
    if (condition) {
        return required
    }
}

export const aboveZero = {
    validator: (rule: string, value: number) => {
        if (value <= 0) {
            return Promise.reject(new Error('0이상의 값만 입력할 수 있습니다.'))
        }
        return Promise.resolve()
    },
}

export const aboveOrEqualToZero = {
    validator: (rule: string, value: number) => {
        if (value < 0) {
            return Promise.reject(new Error('0이상의 값만 입력할 수 있습니다.'))
        }
        return Promise.resolve()
    },
}

const regex = /^[-ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]*$/

export const allowedChars = {
    check: (name: string) => {
        if (!regex.test(name)) {
            // FIXME
            // alert('한글, 숫자, 영문만 입력 가능합니다')
            // return false
        }
        return true
    },
}
