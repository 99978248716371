<template>
    <resource-table
        title="식단 섭취 가이드"
        uri="/api/food-guide-doc"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1000, y: 650 }"
        :columns="columns"
        load-on-mount
        deletable
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import { InputComponents, SelectInputMode } from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FoodGuideDoc',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            title: {
                label: '타이틀',
                value: null,
            },
            content: {
                label: '내용',
                value: null,
            },
            higBroadNameList: {
                label: 'HIG Broad',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
                mode: SelectInputMode.MULTIPLE,
            },
            healthStatusCodeList: {
                label: '건강 상태',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/name',
                mode: SelectInputMode.MULTIPLE,
            },
            dietNameList: {
                label: '식단',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'diet-name',
                mode: SelectInputMode.MULTIPLE,
            },
            relatedNAGroupList: {
                label: '관련 영양소 섭취그룹',
                value: null,
                component: 'AutoCompleteInput',
                uri: '/api/nutrient-allowance-group/name',
                mode: SelectInputMode.MULTIPLE,
            },
            iconUrl: {
                label: '아이콘 URL',
                value: null,
            },
            priority: {
                label: '중요도',
                value: null,
                component: 'AInputNumber',
            },
        }
        const updateSpec = { ...createSpec }
        return {
            selectedRowKeys: [],
            loading: false,
            createSpec,
            updateSpec,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                },
                { title: '타이틀', dataIndex: 'title', width: 50 },
                { title: '내용', dataIndex: 'content', width: 50 },
                {
                    title: 'HIG Broad',
                    dataIndex: 'higBroadNameList',
                    customRender: (value: any) => {
                        return value.value.join(', ')
                    },
                    width: 50,
                },
                {
                    title: '관련성분',
                    dataIndex: 'relatedNAGroupList',
                    customRender: (value: any) => {
                        return value.value
                            .map((a: { name: any }) => a.name)
                            .join(', ')
                    },
                    width: 50,
                },
                {
                    title: '중요도',
                    dataIndex: 'priority',
                    width: 50,
                },
            ],
            rules: {
                title: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['hig-broad-name', 'diet-name'])
    },
})
</script>
