<template>
    <ResourceTable
        title="CRM 템플릿"
        uri="/api/crm-templates"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="createRules"
        :update-rules="updateRules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        load-on-mount
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { formatBoolean } from '@/util/formmater'

export default defineComponent({
    name: 'CrmTemplates',
    components: {
        ResourceTable,
    },
    setup() {
        const createSpec: FormSpecification = {
            name: {
                label: '이름',
                value: null,
            },
            enable: {
                label: '활성화',
                value: null,
                component: InputComponents.BooleanInput,
                notNull: true,
                trueText: '활성',
                falseText: '비활성',
            },
            type: {
                label: '수단',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'crm-type',
            },
            title: {
                label: '제목',
                value: null,
            },
            template: {
                label: '템플릿',
                value: null,
                type: InputType.TEXTAREA,
                height: '150px',
            },
            replacements: {
                label: '대체값',
                value: [],
                component: InputComponents.CrmReplacementInput,
            },
            bizmTemplateId: {
                label: '비즈엠 템플릿 ID',
                value: null,
            },
            bizmButtons: {
                label: '비즈엠 버튼',
                value: [],
                component: InputComponents.CrmBizmButtonInput,
            },
        }
        const createRules = {
            name: [required],
            enable: [required],
            type: [required],
        }
        const updateSpec = { ...createSpec }
        const updateRules = { ...createRules }
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 60,
                fixed: 'left',
            },
            {
                title: '템플릿 이름',
                dataIndex: 'name',
            },
            {
                title: '활성화',
                dataIndex: 'enable',
                customRender: formatBoolean,
                width: 60,
                align: 'center',
            },
            {
                title: '수단',
                dataIndex: 'type',
                width: 120,
            },
            {
                title: '비즈엠 템플릿 ID',
                dataIndex: 'bizmTemplateId',
            },
            {
                title: '제목(길이 20자 제한)',
                dataIndex: 'title',
            },
            {
                title: '템플릿',
                dataIndex: 'template',
            },
        ]
        return {
            createSpec,
            createRules,
            updateSpec,
            updateRules,
            columns,
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['crm-type'])
    },
})
</script>
