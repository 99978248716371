<script setup lang="tsx">
import { defineEmits, defineProps, ref } from 'vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash'

export type FormeMission = {
    id: string
    category: string
    name: string
    type: string
    value: string
}

defineProps({
    value: {
        type: String,
    },
    mode: {
        type: String,
        default: 'default',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const emits = defineEmits(['update:value', 'afterSelect'])

const state = ref<any>({ options: [] })

const fetchOptions = debounce(async (keyword: string) => {
    const response = await adminApi<ApiResponse<Array<FormeMission>>>(
        `/api/forme/diet/search/mission/${keyword}`
    )

    state.value.options =
        response.result?.map((it) => ({
            ...it,
            variable: it.value,
            label: it.name,
            value: it.id,
        })) || []
}, 200)

const onSearch = async (keyword: string) => {
    await fetchOptions(keyword)
}

const onSelect = (value: any) => {
    emits('update:value', value)
    emits(
        'afterSelect',
        state.value.options.filter((it) => it.value === value)[0]
    )
}
</script>

<template>
    <a-auto-complete
        :value="value"
        :options="state.options"
        :mode="mode"
        :disabled="disabled"
        show-search
        @search="onSearch"
        @select="onSelect"
    />
</template>
