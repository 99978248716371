import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_button = _resolveComponent("modal-button")!

  return (_openBlock(), _createBlock(_component_modal_button, _mergeProps({ ref: "modalButtonRef" }, _ctx.$props, {
    onSubmit: _ctx.post,
    onAfterShow: _ctx.showModal
  }), {
    icon: _withCtx(() => [
      _renderSlot(_ctx.$slots, "icon")
    ]),
    beforeButton: _withCtx((data) => [
      _renderSlot(_ctx.$slots, "beforeButton", _normalizeProps(_guardReactiveProps(data)))
    ]),
    button: _withCtx(() => _cache[0] || (_cache[0] = [
      _createTextVNode(" 실행 ")
    ])),
    header: _withCtx((data) => [
      _renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps(data)))
    ]),
    footer: _withCtx((data) => [
      _renderSlot(_ctx.$slots, "footer", _normalizeProps(_guardReactiveProps(data)))
    ]),
    _: 3
  }, 16, ["onSubmit", "onAfterShow"]))
}