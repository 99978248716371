import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

import { onMounted } from 'vue'
import globalFormState from '@/components/globalFormState'
import { useFormeWeekStore } from '@/store/formeWeek'


export default /*@__PURE__*/_defineComponent({
  __name: 'WeekSelectInput',
  props: {
    value: {
        type: [String, Number],
    },
    userId: {
        type: String,
        required: true,
    },
    resourceId: {
        type: String,
    },
},
  emits: ['update:value', 'afterUpdate'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

//const { formeWeek, selectedWeek, selectWeek } = useFormeWeek
const { state, selectWeek } = useFormeWeekStore()
const { formeWeek, selectedWeek } = state

const { form } = globalFormState

const onUpdate = async (value: string | number) => {
    selectWeek(value)
    emits('update:value', value)
    emits('afterUpdate', value, props.resourceId)
}

onMounted(() => {
    emits('update:value', selectedWeek.weekNumber || 1)
})

const thisWeekCss = {
    color: '#0076FF',
    fontWeight: 'bold',
}

const disableButton = (weekStartDate: Date, weekEndDate: Date) => {
    if (form?.value.startDate && form?.value.endDate) {
        return (
            weekEndDate < form.value.startDate ||
            weekStartDate > form.value.endDate
        )
    }
}

return (_ctx: any,_cache: any) => {
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!

  return (_openBlock(), _createBlock(_component_a_radio_group, {
    value: __props.value,
    "onUpdate:value": onUpdate
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formeWeek), (week) => {
        return (_openBlock(), _createBlock(_component_a_radio_button, {
          key: week.weekNumber,
          value: week.weekNumber,
          disabled: disableButton(week.startDate, week.endDate),
          style: _normalizeStyle(week.thisWeek ? thisWeekCss : null)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(week.weekNumber) + "주차 ", 1),
            (week.hasUserFeedback)
              ? (_openBlock(), _createBlock(_component_a_badge, {
                  key: 0,
                  color: "blue",
                  style: {"width":"0"},
                  dot: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["value", "disabled", "style"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["value"]))
}
}

})