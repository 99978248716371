<template>
    <a-row type="flex">
        <a-form ref="formRule" :model="form" :rules="rules" layout="inline">
            <a-col :span="6">
                <a-form-item>
                    <a-input class="property-name" v-model:value="form.name" />
                </a-form-item>
            </a-col>
            <a-col :span="18">
                <enum-select
                    v-model:value="form.type"
                    type-name="ad-property-type"
                />
                <a-button type="primary" @click="add">
                    <template #icon>
                        <plus-outlined />
                    </template>
                </a-button>
            </a-col>
        </a-form>
    </a-row>
    <a-row>
        <a-divider style="height: 1px; background-color: #cdcdcd" />
    </a-row>
    <a-row>
        <draggable :list="valueRef" item-key="name">
            <template #item="{ element, index }">
                <a-form :model="element" :rules="rules" layout="inline">
                    <a-col :span="6">
                        <a-form-item>
                            <a-input
                                class="property-name"
                                v-model:value="element.name"
                            />
                        </a-form-item>
                    </a-col>
                    <a-col :span="18">
                        <enum-select
                            v-model:value="element.type"
                            type-name="ad-property-type"
                        />
                        <a-button type="danger" @click="() => pop(index)">
                            <template #icon>
                                <delete-outlined />
                            </template>
                        </a-button>
                    </a-col>
                    <a-divider
                        style="height: 1px; background-color: #eeeeee"
                        dashed
                    />
                </a-form>
            </template>
        </draggable>
    </a-row>
</template>

<style scoped>
.property-name {
    margin-right: 16px;
}
.property-value-group {
}
</style>

<script lang="ts">
import { defineComponent, Ref, ref, toRef } from 'vue'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import EnumSelect from '@/components/input/EnumSelect.vue'

type PropertyForm = {
    name: string | null
    type: any
}

export default defineComponent({
    name: 'AdPropertyInput',

    components: {
        Draggable,
        EnumSelect,
        PlusOutlined,
        DeleteOutlined,
    },

    setup(props: any) {
        const form: Ref<PropertyForm> = ref({
            name: null,
            type: '',
        })
        const formRule = ref()
        const valueRef = toRef(props, 'value')

        const push = (value: PropertyForm) => {
            valueRef.value.push(value)
        }
        const pop = (index: number) => {
            valueRef.value?.splice(index, 1)
        }

        return { form, formRule, valueRef, push, pop }
    },

    props: {
        rules: {
            type: Object,
            default: () => ({
                name: [required],
                type: [required],
            }),
        },
        value: {
            default: () => [],
        },
    },

    methods: {
        add() {
            return this.formRule.validate().then(() => {
                if (this.value != null) {
                    this.push(this.form)
                    this.form = {
                        name: null,
                        type: '',
                    }
                }
            })
        },
    },
})
</script>
