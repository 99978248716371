import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "nutrient-analyze-report" }

import { adminApi, ApiResponse } from '@/fetchTemplate'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NutrientAnalyzeReport',
  props: {
    userId: {
        type: Number,
        required: true,
    },
    productIds: {
        type: Array,
        required: true,
    },
},
  setup(__props, { expose: __expose }) {

const props = __props

const report = ref<Record<any, any>>({})

const fetchReport = async () => {
    const response = await adminApi<ApiResponse<Record<any, any>>>(
        `/api/v1/questions/nutrient-analyze-report/${props.userId}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ productIds: props.productIds }),
        }
    )
    report.value = response.result || {}
}

const maxByMin = (min: number, udi: number, max: number) => {
    if (max >= 9999999999999) {
        const minM2 = (udi || min) * 1.2
        return minM2
    }
    return max
}

const parseMax = (max: number) => {
    if (max >= 9999999999999) {
        return '∞'
    }
    return max
}

__expose({
    fetchReport,
})

return (_ctx: any,_cache: any) => {
  const _component_a_list_item_meta = _resolveComponent("a-list-item-meta")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_slider = _resolveComponent("a-slider")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_card, { title: "사용자/제품 관련 영양소 분석 요약" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(report.value, (re) => {
          return (_openBlock(), _createBlock(_component_a_card, {
            class: "nutrient-analyze-report-item",
            key: re.nutrientAllowanceGroupId,
            title: re.nutrientAllowanceGroupName
          }, {
            extra: _withCtx(() => [
              _createTextVNode(_toDisplayString((re.recommended && '추천') || '비추천'), 1)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_list, { "item-layout": "vertical" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, { title: "섭취량" }),
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(`${re.udi} ${re.unit}`), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, { title: "권장섭취량" }),
                      _createVNode(_component_a_slider, {
                        value: re.udi,
                        marks: {
                                [re.udi]: `${re.udi}${re.unit}`,
                                [re.rda || re.higMin]: {
                                    style: { color: 'orange' },
                                    label: `${re.rda || re.higMin}${re.unit}`,
                                },
                                [maxByMin(
                                    re.rda || re.higMin,
                                    re.udi,
                                    re.ul || re.higMax
                                )]: {
                                    style: { color: '#f50' },
                                    label: `${parseMax(re.ul || re.higMax)}${
                                        re.unit
                                    }`,
                                },
                            },
                        max: 
                                maxByMin(
                                    re.rda || re.higMin,
                                    re.udi,
                                    re.ul || re.higMax
                                )
                            ,
                        step: 0.1
                      }, null, 8, ["value", "marks", "max"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_a_list_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_list_item_meta, { title: "사유" }),
                      _createVNode(_component_a_typography_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(re.reason), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["title"]))
        }), 128))
      ]),
      _createVNode(_component_a_button, {
        style: {"float":"right"},
        onClick: fetchReport
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("분석 요약 보기 ")
        ])),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})