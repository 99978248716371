import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

import AutoCompleteInput from '@/components/input/AutoCompleteInput.vue'
import router from '@/router'



export default /*@__PURE__*/_defineComponent({
  __name: 'ProductInput',
  props: {
    value: {
        type: Number,
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '20em',
    },
    mode: {
        type: String,
        default: 'default',
    },
},
  emits: ['update:value', 'select'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const showProduct = (id: number | undefined) => {
    router.push({
        path: `/userFeedback/products/edit/${id}?visible=true`,
    })
}

const change = (value: any) => {
    emit('update:value', value)
}

const select = (value: any) => {
    emit('select', value)
}

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(AutoCompleteInput, {
      label: __props.label,
      uri: "/api/products/product-name",
      width: __props.width,
      value: __props.value,
      "onUpdate:value": change,
      onSelect: select,
      mode: __props.mode,
      disabled: __props.disabled
    }, null, 8, ["label", "width", "value", "mode", "disabled"]),
    (__props.mode == 'default')
      ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          disabled: __props.value == null,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showProduct(__props.value)))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("제품 보기")
          ])),
          _: 1
        }, 8, ["disabled"]))
      : _createCommentVNode("", true)
  ]))
}
}

})