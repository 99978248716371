import { stringify } from 'qs'
import { adminApi } from '@/fetchTemplate'
import { defineComponent } from 'vue'

export type Pagination = {
    position?: Array<string>
    showSizeChanger?: boolean
    showTotal?: (total: number) => string
    showSizeChange?: (current: number, size: number) => void
    current: number
    total: number
    pageSize: number
    pageSizeOptions: Array<number>
} & Sorter

export type Sorter = {
    field?: string
    order?: 'ascend' | 'decend' | boolean
}

export const defaultPagination = () => {
    return {
        position: ['topRight'],
        showSizeChanger: true,
        showTotal: (total: number) => `전체 건수: ${total}`,
        current: 1,
        total: 0,
        pageSize: 20,
        order: false,
        pageSizeOptions: [10, 20, 30, 40, 50, 100, 500, 1000],
    }
}

export default defineComponent({
    name: 'DataSourceLoader',
    props: {
        uri: {
            type: String,
            required: true,
        },
        method: {
            type: String,
        },
        pagination: {
            type: Object,
        },
    },
    data(props: any) {
        return {
            loadingDataSource: false,
            tableData: [],
            tablePagination: {
                ...defaultPagination(),
                ...props.pagination,
            },
        }
    },

    methods: {
        async fetchData(pagination: Pagination, filter: Record<string, any>) {
            const { current, pageSize, field, order } = pagination

            let params: Record<string, any> = {
                ...filter,
                page: `${current - 1}`,
                size: `${pageSize}`,
            }

            if (field != null) {
                const orderReformatted = (order === 'ascend' && 'ASC') || 'DESC'
                params.sort = `${field},${orderReformatted}`
            }

            let queryParams = stringify(params, { indices: false })

            try {
                this.loadingDataSource = true
                let options: RequestInit | undefined = undefined
                if (this.method === 'POST') {
                    options = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(filter),
                    }
                    params = {
                        ...filter,
                        page: `${current - 1}`,
                        size: `${pageSize}`,
                        userIdCsv: null,
                    }
                    queryParams = stringify(params, { indices: false })
                }

                const jsonBody = await adminApi<Record<string, any>>(
                    `${this.uri}?${queryParams}`,
                    options
                )
                this.tableData = jsonBody.result.content

                this.tablePagination = {
                    ...this.tablePagination,
                    current: jsonBody.result.number + 1,
                    total: jsonBody.result.totalElements,
                    pageSize: jsonBody.result.size,
                }
            } catch (err) {
                console.error(err)
                throw err
            } finally {
                this.loadingDataSource = false
            }
        },
    },
})
