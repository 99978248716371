<template>
    <ResourceTable
        title="검색 광고"
        uri="/api/search-ad"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        primary-key="id"
        deletable
        load-on-mount
    >
        <template #detail="{ resourceId, record }">
            <a-descriptions
                title="영양소 좋은점"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="제목">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="요약">
                    {{ record.summary }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일시">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="썸네일">
                    <img
                        :key="resourceId"
                        :width="200"
                        :src="record.thumbnailUrl"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'SearchAd',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            campaignName: {
                label: '캠페인 이름',
                value: null,
                typeName: 'campaignName',
            },
            keywords: {
                label: '영양제 검색 키워드',
                value: null,
                help: '예) 유산균, 종합비타민, 비타민C',
            },
            nutrientKeywords: {
                label: '영양소 검색 키워드',
                value: null,
                help: '예) 유산균, 종합비타민, 비타민C',
            },
            higBroadName: {
                label: '건강 목표',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'hig-broad-name',
            },
            products: {
                label: '광고 제품 정보',
                value: [],
                component: InputComponents.SearchAdProductInput,
            },
            memo: {
                label: '설명',
                value: '',
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            filterSpec: {
                id: {
                    label: 'ID',
                    value: null,
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                creationTime: {
                    label: '등록일시',
                    value: null,
                    readonly: true,
                },
                updateTime: {
                    label: '수정일시',
                    value: null,
                    readonly: true,
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '캠페인 이름',
                    width: 20,
                    dataIndex: 'campaignName',
                },
                {
                    title: '영양제 키워드',
                    width: 20,
                    dataIndex: 'keywords',
                },
                {
                    title: '영양소 키워드',
                    width: 20,
                    dataIndex: 'nutrientKeywords',
                },
                {
                    title: '설명',
                    width: 100,
                    dataIndex: 'memo',
                },
            ],
            rules: {
                campaignName: [required],
                products: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['segment-method-type', 'hig-broad-name'])
    },
})
</script>
