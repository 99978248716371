<template>
    <a-row v-if="menuRef === 'quiz'" class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">퀴즈 정답</label>
            <a-textarea
                class="input-area"
                v-model:value="valueRef.quizAnswer"
            />
        </a-col>
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">퀴즈 코멘트</label>
            <a-textarea
                class="input-area"
                placeholder="정답 부분에 %s를 넣어주세요!"
                v-model:value="valueRef.quizCommentFormat"
            />
        </a-col>
    </a-row>
    <a-row v-if="menuRef === 'survey'" class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">최소 글자 수</label>
            <a-input
                v-model:value="valueRef.surveyLongMinLength"
                default-value="0"
            />
        </a-col>
    </a-row>
    <a-row v-if="menuRef === 'survey'" class="event-content-properties">
        <a-col :span="24" class="input-area-wrapper">
            <label class="input-label">최소 글자 수</label>
            <a-input
                v-model:value="valueRef.surveyLongMinLength"
                default-value="0"
            />
        </a-col>
    </a-row>
    <a-row v-if="menuRef === 'request'" class="event-content-properties">
        <a-row v-if="subMenuRef === 'bottom-button'">
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">버튼 라벨</label>
                <a-input v-model:value="valueRef.bbLabel" />
            </a-col>
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">App: 딥링크 (1순위)</label>
                <a-input v-model:value="valueRef.bbAppDeeplinkUrl" />
            </a-col>
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">App: External URL (2순위)</label>
                <a-input v-model:value="valueRef.bbAppExternalUrl" />
            </a-col>
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">
                    Web Url (딥링크 또는 외부링크)
                </label>
                <a-input v-model:value="valueRef.bbWebUrl" />
            </a-col>
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">버튼 설명</label>
                <a-input v-model:value="valueRef.bbDescription" />
            </a-col>
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">버튼 배경색(#000000)</label>
                <a-input v-model:value="valueRef.bbBgColor" />
            </a-col>
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">버튼 폰트색(#000000)</label>
                <a-input v-model:value="valueRef.bbFontColor" />
            </a-col>
        </a-row>
        <a-row
            v-if="subMenuRef === 'image-swiper'"
            class="event-content-properties"
        >
            <a-col :span="24" class="input-area-wrapper">
                <label class="input-label">이미지</label>
                <image-multiple-uploader
                    :values="valueRef.swiperImages"
                    path="/event-swiper-images"
                    @update:value="updateImage"
                />
            </a-col>
        </a-row>
    </a-row>
</template>

<style>
.event-content-properties {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
}

.input-label {
    font-weight: bold;
}

.input-area-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
</style>
<script setup lang="ts">
import ImageMultipleUploader from '@/components/input/ImageMultipleUploader.vue'
import { toRef, defineProps, defineEmits } from 'vue'

const props = defineProps({
    value: {
        type: Object,
        default: () => {},
    },
    menu: {
        type: String,
        default: () => null,
    },
    subMenu: {
        type: String,
        default: () => null,
    },
})

const valueRef = toRef(props, 'value')
const menuRef = toRef(props, 'menu')
const subMenuRef = toRef(props, 'subMenu')

const emits = defineEmits(['update:swiperImages'])

const updateImage = (value: any) => {
    emits('update:swiperImages', value)
}
</script>
