<template>
    <a-row>
        <a-form ref="formRule" :model="form">
            <a-col :span="24" class="content border">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>구분 값</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.key" />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box">
                    <a-col :span="8">
                        <span>설정 값</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input v-model:value="form.value" />
                    </a-col>
                </a-col>
                <a-col :span="4">
                    <a-button @click="add">
                        <template #icon>
                            <plus-outlined />
                        </template>
                    </a-button>
                </a-col>
            </a-col>
        </a-form>
    </a-row>
    <a-row style="margin-top: 5px">
        <a-row :key="index" v-for="(item, index) in value">
            <a-col class="content" :span="24">
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>구분 값</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.key"
                            @change="(e: any) => updateButton(index, 'key', e)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="10" class="input-box key">
                    <a-col :span="8">
                        <span>설정 값</span>
                    </a-col>
                    <a-col :span="16">
                        <a-input
                            :value="item?.value"
                            @change="(e: any) => updateButton(index, 'value', e)"
                        />
                    </a-col>
                </a-col>
                <a-col :span="4">
                    <a-button @click="() => remove(index)">
                        <template #icon>
                            <delete-outlined />
                        </template>
                    </a-button>
                </a-col>
            </a-col>
        </a-row>
    </a-row>
</template>

<style scoped>
.content {
    display: flex;
    align-items: end;
    padding-bottom: 5px;
}
.border {
    border-bottom: 1px solid #dedede;
}
.input-box {
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.input-box.key {
    margin-right: 10px;
}
.input-box span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<script lang="ts">
import { defineComponent, ref, Ref, toRef } from 'vue'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons-vue'

type Value = {
    key: string | null
    value: string | null
}
export default defineComponent({
    name: 'CrmReplacementInput',

    components: {
        DeleteOutlined,
        PlusOutlined,
    },

    props: {
        value: {
            type: Array<Value>,
            default: () => [],
        },
    },

    setup(props: any) {
        const formValue = toRef(props, 'value')
        const form: Ref<any> = ref({
            key: null,
            value: null,
        })
        return {
            formValue,
            form,
        }
    },

    methods: {
        add() {
            this.formValue.push(this.form)
            this.form = {
                key: null,
                value: null,
            }
        },
        remove(index: number) {
            this.formValue?.splice(index, 1)
        },
        updateButton(index: number, key: string, e: any) {
            this.formValue[index][key] = e.target.value
        },
    },
})
</script>
