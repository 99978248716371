import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 2 }

import { baseUrl } from '@/fetchTemplate'
import { computed, ref } from 'vue'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { fileToWebp } from '@/util/image'


export default /*@__PURE__*/_defineComponent({
  __name: 'ContentImageInput',
  props: {
    value: {
        type: String,
        default: '',
    },
    path: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
},
  emits: ['update:value'],
  setup(__props, { emit: __emit }) {

const props = __props
const loading = ref(false)

const uploadUrl = computed(() => {
    return `${baseUrl}/api/file?path=${props.path}`
})

const emits = __emit

const handleInput = (event: any) => {
    emits('update:value', event.target.value)
}

const handleSelect = (value: any) => {
    emits('update:value', value)
}

const handleChange = async (info: any) => {
    if (info.file.status === 'uploading') {
        loading.value = true
        return
    }
    if (info.file.status === 'done') {
        const allDone = !info.fileList.some((f: any) => f.status !== 'done')
        if (allDone) {
            emits('update:value', info.file.response.result)
            loading.value = false
        }
    }
}

const beforeUpload = (file: any) => {
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
        return fileToWebp(file)
    }
    return file
}

return (_ctx: any,_cache: any) => {
  const _component_Vue3Lottie = _resolveComponent("Vue3Lottie")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_upload, {
      name: "file",
      "list-type": "picture-card",
      class: "avatar-uploader",
      "show-upload-list": false,
      multiple: true,
      action: uploadUrl.value,
      onChange: handleChange,
      "before-upload": beforeUpload,
      "with-credentials": "",
      style: {"width":"unset"}
    }, {
      default: _withCtx(() => [
        (
                    __props.value && !__props.value.endsWith('.json') && !__props.value.endsWith('.csv')
                )
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: __props.value,
              alt: "avatar",
              style: {"max-width":"100px","max-height":"100px"}
            }, null, 8, _hoisted_1))
          : (__props.value && __props.value.endsWith('.json'))
            ? (_openBlock(), _createBlock(_component_Vue3Lottie, {
                key: 1,
                animationLink: __props.value,
                width: 100,
                height: 100
              }, null, 8, ["animationLink"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (loading.value)
                  ? (_openBlock(), _createBlock(_unref(LoadingOutlined), { key: 0 }))
                  : (_openBlock(), _createBlock(_unref(PlusOutlined), { key: 1 })),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "ant-upload-text" }, "Upload", -1))
              ]))
      ]),
      _: 1
    }, 8, ["action"]),
    _createElementVNode("span", null, [
      _cache[1] || (_cache[1] = _createElementVNode("label", null, "파일 주소: ", -1)),
      _createVNode(_component_a_input, {
        value: __props.value,
        onChange: handleInput,
        style: {"width":"200px"}
      }, null, 8, ["value"])
    ])
  ]))
}
}

})