import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#f9fefe","padding":"13px","width":"auto"} }

import { ref, toRef, watch } from 'vue'
import NutrientInput from '@/components/input/NutrientInput.vue'
import KoreanNumberInput from '@/components/input/KoreanNumberInput.vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { SwapOutlined, DeleteOutlined } from '@ant-design/icons-vue'

type Amounts = {
    id?: number
    nutrientName?: string
    amount: number
    isCalculated: boolean
    isImportant: boolean
    unit?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodNutrientInput',
  props: {
    rules: {
        type: Object,
        default: () => ({
            nutrientName: [required],
            amount: [required, aboveOrEqualToZero],
            unit: [required],
            isCalculated: [required],
            isImportant: [required],
        }),
    },
    productId: {
        type: Number,
    },
    value: {
        type: Array,
        default: () => [],
    },
    disabled: {
        type: Boolean,
        default: false,
    },
},
  setup(__props) {

const props = __props

const form = ref<Amounts>({
    amount: 0,
    isCalculated: false,
    isImportant: false,
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const push = (value: Amounts) => {
    valueRef.value.push(value)
}

const pop = (index: number) => {
    valueRef.value?.splice(index, 1)
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (form.value.unit == undefined) {
            alert('영양소를 셀렉트 박스에서 클릭 해서 선택해주세요.')
            return
        }
        if (props.value != null) {
            push(form.value)
            form.value = {
                amount: 0,
                isCalculated: false,
                isImportant: false,
            }
        }
    })
}

const setNutrient = (
    {
        name,
        unit,
    }: {
        name: string
        unit: string
    },
    form: Amounts
) => {
    form.nutrientName = name
    form.unit = unit
}

watch(
    () => valueRef.value,
    (value: Array<any>) => {
        const fat = value.find((v) => v.nutrientName == '지방')
        const saturatedFat = value.find((v) => v.nutrientName == '포화지방')
        const unsaturatedFat = value.find(
            (v) => v.nutrientName == '불포화지방산'
        )
        const transFat = value.find((v) => v.nutrientName == '트랜스지방산')
        const unsaturatedFatAmount =
            (fat?.amount || 0) -
            ((saturatedFat?.amount || 0) + (transFat?.amount || 0))

        if (
            fat != undefined &&
            (saturatedFat != undefined || transFat != undefined) &&
            unsaturatedFat == undefined
        ) {
            valueRef.value.push({
                nutrientName: '불포화지방산',
                amount: unsaturatedFatAmount,
                unit: 'g',
            })
        }

        if (
            unsaturatedFat != undefined &&
            (saturatedFat != undefined || transFat != undefined) &&
            unsaturatedFatAmount != unsaturatedFat.amount
        ) {
            unsaturatedFat.amount = unsaturatedFatAmount
        }
    },
    { deep: true }
)

return (_ctx: any,_cache: any) => {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref_key: "formRule",
          ref: formRule,
          model: form.value,
          rules: __props.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(NutrientInput, {
              value: form.value.nutrientName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((form.value.nutrientName) = $event)),
              name: "nutrientName",
              onSelect: _cache[1] || (_cache[1] = (args) => setNutrient(args, form.value)),
              width: "200px",
              disabled: __props.disabled
            }, null, 8, ["value", "disabled"]),
            _createVNode(_component_a_form_item, { name: "amount" }, {
              default: _withCtx(() => [
                _createVNode(KoreanNumberInput, {
                  class: "amount-input",
                  value: form.value.amount,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((form.value.amount) = $event)),
                  "addon-after": form.value.unit,
                  disabled: __props.disabled
                }, null, 8, ["value", "addon-after", "disabled"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add,
                  disabled: __props.disabled
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" 영양소 추가 ")
                  ])),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_unref(Draggable), {
          list: valueRef.value,
          "item-key": "nutrientName"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: __props.rules,
              layout: "inline",
              style: {"border-color":"#6c2bf2"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(SwapOutlined), { rotate: 90 })
                  ]),
                  _: 1
                }),
                _createVNode(NutrientInput, {
                  value: element.nutrientName,
                  "onUpdate:value": ($event: any) => ((element.nutrientName) = $event),
                  name: `nutrientName${index}`,
                  onSelect: (args) => setNutrient(args, element),
                  width: "280px",
                  mode: "default",
                  disabled: element.isCalculated || __props.disabled
                }, null, 8, ["value", "onUpdate:value", "name", "onSelect", "disabled"]),
                _createVNode(_component_a_form_item, {
                  name: `amount${index}`
                }, {
                  default: _withCtx(() => [
                    _createVNode(KoreanNumberInput, {
                      class: "amount-input",
                      value: element.amount,
                      "onUpdate:value": ($event: any) => ((element.amount) = $event),
                      "addon-after": element.unit,
                      disabled: element.isCalculated || __props.disabled
                    }, null, 8, ["value", "onUpdate:value", "addon-after", "disabled"])
                  ]),
                  _: 2
                }, 1032, ["name"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => pop(index),
                      disabled: element.isCalculated || __props.disabled
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_unref(DeleteOutlined))
                      ]),
                      _: 2
                    }, 1032, ["onClick", "disabled"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ]))
}
}

})