<template>
    <ResourceTable
        title="공지사항 및 이벤트 알림"
        uri="/api/admin-notification"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :action-spec="actionSpec"
        :create-rules="rules"
        :update-rules="rules"
        action-uri="/api/admin-notification/notice"
        action-modal-title="알림"
        :action-modal-disabled="actionModalDisabled"
        :columns="columns"
        :scroll="{ x: 2000, y: 650 }"
        load-on-mount
        deletable
    >
        <template #actionModalHeader="{ record }">
            <span>{{ `ID: ${record.id}를 처리하시겠습니까?` }}</span>
        </template>
        <template #actions>
            <job-monitor job-name="noticeJob" />
        </template>
        <template #actionModalIcon>
            <NotificationOutlined />
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { required } from '@/util/input-validation'
import JobMonitor from '@/components/JobMonitor.vue'
import { defineComponent, Ref, ref } from 'vue'
import { NotificationOutlined } from '@ant-design/icons-vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'AdminNotification',
    mixins: [TableSupport],
    components: {
        ResourceTable,
        JobMonitor,
        NotificationOutlined,
    },

    setup() {
        const columns = ref([
            { title: 'ID', dataIndex: 'id', width: 100, fixed: 'left' },
            {
                title: '알림유형',
                dataIndex: 'notificationType',
                customRender: formatEnum('notification-type'),
            },
            {
                title: 'Icon',
                width: 130,
                dataIndex: 'iconUrl',
                type: 'img',
            },
            { title: '제목', dataIndex: 'title' },
            { title: '내용', dataIndex: 'body' },
            { title: '링크', dataIndex: 'link' },
            { title: '상세링크', dataIndex: 'detailLink' },
            {
                title: '알림 실행여부',
                dataIndex: 'success',
                customRender: (event: any) => {
                    if (event.record.success === true) {
                        return '성공'
                    }
                    if (event.value) {
                        return '실패'
                    }
                    return '실행전'
                },
            },
            {
                title: '등록일시',
                dataIndex: 'creationTime',
                customRender: formatLocalDateTime,
                sorter: true,
                width: 165,
            },
            {
                title: '생성자',
                dataIndex: 'createdBy',
            },
            {
                title: '수정일시',
                dataIndex: 'updateTime',
                customRender: formatLocalDateTime,
                sorter: true,
                width: 165,
            },
            {
                title: '수정자',
                dataIndex: 'updatedBy',
            },
        ])

        const filterSpec: Ref<FormSpecification> = ref({
            id: {
                label: 'ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            success: {
                label: '알림 실행여부',
                value: null,
                component: InputComponents.BooleanInput,
                labelCol: 6,
                notNull: true,
            },
            createdBy: {
                label: '생성자',
                value: null,
            },
            updatedBy: {
                label: '수정자',
                value: null,
            },
        })

        const inputSpec: FormSpecification = {
            iconUrl: {
                label: 'Icon',
                value: null,
                component: InputComponents.ImageInput,
            },
            title: { label: '제목', value: null },
            notificationType: {
                label: '알림유형',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'notification-type',
                filter: (value: any) => ['NOTICE', 'EVENT'].includes(value),
            },
            body: {
                label: '내용',
                value: null,
            },
            link: {
                label: '링크',
                value: null,
            },
            detailLink: {
                label: '상세링크',
                value: null,
            },
        }

        const createSpec: Ref<FormSpecification> = ref(inputSpec)

        const updateSpec: Ref<FormSpecification> = ref(inputSpec)

        const actionSpec: Ref<FormSpecification> = ref({})

        const rules = ref({
            title: [required],
            notificationType: [required],
            success: [required],
            link: [required],
        })

        return {
            columns,
            filterSpec,
            createSpec,
            updateSpec,
            actionSpec,
            rules,
        }
    },

    methods: {
        actionModalDisabled(record: Record<string, any>) {
            return record.success === true
        },
        mounted() {
            const enumTypeStore = useEnumTypeStore()
            enumTypeStore.dispatchEnums(['notification-type'])
        },
    },
})
</script>
