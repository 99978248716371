<template>
    <modal-button v-bind="$props" ref="modalButtonRef" @submit="patch">
        <template #button> 실행 </template>
    </modal-button>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import { defineComponent, ref } from 'vue'
import ModalButton from './ModalButton.vue'
import globalFormState from '@/components/globalFormState'

export default defineComponent({
    name: 'BulkActionModal',
    extends: ModalButton,
    components: {
        ModalButton,
    },

    props: {
        label: {
            type: String,
            default: '',
        },
    },
    setup() {
        const modalButtonRef = ref()
        return { ...globalFormState, modalButtonRef }
    },

    methods: {
        async patch() {
            const { toggleLoading, setErrorMessage, toggleVisible, alertFail } =
                this.modalButtonRef
            const queryParams = {
                ...this.form,
            }
            console.log(this.form)
            if (this.resourceIds != null && this.resourceIds.length <= 20) {
                queryParams.ids = this.resourceIds
            }
            if (this.form != null) {
                this.form.ids = this.resourceIds
            }
            toggleLoading()

            try {
                const response = await adminApi<ApiResponse<void>>(
                    `${this.uri}?${stringify(queryParams, { indices: false })}`,
                    {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.form),
                    }
                )

                toggleLoading()

                if (
                    response.success === undefined ||
                    response.success === false
                ) {
                    setErrorMessage(
                        response.errorMessage ||
                            '예외 발생 - 개발자에게 문의하세요'
                    )
                    alertFail()
                } else {
                    this.$emit('afterSubmit')
                    this.resetForm()
                    toggleVisible()
                }
            } catch (error) {
                const err = error as Error
                toggleLoading()
                setErrorMessage(err.message)
                alertFail()
                throw error
            }
        },
    },
})
</script>
