<template>
    <ResourceTable
        title="제품 태그"
        uri="/api/product-tag"
        :columns="columns"
        :create-spec="createSpec"
        :update-spec="updateSpec"
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { formatLocalDateTime } from '@/util/formmater'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ProductTag',
    components: {
        ResourceTable,
    },

    data() {
        return {
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: 'TAG',
                    dataIndex: 'name',
                    width: 100,
                },
                {
                    title: '검색 태그',
                    dataIndex: 'keywords',
                    scopedSlots: { customRender: 'tags' },
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                    align: 'center',
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                    align: 'center',
                },
            ],
            createSpec: {
                name: {
                    label: 'TAG',
                    value: null,
                },
                keywords: {
                    label: '검색태그',
                    value: [],
                    component: 'ASelect',
                    mode: 'tags',
                },
            },
            updateSpec: { ...this.createSpec },
        }
    },
})
</script>
