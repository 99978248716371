<template>
    <!-- eslint-disable-next-line vue/no-multiple-template-root -->
    <router-view />
</template>

<style lang="scss">
@font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Bold.otf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Light.otf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Pretendard';
    src: url('assets/fonts/Pretendard-Medium.otf') format('truetype');
    font-weight: 500;
}
@font-face {
    font-family: 'Gmarket Sans TTF';
    src: url('assets/fonts/GmarketSansLight.otf') format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: 'Gmarket Sans TTF';
    src: url('assets/fonts/GmarketSansBold.otf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'Gmarket Sans TTF';
    src: url('assets/fonts/GmarketSansMedium.otf') format('truetype');
    font-weight: 500;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
    width: 100%;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'AppView',
})
</script>
