import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "필라이즈 팁",
    uri: "/api/product-consumer-guide",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    scroll: { x: true },
    columns: _ctx.columns,
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec", "columns"]))
}