import { adminApi, ApiResponse } from '@/fetchTemplate'
import { defineStore } from 'pinia'

export type CrmTemplateType = {
    label: string
    value: number
}

type CrmTemplateState = Array<CrmTemplateType>

type SmsTemplateState = {
    smsTemplates: CrmTemplateState
}

type BizmTemplateState = {
    bizmTemplates: CrmTemplateState
}

const smsTemplateState: SmsTemplateState = {
    smsTemplates: [],
}

const bizmTemplateState: BizmTemplateState = {
    bizmTemplates: [],
}

export const useCrmSmsTemplateStore = defineStore('crm-sms-templates', {
    persist: true,
    state: () => ({ ...smsTemplateState }),
    actions: {
        async fetchCrmSmsTemplates() {
            try {
                const response = await adminApi<ApiResponse<any>>(
                    '/api/constant/crm-sms-template'
                )

                if (response.success === false) {
                    throw new Error(response.errorMessage)
                }

                if (response.result != null) {
                    this.smsTemplates = response.result
                }
            } catch (error) {
                if (this.smsTemplates != null) {
                    this.smsTemplates = []
                }
                console.error(error)
            }
        },
    },
})

export const useCrmBizmTemplateStore = defineStore('crm-bizm-templates', {
    persist: true,
    state: () => ({ ...bizmTemplateState }),
    actions: {
        async fetchCrmBizmTemplates() {
            try {
                const response = await adminApi<ApiResponse<any>>(
                    '/api/constant/crm-bizm-template'
                )

                if (response.success === false) {
                    throw new Error(response.errorMessage)
                }

                if (response.result != null) {
                    this.bizmTemplates = response.result
                }
            } catch (error) {
                if (this.bizmTemplates != null) {
                    this.bizmTemplates = []
                }
                console.error(error)
            }
        },
    },
})
