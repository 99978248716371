import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_outlined = _resolveComponent("loading-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_upload, {
      name: "file",
      "list-type": "picture-card",
      class: "avatar-uploader",
      "show-upload-list": false,
      multiple: true,
      action: _ctx.uploadUrl,
      withCredentials: true,
      onChange: _ctx.handleChange
    }, {
      default: _withCtx(() => [
        (_ctx.imageUrl)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.imageUrl,
              alt: "file"
            }, null, 8, _hoisted_1))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_loading_outlined, { key: 0 }))
                : (_openBlock(), _createBlock(_component_plus_outlined, { key: 1 })),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "ant-upload-text" }, "Upload", -1))
            ]))
      ]),
      _: 1
    }, 8, ["action", "onChange"]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_alert, {
        message: "파일명은 영문으로 바꿔서 올려주세요",
        type: "error"
      })
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_a_card, {
        title: "업로드된 파일 목록",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (f) => {
            return (_openBlock(), _createElementBlock("p", {
              key: f.uid
            }, [
              (f.response != undefined)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(f.response.result) + " (" + _toDisplayString(parseInt(f.size / 1024)) + "KB) ", 1))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_a_input, {
      value: _ctx.path,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.path) = $event)),
      "addon-before": "경로"
    }, null, 8, ["value"])
  ]))
}