import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_row, { type: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref: "formRule",
          model: _ctx.form,
          rules: _ctx.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      class: "property-name",
                      value: _ctx.form.name,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 18 }, {
              default: _withCtx(() => [
                _createVNode(_component_enum_select, {
                  value: _ctx.form.type,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.type) = $event)),
                  "type-name": "ad-property-type"
                }, null, 8, ["value"]),
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.add
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_plus_outlined)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_divider, { style: {"height":"1px","background-color":"#cdcdcd"} })
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_draggable, {
          list: _ctx.valueRef,
          "item-key": "name"
        }, {
          item: _withCtx(({ element, index }) => [
            _createVNode(_component_a_form, {
              model: element,
              rules: _ctx.rules,
              layout: "inline"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          class: "property-name",
                          value: element.name,
                          "onUpdate:value": ($event: any) => ((element.name) = $event)
                        }, null, 8, ["value", "onUpdate:value"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_col, { span: 18 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_enum_select, {
                      value: element.type,
                      "onUpdate:value": ($event: any) => ((element.type) = $event),
                      "type-name": "ad-property-type"
                    }, null, 8, ["value", "onUpdate:value"]),
                    _createVNode(_component_a_button, {
                      type: "danger",
                      onClick: () => _ctx.pop(index)
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_delete_outlined)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_a_divider, {
                  style: {"height":"1px","background-color":"#eeeeee"},
                  dashed: ""
                })
              ]),
              _: 2
            }, 1032, ["model", "rules"])
          ]),
          _: 1
        }, 8, ["list"])
      ]),
      _: 1
    })
  ], 64))
}