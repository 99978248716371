<template>
    <a-row class="overlay" v-if="loading">
        <a-spin />
    </a-row>
    <a-row class="slot-form">
        <CreateFormModal
            title="섹션 추가"
            label="섹션 추가"
            :uri="'/api/advertisements/slots/benefit'"
            :input-spec="createSpec"
            :rules="createRules"
            @after-submit="init"
        />
    </a-row>
    <a-row class="slot">
        <ad-benefit-slot-tab
            :props-slot="slot"
            :props-slot-id="slotId"
            :props-slot-list="slotList"
            :props-status="status"
            :props-min-build-number-list="minBuildNumberList"
            @change:slot="changeSlot"
        >
            <template #advertise>
                <a-row class="advertise" v-if="slotId">
                    <a-col class="advertise-on" :span="6">
                        <div class="user-event">
                            <a-button
                                type="primary"
                                class="btn-save"
                                @click="save"
                            >
                                광고 저장
                            </a-button>
                        </div>
                        <ad-current-instance
                            :header="'기본광고'"
                            :instances="baseInstances"
                            @remove="removeBaseInstance"
                        />
                        <ad-current-instance
                            :header="'광고'"
                            :instances="instances"
                            @remove="removeInstances"
                        />
                    </a-col>
                    <a-col class="advertise-table" :span="18">
                        <ad-instance
                            :slot-id="slotId"
                            :min-build-number="1"
                            @update:instances="updateInstances"
                            @update:base-instance="updateBaseInstances"
                        />
                    </a-col>
                </a-row>
            </template>
        </ad-benefit-slot-tab>
    </a-row>
</template>

<style scoped>
.overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.9);
    z-index: 9999;
}
.slot {
    padding-bottom: 12px;
    border-bottom: 1px solid #e8e8e8;
}
.slot-form {
    padding-bottom: 12px;
    margin-bottom: 36px;
    border-bottom: 1px solid #e8e8e8;
}
.advertise-on {
    z-index: 9;
    overflow: scroll;
    border-right: 1px solid #e8e8e8;
}
.advertise-table {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.advertise-table::-webkit-scrollbar {
    display: none;
}
.user-event {
    padding: 6px 0;
}
</style>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import AdBenefitSlotTab from '@/views/ad/benefit/AdBenefitSlotTab.vue'
import CreateFormModal from '@/components/modal/CreateFormModal.vue'
import { required } from '@/util/input-validation'
import AdInstance from '@/views/ad/AdInstance.vue'
import AdCurrentInstance from '@/views/ad/AdCurrentInstance.vue'
import { getApi, postApi } from '@/fetchTemplate'
import { isNil } from 'lodash'
import { InputComponents } from '@/components/InputMapper.vue'

export default defineComponent({
    name: 'AdBenefitSection',

    components: {
        AdBenefitSlotTab,
        AdCurrentInstance,
        AdInstance,
        CreateFormModal,
    },

    setup() {
        const baseInstances = ref([] as any[])
        const instances = ref([] as any[])
        const reloadCount = ref(0)

        const slot = ref({} as any)
        const slotId = ref(0)
        const slotList = ref([])
        const slotFilter = ref(['benefit'])
        const status = ref()

        const loading = ref(false)

        const minBuildNumberList = ref([])

        const createSpec = {
            name: {
                label: '이름',
                value: null,
            },
            page: {
                label: '페이지',
                value: 'benefit',
                disabled: true,
            },
            position: {
                label: '섹션 구분 값(영어로)',
                value: null,
            },
            isDA: {
                label: 'DA 여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: 'DA',
                falseText: '혜택 Section',
                notNull: true,
            },
        }
        const createRules = {
            page: [required],
            position: [required],
            name: [required],
            isDA: [required],
        }

        return {
            baseInstances,
            instances,
            reloadCount,
            slot,
            slotId,
            slotList,
            slotFilter,
            status,
            minBuildNumberList,
            loading,
            createSpec,
            createRules,
        }
    },

    methods: {
        changeSlot(slot: any) {
            this.slotId = slot?.id
            this.baseInstances = []
            this.instances = []
            this.fetchInstances()
        },
        removeBaseInstance(index: any) {
            this.baseInstances.splice(index, 1)
        },
        removeInstances(index: any) {
            this.instances.splice(index, 1)
        },
        updateBaseInstances(baseInstances: any[]) {
            this.baseInstances.push(...baseInstances)
        },
        updateInstances(instances: any[]) {
            this.instances.push(...instances)
        },
        updatePublishGroups(list: any[]) {
            console.log('update publish-groups : ', list)
        },
        async fetchInstances() {
            if (isNil(this.slotId)) {
                return
            }
            try {
                const url = `/api/advertisements/current?slotId=${this.slotId}&minBuildNumber=1`
                const response = await getApi(url)
                this.baseInstances = response?.result.baseInstances || []
                this.instances = response?.result.instances || []
            } catch (error: any) {
                console.log('e : ', error)
            }
        },
        async save() {
            const url = '/api/advertisements'
            const orders = this.instances.map((it: any, index: number) => {
                return {
                    instanceId: it.id,
                    layoutId: it.layout.layoutId,
                    order: index,
                }
            })
            const baseOrders = this.baseInstances.map(
                (it: any, index: number) => {
                    return {
                        instanceId: it.id,
                        layoutId: it.layout.layoutId,
                        order: index,
                    }
                }
            )
            const payload = {
                enable: true,
                slotId: this.slotId,
                minBuildNumber: 1,
                orders: orders,
                baseOrders: baseOrders,
            }
            this.loading = true
            await postApi(url, payload, true, '업데이트 실패!')
            this.loading = false
        },
        async init() {
            const slots = await this.fetchSlots()
            const minBuildNumbers = await this.fetchMinBuildNumbers()
            const slotList = slots.filter((obj: any) =>
                this.slotFilter.includes(obj.page)
            )
            const selected = slotList[0]
            this.slot = selected
            this.slotId = selected.id
            this.slotList = slotList
            this.minBuildNumberList = minBuildNumbers
            const publish = minBuildNumbers?.find(
                (it: any) => it.slotId === selected.id
            )
            this.status = publish?.enable
            await this.fetchInstances()
        },
        async fetchMinBuildNumbers() {
            const url = '/api/advertisements/min-build-numbers'
            const response = await getApi(url)
            return response?.result || []
        },
        async fetchSlots() {
            const url = '/api/advertisements/slots?page=0&size=100'
            const response = await getApi(url)
            return response?.result.content || []
        },
    },

    mounted() {
        this.init()
    },
})
</script>
