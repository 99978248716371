import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import BulkActionModal from '@/components/modal/BulkActionModal.vue'
import { formatBoolean } from '@/util/formmater'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'FoodLensMap',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        fixed: 'left',
    },
    {
        title: '푸드렌즈 음식 ID',
        dataIndex: 'foodLensId',
        sorter: true,
    },
    {
        title: '푸드렌즈 음식 이름',
        dataIndex: 'foodLensName',
        sorter: true,
    },
    {
        title: '음식 ID',
        dataIndex: 'foodId',
    },
    {
        title: '음식 이름',
        dataIndex: 'foodName',
    },
    {
        title: '편집 거리',
        dataIndex: 'levenshteinDistance',
        sorter: true,
    },
    {
        title: '완료여부',
        dataIndex: 'resolved',
        customRender: formatBoolean,
        sorter: true,
    },
]

const updateSpec = ref({
    foodId: {
        label: '음식 ID',
        value: null,
        component: InputComponents.FoodInput,
    },
    resolved: {
        label: '완료여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
})

const filterSpec = ref({
    id: {
        label: 'ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    foodLensId: {
        label: '푸드렌즈 음식 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    foodLensName: {
        label: '푸드렌즈 음식 이름',
        value: null,
        labelCol: 8,
    },
    foodId: {
        label: '음식 ID',
        value: null,
        component: InputComponents.FoodInput,
    },
    levenshteinDistance: {
        label: '편집 거리',
        value: null,
        component: InputComponents.AInputNumber,
    },
    resolved: {
        label: '완료여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
})

const selectedRowKeys = ref<Array<number>>([])

const onSelectChange = (key: Array<number>) => {
    selectedRowKeys.value = key
}

const resolvedSpec = ref({
    foodId: {
        label: '음식 ID',
        value: null,
        component: InputComponents.FoodInput,
    },
    resolved: {
        label: '완료여부',
        value: null,
        component: InputComponents.BooleanInput,
    },
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    title: "푸드렌즈 음식 매칭",
    uri: "/api/food-lens-map",
    columns: columns,
    "filter-spec": filterSpec.value,
    "update-spec": updateSpec.value,
    "row-selection": {
            selectedRowKeys: selectedRowKeys.value,
            onChange: onSelectChange,
        },
    "load-on-mount": ""
  }, {
    actions: _withCtx(({ uri, search }) => [
      _createVNode(BulkActionModal, {
        onAfterSubmit: search,
        "resource-ids": selectedRowKeys.value,
        uri: `${uri}/resolve`,
        "input-spec": resolvedSpec.value,
        label: "포괄처리",
        disabled: selectedRowKeys.value.length === 0
      }, null, 8, ["onAfterSubmit", "resource-ids", "uri", "input-spec", "disabled"])
    ]),
    _: 1
  }, 8, ["filter-spec", "update-spec", "row-selection"]))
}
}

})