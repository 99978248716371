import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  style: {"display":"inline-block","white-space":"nowrap"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_mentions_option = _resolveComponent("a-mentions-option")!
  const _component_a_mentions = _resolveComponent("a-mentions")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tag-space",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickTag && _ctx.onClickTag(...args)))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userNames, (tag) => {
            return (_openBlock(), _createBlock(_component_a_tag, {
              key: tag,
              color: "blue"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_a_mentions, {
            value: _ctx.mentionText,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.mentionText) = $event)),
            style: {"max-width":"70%","margin-right":"10px"}
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companion, ({ id, userName }) => {
                return (_openBlock(), _createBlock(_component_a_mentions_option, {
                  key: id,
                  value: userName
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(userName), 1)
                  ]),
                  _: 2
                }, 1032, ["value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"]),
          _createVNode(_component_a_button, { onClick: _ctx.onClickAllocate }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("할당")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]))
  ]))
}