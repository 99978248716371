import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "영양소 좋은점",
    uri: "/api/nutrient-good-point",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns,
    "load-on-mount": ""
  }, {
    detail: _withCtx(({ resourceId, record }) => [
      _createVNode(_component_a_descriptions, {
        title: "영양소 좋은점",
        bordered: true,
        layout: "vertical",
        column: 1,
        size: "small"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions_item, { label: "제목" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.title), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "요약" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.summary), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "등록일시" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(record.creationTime), 1)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_a_descriptions_item, { label: "썸네일" }, {
            default: _withCtx(() => [
              (_openBlock(), _createElementBlock("img", {
                key: resourceId,
                width: 200,
                src: record.thumbnailUrl
              }, null, 8, _hoisted_1))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["create-spec", "update-spec", "filter-spec", "create-rules", "update-rules", "columns"]))
}