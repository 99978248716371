import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#f9fefe","padding":"13px","width":"auto"} }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_4 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_5 = { class: "input-row" }
const _hoisted_6 = { class: "input-row" }
const _hoisted_7 = { class: "input-row" }
const _hoisted_8 = { class: "input-row" }
const _hoisted_9 = {
  key: 0,
  class: "input-row"
}
const _hoisted_10 = {
  key: 1,
  class: "input-row"
}
const _hoisted_11 = ["textContent"]
const _hoisted_12 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_13 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_14 = { class: "input-row" }
const _hoisted_15 = { class: "input-row" }
const _hoisted_16 = { class: "input-row" }
const _hoisted_17 = { class: "input-row" }
const _hoisted_18 = ["textContent"]
const _hoisted_19 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_20 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_21 = {
  key: 0,
  style: {"display":"flex","align-items":"center","margin-bottom":"10px"}
}
const _hoisted_22 = { class: "input-row" }
const _hoisted_23 = { class: "input-row" }
const _hoisted_24 = { class: "input-row" }
const _hoisted_25 = { class: "input-row" }
const _hoisted_26 = {
  key: 2,
  style: {"display":"flex","align-items":"center","margin-bottom":"10px"}
}
const _hoisted_27 = ["textContent"]
const _hoisted_28 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_29 = { style: {"display":"flex","align-items":"center","margin-bottom":"10px"} }
const _hoisted_30 = {
  key: 0,
  style: {"display":"flex","align-items":"center","margin-bottom":"10px"}
}
const _hoisted_31 = { class: "input-row" }
const _hoisted_32 = { class: "input-row" }
const _hoisted_33 = { class: "input-row" }
const _hoisted_34 = { class: "input-row" }
const _hoisted_35 = { class: "input-row" }

import { onMounted, ref, toRef } from 'vue'
import { aboveOrEqualToZero, required } from '@/util/input-validation'
import Draggable from 'vuedraggable'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { useEnumTypeStore } from '@/store/enumType'
import ContentImageInput from '@/components/input/ContentImageInput.vue'
import EnumSelect from '@/components/input/EnumSelect.vue'
import { webBaseUrl } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import EventContentPropertiesInput from '@/components/input/EventContentPropertiesInput.vue'

type EventContent = {
    contentId: string
    name: string
    subName: string
    resourceUrl: string
    contentType: string
    exposeType: string
    actionType: string
    actionParameter: string
    properties: any
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventContentInput',
  props: {
    rules: {
        type: Object,
        default: () => ({
            name: [required],
            resourceUrl: [required],
            orderNo: [required, aboveOrEqualToZero],
            contentType: [required],
            exposeType: [required],
            actionType: [required],
        }),
    },
    resourceId: {
        type: Number,
    },
    value: {
        type: Object,
        default: () => {},
    },
},
  setup(__props) {

const props = __props

const form = ref<EventContent>({
    contentId: '',
    name: '',
    subName: '',
    resourceUrl: '',
    contentType: 'IMAGE',
    exposeType: 'ALL',
    actionType: 'NONE',
    actionParameter: '',
    properties: {
        quizAnswer: '',
        quizComment: '',
        surveyLongMinLength: 0,
        bbLabel: '',
        bbAppDeeplinkUrl: '',
        bbAppExternalUrl: '',
        bbWebUrl: '',
        bbBgColor: '',
        bbFontColor: '',
        bbDescription: '',
        swiperImages: [],
    },
})

const formRule = ref()
const valueRef = toRef(props, 'value')

const menu = [
    {
        key: 'request',
        label: '신청',
        title: '신청',
    },
    {
        key: 'proof',
        label: '인증',
        title: '인증',
    },
    {
        key: 'survey',
        label: '설문',
        title: '설문',
    },
    {
        key: 'quiz',
        label: '퀴즈',
        title: '퀴즈',
    },
]
const selectedMenu = ref(['request'])

const push = (value: EventContent) => {
    if (selectedMenu.value.includes('request')) {
        valueRef.value.contentList.push(value)
    } else if (selectedMenu.value.includes('proof')) {
        valueRef.value.proofContentList.push(value)
    } else if (selectedMenu.value.includes('survey')) {
        valueRef.value.surveyContentList.push(value)
    } else if (selectedMenu.value.includes('quiz')) {
        valueRef.value.quizContentList.push(value)
    }
}

const pop = (index: number) => {
    if (selectedMenu.value.includes('request')) {
        valueRef.value.contentList.splice(index, 1)
    } else if (selectedMenu.value.includes('proof')) {
        valueRef.value.proofContentList.splice(index, 1)
    } else if (selectedMenu.value.includes('survey')) {
        valueRef.value.surveyContentList.splice(index, 1)
    } else if (selectedMenu.value.includes('quiz')) {
        valueRef.value.quizContentList.splice(index, 1)
    }
}

const add = () => {
    return formRule.value.validate().then(() => {
        if (props.value != null) {
            push(form.value)
            console.log('add called')
            message.info('추가되었습니다.')
            form.value = {
                contentId: '',
                name: '',
                subName: '',
                resourceUrl: '',
                contentType: 'IMAGE',
                exposeType: 'ALL',
                actionType: 'NONE',
                actionParameter: '',
                properties: {
                    quizAnswer: '',
                    quizComment: '',
                    surveyLongMinLength: 0,
                    bbLabel: '',
                    bbAppDeeplinkUrl: '',
                    bbAppExternalUrl: '',
                    bbWebUrl: '',
                    bbBgColor: '',
                    bbFontColor: '',
                    bbDescription: '',
                    swiperImages: [],
                },
            }
        }
    })
}

const testWeb = () => {
    const now = new Date()
    const isProof = selectedMenu.value.includes('proof')

    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const dateParam = `${year}${month}${day}${hours}${minutes}`
    const link = `${webBaseUrl}/event/${props.resourceId}/test?date=${dateParam}&proof=${isProof}`
    window.open(link)
}

const testWeb2 = () => {
    const now = new Date()

    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const dateParam = `${year}${month}${day}${hours}${minutes}`
    const link = `${webBaseUrl}/event/${props.resourceId}/join/test?date=${dateParam}`
    window.open(link)
}

const testSurvey = () => {
    const now = new Date()

    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const dateParam = `${year}${month}${day}${hours}${minutes}`
    const link = `${webBaseUrl}/event/${props.resourceId}/survey/test`
    window.open(link)
}

const addSwiperImages = (element: any, imageUrl: any) => {
    console.log('add images : ', imageUrl)
    element.properties.swiperImages = imageUrl
    form.value.properties.swiperImages = imageUrl
}

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['event-content-type'])
    enumTypeStore.dispatchEnums(['event-survey-type'])
    enumTypeStore.dispatchEnums(['event-content-expose-type'])
    enumTypeStore.dispatchEnums(['event-action-type'])
})

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_row, { style: {"margin-bottom":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          ref_key: "formRule",
          ref: formRule,
          model: form.value,
          rules: __props.rules,
          layout: "inline"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: add
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 컨텐츠 추가 ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: testWeb
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" 이벤트 페이지 미리보기 ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            }),
            (selectedMenu.value.includes('request'))
              ? (_openBlock(), _createBlock(_component_a_form_item, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: testWeb2
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" 참가 페이지 미리보기 ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (selectedMenu.value.includes('survey'))
              ? (_openBlock(), _createBlock(_component_a_form_item, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: testSurvey
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" 설문 페이지 미리보기 ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model", "rules"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_menu, {
      selectedKeys: selectedMenu.value,
      "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((selectedMenu).value = $event)),
      items: menu,
      mode: "horizontal"
    }, null, 8, ["selectedKeys"]),
    (selectedMenu.value.includes('request'))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 0,
          style: {"margin-top":"20px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Draggable), {
              list: valueRef.value.contentList,
              "item-key": "value"
            }, {
              item: _withCtx(({ element, index }) => [
                _createVNode(_component_a_form, {
                  model: element,
                  rules: __props.rules,
                  layout: "inline",
                  style: {"padding-bottom":"10px","display":"block","border":"2px solid black","border-radius":"10px","margin-bottom":"10px","padding":"20px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          textContent: _toDisplayString(index + 1)
                        }, null, 8, _hoisted_2),
                        _createVNode(_component_a_button, {
                          type: "danger",
                          onClick: () => pop(index)
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_unref(DeleteOutlined))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_3, [
                      _cache[5] || (_cache[5] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 아이디: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.contentId,
                        "onUpdate:value": ($event: any) => ((element.contentId) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _cache[6] || (_cache[6] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 이름: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.name,
                        "onUpdate:value": ($event: any) => ((element.name) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createVNode(ContentImageInput, {
                      value: element.resourceUrl,
                      "onUpdate:value": ($event: any) => ((element.resourceUrl) = $event),
                      name: "resourceUrl",
                      path: "/event-content",
                      style: {"display":"flex"}
                    }, null, 8, ["value", "onUpdate:value"]),
                    _createElementVNode("div", _hoisted_5, [
                      _cache[7] || (_cache[7] = _createElementVNode("label", { style: {"width":"100%"} }, "컨텐츠 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.contentType,
                        "onUpdate:value": ($event: any) => ((element.contentType) = $event),
                        typeName: "event-content-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _cache[8] || (_cache[8] = _createElementVNode("label", { style: {"width":"100%"} }, "노출 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.exposeType,
                        "onUpdate:value": ($event: any) => ((element.exposeType) = $event),
                        typeName: "event-content-expose-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _cache[9] || (_cache[9] = _createElementVNode("label", { style: {"width":"100%"} }, "액션 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.actionType,
                        "onUpdate:value": ($event: any) => ((element.actionType) = $event),
                        typeName: "event-action-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[10] || (_cache[10] = _createElementVNode("label", { style: {"width":"100px"} }, "액션파라미터: ", -1)),
                      _createVNode(_component_a_textarea, {
                        value: element.actionParameter,
                        "onUpdate:value": ($event: any) => ((element.actionParameter) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    (element.contentType === 'BOTTOM_BUTTON')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _cache[11] || (_cache[11] = _createElementVNode("label", { style: {"width":"100px"} }, "하단 버튼 설정", -1)),
                          _createVNode(EventContentPropertiesInput, {
                            menu: "request",
                            "sub-menu": "bottom-button",
                            value: element.properties,
                            "onUpdate:value": ($event: any) => ((element.properties) = $event)
                          }, null, 8, ["value", "onUpdate:value"])
                        ]))
                      : _createCommentVNode("", true),
                    (element.contentType === 'IMAGE_SWIPER')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _cache[12] || (_cache[12] = _createElementVNode("label", { style: {"width":"100px"} }, "이미지 스와이퍼", -1)),
                          _createVNode(EventContentPropertiesInput, {
                            menu: "request",
                            "sub-menu": "image-swiper",
                            value: element.properties,
                            "onUpdate:value": ($event: any) => ((element.properties) = $event),
                            "onUpdate:swiperImages": (images) => addSwiperImages(element, images)
                          }, null, 8, ["value", "onUpdate:value", "onUpdate:swiperImages"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["list"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (selectedMenu.value.includes('proof'))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 1,
          style: {"margin-top":"20px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Draggable), {
              list: valueRef.value.proofContentList,
              "item-key": "value"
            }, {
              item: _withCtx(({ element, index }) => [
                _createVNode(_component_a_form, {
                  model: element,
                  rules: __props.rules,
                  layout: "inline",
                  style: {"padding-bottom":"10px","display":"block","border":"2px solid black","border-radius":"10px","margin-bottom":"10px","padding":"20px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          textContent: _toDisplayString(index + 1)
                        }, null, 8, _hoisted_11),
                        _createVNode(_component_a_button, {
                          type: "danger",
                          onClick: () => pop(index)
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_unref(DeleteOutlined))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[13] || (_cache[13] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 아이디: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.contentId,
                        "onUpdate:value": ($event: any) => ((element.contentId) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _cache[14] || (_cache[14] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 이름: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.name,
                        "onUpdate:value": ($event: any) => ((element.name) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createVNode(ContentImageInput, {
                      value: element.resourceUrl,
                      "onUpdate:value": ($event: any) => ((element.resourceUrl) = $event),
                      name: "resourceUrl",
                      path: "/event-content",
                      style: {"display":"flex"}
                    }, null, 8, ["value", "onUpdate:value"]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[15] || (_cache[15] = _createElementVNode("label", { style: {"width":"100%"} }, "컨텐츠 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.contentType,
                        "onUpdate:value": ($event: any) => ((element.contentType) = $event),
                        typeName: "event-content-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _cache[16] || (_cache[16] = _createElementVNode("label", { style: {"width":"100%"} }, "노출 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.exposeType,
                        "onUpdate:value": ($event: any) => ((element.exposeType) = $event),
                        typeName: "event-content-expose-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _cache[17] || (_cache[17] = _createElementVNode("label", { style: {"width":"100%"} }, "액션 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.actionType,
                        "onUpdate:value": ($event: any) => ((element.actionType) = $event),
                        typeName: "event-action-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _cache[18] || (_cache[18] = _createElementVNode("label", { style: {"width":"100px"} }, "액션파라미터: ", -1)),
                      _createVNode(_component_a_textarea, {
                        value: element.actionParameter,
                        "onUpdate:value": ($event: any) => ((element.actionParameter) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["list"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (selectedMenu.value.includes('survey'))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 2,
          style: {"margin-top":"20px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Draggable), {
              list: valueRef.value.surveyContentList,
              "item-key": "value"
            }, {
              item: _withCtx(({ element, index }) => [
                _createVNode(_component_a_form, {
                  model: element,
                  rules: __props.rules,
                  layout: "inline",
                  style: {"padding-bottom":"10px","display":"block","border":"2px solid black","border-radius":"10px","margin-bottom":"10px","padding":"20px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          textContent: _toDisplayString(index + 1)
                        }, null, 8, _hoisted_18),
                        _createVNode(_component_a_button, {
                          type: "danger",
                          onClick: () => pop(index)
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_unref(DeleteOutlined))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_19, [
                      _cache[19] || (_cache[19] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 아이디: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.contentId,
                        "onUpdate:value": ($event: any) => ((element.contentId) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                      _cache[20] || (_cache[20] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 이름: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.name,
                        "onUpdate:value": ($event: any) => ((element.name) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    (element.contentType !== 'IMAGE')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                          _cache[21] || (_cache[21] = _createElementVNode("label", { style: {"width":"100px"} }, "서브타이틀: ", -1)),
                          _createVNode(_component_a_input, {
                            value: element.subName,
                            "onUpdate:value": ($event: any) => ((element.subName) = $event)
                          }, null, 8, ["value", "onUpdate:value"])
                        ]))
                      : _createCommentVNode("", true),
                    (element.contentType === 'IMAGE')
                      ? (_openBlock(), _createBlock(ContentImageInput, {
                          key: 1,
                          value: element.resourceUrl,
                          "onUpdate:value": ($event: any) => ((element.resourceUrl) = $event),
                          name: "resourceUrl",
                          path: "/event-content",
                          style: {"display":"flex"}
                        }, null, 8, ["value", "onUpdate:value"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_22, [
                      _cache[22] || (_cache[22] = _createElementVNode("label", { style: {"width":"100%"} }, "컨텐츠 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.contentType,
                        "onUpdate:value": ($event: any) => ((element.contentType) = $event),
                        typeName: "event-survey-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _cache[23] || (_cache[23] = _createElementVNode("label", { style: {"width":"100%"} }, "노출 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.exposeType,
                        "onUpdate:value": ($event: any) => ((element.exposeType) = $event),
                        typeName: "event-content-expose-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _cache[24] || (_cache[24] = _createElementVNode("label", { style: {"width":"100%"} }, "액션 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.actionType,
                        "onUpdate:value": ($event: any) => ((element.actionType) = $event),
                        typeName: "event-action-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _cache[25] || (_cache[25] = _createElementVNode("label", { style: {"width":"100px"} }, "액션파라미터: ", -1)),
                      _createVNode(_component_a_textarea, {
                        value: element.actionParameter,
                        "onUpdate:value": ($event: any) => ((element.actionParameter) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    (
                                element.contentType === 'SURVEY_SUBJECTIVE_LONG'
                            )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                          _cache[26] || (_cache[26] = _createElementVNode("label", { style: {"width":"100px"} }, "설문 추가 속성", -1)),
                          _createVNode(EventContentPropertiesInput, {
                            menu: "survey",
                            value: element.properties,
                            "onUpdate:value": ($event: any) => ((element.properties) = $event)
                          }, null, 8, ["value", "onUpdate:value"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1032, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["list"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (selectedMenu.value.includes('quiz'))
      ? (_openBlock(), _createBlock(_component_a_row, {
          key: 3,
          style: {"margin-top":"20px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(Draggable), {
              list: valueRef.value.quizContentList,
              "item-key": "value"
            }, {
              item: _withCtx(({ element, index }) => [
                _createVNode(_component_a_form, {
                  model: element,
                  rules: __props.rules,
                  layout: "inline",
                  style: {"padding-bottom":"10px","display":"block","border":"2px solid black","border-radius":"10px","margin-bottom":"10px","padding":"20px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          textContent: _toDisplayString(index + 1)
                        }, null, 8, _hoisted_27),
                        _createVNode(_component_a_button, {
                          type: "danger",
                          onClick: () => pop(index)
                        }, {
                          icon: _withCtx(() => [
                            _createVNode(_unref(DeleteOutlined))
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_28, [
                      _cache[27] || (_cache[27] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 아이디: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.contentId,
                        "onUpdate:value": ($event: any) => ((element.contentId) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[28] || (_cache[28] = _createElementVNode("label", { style: {"width":"100px"} }, "컨텐츠 이름: ", -1)),
                      _createVNode(_component_a_input, {
                        value: element.name,
                        "onUpdate:value": ($event: any) => ((element.name) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    (element.contentType !== 'IMAGE')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                          _cache[29] || (_cache[29] = _createElementVNode("label", { style: {"width":"100px"} }, "서브타이틀: ", -1)),
                          _createVNode(_component_a_input, {
                            value: element.subName,
                            "onUpdate:value": ($event: any) => ((element.subName) = $event)
                          }, null, 8, ["value", "onUpdate:value"])
                        ]))
                      : _createCommentVNode("", true),
                    (element.contentType === 'IMAGE')
                      ? (_openBlock(), _createBlock(ContentImageInput, {
                          key: 1,
                          value: element.resourceUrl,
                          "onUpdate:value": ($event: any) => ((element.resourceUrl) = $event),
                          name: "resourceUrl",
                          path: "/event-content",
                          style: {"display":"flex"}
                        }, null, 8, ["value", "onUpdate:value"]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[30] || (_cache[30] = _createElementVNode("label", { style: {"width":"100%"} }, "컨텐츠 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.contentType,
                        "onUpdate:value": ($event: any) => ((element.contentType) = $event),
                        typeName: "event-survey-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _cache[31] || (_cache[31] = _createElementVNode("label", { style: {"width":"100%"} }, "노출 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.exposeType,
                        "onUpdate:value": ($event: any) => ((element.exposeType) = $event),
                        typeName: "event-content-expose-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _cache[32] || (_cache[32] = _createElementVNode("label", { style: {"width":"100%"} }, "액션 타입: ", -1)),
                      _createVNode(EnumSelect, {
                        value: element.actionType,
                        "onUpdate:value": ($event: any) => ((element.actionType) = $event),
                        typeName: "event-action-type"
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_34, [
                      _cache[33] || (_cache[33] = _createElementVNode("label", { style: {"width":"100px"} }, "액션파라미터: ", -1)),
                      _createVNode(_component_a_textarea, {
                        value: element.actionParameter,
                        "onUpdate:value": ($event: any) => ((element.actionParameter) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _cache[34] || (_cache[34] = _createElementVNode("span", { class: "input-header" }, "속성", -1)),
                      _createVNode(EventContentPropertiesInput, {
                        menu: "quiz",
                        value: element.properties,
                        "onUpdate:value": ($event: any) => ((element.properties) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["list"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})