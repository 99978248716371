import router from './router'
import { useUserStore } from './store/user'

export type ApiResponse<T> = {
    result?: T
    success: boolean
    errorCode?: string
    errorMessage?: string
}

export const baseUrl = `${process.env.VUE_APP_BASEURL}`
export const webBaseUrl = `${process.env.VUE_APP_WEB_BASEURL}`

export const adminApi = async <ApiResponse>(
    uri: string,
    options?: RequestInit
): Promise<ApiResponse> => {
    const response = await fetch(`${baseUrl}${uri}`, {
        ...options,
        credentials: 'include',
    })

    if (response.status === 403) {
        useUserStore().$reset()
        router.push('/login')
    }

    if (!response.ok) {
        const jsonBody = await response.json()
        throw Error(jsonBody.message || jsonBody.errorMessage)
    }

    return await response.json()
}

export const adminApiWithResponse = (
    uri: string,
    options?: RequestInit
): Promise<Response> =>
    fetch(`${baseUrl}${uri}`, {
        ...options,
        credentials: 'include',
    })

export const mlUrl = `${process.env.VUE_APP_MLURL}`

export const mlApi = async (
    uri: string,
    options?: RequestInit
): Promise<any> => {
    const response = await fetch(`${mlUrl}${uri}`, {
        ...options,
        credentials: 'include',
    })
    return await response.json()
}

export const postApi = async (
    uri: string,
    payload: any,
    alert?: boolean,
    alertMessage?: string
) => {
    try {
        const response = await adminApi<ApiResponse<void>>(uri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
        if (!response.success && alert) {
            window.alert(alertMessage)
            return
        }
        return response
    } catch (error: any) {
        console.error('post error : ', error)
    }
}

export const patchApi = async (
    uri: string,
    payload: any,
    alert?: boolean,
    alertMessage?: string
) => {
    try {
        const response = await adminApi<ApiResponse<void>>(uri, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
        if (!response.success && alert) {
            window.alert(alertMessage)
            return
        }
        return response
    } catch (error: any) {
        console.error('patch status : ', error)
    }
}

export const getApi = async (uri: string) => {
    try {
        const response = await adminApi<ApiResponse<any>>(uri)
        if (!response.success) {
            console.log('error : ", response')
            return null
        }
        return response
    } catch (error: any) {
        console.log('e : ', error)
    }
}
