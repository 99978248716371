import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_minus_outlined = _resolveComponent("minus-outlined")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { class: "row" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_select, {
        value: _ctx.value,
        options: _ctx.options,
        style: {"min-width":"256px","width":"auto"},
        onChange: _ctx.change,
        onSelect: _ctx.onSelect
      }, null, 8, ["value", "options", "onChange", "onSelect"]),
      _createVNode(_component_a_button, {
        onClick: _withModifiers(_ctx.refreshOptions, ["prevent"]),
        style: {"margin-left":"5px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_reload_outlined)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_a_button, {
        onClick: _withModifiers(_ctx.resetValue, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_minus_outlined)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}