import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_col = _resolveComponent("a-col")!

  return (_openBlock(), _createBlock(_component_a_col, { class: "container" }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.header), 1),
      (_ctx.type === 'select')
        ? (_openBlock(), _createBlock(_component_a_select, {
            key: 0,
            value: _ctx.value,
            options: _ctx.options,
            onChange: _ctx.change
          }, null, 8, ["value", "options", "onChange"]))
        : (_ctx.type === 'number')
          ? (_openBlock(), _createBlock(_component_a_input_number, {
              key: 1,
              value: _ctx.value,
              onChange: _ctx.change
            }, null, 8, ["value", "onChange"]))
          : (_openBlock(), _createBlock(_component_a_input, {
              key: 2,
              value: _ctx.value,
              onChange: _ctx.change
            }, null, 8, ["value", "onChange"]))
    ]),
    _: 1
  }))
}