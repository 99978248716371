<template>
    <ResourceTable
        title="댓글"
        uri="/api/v1/comments"
        :filter-spec="filterSpec"
        :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
        }"
        :scroll="{ x: true }"
        :columns="columns"
        exportable
        load-on-mount
    >
        <template #actions="{ uri, search }">
            <a-button style="color: black" :loading="loading" disabled
                >댓글 상태 변경</a-button
            >
            <a-button
                @click="(event) => updateStatus(event, uri, search)"
                value="NORMAL"
                >노멀</a-button
            >
            <a-button
                @click="(event) => updateStatus(event, uri, search)"
                value="ADMIN_BLIND"
                >블라인드</a-button
            >
            <a-button
                @click="(event) => updateStatus(event, uri, search)"
                value="ADMIN_DELETED"
                >운영자 삭제</a-button
            >
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { stringify } from 'qs'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent, Ref, ref } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'ReviewComment',

    mixins: [TableSupport],

    components: {
        ResourceTable,
    },

    data() {
        const filterSpec: FormSpecification = {
            id: {
                label: 'ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            userId: {
                label: '사용자 ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            status: {
                label: '상태',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'comment-status',
                input: () => {
                    super.onSearch()
                },
            },
            creationTime: {
                label: '등록일',
                value: null,
                component: InputComponents.ARangePicker,
            },
            updateTime: {
                label: '수정일',
                value: null,
                component: InputComponents.ARangePicker,
            },
        }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 80,
            },
            {
                title: '사용자 ID',
                dataIndex: 'userId',
                width: 80,
            },
            {
                title: '제목',
                dataIndex: 'title',
                width: 100,
            },
            {
                title: '본문',
                dataIndex: 'body',
                width: 200,
            },
            {
                title: '타입',
                dataIndex: 'targetType',
                width: 50,
            },
            {
                title: '타입 ID',
                dataIndex: 'targetId',
                width: 50,
            },
            {
                title: '상태',
                dataIndex: 'status',
                customRender: formatEnum('comment-status'),
                width: 80,
            },
            {
                title: '등록일시',
                dataIndex: 'creationTime',
                customRender: formatLocalDateTime,
                sorter: true,
                width: 165,
            },
            {
                title: '수정일시',
                dataIndex: 'updateTime',
                customRender: formatLocalDateTime,
                width: 165,
            },
        ]

        return {
            columns,
            filterSpec,
        }
    },

    setup() {
        super.setup()
        const selectedRowKeys: Ref<Array<number>> = ref([])
        const loading = ref(false)

        return { selectedRowKeys, loading }
    },

    methods: {
        onSelectChange(selectedRowKeys: Array<number>) {
            this.selectedRowKeys = selectedRowKeys
        },
        updateStatus(click: any, uri: string, search: () => void) {
            const params: Record<string, any> = {
                ids: this.selectedRowKeys,
                commentStatus: click.target.value,
            }
            const queryParams = stringify(params, { indices: false })
            this.updateComment(queryParams, uri, search)
        },
        async updateComment(param: string, uri: string, search: () => void) {
            if (this.selectedRowKeys.length > 0 && this.loading === false) {
                this.loading = true
                try {
                    await adminApi<ApiResponse<void>>(`${uri}?${param}`, {
                        method: 'PATCH',
                    })
                } catch (err) {
                    console.error(err)
                    throw err
                } finally {
                    setTimeout(() => {
                        this.loading = false
                        this.selectedRowKeys = []
                        search()
                    }, 1000)
                }
            }
        },
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['comment-status'])
    },
})
</script>
