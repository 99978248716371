import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_enum_select = _resolveComponent("enum-select")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    ref: "formRule",
    model: _ctx.form
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_descriptions, {
            bordered: true,
            column: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, { label: "세그먼트" }, {
                default: _withCtx(() => [
                  _createVNode(_component_enum_select, {
                    value: _ctx.form.targetUser.segId,
                    typeName: "segments",
                    "onUpdate:value": _ctx.updateValue
                  }, null, 8, ["value", "onUpdate:value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}