import { adminApi, ApiResponse } from '@/fetchTemplate'
import { ActionContext } from 'vuex'

export type ProductTag = {
    name: string
    keywords: Array<string>
}

type TagState = {
    tags?: Array<ProductTag>
}

const defaultState = {}

const SET_PRODUCT_TAG = 'SET_PRODUCT_TAG'

export default {
    state: defaultState,
    getters: {
        productTag: (state: TagState) => state.tags,
    },
    mutations: {
        [SET_PRODUCT_TAG]: (state: TagState, tags: Array<ProductTag>) => {
            state.tags = tags
        },
    },
    actions: {
        async fetchTags({ commit }: ActionContext<TagState, any>) {
            const response = await adminApi<ApiResponse<any>>(
                `/api/product-tag`
            )

            commit(SET_PRODUCT_TAG, response.result.content)
        },
    },
}
