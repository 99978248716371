<template>
    <resource-table
        title="신호등 Flag 관리"
        uri="/api/intake-advisor"
        :columns="columns"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1800, y: 650 }"
        exportable
        deletable
    >
        <template #actions>
            <replace-modal
                title="사유 변경"
                label="사유 변경"
                uri="/api/intake-advisor/reason"
                :input-spec="reasonReplaceSpec"
            />
        </template>
    </resource-table>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import { Column } from '.'
import { formatEnum, formatKoreanNumber } from '@/util/formmater'
import { defineComponent } from 'vue'
import ReplaceModal from '@/components/modal/ReplaceModal.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { required } from '@/util/input-validation'
import NutrientAllowanceGroup from './NutrientAllowanceGroup.vue'

export default defineComponent({
    name: 'IntakeAdvisor',
    components: {
        ResourceTable,
        ReplaceModal,
    },

    data() {
        const filterSpec: FormSpecification = {
            id: {
                label: 'ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            healthStatusCode: {
                label: '건강 상태',
                value: null,
                mode: true,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/name',
            },
            nutrientAllowanceGroupName: {
                label: '영양소 섭취그룹명',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
                width: '200px',
                labelCol: 8,
            },
            isGood: {
                label: '추천여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '추천',
                falseText: '비추천',
            },
            healthStatusCategory: {
                label: '항목',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'health-status-category',
            },
        }

        const createSpec: FormSpecification = {
            isGood: {
                label: '추천여부',
                value: null,
                component: InputComponents.BooleanInput,
                trueText: '추천',
                falseText: '비추천',
            },
            statusCode: {
                label: '건상 상태',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/health-status/name',
                mode: true,
            },
            nutrientAllowanceGroupName: {
                label: '영양소 섭취그룹명',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
            },
            rankNo: {
                label: '순위',
                value: null,
                component: InputComponents.AInputNumber,
            },
            higMin: {
                label: 'HIG 최소',
                value: null,
                component: InputComponents.AInputNumber,
            },
            higMax: {
                label: 'HIG 최대',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            rda: {
                label: 'rda',
                value: null,
                component: InputComponents.AInputNumber,
            },
            odi: {
                label: 'odi',
                value: null,
                component: InputComponents.AInputNumber,
            },
            ul: {
                label: 'ul',
                value: null,
                component: InputComponents.AInputNumber,
                infinity: true,
            },
            reason: {
                label: '사유',
                value: null,
                type: InputType.TEXTAREA,
            },
            reasonShort: {
                label: '사유(간결)',
                value: null,
            },
            memo: {
                label: '관리자 메모',
                value: null,
                type: InputType.TEXTAREA,
            },
        }

        const updateSpec = { ...createSpec }

        const columns: Array<Column> = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 100,
                fixed: 'left',
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
                sorter: true,
            },
            {
                title: '추천',
                dataIndex: 'isGood',
                customRender: (value: any) => {
                    if (value.value) {
                        return '추천'
                    }
                    return '비추천'
                },
                width: 100,
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
                sorter: true,
            },
            {
                title: '항목',
                dataIndex: 'category',
                alias: 'healthStatusCategory',
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
                width: 100,
                customRender: formatEnum('health-status-category'),
            },
            {
                title: '상태',
                dataIndex: 'status',
                alias: 'healthStatusName',
                width: 100,
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
            },
            {
                title: '영양소 섭취그룹명',
                dataIndex: 'nutrientAllowanceGroupName',
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
                width: 200,
            },
            {
                title: '순위',
                dataIndex: 'rankNo',
                sorter: true,
                align: 'right',
                width: 100,
            },
            {
                title: 'RDA',
                dataIndex: 'rda',
                sorter: true,
                align: 'right',
                customRender: formatKoreanNumber,
            },
            {
                title: 'ODI',
                dataIndex: 'odi',
                sorter: true,
                align: 'right',
                customRender: formatKoreanNumber,
            },
            {
                title: 'UL',
                dataIndex: 'ul',
                sorter: true,
                align: 'right',
                customRender: formatKoreanNumber,
            },
            {
                title: 'HIG 최소',
                dataIndex: 'higMin',
                sorter: true,
                customRender: formatKoreanNumber,
                align: 'right',
            },
            {
                title: 'HIG 최대',
                dataIndex: 'higMax',
                sorter: true,
                customRender: formatKoreanNumber,
                align: 'right',
            },
            { title: '사유(간략)', dataIndex: 'reasonShort' },
        ]

        const reasonReplaceSpec: FormSpecification = {
            reasonFrom: {
                label: '사유',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/intake-advisor/reason',
                countable: true,
            },
            reasonTo: {
                label: '사유 변경',
                value: null,
            },
            reasonShortFrom: {
                label: '사유(간결)',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/intake-advisor/reason-short',
                countable: true,
            },
            reasonShortTo: {
                label: '사유(간결) 변경',
                value: null,
            },
        }

        const rules = {
            statusCode: [required],
            nutrientAllowanceGroupName: [required],
            rankNo: [required],
        }

        return {
            createSpec,
            updateSpec,
            filterSpec,
            reasonReplaceSpec,
            columns,
            rules,
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['health-status-category'])
    },
})
</script>
