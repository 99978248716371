<template>
    <a-row class="scoped-container">
        <a-col :span="24" v-if="list?.length > 0">
            <draggable
                class="list-group"
                itemKey="id"
                :list="list || []"
                :group="{ name: 'instances' }"
            >
                <template #item="{ element, index }">
                    <div
                        v-if="element.instances.length >= 1"
                        class="benefit-section"
                    >
                        <div class="benefit-section-header">
                            <span>
                                {{ element.slot.name }}
                            </span>
                            <a-button danger @click="remove(index)">
                                <delete-outlined />
                            </a-button>
                        </div>
                        <div class="preview-layout">
                            <div
                                v-if="element.instances.length > 1"
                                class="multiple"
                            >
                                <swiper
                                    :slides-per-view="'auto'"
                                    :space-between="15"
                                >
                                    <swiper-slide
                                        class="pillyze-swiper-slide"
                                        v-for="instance in element.instances"
                                        v-bind:key="instance.id"
                                    >
                                        <ad-preview
                                            class="preview"
                                            :index="index"
                                            :instance="instance"
                                            :is-swiper-props="true"
                                        />
                                    </swiper-slide>
                                </swiper>
                            </div>
                            <div v-else class="single">
                                <div
                                    v-for="instance in element.instances"
                                    :key="instance.id"
                                >
                                    <ad-preview
                                        class="preview"
                                        :index="index"
                                        :instance="instance"
                                        :is-swiper-props="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else-if="element.baseInstances.length >= 1"
                        class="benefit-section"
                    >
                        <div class="benefit-section-header">
                            <span>
                                {{ element.slot.name }}
                            </span>
                            <a-button danger @click="remove(index)">
                                <delete-outlined />
                            </a-button>
                        </div>
                        <div class="preview-layout">
                            <div
                                v-if="element.baseInstances.length > 1"
                                class="multiple"
                            >
                                <swiper
                                    :slides-per-view="'auto'"
                                    :space-between="15"
                                >
                                    <swiper-slide
                                        class="pillyze-swiper-slide"
                                        v-for="instance in element.baseInstances"
                                        v-bind:key="instance.id"
                                    >
                                        <ad-preview
                                            class="preview"
                                            :index="index"
                                            :instance="instance"
                                            :is-swiper-props="true"
                                        />
                                    </swiper-slide>
                                </swiper>
                            </div>
                            <div v-else class="single">
                                <div
                                    v-for="instance in element.baseInstances"
                                    :key="instance.id"
                                >
                                    <ad-preview
                                        class="preview"
                                        :index="index"
                                        :instance="instance"
                                        :is-swiper-props="false"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="benefit-section">
                        <div class="benefit-section-header">
                            <span>
                                {{ element.slot.name }}
                            </span>
                            <a-button danger @click="remove(index)">
                                <delete-outlined />
                            </a-button>
                        </div>
                        <div>데이터 없음</div>
                    </div>
                </template>
            </draggable>
        </a-col>
        <a-col :span="24" v-else>데이터 없음</a-col>
    </a-row>
</template>

<style scoped>
.scoped-container {
    padding-right: 15px;
    overflow: scroll;
}
.benefit-section {
    padding-bottom: 40px;
}
.benefit-section-header {
    display: flex;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;
}
.pillyze-swiper-slide {
    width: 70%;
}
</style>
<script lang="ts">
import { defineComponent, toRef } from 'vue'
import AdPreview from '@/views/ad/AdPreview.vue'
import draggable from 'vuedraggable'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'

export default defineComponent({
    name: 'AdBenefitCurrent',

    components: {
        DeleteOutlined,
        draggable,
        AdPreview,
        Swiper,
        SwiperSlide,
    },

    props: {
        propsList: {
            type: Array,
        },
    },

    emits: ['remove'],

    setup(props: any) {
        const list = toRef(props, 'propsList')
        return {
            list,
        }
    },

    methods: {
        remove(index: any) {
            this.$emit('remove', index)
        },
    },
})
</script>
