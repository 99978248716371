<template>
    <a-row class="row">
        <a-select
            :value="value"
            :options="options"
            style="min-width: 256px; width: auto"
            @change="change"
            @select="onSelect"
        />
        <a-button @click.prevent="refreshOptions" style="margin-left: 5px">
            <reload-outlined />
        </a-button>
        <a-button @click.prevent="resetValue">
            <minus-outlined />
        </a-button>
    </a-row>
</template>

<style scoped>
.row {
    display: flex;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { SelectOption } from '@/store/enumType'
import { AdSlot, useSlotStore } from '@/store/advertisement'

export default defineComponent({
    name: 'AdSlotInput',

    components: {
        ReloadOutlined,
        MinusOutlined,
    },

    props: {
        showReload: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },

    setup() {
        const slotStore = useSlotStore()

        return {
            slotStore,
        }
    },

    emits: ['select', 'update:value'],

    computed: {
        options(): Array<SelectOption> {
            return this.slotStore.slot.map((it: AdSlot) => ({
                label: `[${it.id}] ${it.name}`,
                value: it.id,
                key: it.id,
            }))
        },
    },

    methods: {
        refreshOptions() {
            this.slotStore.fetchAdSlot()
        },
        onSelect(value: any, option: Record<string, any>) {
            this.$emit('select', value, option)
        },
        change(value: any) {
            return this.$emit('update:value', value)
        },
        resetValue() {
            this.$emit('update:value', null)
        },
    },
})
</script>
