<template>
    <ResourceTable
        title="광고 Layout"
        uri="/api/advertisements/layouts"
        :create-spec="createSpec"
        :create-rules="createRules"
        :update-spec="updateSpec"
        :update-rules="updateRules"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
        load-on-mount
    >
    </ResourceTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'
import ResourceTable from '@/components/ResourceTable.vue'

export default defineComponent({
    name: 'AdLayout',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            name: {
                label: '이름',
                value: null,
            },
            layoutType: {
                label: '구분 값',
                value: null,
            },
            properties: {
                label: '구성 요소',
                value: [],
                component: InputComponents.AdPropertyInput,
            },
        }

        const createRules = {
            name: [required],
            layoutType: [required],
            properties: [required],
        }

        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '32px',
                align: 'center',
            },
            {
                title: '이름',
                dataIndex: 'name',
                width: '256px',
            },
            {
                title: '구분 값',
                dataIndex: 'layoutType',
                width: '256px',
            },
            {
                title: '구성 요소',
                dataIndex: 'properties',
                type: 'ad-properties-obj',
            },
        ]

        return {
            columns: columns,
            createSpec: createSpec,
            createRules: createRules,
            updateSpec: createSpec,
            updateRules: createRules,
        }
    },
})
</script>
