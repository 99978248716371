import { adminApi, ApiResponse } from '@/fetchTemplate'
import Vue from 'vue'
import { ActionContext } from 'vuex'
import { StandardUnit } from './standardUnit'

type UnitConversionState = {
    unitConversion: {
        [fromUnit: string]: {
            [toUnit: string]: number
        }
    }
}

type UnitConversionFormular = {
    fromUnit: StandardUnit
    toUnit: StandardUnit
    formula: number
}

const defaultState = {
    unitConversion: {},
} as UnitConversionState

const SET_UNIT_CONVERSION = 'SET_UNIT_CONVERSION'

export default {
    state: defaultState,
    getters: {
        formula:
            (state: UnitConversionState) =>
            (fromUnit: string, toUnit: string) =>
                state.unitConversion[fromUnit] &&
                state.unitConversion[fromUnit][toUnit],
    },
    mutations: {
        [SET_UNIT_CONVERSION]: (
            state: UnitConversionState,
            conversion: Array<UnitConversionFormular>
        ) => {
            const newState = conversion.reduce(
                (acc: Record<string, any>, cur: UnitConversionFormular) => {
                    acc[cur.fromUnit.code] = {
                        ...acc[cur.fromUnit.code],
                        [cur.toUnit.code]: cur.formula,
                    }
                    return acc
                },
                {} as UnitConversionState
            )
            state.unitConversion = newState
        },
    },
    actions: {
        async fetchUnitConversion({
            commit,
        }: ActionContext<UnitConversionState, any>) {
            const jsonBody = await adminApi<
                ApiResponse<Array<UnitConversionFormular>>
            >(`/api/unit-conversion`)

            commit(SET_UNIT_CONVERSION, jsonBody.result)
        },
    },
}
