import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import { required } from '@/util/input-validation'



export default /*@__PURE__*/_defineComponent({
  __name: 'FoodUserRequestEdit',
  props: {
    title: {
        type: String,
        required: true,
    },
    resourceId: {
        type: [Number, String],
        required: true,
    },
    onSearch: {
        type: Function,
        required: true,
    },
},
  setup(__props) {



const updateRules = {
    foodName: [required],
}

const updateSpec: FormSpecification = {
    userId: {
        label: '사용자 ID',
        value: null,
        readonly: true,
    },
    foodId: {
        label: '식품 ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    type: {
        label: '요청 유형',
        value: null,
    },
    foodName: {
        label: '식품명',
        value: null,
    },
    brandName: {
        label: '브랜드명',
        value: null,
    },
    foodType: {
        label: '식품 유형',
        value: null,
    },
    category1: {
        label: '식품 카테고리1',
        value: null,
    },
    category2: {
        label: '식품 카테고리2',
        value: null,
    },
    year: {
        label: '출시년도',
        value: null,
        component: InputComponents.AInputNumber,
    },
    servingSize: {
        label: '1회 제공량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    totalAmount: {
        label: '총 제공량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    calorie: {
        label: '열량',
        value: null,
        component: InputComponents.AInputNumber,
    },
    nutrients: {
        label: '영양소',
        value: [],
        component: InputComponents.ProductNutrientInput,
    },
}

return (_ctx: any,_cache: any) => {
  const _component_UpdateFormModal = _resolveComponent("UpdateFormModal")!

  return (_openBlock(), _createBlock(_component_UpdateFormModal, {
    title: __props.title,
    "resource-id": __props.resourceId,
    uri: "/api/food-user-request",
    "input-spec": updateSpec,
    rules: updateRules,
    onAfterSubmit: __props.onSearch
  }, null, 8, ["title", "resource-id", "onAfterSubmit"]))
}
}

})