import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import store from '@/store'
import { useEnumTypeStore } from '@/store/enumType'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import { ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'SurveyView',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 80,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '이름',
        dataIndex: 'name',
    },
    {
        title: '형식',
        dataIndex: 'surveyType',
        customRender: formatEnum('survey-type'),
    },
    {
        title: '아이콘',
        dataIndex: 'iconUrl',
        type: 'img',
        width: 130,
    },
    {
        title: '내용',
        dataIndex: 'content',
        width: 300,
    },
    {
        title: '등록일시',
        dataIndex: 'creationTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
    {
        title: '수정일시',
        dataIndex: 'updateTime',
        customRender: formatLocalDateTime,
        width: 165,
        sorter: true,
        align: 'center',
    },
]

const inputSpec = ref({
    name: {
        label: '이름',
        value: null,
    },
    surveyType: {
        label: '형식',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'survey-type',
    },
    content: {
        label: '내용',
        value: null,
        type: 'textarea',
    },
    iconUrl: {
        label: '아이콘',
        value: null,
        component: InputComponents.ImageInput,
    },
    optionList: {
        label: '선택지',
        component: InputComponents.SurveyOptionInput,
        value: [],
    },
})

const filterSpec = ref({
    name: {
        label: '이름',
        value: null,
    },
    surveyType: {
        label: '형식',
        value: null,
        component: InputComponents.EnumSelect,
        typeName: 'survey-type',
    },
    content: {
        label: '내용',
        value: null,
    },
})

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['survey-type'])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "survey",
    title: "설문 관리",
    uri: "/api/survey",
    columns: columns,
    "create-spec": inputSpec.value,
    "update-spec": inputSpec.value,
    "filter-spec": filterSpec.value,
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec", "filter-spec"]))
}
}

})