<template>
    <ResourceTable
        title="이벤트 토스트"
        uri="/api/event-toast"
        :columns="columns"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
    />
</template>

<script lang="ts">
import { Column } from '.'
import { formatEnum, formatLocalDateTime } from '@/util/formmater'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { required } from '@/util/input-validation'
import { defineComponent } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'EventToast',
    components: {
        ResourceTable,
    },

    data() {
        const columns: Array<Column> = [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 100,
                fixed: 'left',
                scopedSlots: {
                    filterDropdown: 'filterDropdown',
                    filterIcon: 'filterIcon',
                },
                sorter: true,
            },
            {
                title: 'Title',
                dataIndex: 'title',
            },
            {
                title: 'viewName',
                dataIndex: 'viewName',
            },
            {
                title: '위치',
                dataIndex: 'position',
                customRender: formatEnum('event-toast-position'),
            },
            {
                title: '등록일시',
                dataIndex: 'creationTime',
                customRender: formatLocalDateTime,
                width: 165,
                sorter: true,
                align: 'center',
            },
            {
                title: '수정일시',
                dataIndex: 'updateTime',
                customRender: formatLocalDateTime,
                width: 165,
                sorter: true,
                align: 'center',
            },
        ]

        const filterSpec: FormSpecification = {
            id: {
                label: 'ID',
                value: null,
                component: InputComponents.AInputNumber,
            },
            title: { label: '제목', value: null },
            viewName: { label: '뷰', value: null },
            position: {
                label: '위치',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'event-toast-position',
            },
            active: {
                label: '활성화',
                value: null,
                component: InputComponents.BooleanInput,
                notNull: true,
            },
        }

        const createSpec: FormSpecification = {
            title: { label: '제목', value: null },
            body: {
                label: '내용',
                value: null,
                type: InputType.TEXTAREA,
            },
            viewName: { label: '뷰', value: null },
            position: {
                label: '위치',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'event-toast-position',
            },
            linkUrl: {
                label: '링크',
                value: null,
            },
            active: {
                label: '활성화',
                value: null,
                component: InputComponents.BooleanInput,
                notNull: true,
            },
        }

        const updateSpec = {
            ...createSpec,
        }

        const rules = {
            title: [required],
            body: [required],
            viewName: [required],
            position: [required],
            linkUrl: [required],
        }

        return {
            columns,
            filterSpec,
            createSpec,
            updateSpec,
            rules,
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['event-toast-position'])
    },
})
</script>
