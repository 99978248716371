<template>
    <span style="display: inline-block; white-space: nowrap; width: 90%">
        <a-select
            v-model:value="valueByMode"
            :options="options"
            :mode="mode"
            :disabled="disabled"
            :style="{ width: width }"
            show-search
            option-filter-prop="label"
            @select="onSelect"
        >
            <template #option="{ label, image }">
                <span role="img" :aria-label="label">
                    {{ image }}
                </span>
                &nbsp;&nbsp;{{ label }}
            </template>
        </a-select>
        <a-button @click.prevent="refreshOptions" style="margin-left: 5px">
            <reload-outlined />
        </a-button>
        <a-button @click.prevent="resetValue">
            <minus-outlined />
        </a-button>
    </span>
</template>

<script lang="ts">
import { SelectOption, useEnumTypeStore } from '@/store/enumType'
import { defineComponent } from 'vue'
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons-vue'

export default defineComponent({
    name: 'EnumSelect',
    components: {
        ReloadOutlined,
        MinusOutlined,
    },

    props: {
        value: {
            type: [String, Array],
        },
        typeName: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            default: 'default',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: '15em',
        },
        filter: {
            type: Function,
        },
    },

    computed: {
        options(): Array<SelectOption> {
            const enumTypeStore = useEnumTypeStore()
            if (this.filter != null) {
                return enumTypeStore
                    .getEnumType(this.typeName)
                    ?.filter(
                        (it: SelectOption) =>
                            this.filter != null && this.filter(it.value)
                    )
            }
            return enumTypeStore.getEnumType(this.typeName)
        },
        valueByMode: {
            get() {
                if (this.mode === 'tags' || this.mode === 'multiple') {
                    return this.value || []
                }
                return this.value
            },
            set(value: any) {
                this.$emit('update:value', value)
            },
        },
    },

    emits: ['input', 'update:value', 'select'],

    methods: {
        refreshOptions() {
            const enumTypeStore = useEnumTypeStore()
            enumTypeStore.fetchEnum(this.typeName)
        },
        onSelect(value: any, option: Record<string, any>) {
            this.$emit('select', value, option)
        },
        resetValue() {
            this.$emit('update:value', null)
        },
    },
})
</script>
