import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { InputComponents } from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import store from '@/store'
import { useEnumTypeStore } from '@/store/enumType'
import { formatEnum } from '@/util/formmater'
import { required } from '@/util/input-validation'
import { onMounted, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkoutTrackerActivityManager',
  setup(__props) {

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '코드',
        dataIndex: 'code',
    },
    {
        title: '설명',
        dataIndex: 'name',
    },
    {
        title: '매핑된 운동 ID',
        dataIndex: 'workoutId',
    },
    {
        title: '매핑된 운동 이름',
        dataIndex: 'workoutName',
    },
]

const filterSpec = ref({
    id: { label: 'ID', value: null, component: 'AInputNumber' },
    workoutId: { label: '운동 ID', value: null },
    name: { label: '이름', value: null },
    code: { label: 'code', value: null },
})

const inputSpec = {
    name: {
        label: '이름',
        value: null,
    },
    code: {
        label: 'code',
        value: null,
    },
    workoutId: {
        label: '운동 Id',
        component: 'WorkoutInput',
        width: '300px',
        help: '',
        value: null,
        mode: 'single',
    },
}

const inputRules = {
    name: [required],
    code: [required],
}

const updateSpec = ref({ ...inputSpec })

onMounted(() => {
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['tracker-activity-type'])
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    "resource-name": "workoutTrackerActivity",
    title: "운동 트래커 운동 관리",
    uri: "/api/workout-tracker-activity",
    columns: columns,
    "filter-spec": filterSpec.value,
    "update-spec": updateSpec.value,
    "update-rules": inputRules,
    "load-on-mount": "",
    exportable: ""
  }, null, 8, ["filter-spec", "update-spec"]))
}
}

})