<template>
    <a-row class="row">
        <a-select
            :value="value"
            :options="options"
            style="min-width: 256px; width: auto; margin-right: 12px"
            @change="change"
        />
    </a-row>
</template>

<style scoped>
.row {
    display: flex;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue'
import { SelectOption } from '@/store/enumType'

export default defineComponent({
    name: 'AdMissionInput',

    props: {
        showReload: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
    },

    emits: ['select', 'update:value'],

    computed: {
        options(): Array<SelectOption> {
            return [
                { label: '광고 리워드', value: 'ADVERTISEMENT_DAILY_REWARD' },
                { label: '없음', value: null },
            ].map((it: any) => ({
                label: it.label,
                value: it.value,
                key: it.value,
            }))
        },
    },

    methods: {
        onSelect(value: any, option: Record<string, any>) {
            this.$emit('select', value, option)
        },
        change(value: any) {
            return this.$emit('update:value', value)
        },
    },
})
</script>
