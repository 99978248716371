<template>
    <a-modal
        width="80%"
        height="80%"
        v-model:open="isOpen"
        title="이벤트 CRM"
        cancel-text="취소"
        ok-text="발송"
        :ok-button-props="{ disabled: !isTestDone }"
        @cancel="closeModal"
        @ok="clickSend"
    >
        <a-spin v-if="loading" />
        <a-row v-if="!loading">
            <a-col :span="24">
                <a-descriptions
                    layout="horizontal"
                    :column="1"
                    :bordered="true"
                >
                    <a-descriptions-item label="발송 수단">
                        {{ type }}
                    </a-descriptions-item>
                    <a-descriptions-item label="템플릿">
                        <a-row>
                            <a-col :span="16">
                                <a-select
                                    style="width: 100%"
                                    :options="options"
                                    :value="templateId"
                                    @select="selectTemplate"
                                />
                            </a-col>
                            <a-col :span="8">
                                <a-button
                                    @click.prevent="refreshOptions"
                                    style="margin-left: 5px"
                                >
                                    <reload-outlined />
                                </a-button>
                                <a-button @click.prevent="resetValue">
                                    <minus-outlined />
                                </a-button>
                            </a-col>
                        </a-row>
                    </a-descriptions-item>
                    <a-descriptions-item
                        v-if="type === 'KAKAO_TALK'"
                        label="비즈엠 템플릿 Id"
                    >
                        <a-input disabled v-model:value="bizmTemplateId" />
                    </a-descriptions-item>
                    <a-descriptions-item v-if="type === 'SMS'" label="제목">
                        <a-input v-model:value="smsTitle" />
                    </a-descriptions-item>
                    <a-descriptions-item label="본문">
                        <a-row>
                            <a-col :span="24">
                                <a-textarea
                                    style="
                                        width: 100% !important;
                                        min-height: 350px;
                                    "
                                    :max-length="10"
                                    v-model:value="smsTemplate"
                                />
                            </a-col>
                        </a-row>
                    </a-descriptions-item>
                    <a-descriptions-item label="치환변수">
                        <a-descriptions
                            layout="horizontal"
                            :column="2"
                            :bordered="true"
                            v-for="(item, index) in replacements"
                            :key="index"
                        >
                            <a-descriptions-item label="변수">
                                <a-input :value="item?.key" :disabled="true" />
                            </a-descriptions-item>
                            <a-descriptions-item label="치환값">
                                <a-input
                                    :value="item?.value"
                                    @change="(e: any) => update(index, 'value', e)"
                                />
                            </a-descriptions-item>
                        </a-descriptions>
                    </a-descriptions-item>
                    <a-descriptions-item label="비즈엠 버튼">
                        <a-descriptions
                            layout="horizontal"
                            :column="2"
                            :bordered="true"
                            v-for="(item, index) in bizmButtons"
                            :key="index"
                        >
                            <a-descriptions-item label="버튼명">
                                <a-input :value="item.name" :disabled="true" />
                            </a-descriptions-item>
                            <a-descriptions-item label="버튼타입">
                                <a-input :value="item?.type" :disabled="true" />
                            </a-descriptions-item>
                            <a-descriptions-item label="android">
                                <a-input
                                    :value="item?.schema_android"
                                    @change="(e: any) => updateButtons(index, 'schema_android', e)"
                                />
                            </a-descriptions-item>
                            <a-descriptions-item label="ios">
                                <a-input
                                    :value="item?.schema_ios"
                                    @change="(e: any) => updateButtons(index, 'schema_ios', e)"
                                />
                            </a-descriptions-item>
                            <a-descriptions-item label="mobile">
                                <a-input
                                    :value="item?.url_mobile"
                                    @change="(e: any) => updateButtons(index, 'url_mobile', e)"
                                />
                            </a-descriptions-item>
                            <a-descriptions-item label="pc">
                                <a-input
                                    :value="item?.url_pc"
                                    @change="(e: any) => updateButtons(index, 'url_pc', e)"
                                />
                            </a-descriptions-item>
                        </a-descriptions>
                    </a-descriptions-item>
                    <a-descriptions-item label="테스트 발송(userId)">
                        <a-row style="padding-bottom: 5px">
                            <a-col :span="24">
                                <a-input-number
                                    class="input-number"
                                    v-model:value="testUserId"
                                />
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="12" style="padding-right: 5px">
                                <a-button
                                    warning
                                    class="btn btn-send-test"
                                    @click.prevent="clickSendToMe"
                                >
                                    테스트 발송
                                </a-button>
                            </a-col>
                            <a-col :span="12" style="padding-left: 5px">
                                <a-button
                                    type="primary"
                                    class="btn btn-check-test"
                                    @click.prevent="checkSendTest"
                                >
                                    발송 확인
                                </a-button>
                            </a-col>
                        </a-row>
                        <a-row style="padding: 10px">
                            <span
                                style="
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                "
                                >{{ responseMessage }}</span
                            >
                        </a-row>
                    </a-descriptions-item>
                    <a-descriptions-item label="수신인 리스트">
                        <a-row>
                            <a-col :span="24" class="event-user-label">
                                <span
                                    class="event-user-id"
                                    v-for="eventUserId in eventUserList"
                                    :key="eventUserId"
                                >
                                    {{ eventUserId }}
                                </span>
                            </a-col>
                        </a-row>
                    </a-descriptions-item>
                </a-descriptions>
            </a-col>
        </a-row>
    </a-modal>
</template>

<style scoped>
.input-number,
.btn {
    width: 100%;
}
.event-user-id {
    padding: 6px 12px;
    margin-right: 6px;
    margin-bottom: 6px;
    background-color: #6dafcc;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    color: white;
}
.event-user-label {
    display: flex;
    flex-wrap: wrap;
}
</style>
<script lang="ts">
import { defineComponent, ref, toRef } from 'vue'
import { SelectOption } from '@/store/enumType'
import {
    CrmTemplateType,
    useCrmSmsTemplateStore,
    useCrmBizmTemplateStore,
} from '@/store/crm'
import { MinusOutlined, ReloadOutlined } from '@ant-design/icons-vue'
import { getApi, postApi } from '@/fetchTemplate'

export default defineComponent({
    name: 'SendCrmModal',
    components: {
        ReloadOutlined,
        MinusOutlined,
    },

    props: {
        eventUserProps: {
            type: String,
        },
        eventUserListProps: {
            type: Array,
            required: false,
        },
        eventUserIdListProps: {
            type: Array,
            required: false,
        },
        typeProps: {
            type: String,
        },
        labelProps: {
            type: String,
        },
        isOpenProps: {
            type: Boolean,
            default: () => false,
        },
    },

    setup(props: any) {
        const isOpen = toRef(props, 'isOpenProps')
        const eventUser = toRef(props, 'eventUserProps')
        const eventUserList = toRef(props, 'eventUserListProps')
        const eventUserIdList = toRef(props, 'eventUserIdListProps')
        const type = toRef(props, 'typeProps')
        const label = toRef(props, 'labelProps')
        const loading = ref(false)
        const crmSmsTemplateStore = useCrmSmsTemplateStore()
        const crmBizmTemplateStore = useCrmBizmTemplateStore()
        const smsTitle = ref()
        const smsTemplate = ref()
        const templateId = ref()
        const testUserId = ref()
        const bizmTemplateId = ref()
        const bizmButtons = ref([])
        const replacements = ref([])
        const isTestDone = ref(false)
        const responseMessage = ref()

        return {
            isOpen,
            loading,
            eventUser,
            eventUserList,
            eventUserIdList,
            type,
            label,
            crmSmsTemplateStore,
            crmBizmTemplateStore,
            smsTitle,
            smsTemplate,
            templateId,
            bizmTemplateId,
            bizmButtons,
            replacements,
            testUserId,
            isTestDone,
            responseMessage,
        }
    },

    computed: {
        options(): Array<SelectOption> {
            if (this.type === 'SMS') {
                return this.crmSmsTemplateStore.smsTemplates.map(
                    (it: CrmTemplateType) => ({
                        label: `${it.label}`,
                        value: it.value,
                        key: it.value,
                    })
                )
            }
            return this.crmBizmTemplateStore.bizmTemplates.map(
                (it: CrmTemplateType) => ({
                    label: `${it.label}`,
                    value: it.value,
                    key: it.value,
                })
            )
        },
    },

    emits: ['modal:close', 'modal:open'],

    methods: {
        openModal() {
            this.isOpen = true
        },
        closeModal() {
            this.resetValue()
            this.isOpen = false
            this.$emit('modal:close')
        },
        selectTemplate(templateId: number) {
            this.templateId = templateId
            this.getTemplate(templateId)
        },
        clickSend() {
            const payload = {
                type: this.type,
                title: this.smsTitle,
                content: this.smsTemplate,
                eventId: this.eventUser.eventId,
                eventUserIdList: this.eventUserIdList,
                bizmTemplateId: this.bizmTemplateId,
                bizmButtons: this.bizmButtons,
                replacements: this.replacements,
                isSendToMe: false,
            }
            console.log('click send - send : ', payload)
            this.send(payload)
            this.resetValue()
            this.isOpen = false
        },
        clickSendToMe() {
            const payload = {
                userId: this.testUserId,
                type: this.type,
                title: this.smsTitle,
                content: this.smsTemplate,
                eventId: this.eventUser.eventId,
                eventUserIdList: [this.eventUserIdList[0]],
                bizmTemplateId: this.bizmTemplateId,
                bizmButtons: this.bizmButtons,
                replacements: this.replacements,
                isSendToMe: true,
            }
            this.send(payload)
            console.log('click send - send to me : ', payload)
        },
        checkSendTest() {
            this.isTestDone = true
        },
        async getTemplate(templateId: number) {
            const url = `/api/crm-templates/${templateId}`
            const response = await getApi(url)
            const result = response?.result || []
            this.smsTemplate = result.template
            this.smsTitle = result.title
            this.bizmTemplateId = result.bizmTemplateId
            this.bizmButtons = result.bizmButtons
            this.replacements = result.replacements
        },
        async send(payload: any) {
            const url = '/api/crm-templates/send'
            const response = await postApi(url, payload, true, '발송 실패!')
            const isSuccess = response?.success
            this.responseMessage = isSuccess ? '발송 성공' : '발송 실패'
        },
        refreshOptions() {
            this.crmSmsTemplateStore.fetchCrmSmsTemplates()
            this.crmBizmTemplateStore.fetchCrmBizmTemplates()
        },
        resetValue() {
            this.smsTemplate = null
            this.smsTitle = null
            this.bizmTemplateId = null
            this.templateId = null
            this.testUserId = null
            this.isTestDone = false
            this.responseMessage = ''
            this.eventUser = null
            this.eventUserList = []
        },
        updateButtons(index: number, key: string, e: any) {
            const button: any = this.bizmButtons[index]
            button[key] = e.target.value
        },
        update(index: number, key: string, e: any) {
            const replacement: any = this.replacements[index]
            replacement[key] = e.target.value
        },
    },
})
</script>
