import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import AutoCompleteInput from '@/components/input/AutoCompleteInput.vue'
import router from '@/router'



export default /*@__PURE__*/_defineComponent({
  __name: 'WorkoutInput',
  props: {
    value: {
        type: [Number, String],
    },
    label: {
        type: String,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '100%',
    },
    mode: {
        type: String,
        default: 'default',
    },
},
  emits: ['update:value', 'select'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const showFood = (id: number | undefined) => {
    router.push({
        path: `/userFeedback/food/edit/${id}?visible=true`,
    })
}

const change = (value: any) => {
    emit('update:value', value)
}

const select = (value: any) => {
    emit('select', value)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(AutoCompleteInput, {
      label: __props.label,
      uri: "/api/workout/name",
      width: __props.width,
      value: __props.value,
      "onUpdate:value": change,
      onSelect: select,
      mode: __props.mode,
      disabled: __props.disabled
    }, null, 8, ["label", "width", "value", "mode", "disabled"])
  ]))
}
}

})