<template>
    <span>
        <a-form-item :name="name" :label="label">
            <a-select
                :options="options"
                :value="value || []"
                @change="change"
                @select="(value: any) => emit('select', value)"
                @search="fetchIntakeUnit"
                :default-active-first-option="false"
                :not-found-content="null"
                :filter-option="false"
                :style="{ width: width }"
                :dropdown-match-select-width="500"
                show-search
            />
        </a-form-item>
    </span>
</template>

<script setup lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { debounce } from 'lodash'
import { ref, defineProps, defineEmits } from 'vue'

defineProps({
    value: {
        type: [String, Array],
        default: '',
    },
    width: {
        type: String,
        default: '100px',
    },
    name: {
        type: String,
    },
    label: {
        type: String,
    },
})

const options = ref<Array<any>>([])

const fetchIntakeUnit = debounce(async (name?: string) => {
    try {
        const response = await adminApi<ApiResponse<Array<string>>>(
            `/api/food/intake-unit/${name}`
        )
        options.value =
            response.result?.map((it) => ({ label: it, value: it })) || []
    } catch (error) {
        throw error as Error
    }
})

const emit = defineEmits(['update:value', 'select'])

const change = (value: any) => {
    emit('update:value', value)
}
</script>
