import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "영양소 섭취 그룹",
    uri: "/api/nutrient-allowance-group",
    columns: _ctx.columns,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "filter-spec": _ctx.filterSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    exportable: ""
  }, null, 8, ["columns", "create-spec", "update-spec", "filter-spec", "create-rules", "update-rules"]))
}