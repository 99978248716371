<template>
    <a-space direction="vertical">
        <a-radio-group v-model:value="request.model">
            <a-radio value="gpt-3.5-turbo">gpt-3</a-radio>
            <a-radio value="gpt-4">gpt-4</a-radio>
        </a-radio-group>
        <a-textarea :value="value" @change="onChange" :rows="8" />
        <a-row style="justify-content: end">
            <a-typography-text>
                {{ `token 수: ${tokenLength}` }}
            </a-typography-text>
        </a-row>
        <a-button @click="onRequest" style="float: right" :loading="loading">
            <template #icon>
                <form-outlined />
            </template>
        </a-button>
    </a-space>
</template>

<script setup lang="ts">
import { ApiResponse, adminApi, mlApi } from '@/fetchTemplate'
import { message } from 'ant-design-vue'
import { defineProps, ref, toRef, defineEmits } from 'vue'
import { FormOutlined } from '@ant-design/icons-vue'
import { ChatCompletionRequest, ChatMessage } from './inputComplete'
import { debounce } from 'lodash'

const props = defineProps({
    name: {
        type: String,
    },
    value: {
        type: String,
    },
    chatMessage: {
        type: Array,
    },
})

const emits = defineEmits(['update:value', 'change'])

const messages = toRef(props, 'chatMessage')

const request = ref<ChatCompletionRequest>({
    model: 'gpt-3.5-turbo',
    messages: [],
    topP: 1,
})

const loading = ref(false)
const tokenLength = ref(0)

const onRequest = async () => {
    try {
        loading.value = true
        if (messages.value != null && messages.value.length > 0) {
            const prompts = messages.value as ChatMessage[]
            request.value.messages = [
                ...prompts,
                { role: 'user', content: props.value || '' },
            ]
        }
        const response = await adminApi<ApiResponse<any>>(
            `/api/openai/chat-completion`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(request.value),
            }
        )
        emits('change', JSON.parse(response.result.choices[0].message.content))
    } catch (e) {
        const error = e as Error
        message.error(error.message)
    } finally {
        loading.value = false
    }
}

const onChange = (e: any) => {
    emits('update:value', e.target.value)
    onChangeMessage(e)
}

const onChangeMessage = debounce(async (event: any) => {
    if (event.target.value) {
        const response = await mlApi(`/open-ai/token-length`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text: event.target.value }),
        })
        tokenLength.value = response
    }
}, 500)
</script>
