<template>
    <ResourceTable
        title="광고 구좌"
        uri="/api/advertisements/slots"
        :create-spec="createSpec"
        :create-rules="createRules"
        :update-spec="updateSpec"
        :update-rules="updateRules"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
        load-on-mount
    >
    </ResourceTable>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import ResourceTable from '@/components/ResourceTable.vue'
import { useLayoutStore } from '@/store/advertisement'

export default defineComponent({
    name: 'AdSlot',
    components: {
        ResourceTable,
    },

    setup() {
        const layoutStore = useLayoutStore()

        const createSpec = {
            name: {
                label: '이름',
                value: null,
            },
            page: {
                label: '페이지',
                value: null,
            },
            position: {
                label: '위치',
                value: null,
            },
            supportedLayoutIds: {
                label: '지원하는 Layout',
                value: null,
                component: 'AdLayoutInput',
            },
        }

        const createRules = {
            page: [required],
            position: [required],
            name: [required],
            supportedLayoutIds: [required],
        }

        const columns = [
            {
                title: 'id',
                dataIndex: 'id',
                width: '32px',
                align: 'center',
            },
            {
                title: '이름',
                dataIndex: 'name',
                width: '256px',
            },
            {
                title: '페이지',
                dataIndex: 'page',
                width: '128px',
            },
            {
                title: '위치',
                dataIndex: 'position',
                width: '128px',
            },
            {
                title: '지원하는 Layout',
                dataIndex: 'supportedLayouts',
                type: 'ad-layout-supported',
            },
        ]

        return {
            layoutStore,
            columns: columns,
            createSpec: createSpec,
            createRules: createRules,
            updateSpec: createSpec,
            updateRules: createRules,
        }
    },
})
</script>
