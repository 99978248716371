<template>
    <ResourceTable
        title="섭취량"
        uri="/api/nutrient-allowance"
        :columns="columns"
        :scroll="{ x: 2500, y: 650 }"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :create-rules="rules"
        :update-rules="rules"
        exportable
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { useEnumTypeStore } from '@/store/enumType'
import { formatLocaleNumber } from '@/util/formmater'
import { required } from '@/util/input-validation'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NutrientAllowance',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            nutrientAllowanceGroupName: {
                label: '영양소 섭취그룹명',
                value: null,
                component: 'AutoCompleteInput',
                uri: '/api/nutrient-allowance-group/name',
            },
            rda: {
                label: '권장섭취량',
                value: null,
                component: 'AInputNumber',
                infinity: true,
            },
            odi: {
                label: '취적섭취량',
                value: null,
                component: 'AInputNumber',
                infinity: true,
            },
            ul: {
                label: '상한섭취량',
                value: null,
                component: 'AInputNumber',
                infinity: true,
            },
            higMin: {
                label: '최소섭취량',
                value: null,
                component: 'AInputNumber',
            },
            higMax: {
                label: '최대섭취량',
                value: null,
                component: 'AInputNumber',
                infinity: true,
            },
            higBroadName: {
                label: 'HIG Broad',
                component: 'EnumSelect',
                typeName: 'hig-broad-name',
                value: null,
            },
            higDetailName: {
                label: 'HIG Detail',
                component: 'EnumSelect',
                typeName: 'hig-detail-name',
                value: null,
            },
            higDescription: {
                label: '설명',
                value: null,
            },
            higDescriptionShort: {
                label: '설명 요약',
                value: null,
                type: 'textarea',
            },
            memo: {
                label: '관리자 메모',
                value: null,
                type: 'textarea',
            },
        }
        return {
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '필수',
                    dataIndex: 'isEssential',
                    sorter: true,
                    customRender: (value: boolean) => {
                        if (value) {
                            return '필수'
                        }
                        return '목적'
                    },
                    width: 100,
                },
                {
                    title: '영양소 섭취그룹명',
                    dataIndex: 'nutrientAllowanceGroupName',
                },
                {
                    title: 'rda',
                    dataIndex: 'rda',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'odi',
                    dataIndex: 'odi',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'ul',
                    dataIndex: 'ul',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'higMin',
                    dataIndex: 'higMin',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'higMax',
                    dataIndex: 'higMax',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: 'HIG Broad',
                    dataIndex: 'higBroadName',
                },
                {
                    title: 'HIG Detail',
                    dataIndex: 'higDetailName',
                },
                {
                    title: '설명',
                    dataIndex: 'higDescription',
                    width: 300,
                },
                {
                    title: '설명 요약',
                    dataIndex: 'higDescriptionShort',
                },
            ],

            filterSpec: {
                id: {
                    label: 'ID',
                    value: null,
                    component: 'AInputNumber',
                },
                nutrientAllowanceGroupName: {
                    label: '영양소 섭취그룹명',
                    value: null,
                    component: 'AutoCompleteInput',
                    uri: '/api/nutrient-allowance-group/name',
                    labelCol: 8,
                    width: '200px',
                },
                higBroadName: {
                    label: 'HIG Broad',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'hig-broad-name',
                    labelCol: 5,
                    wrapperCol: 5,
                },
                higDetailName: {
                    label: 'HIG Detail',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'hig-detail-name',
                    labelCol: 5,
                    wrapperCol: 5,
                },
            },
            createSpec,
            updateSpec: { ...createSpec },
            rules: {
                nutrientAllowanceGroupName: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['hig-broad-name', 'hig-detail-name'])
    },
})
</script>
