<script setup lang="ts">
import { ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'

const accessTokenInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    accessToken: {
        label: 'Access Token',
        value: null,
        copy: true,
    },
})

const secureUserIdInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    secureUserId: {
        label: 'Secure User ID',
        value: null,
        copy: true,
    },
})

const accessTokenRules = {
    userId: {
        required: true,
    },
}

const loading = ref(false)

const fetchAccessToken = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/access-token/${form.userId}`
    )
    accessTokenInputSpec.value.accessToken.value = response.result
    loading.value = false
}

const fetchSecureUserId = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/secure-user-id/${form.userId}`
    )
    secureUserIdInputSpec.value.secureUserId.value = response.result
    loading.value = false
}
</script>

<template>
    <div>
        <a-page-header title="통합 QA 도구" />
        <a-card title="Access Token 조회">
            <p-form
                title="Access Token 조회"
                :rules="accessTokenRules"
                :input-spec="accessTokenInputSpec"
                :loading="loading"
                @submit="fetchAccessToken"
            >
                <template #button>조회</template>
            </p-form>
        </a-card>
        <a-card title="Secure User id 조회">
            <p-form
                title="Secure User id 조회"
                :rules="accessTokenRules"
                :input-spec="secureUserIdInputSpec"
                :loading="loading"
                @submit="fetchSecureUserId"
            >
                <template #button>조회</template>
            </p-form>
        </a-card>
    </div>
</template>
