<template>
    <ResourceTable
        title="브랜드"
        uri="/api/brand"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1400, y: 650 }"
        :columns="columns"
        load-on-mount
    >
        <template #detail="{ resourceId, record }">
            <a-descriptions
                title="영양소 좋은점"
                :bordered="true"
                layout="vertical"
                :column="1"
                size="small"
            >
                <a-descriptions-item label="제목">
                    {{ record.title }}
                </a-descriptions-item>
                <a-descriptions-item label="요약">
                    {{ record.summary }}
                </a-descriptions-item>
                <a-descriptions-item label="등록일시">
                    {{ record.creationTime }}
                </a-descriptions-item>
                <a-descriptions-item label="썸네일">
                    <img
                        :key="resourceId"
                        :width="200"
                        :src="record.thumbnailUrl"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import { formatBoolean } from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'ProductBrand',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec: FormSpecification = {
            name: {
                label: '이름(한글)',
                value: null,
            },
            nameEn: {
                label: 'Name(영어)',
                value: null,
                help: '해외브랜드만 입력하면 됩니다',
            },
            imageUrl: {
                label: '브랜드 이미지 주소',
                value: null,
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            filterSpec: {
                nameEn: { label: '이름(영어)', value: null },
                name: {
                    label: '브랜드명',
                    value: null,
                    component: InputComponents.AutoCompleteInput,
                    uri: '/api/brand/name',
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                name: {
                    label: '이름(한글)',
                    value: null,
                    readonly: true,
                },
                id: {
                    label: 'ID',
                    value: null,
                    readonly: true,
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 50,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '이름(한글)',
                    width: 150,
                    dataIndex: 'name',
                },
                {
                    title: 'Name(영어)',
                    width: 150,
                    dataIndex: 'nameEn',
                },
                {
                    title: '브랜드 아이콘',
                    dataIndex: 'imageUrl',
                },
            ],
            rules: {
                name: [required],
                categoryName: [required],
                goodReason: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'column-type',
            'hig-broad-name',
            'column-category',
        ])
    },
})
</script>
