<template>
    <div>
        <h2>
            필라이즈 어드민 입니다.<br />
            API: {{ `${apiServerAddress}` }} 입니다.
        </h2>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'HomeView',

    computed: {
        apiServerAddress(): string {
            return process.env.VUE_APP_BASEURL
        },
    },
})
</script>
