import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_auto_complete = _resolveComponent("a-auto-complete")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.mode)
      ? (_openBlock(), _createBlock(_component_a_form_item, {
          key: 0,
          name: _ctx.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              options: _ctx.options,
              value: _ctx.valueByMode,
              mode: _ctx.mode,
              onChange: _ctx.change,
              onSelect: _ctx.select,
              onSearch: _ctx.fetchNutrient,
              "default-active-first-option": false,
              "not-found-content": null,
              "filter-option": false,
              style: _normalizeStyle(`width: ${_ctx.width}`),
              "show-search": "",
              disabled: _ctx.disabled
            }, null, 8, ["options", "value", "mode", "onChange", "onSelect", "onSearch", "style", "disabled"])
          ]),
          _: 1
        }, 8, ["name"]))
      : (_openBlock(), _createBlock(_component_a_form_item, {
          key: 1,
          name: _ctx.name
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_auto_complete, {
              "data-source": _ctx.options,
              value: _ctx.value,
              onChange: _ctx.change,
              onSelect: _ctx.select,
              onSearch: _ctx.fetchNutrient,
              style: _normalizeStyle(`width: ${_ctx.width}`),
              disabled: _ctx.disabled,
              "dropdown-match-select-width": 700
            }, null, 8, ["data-source", "value", "onChange", "onSelect", "onSearch", "style", "disabled"])
          ]),
          _: 1
        }, 8, ["name"]))
  ]))
}