<template>
    <ResourceTable
        title="영양소"
        uri="/api/nutrient"
        :columns="columns"
        :filter-spec="filterSpec"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :scroll="{ x: 3400, y: 650 }"
        :create-rules="rules"
        :update-rules="rules"
        exportable
    >
        <template #updateModalHeader="{ resourceId }">
            <NestedResourceTable
                title="관련 섭취량표"
                uri="/api/nutrient-allowance"
                :columns="nestedTableColumns"
                :params="{ nutrientId: resourceId }"
                :scroll="{ x: 1500, y: 300 }"
            />
        </template>
    </ResourceTable>
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import NestedResourceTable from '@/components/NestedResourceTable.vue'
import { recommendTimeCase, recommendTimeWithMealCase } from '@/constants'
import { formatKoreanNumber, formatLocaleNumber } from '@/util/formmater'
import { required } from '@/util/input-validation'
import { defineComponent } from 'vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'NutrientView',
    components: {
        ResourceTable,
        NestedResourceTable,
    },
    data() {
        const createSpec = {
            category: {
                label: '카테고리',
                value: null,
                component: 'EnumSelect',
                typeName: 'nutrient-category-name',
            },
            type: {
                label: '종류',
                value: null,
                component: 'EnumSelect',
                typeName: 'nutrient-type-name',
                allowEmptyString: true,
            },
            name: {
                label: '이름',
                value: null,
                help: 'Key값, 식약처 이름 최대한 살리고, 구분 명확하게',
            },
            displayName: {
                label: 'display 명',
                value: null,
                help: '제품 영양성분표에 보여지는 이름, 상세이름(모체이름)',
            },
            displayNameEn: { label: '영문 display 명', value: null },
            groupName: {
                label: '그룹명',
                value: null,
                component: 'EnumSelect',
                typeName: 'nutrient-group-name',
                help: '검색에 사용되는 이름',
            },
            parentName: {
                label: '상위 영양소',
                value: null,
                component: 'NutrientInput',
            },
            allowanceGroupName: {
                label: '섭취 그룹명',
                value: null,
                component: 'AutoCompleteInput',
                uri: '/api/nutrient-allowance-group/name',
            },
            transmutationConstant: {
                label: '변환상수',
                value: null,
                component: 'AInputNumber',
            },
            synonyms: {
                label: '동의어',
                value: [],
                component: 'ASelect',
                mode: 'tags',
                help: '제품 정보를 매칭시키기 위한 동의어',
            },
            synonymsEn: {
                label: '영문 동의어',
                value: [],
                component: 'ASelect',
                mode: 'tags',
            },
            unitCode: {
                label: '기본 단위',
                value: null,
                component: 'StandardUnitInput',
            },
            goodPointName: {
                label: '영양소의 좋은점',
                value: null,
                component: 'AutoCompleteInput',
                help: '영양소를 포함하는 제품들에 좋은점이 강조됩니다.',
                uri: '/api/nutrient-good-point/name',
            },
            coreFunctionDetailText: { label: '핵심기능 상세', value: null },
            coreFunctionSimpleText: { label: '핵심기능', value: null },
            excessDetailText: { label: '과도 상세', value: null },
            lackDetailText: { label: '부족 상세', value: null },
            lackSimpleText: { label: '부족', value: null },
            recommendedTime: {
                label: '추천 섭취시간',
                value: null,
                component: 'ASelect',
                options: recommendTimeCase,
            },
            recommendedTimeWithMeals: {
                label: '식전/식후',
                value: null,
                component: 'ASelect',
                options: recommendTimeWithMealCase,
            },
            recommendedTimeWithMealsExplain: {
                label: '추천 시간 설명',
                value: null,
            },
            comment: {
                label: '관리자메모',
                value: null,
                type: 'textarea',
            },
        }
        return {
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInputNumber' },
                categoryName: {
                    label: '카테고리',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'nutrient-category-name',
                },
                typeName: {
                    label: '종류',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'nutrient-type-name',
                },
                name: {
                    label: '이름',
                    value: null,
                    component: 'NutrientInput',
                },
                nutrientAllowanceGroupName: {
                    label: '영양소 섭취그룹명',
                    value: null,
                    component: 'AutoCompleteInput',
                    uri: '/api/nutrient-allowance-group/name',
                    width: '200px',
                    labelCol: 6,
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                relatedProductCount: {
                    label: '포함 제품수',
                    value: 0,
                    readonly: true,
                },
                searchTag: {
                    label: 'search tag',
                    value: null,
                    readonly: true,
                },
            },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '포함 제품수',
                    dataIndex: 'relatedProductCount',
                    customRender: formatLocaleNumber,
                    align: 'right',
                },
                { title: '카테고리', dataIndex: 'category', sorter: true },
                { title: '종류', dataIndex: 'type', sorter: true },
                { title: '이름', dataIndex: 'name', width: 200, sorter: true },
                { title: 'display명', dataIndex: 'displayName', width: 200 },
                {
                    title: '영문 display명',
                    dataIndex: 'displayNameEn',
                    width: 200,
                },
                { title: '그룹명', dataIndex: 'groupName' },
                { title: '상위 영양소', dataIndex: 'parentName' },
                {
                    title: '변환상수',
                    dataIndex: 'transmutationConstant',
                    sorter: true,
                },
                {
                    title: '동의어',
                    dataIndex: 'synonyms',
                    scopedSlots: { customRender: 'tags' },
                    width: 200,
                },
                {
                    title: '영문 동의어',
                    dataIndex: 'synonymsEn',
                    scopedSlots: { customRender: 'tags' },
                    width: 200,
                },
                { title: '단위', dataIndex: 'unitExpression' },
                {
                    title: '핵심기능 상세',
                    dataIndex: 'coreFunctionDetailText',
                    width: 300,
                },
                { title: '핵심기능', dataIndex: 'coreFunctionSimpleText' },
                {
                    title: '과도 상세',
                    dataIndex: 'excessDetailText',
                    width: 300,
                },
                { title: '부족 상세', dataIndex: 'lackDetailText', width: 300 },
                { title: '부족', dataIndex: 'lackSimpleText' },
                { title: '추천 섭취시간', dataIndex: 'recommendedTime' },
                { title: '식전/식후', dataIndex: 'recommendedTimeWithMeals' },
                {
                    title: '(그룹) RDA',
                    dataIndex: 'rda',
                    customRender: formatKoreanNumber,
                },
                {
                    title: '(그룹) PODI',
                    dataIndex: 'podi',
                    customRender: formatKoreanNumber,
                },
                {
                    title: '(그룹) UL',
                    dataIndex: 'ul',
                    customRender: formatKoreanNumber,
                },
            ],
            nestedTableColumns: [
                {
                    title: 'HIG Broad',
                    dataIndex: 'higBroadName',
                    width: 100,
                },
                {
                    title: 'HIG Detail',
                    dataIndex: 'higDetailName',
                    width: 100,
                },
                {
                    title: '필수',
                    dataIndex: 'isEssential',
                    customRender: (value: boolean) => {
                        if (value) {
                            return '필수'
                        }
                        return '부가'
                    },
                },
                {
                    title: '영양소 이름',
                    dataIndex: 'nutrientName',
                },
                {
                    title: 'rda',
                    dataIndex: 'rda',
                    customRender: formatLocaleNumber,
                    align: 'right',
                },
                {
                    title: 'odi',
                    dataIndex: 'odi',
                    customRender: formatLocaleNumber,
                    align: 'right',
                },
                {
                    title: 'ul',
                    dataIndex: 'ul',
                    customRender: formatLocaleNumber,
                    align: 'right',
                },
                {
                    title: 'higMin',
                    dataIndex: 'higMin',
                    customRender: formatLocaleNumber,
                    align: 'right',
                },
                {
                    title: 'higMax',
                    dataIndex: 'higMax',
                    customRender: formatLocaleNumber,
                    align: 'right',
                },
                {
                    title: '설명',
                    dataIndex: 'higDescription',
                    width: 300,
                },
                {
                    title: '설명 요약',
                    dataIndex: 'higDescriptionShort',
                    width: 200,
                },
            ],
            rules: {
                category: [required],
                name: [required],
                groupName: [required],
                unitCode: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'nutrient-category-name',
            'nutrient-type-name',
            'nutrient-group-name',
        ])
    },
})
</script>
