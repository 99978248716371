<template>
    <div>
        <span style="display: inline-block; white-space: nowrap; width: 90%">
            <a-select
                :mode="mode"
                :value="valueByMode"
                @change="(value: any) => $emit('update:value', value)"
                @select="select"
                @deselect="deselect"
                style="width: 100%"
                show-search
            >
                <a-select-option v-for="tag of tags" :key="tag.name">
                    {{ tag.name }}
                </a-select-option>
            </a-select>
            <a-button @click="refreshTags" style="margin-left: 5px"
                ><reload-outlined
            /></a-button>
        </span>
        <a-modal
            title="제품 tag 등록"
            v-model:visible="meta.createVisible"
            :footer="null"
        >
            <a-form
                :form="createForm"
                layout="horizontal"
                @submit.prevent="onCreate"
            >
                <a-form-item
                    label="태그 이름"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }"
                >
                    <a-input v-model:value.trim="createForm.name" />
                </a-form-item>
                <a-form-item>
                    <a-button
                        type="primary"
                        html-type="submit"
                        :loading="createForm.loading"
                        style="float: right"
                    >
                        등록
                    </a-button>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal
            title="제품 tag 수정"
            v-model:visible="meta.updateVisible"
            :footer="null"
        >
            <a-form :form="updateForm" @submit.prevent="onUpdate">
                <a-form-item
                    label="태그 이름"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ offset: 4 }"
                >
                    <EnumSelect
                        v-model:value.trim="updateForm.name"
                        type-name="product-tag"
                    />
                </a-form-item>
                <a-form-item
                    label="변경 이름"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ offset: 4 }"
                >
                    <a-input
                        v-model:value.trim="updateForm.targetName"
                        :formatter="trim"
                    />
                </a-form-item>
                <a-form-item>
                    <a-button
                        type="primary"
                        html-type="submit"
                        :loading="updateForm.loading"
                        style="float: right"
                    >
                        수정
                    </a-button>
                </a-form-item>
            </a-form>
        </a-modal>
        <a-space>
            <a-button @click="showCreateModal">등록</a-button>
            <a-button @click="showUpdateModal">수정</a-button>
        </a-space>
        <FailResultModal
            :title="meta.errorTitle"
            ref="failResultModal"
            :error-message="meta.errorMessage"
        />
    </div>
</template>

<script lang="ts">
import { adminApi, ApiResponse } from '@/fetchTemplate'
import FailResultModal from '@/components/modal/FailResultModal.vue'
import EnumSelect from '@/components/input/EnumSelect.vue'
import { ProductTag } from '@/store/tag'
import { defineComponent, ref } from 'vue'
import { ReloadOutlined } from '@ant-design/icons-vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'ProductTagInput',

    components: {
        FailResultModal,
        EnumSelect,
        ReloadOutlined,
    },

    setup() {
        const meta = ref({
            createVisible: false,
            updateVisible: false,
            errorMessage: '',
            errorTitle: '',
        })
        const createForm = ref({
            name: '',
            loading: false,
        })
        const updateForm = ref({
            name: '',
            targetName: '',
            loading: false,
        })
        const uri = ref('/api/product-tag')
        const failResultModal = ref()

        return { meta, createForm, updateForm, uri, failResultModal }
    },

    props: {
        value: {
            type: Array,
        },
        mode: {
            type: String,
        },
    },

    emits: ['update:value', 'select', 'deselect'],

    computed: {
        tags() {
            return this.$store.getters['productTag']
        },

        valueByMode() {
            if (this.mode === 'default') {
                return this.value || ''
            }
            return this.value || []
        },
    },

    methods: {
        select(tag: string) {
            this.$emit(
                'select',
                this.tags.filter((it: ProductTag) => it.name == tag)[0]
            )
        },

        deselect(tag: string) {
            this.$emit(
                'deselect',
                this.tags.filter((it: ProductTag) => it.name == tag)[0]
            )
        },

        refreshTags() {
            this.$store.dispatch('fetchTags')
        },

        showCreateModal() {
            this.meta.createVisible = !this.meta.createVisible
        },

        showUpdateModal() {
            this.meta.updateVisible = !this.meta.updateVisible
        },

        async onCreate() {
            this.createForm.loading = true
            const response = await adminApi<ApiResponse<void>>(
                `${this.uri}/name/${this.createForm.name}`,
                { method: 'POST' }
            )

            if (!response.success) {
                this.meta.errorTitle = '등록 실패'
                this.meta.errorMessage = response.errorMessage || ''
                this.failResultModal.open()
            }
            this.createForm.loading = false
            this.meta.createVisible = false
        },

        async onUpdate() {
            this.updateForm.loading = true
            const response = await adminApi<ApiResponse<void>>(
                `${this.uri}/name/${this.updateForm.name}`,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        targetName: this.updateForm.targetName,
                    }),
                }
            )

            if (!response.success) {
                this.meta.errorTitle = '수정 실패'
                this.meta.errorMessage = response.errorMessage || ''
                this.failResultModal.open()
            } else {
                const enumTypeStore = useEnumTypeStore()
                enumTypeStore.fetchEnum('product-tag')
            }

            this.updateForm.loading = false
            this.meta.updateVisible = false
        },
    },

    mounted() {
        this.$store.dispatch('fetchTags')
    },
})
</script>
