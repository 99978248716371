import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_row, { type: "flex" }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, { span: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_ResourceTable, {
              title: "Good effect 태그",
              uri: "/api/review-tag/good-effect-tag",
              columns: _ctx.goodEffectTagColumns,
              "create-spec": _ctx.goodEffectCreateSpec,
              "update-spec": _ctx.goodEffectUpdateSpec,
              "filter-spec": _ctx.goodEffectFilterSpec,
              scroll: { y: 650, x: 1000 },
              "count-of-filter-column": 2,
              "load-on-mount": ""
            }, null, 8, ["columns", "create-spec", "update-spec", "filter-spec"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: "12" }, {
          default: _withCtx(() => [
            _createVNode(_component_ResourceTable, {
              title: "Side effect 태그",
              uri: "/api/review-tag/side-effect-tag",
              columns: _ctx.sideEffectTagColumns,
              "create-spec": _ctx.sideEffectCreateSpec,
              "update-spec": _ctx.sideEffectUpdateSpec,
              "filter-spec": _ctx.sideEffectFilterSpec,
              scroll: { y: 650, x: 1000 },
              "count-of-filter-column": 2,
              "load-on-mount": ""
            }, null, 8, ["columns", "create-spec", "update-spec", "filter-spec"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}