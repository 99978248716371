<template>
    <div>
        <a-row type="flex">
            <a-col span="12">
                <ResourceTable
                    title="Good effect 태그"
                    uri="/api/review-tag/good-effect-tag"
                    :columns="goodEffectTagColumns"
                    :create-spec="goodEffectCreateSpec"
                    :update-spec="goodEffectUpdateSpec"
                    :filter-spec="goodEffectFilterSpec"
                    :scroll="{ y: 650, x: 1000 }"
                    :count-of-filter-column="2"
                    load-on-mount
                />
            </a-col>
            <a-col span="12">
                <ResourceTable
                    title="Side effect 태그"
                    uri="/api/review-tag/side-effect-tag"
                    :columns="sideEffectTagColumns"
                    :create-spec="sideEffectCreateSpec"
                    :update-spec="sideEffectUpdateSpec"
                    :filter-spec="sideEffectFilterSpec"
                    :scroll="{ y: 650, x: 1000 }"
                    :count-of-filter-column="2"
                    load-on-mount
                />
            </a-col>
        </a-row>
    </div>
</template>

<script lang="ts">
import TableSupport from '@/views/tableSupport'
import ResourceTable from '@/components/ResourceTable.vue'
import { formatBoolean, formatLocalDateTime } from '@/util/formmater'
import { defineComponent } from 'vue'
import { InputComponents, SelectInputMode } from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'ReviewTag',
    mixin: [TableSupport],
    components: {
        ResourceTable,
    },
    data() {
        const goodEffectCreateSpec = {
            productTag: {
                label: '제품 태그',
                value: null,
                component: InputComponents.ProductTagInput,
                mode: SelectInputMode.DEFAULT,
            },
            category: {
                label: '항목',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'good-effect-tag-category',
            },
            tag: { label: '태그', value: null },
            orderNo: {
                label: '순서번호',
                value: null,
                component: InputComponents.AInputNumber,
            },
            isPrimary: {
                label: '우선',
                value: null,
                component: InputComponents.BooleanInput,
            },
        }
        const sideEffectCreateSpec = {
            category: {
                label: '항목',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'side-effect-tag-category',
            },
            tag: {
                label: '태그',
                value: null,
            },
            orderNo: {
                label: '순서번호',
                value: null,
                component: InputComponents.AInputNumber,
            },
            isPrimary: {
                label: '우선',
                value: null,
                component: InputComponents.BooleanInput,
            },
        }
        return {
            goodEffectTagColumns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    sorter: true,
                    width: 100,
                    fixed: 'left',
                },
                {
                    title: '제품 태그',
                    dataIndex: 'productTag',
                },
                {
                    title: '항목',
                    dataIndex: 'category',
                },
                {
                    title: '태그',
                    dataIndex: 'tag',
                },
                {
                    title: '순서번호',
                    dataIndex: 'orderNo',
                    align: 'right',
                    sorter: true,
                    width: 100,
                },
                {
                    title: '우선',
                    dataIndex: 'isPrimary',
                    customRender: formatBoolean,
                    align: 'center',
                    width: 100,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    sorter: true,
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    sorter: true,
                },
            ],

            goodEffectFilterSpec: {
                productTag: {
                    label: '제품 태그',
                    value: null,
                    component: 'ProductTagInput',
                    mode: 'default',
                },
                category: {
                    label: '항목',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'good-effect-tag-category',
                },
                tag: { label: '태그', value: null },
                isPrimary: {
                    label: '우선',
                    value: null,
                    component: 'BooleanInput',
                },
            },
            goodEffectCreateSpec,

            goodEffectUpdateSpec: {
                ...goodEffectCreateSpec,
            },
            sideEffectTagColumns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    sorter: true,
                    width: 100,
                },
                {
                    title: '항목',
                    dataIndex: 'category',
                },
                {
                    title: '태그',
                    dataIndex: 'tag',
                },
                {
                    title: '순서번호',
                    dataIndex: 'orderNo',
                    align: 'right',
                    sorter: true,
                    width: 100,
                },
                {
                    title: '관련증상',
                    dataIndex: 'relatedSymptom',
                },
                {
                    title: '우선',
                    dataIndex: 'isPrimary',
                    customRender: formatBoolean,
                    align: 'center',
                    width: 100,
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    sorter: true,
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    align: 'center',
                    sorter: true,
                },
            ],

            sideEffectCreateSpec,
            sideEffectUpdateSpec: { ...sideEffectCreateSpec },
            sideEffectFilterSpec: {
                category: {
                    label: '항목',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'side-effect-tag-category',
                },
                tag: {
                    label: '태그',
                    value: null,
                },
                orderNo: {
                    label: '순서번호',
                    value: null,
                    component: 'AInputNumber',
                },
                isPrimary: {
                    label: '우선',
                    value: null,
                    component: 'BooleanInput',
                },
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums([
            'good-effect-tag-category',
            'side-effect-tag-category',
        ])
    },
})
</script>
