import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","white-space":"nowrap","width":"90%"} }
const _hoisted_2 = ["src"]

import { ApiResponse, adminApi } from '@/fetchTemplate'
import { ReloadOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { OptionProps } from 'ant-design-vue/es/select'
import { onMounted, ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionImageInput',
  props: {
    value: {
        type: String,
    },
    typeName: {
        type: String,
        required: true,
    },
    mode: {
        type: String,
        default: 'default',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    width: {
        type: String,
        default: '15em',
    },
    filter: {
        type: Function,
    },
    resourceId: {
        type: Number,
    },
},
  emits: ['input', 'update:value', 'select'],
  setup(__props, { emit: __emit }) {

const props = __props

const options = ref<Array<OptionProps>>([])
const questionImages = ref<Array<OptionProps>>([])

const emits = __emit

const onSelect = (value: any, option: Record<string, any>) => {
    emits('select', value, option)
}

const onChange = (value: any) => {
    emits('update:value', value)
}

const resetValue = () => {
    emits('update:value', null)
}

const refreshOptions = () => {
    fetchOptions()
}

const fetchOptions = async () => {
    if (props.resourceId != null) {
        const response = await adminApi<ApiResponse<Array<OptionProps>>>(
            `/api/v1/questions/image/${props.resourceId}`
        )
        options.value = response.result ?? []
        questionImages.value = response.result ?? []
    }
}

const onSearch = async (value: string) => {
    if (value) {
        const response = await adminApi<ApiResponse<Array<OptionProps>>>(
            `/api/pillyze-image-asset/name/${value}`
        )
        if (response.result) {
            console.log('result ', response.result)
            console.log('options.value ', options.value)
            options.value = [...response.result, ...questionImages.value]
        }
    }
}

onMounted(() => {
    fetchOptions()
})

return (_ctx: any,_cache: any) => {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_flex = _resolveComponent("a-flex")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_flex, {
      justify: "center",
      style: {"margin":"10px 0 10px 0"}
    }, {
      default: _withCtx(() => [
        (__props.value && !__props.value.endsWith('.json'))
          ? (_openBlock(), _createBlock(_component_a_image, {
              key: 0,
              src: __props.value,
              width: 128
            }, null, 8, ["src"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_a_select, {
        value: __props.value,
        options: options.value,
        disabled: __props.disabled,
        style: { width: '400px' },
        "show-search": "",
        "option-filter-prop": "label",
        onSelect: onSelect,
        onChange: onChange,
        onSearch: onSearch
      }, {
        option: _withCtx(({ label, image }) => [
          _createElementVNode("img", {
            src: image,
            width: 64
          }, null, 8, _hoisted_2),
          _createTextVNode("   " + _toDisplayString(label), 1)
        ]),
        _: 1
      }, 8, ["value", "options", "disabled"]),
      _createVNode(_component_a_button, {
        onClick: _withModifiers(refreshOptions, ["prevent"]),
        style: {"margin-left":"5px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(ReloadOutlined))
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, {
        onClick: _withModifiers(resetValue, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(MinusOutlined))
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})