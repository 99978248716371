import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
} from '@/components/InputMapper.vue'
import PForm from '@/components/PForm.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'


export default /*@__PURE__*/_defineComponent({
  __name: 'QaTool',
  setup(__props) {

const accessTokenInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    accessToken: {
        label: 'Access Token',
        value: null,
        copy: true,
    },
})

const secureUserIdInputSpec = ref<FormSpecification>({
    userId: {
        label: 'User ID',
        value: null,
        component: InputComponents.AInputNumber,
    },
    secureUserId: {
        label: 'Secure User ID',
        value: null,
        copy: true,
    },
})

const accessTokenRules = {
    userId: {
        required: true,
    },
}

const loading = ref(false)

const fetchAccessToken = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/access-token/${form.userId}`
    )
    accessTokenInputSpec.value.accessToken.value = response.result
    loading.value = false
}

const fetchSecureUserId = async (form: Record<string, any>) => {
    loading.value = true
    const response = await adminApi<ApiResponse<string>>(
        `/api/devtool/secure-user-id/${form.userId}`
    )
    secureUserIdInputSpec.value.secureUserId.value = response.result
    loading.value = false
}

return (_ctx: any,_cache: any) => {
  const _component_a_page_header = _resolveComponent("a-page-header")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_page_header, { title: "통합 QA 도구" }),
    _createVNode(_component_a_card, { title: "Access Token 조회" }, {
      default: _withCtx(() => [
        _createVNode(PForm, {
          title: "Access Token 조회",
          rules: accessTokenRules,
          "input-spec": accessTokenInputSpec.value,
          loading: loading.value,
          onSubmit: fetchAccessToken
        }, {
          button: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("조회")
          ])),
          _: 1
        }, 8, ["input-spec", "loading"])
      ]),
      _: 1
    }),
    _createVNode(_component_a_card, { title: "Secure User id 조회" }, {
      default: _withCtx(() => [
        _createVNode(PForm, {
          title: "Secure User id 조회",
          rules: accessTokenRules,
          "input-spec": secureUserIdInputSpec.value,
          loading: loading.value,
          onSubmit: fetchSecureUserId
        }, {
          button: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("조회")
          ])),
          _: 1
        }, 8, ["input-spec", "loading"])
      ]),
      _: 1
    })
  ]))
}
}

})