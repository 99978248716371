<template>
    <a-form ref="formRule" :model="form">
        <a-row>
            <a-descriptions :bordered="true" :column="1">
                <a-descriptions-item label="타입">
                    <enum-select
                        :value="form['requirement']?.type"
                        type-name="ad-requirement-type"
                        @select="changeFormType"
                        @update:value="resetValue"
                    />
                </a-descriptions-item>
                <a-descriptions-item label="칼럼 ID">
                    <a-input-number
                        :value="form['requirement']?.columnId"
                        @change="changeColumnId"
                    />
                </a-descriptions-item>
            </a-descriptions>
        </a-row>
    </a-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import globalFormState from '@/components/globalFormState'
import EnumSelect from '@/components/input/EnumSelect.vue'

export default defineComponent({
    name: 'AdRequirementInput',

    components: {
        EnumSelect,
    },

    setup() {
        return {
            form: globalFormState.form,
        }
    },

    methods: {
        changeColumnId(value: any) {
            this.form.requirement.columnId = value
        },
        changeFormType(value: any) {
            this.form.requirement.type = value
        },
        resetValue() {
            this.form.requirement.type = null
            this.form.requirement.columnId = null
        },
    },
})
</script>
