import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { style: {"float":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_fullscreen_outlined = _resolveComponent("fullscreen-outlined")!
  const _component_fullscreen_exit_outlined = _resolveComponent("fullscreen-exit-outlined")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_InputMapper = _resolveComponent("InputMapper")!
  const _component_a_affix = _resolveComponent("a-affix")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_FailResultModal = _resolveComponent("FailResultModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showButton)
      ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          class: "modal__button",
          onClick: _ctx.showModal,
          disabled: _ctx.disabled
        }, {
          icon: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon"),
            _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
          ]),
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default"),
            _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1))
          ]),
          _: 3
        }, 8, ["onClick", "disabled"]))
      : _renderSlot(_ctx.$slots, "icon", {
          key: 1,
          showModal: _ctx.showModal,
          disabled: _ctx.disabled
        }),
    _createVNode(_component_a_modal, {
      "wrap-class-name": (_ctx.expand && 'full-modal') || 'basic-modal',
      title: _ctx.title,
      visible: _ctx.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      footer: null,
      width: (_ctx.expand && '100%') || '80em',
      onCancel: _ctx.resetForm
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, [
          (!_ctx.expand)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                onClick: _ctx.toggleExpand
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_fullscreen_outlined)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : (_ctx.expand)
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  onClick: _ctx.toggleExpand
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_fullscreen_exit_outlined)
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "header", {
          record: _ctx.record,
          resourceIds: _ctx.resourceIds,
          resourceId: _ctx.resourceId,
          form: _ctx.form
        }),
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_a_skeleton, { key: 0 }))
          : (_openBlock(), _createBlock(_component_a_form, {
              key: 1,
              ref: "formRef",
              model: _ctx.form,
              rules: _ctx.rules
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formKeys, (key, index) => {
                  return (_openBlock(), _createBlock(_component_InputMapper, {
                    key: index,
                    "input-spec": _ctx.formSpec[key],
                    form: _ctx.form,
                    prop: key,
                    "resource-id": _ctx.resourceId
                  }, null, 8, ["input-spec", "form", "prop", "resource-id"]))
                }), 128)),
                _createVNode(_component_a_form_item, { class: "footer__row" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_affix, {
                      "offset-bottom": 100,
                      style: {"margin-right":"50px"}
                    }, {
                      default: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "beforeButton", {
                          record: _ctx.record,
                          resourceId: _ctx.resourceId,
                          form: _ctx.form
                        }),
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: _withModifiers(_ctx.onSubmit, ["prevent"]),
                          "html-type": "submit",
                          loading: _ctx.loading
                        }, {
                          default: _withCtx(() => [
                            _renderSlot(_ctx.$slots, "button")
                          ]),
                          _: 3
                        }, 8, ["onClick", "loading"])
                      ]),
                      _: 3
                    })
                  ]),
                  _: 3
                })
              ]),
              _: 3
            }, 8, ["model", "rules"])),
        _renderSlot(_ctx.$slots, "footer", {
          record: _ctx.record,
          resourceIds: _ctx.resourceIds,
          resourceId: _ctx.resourceId
        })
      ]),
      _: 3
    }, 8, ["wrap-class-name", "title", "visible", "width", "onCancel"]),
    _createVNode(_component_FailResultModal, {
      ref: "failResultModal",
      title: `${_ctx.title} 실패`,
      "error-message": _ctx.errorMessage
    }, null, 8, ["title", "error-message"])
  ], 64))
}