import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "신규기능 알림",
    uri: "/api/admin/release-notification",
    "filter-spec": _ctx.filterSpec,
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "create-rules": _ctx.rules,
    "update-rules": _ctx.rules,
    columns: _ctx.columns,
    scroll: { x: 2000, y: 650 },
    "load-on-mount": ""
  }, null, 8, ["filter-spec", "create-spec", "update-spec", "create-rules", "update-rules", "columns"]))
}