import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_FailResultModal = _resolveComponent("FailResultModal")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, { onClick: _ctx.showModal }, {
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createTextVNode(" 상세 ")),
      _createVNode(_component_a_modal, {
        title: _ctx.title,
        visible: _ctx.visible,
        "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
        footer: null,
        width: "800px"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "header", {
            resourceId: _ctx.resourceId,
            record: _ctx.record,
            fetchResource: _ctx.fetchResource
          }),
          _renderSlot(_ctx.$slots, "body", {
            resourceId: _ctx.resourceId,
            record: _ctx.record,
            fetchResource: _ctx.fetchResource
          }),
          _renderSlot(_ctx.$slots, "footer", {
            resourceId: _ctx.resourceId,
            record: _ctx.record,
            fetchResource: _ctx.fetchResource
          })
        ]),
        _: 3
      }, 8, ["title", "visible"]),
      _createVNode(_component_FailResultModal, {
        ref: "failResultModal",
        "error-message": _ctx.errorMessage
      }, null, 8, ["error-message"])
    ]),
    _: 3
  }, 8, ["onClick"]))
}