<template>
    <ResourceTable
        title="영양소 섭취 그룹"
        uri="/api/nutrient-allowance-group"
        :columns="columns"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        exportable
    />
</template>

<script lang="ts">
import ResourceTable from '@/components/ResourceTable.vue'
import { formatLocalDateTime } from '@/util/formmater'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import { InputComponents, SelectInputMode } from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    name: 'NutrientAllowanceGroup',
    components: {
        ResourceTable,
    },
    data() {
        const rules = {
            name: [required],
            displayName: [required],
            generalMin: [required],
            generalMax: [required],
            groupName: [required],
            unitCode: [required],
            needsWarningsForSensitiveGroup: [required],
        }

        const createSpec = {
            name: { label: '이름', value: null },
            displayName: { label: 'display 이름', value: null },
            generalMin: {
                label: '최소섭취량(RDA|HIG_MIN)',
                value: null,
                component: 'AInputNumber',
                labelCol: 6,
                infinity: true,
            },
            generalMax: {
                label: '최대섭취량(UL|HIG_MAX)',
                value: null,
                component: 'AInputNumber',
                labelCol: 6,
                infinity: true,
            },
            generalPodi: {
                label: '최적섭취량(PODI)',
                value: null,
                component: 'AInputNumber',
                infinity: true,
            },
            groupName: {
                label: '영양소 그룹명',
                value: null,
                component: 'EnumSelect',
                typeName: 'nutrient-group-name',
            },
            parentName: {
                label: '부모 영양소 섭취량 그룹명',
                value: null,
                component: 'EnumSelect',
                typeName: 'nutrient-allowance-group-name',
            },
            unitCode: {
                label: '단위',
                value: null,
                component: 'StandardUnitInput',
            },
            isEssentialPack: {
                label: '필수팩 여부',
                value: null,
                component: 'BooleanInput',
            },
            family: {
                label: '패밀리',
                value: null,
                component: 'EnumSelect',
                typeName: 'nutrient-family',
            },
            needsWarningsForSensitiveGroup: {
                label: '민감군(임신) 경고',
                value: true,
                component: 'BooleanInput',
                help: '임신, 수유 관련 사용자에게 경고. 만약 임신부에 안전한 영양소면 False 선택',
            },
        }
        return {
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                    },
                    sorter: true,
                },
                {
                    title: '이름',
                    dataIndex: 'name',
                },
                {
                    title: 'display 이름',
                    dataIndex: 'displayName',
                },
                {
                    title: '최소섭취량(RDA|HIG_MIN)',
                    dataIndex: 'generalMin',
                },
                {
                    title: '최대섭취량(UL|HIG_MAX)',
                    dataIndex: 'generalMax',
                },
                {
                    title: '최적섭취량(PODI)',
                    dataIndex: 'generalPodi',
                },
                {
                    title: '영양소 그룹명',
                    dataIndex: 'groupName',
                },
                {
                    title: '부모 섭취량그룹',
                    dataIndex: 'parentName',
                    sorter: true,
                },
                {
                    title: '단위',
                    dataIndex: 'unitName',
                },
                {
                    title: '등록일시',
                    dataIndex: 'creationTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                    align: 'center',
                },
                {
                    title: '수정일시',
                    dataIndex: 'updateTime',
                    customRender: formatLocalDateTime,
                    width: 165,
                    sorter: true,
                    align: 'center',
                },
            ],
            filterSpec: {
                id: { label: 'ID', value: null, component: 'AInputNumber' },
                name: {
                    label: '영양소 섭취그룹',
                    value: null,
                    component: 'AutoCompleteInput',
                    uri: '/api/nutrient-allowance-group/name',
                    labelCol: 7,
                    wrapperCol: 4,
                },
                nutrientGroupGroupName: {
                    label: '영양소 그룹명',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'nutrient-group-name',
                    labelCol: 6,
                    wrapperCol: 4,
                },
                isEssentialPack: {
                    label: '필수팩 여부',
                    value: null,
                    component: 'BooleanInput',
                    labelCol: 5,
                    wrapperCol: 4,
                },
                family: {
                    label: '패밀리',
                    value: null,
                    component: 'EnumSelect',
                    typeName: 'nutrient-family',
                },
            },
            createSpec,
            updateSpec: {
                ...createSpec,
                nutrientName: {
                    label: '관련 영양소',
                    component: InputComponents.NutrientInput,
                    value: [],
                    mode: SelectInputMode.MULTIPLE,
                    readonly: true,
                },
            },
            rules,
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['nutrient-family', 'nutrient-group-name'])
    },
})
</script>
