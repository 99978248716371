import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { style: {"display":"inline-block","white-space":"nowrap","width":"90%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_EnumSelect = _resolveComponent("EnumSelect")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_FailResultModal = _resolveComponent("FailResultModal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_a_select, {
        mode: _ctx.mode,
        value: _ctx.valueByMode,
        onChange: _cache[0] || (_cache[0] = (value) => _ctx.$emit('update:value', value)),
        onSelect: _ctx.select,
        onDeselect: _ctx.deselect,
        style: {"width":"100%"},
        "show-search": ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createBlock(_component_a_select_option, {
              key: tag.name
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tag.name), 1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["mode", "value", "onSelect", "onDeselect"]),
      _createVNode(_component_a_button, {
        onClick: _ctx.refreshTags,
        style: {"margin-left":"5px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_reload_outlined)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createVNode(_component_a_modal, {
      title: "제품 tag 등록",
      visible: _ctx.meta.createVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.meta.createVisible) = $event)),
      footer: null
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          form: _ctx.createForm,
          layout: "horizontal",
          onSubmit: _withModifiers(_ctx.onCreate, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "태그 이름",
              "label-col": { span: 4 },
              "wrapper-col": { span: 20 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.createForm.name,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.createForm.name) = $event)),
                  valueModifiers: { trim: true }
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit",
                  loading: _ctx.createForm.loading,
                  style: {"float":"right"}
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" 등록 ")
                  ])),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["form", "onSubmit"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      title: "제품 tag 수정",
      visible: _ctx.meta.updateVisible,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.meta.updateVisible) = $event)),
      footer: null
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          form: _ctx.updateForm,
          onSubmit: _withModifiers(_ctx.onUpdate, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "태그 이름",
              "label-col": { span: 4 },
              "wrapper-col": { offset: 4 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EnumSelect, {
                  value: _ctx.updateForm.name,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.updateForm.name) = $event)),
                  valueModifiers: { trim: true },
                  "type-name": "product-tag"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, {
              label: "변경 이름",
              "label-col": { span: 4 },
              "wrapper-col": { offset: 4 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _ctx.updateForm.targetName,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.updateForm.targetName) = $event)),
                  valueModifiers: { trim: true },
                  formatter: _ctx.trim
                }, null, 8, ["value", "formatter"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  "html-type": "submit",
                  loading: _ctx.updateForm.loading,
                  style: {"float":"right"}
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" 수정 ")
                  ])),
                  _: 1
                }, 8, ["loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["form", "onSubmit"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_space, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_button, { onClick: _ctx.showCreateModal }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("등록")
          ])),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, { onClick: _ctx.showUpdateModal }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("수정")
          ])),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_FailResultModal, {
      title: _ctx.meta.errorTitle,
      ref: "failResultModal",
      "error-message": _ctx.meta.errorMessage
    }, null, 8, ["title", "error-message"])
  ]))
}