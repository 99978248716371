import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, unref as _unref, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"float":"right"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  style: {"margin-right":"10px"}
}
const _hoisted_4 = { style: {"float":"right"} }
const _hoisted_5 = {
  key: 0,
  style: {"margin-right":"10px"}
}
const _hoisted_6 = ["href"]
const _hoisted_7 = { style: {"float":"right"} }
const _hoisted_8 = { style: {"margin-right":"10px"} }

import { computed, onMounted, ref } from 'vue'
import {
    FormSpecification,
    InputComponents,
    InputType,
} from '@/components/InputMapper.vue'
import WeekSelectInput from '@/components/input/WeekSelectInput.vue'
import { adminApi, ApiResponse } from '@/fetchTemplate'
import { getDateList } from '@/util/date-util'
import NestedResourceTable from '@/components/NestedResourceTable.vue'
import { formatLocalDate, formatLocalDateTime } from '@/util/formmater'
import { differenceInDays, format, isBefore } from 'date-fns'
import { ko } from 'date-fns/locale'
import { Button, message } from 'ant-design-vue'
import {
    EyeInvisibleOutlined,
    EyeOutlined,
    LinkOutlined,
} from '@ant-design/icons-vue'
import globalFormState from '@/components/globalFormState'
import { required } from '@/util/input-validation'
import { stringify } from 'qs'
import { debounce } from 'lodash'
import Draggable from 'vuedraggable'
import Tiptap from '@/components/input/Tiptap.vue'
import { useFormeWeekStore } from '@/store/formeWeek'
import { useEnumTypeStore } from '@/store/enumType'


export default /*@__PURE__*/_defineComponent({
  __name: 'UserDietProgramEdit',
  props: {
    userId: {
        type: String,
        required: true,
    },
},
  setup(__props) {

const props = __props

const loading = ref(false)
const findFactorFail = ref(false)

const table = ref<any>(null)

const hideWeeklyMission = async (id: string) => {
    loading.value = true
    await adminApi(`/api/forme/diet/mission/hide/${props.userId}/${id}`, {
        method: 'POST',
    })
    await fetchTable()
    loading.value = false
}

//const { selectedWeek, getWeekList } = useFormeWeek
const { state, getWeekList } = useFormeWeekStore()
const { selectedWeek } = state

const columns = computed(() => {
    const dateTileList =
        selectedWeek != null && selectedWeek != null
            ? getDateList(selectedWeek?.startDate, selectedWeek?.endDate).map(
                  (it, index) => ({
                      title: format(it, 'M.d(EEE)', { locale: ko }),
                      dataIndex: `day${index + 1}`,
                      customRender: (column: any) => {
                          if (column.value) {
                              return '✅'
                          } else {
                              return ''
                          }
                      },
                  })
              )
            : []
    return [
        {
            title: 'ID',
            dataIndex: 'userMissionId',
            width: 80,
            fixed: 'left',
        },
        {
            title: '타입',
            dataIndex: 'type',
        },
        {
            title: '종류',
            dataIndex: 'category',
        },
        {
            title: '미션',
            dataIndex: 'name',
            customRender: ({ value, record }) => {
                if (value.includes('{변수}')) {
                    return value.replace('{변수}', record.value)
                }
                return value
            },
            customCell: (record: Record<any, any>) => {
                if (record.feedbackScore === 1) {
                    return {
                        style: {
                            backgroundColor: '#FFF9DE',
                        },
                    }
                } else if (record.feedbackScore === 5) {
                    return {
                        style: {
                            backgroundColor: '#FFEFF2',
                        },
                    }
                }
            },
        },
        {
            title: '가중치 점수',
            dataIndex: 'weight',
        },
        ...dateTileList,
        {
            title: '달성률',
            dataIndex: 'completeRatio',
            align: 'center',
            customRender: ({ value, record }: any) => {
                return `${value}%`
            },
            customCell: (record: any) => {
                const ratio = record.completeRatio
                if (ratio >= 85) {
                    return {
                        style: {
                            backgroundColor: '#efffff',
                        },
                    }
                } else if (ratio >= 70 && ratio < 85) {
                    return {
                        style: {
                            backgroundColor: '#effff4',
                        },
                    }
                } else if (ratio >= 55 && ratio < 70) {
                    return {
                        style: {
                            backgroundColor: '#fff9de',
                        },
                    }
                }
                return {
                    style: {
                        backgroundColor: '#ffeff2',
                    },
                }
            },
        },
        {
            title: '수정 날짜',
            dataIndex: 'updatedAt',
            customRender: formatLocalDate,
        },
        {
            title: '상태',
            dataIndex: 'hidden',
            customRender: ({
                value,
                record,
            }: {
                value: any
                record: Record<any, any>
            }) => {
                return value ? (
                    <Button
                        style="background-color: #9ba1aa; color: white"
                        loading={loading.value}
                        size="middle"
                        onClick={async () =>
                            await hideWeeklyMission(record.userMissionId)
                        }
                    >
                        {loading.value == false && <EyeInvisibleOutlined />}
                    </Button>
                ) : (
                    <Button loading={loading.value} size="middle">
                        <EyeOutlined
                            onClick={async () =>
                                await hideWeeklyMission(
                                    record.userMissionId
                                ).then()
                            }
                        />
                    </Button>
                )
            },
            align: 'center',
        },
        {
            title: '수정',
            type: 'edit',
            align: 'center',
            fixed: 'right',
        },
    ]
})

const userDetail = ref<any>({})
const setUserDetail = async (userId: string) => {
    const response = await adminApi<ApiResponse<any>>(
        `/api/forme/diet/user/${userId}?weekNumber=${
            selectedWeek.weekNumber || 1
        }`
    )
    userDetail.value = response.result || {}
}

onMounted(async () => {
    await getWeekList(props.userId)
    await setUserDetail(props.userId)
    getWeeklyMessage(props.userId, selectedWeek?.weekNumber || 1)
    const enumTypeStore = useEnumTypeStore()
    enumTypeStore.dispatchEnums(['forme-category'])
})

const { setFormValues, form, fetchFormSpec } = globalFormState

const rules = {
    priority: [required],
    category: [required],
    type: [required],
    startDate: [required],
    endDate: [required],
    weight: [required],
    name: [required],
}

const selectMissionId = (value: any) => {
    let name = value.name
    if (value.name != null && value.name.includes('{변수}')) {
        name = value.name.replace('{변수}', value.variable)
    }
    setFormValues({
        category: value.category,
        type: value.type,
        name: name,
        nameTemplate: value.name,
        value: value.variable,
    })
}

const createSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
        readonly: true,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀)',
        value: '커스텀',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름(치환할 값은 {변수}로)',
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
        input: (event: any) => {
            if (form.value?.nameTemplate?.includes('{변수}')) {
                setFormValues({
                    name: form.value.nameTemplate?.replace(
                        '{변수}',
                        event.target.value
                    ),
                })
            }
        },
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    guide: {
        label: '코칭 가이드',
        component: InputComponents.Tiptap,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const updateSpec = ref<FormSpecification>({
    missionId: {
        label: '미션 ID',
        component: InputComponents.FormeMissionInput,
        select: selectMissionId,
        disabled: true,
    },
    userMissionId: {
        label: '유저 미션 ID',
        readonly: true,
    },
    category: {
        label: '카테고리(혈당/식단/수면 등)',
        component: InputComponents.EnumSelect,
        typeName: 'forme-category',
    },
    type: {
        label: '미션 타입(AI타입/커스텀(?))',
    },
    priority: {
        label: '우선순위',
        component: InputComponents.AInputNumber,
    },
    name: {
        label: '미션 이름',
    },
    nameTemplate: {
        hidden: true,
    },
    value: {
        label: '변수값',
        input: (event: any) => {
            if (form.value?.nameTemplate?.includes('{변수}')) {
                setFormValues({
                    name: form.value.nameTemplate?.replace(
                        '{변수}',
                        event.target.value
                    ),
                })
            }
        },
    },
    weight: {
        label: '가중치 점수',
        component: InputComponents.AInputNumber,
        disabled: true,
    },
    startDate: {
        label: '미션 시작',
        component: InputComponents.ADatePicker,
        input: (value: any) => {
            if (value && form.value.endDate) {
                const diff = differenceInDays(form.value.endDate, value)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    endDate: {
        label: '미션 종료',
        component: InputComponents.ADatePicker,
        input: (value) => {
            if (value && form.value.startDate) {
                const diff = differenceInDays(value, form.value.startDate)
                setFormValues({
                    duration: diff,
                })
            }
        },
    },
    duration: {
        label: '미션 기간',
        component: InputComponents.AInputNumber,
    },
    weekNumber: {
        label: '주차',
        component: InputComponents.WeekSelectInput,
        userId: props.userId,
        input: (value: any, resourceId: any) => {
            fetchFormSpec(
                `/api/forme/diet/mission/${props.userId}/${resourceId}?weekNumber=${value}`
            )
        },
    },
    guide: {
        label: '코칭 가이드',
        component: InputComponents.Tiptap,
        disabled: () => isBefore(new Date(), selectedWeek?.startDate),
    },
    feedbackScore: {
        hidden: true,
    },
    feedback: {
        label: '유저 피드백',
        readonly: true,
        type: InputType.PILLZ,
    },
    hide: {
        label: '숨김 여부',
        component: InputComponents.BooleanInput,
    },
})

const filterParams = ref<Record<string, any>>({
    weekNumber: 1,
})

const writeMessage = async (
    userId: string,
    formeMessageType: string,
    message: string,
    targetDate: Date,
    weekNumber: number,
    formeUserMissionId?: number
) => {
    loading.value = true
    await adminApi(`/api/forme/diet/message`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userId: userId,
            formeUserMissionId,
            formeMessageType,
            message,
            targetDate,
            weekNumber,
        }),
    })
    await setUserDetail(props.userId)
    loading.value = false
}

const writeWmNote = debounce(async (userId: string, wmNote: string) => {
    await adminApi(`/api/forme/diet/wm/note/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            note: wmNote,
        }),
    })
    message.success('WM 메모가 성공적으로 수정되었습니다.')
}, 500)

const onWriteWmNote = (value: string) => {
    userDetail.value.wmMemo = value
    writeWmNote(props.userId, value)
}

const writeNotionLink = debounce(async (userId: string, notionLink: string) => {
    await adminApi(`/api/forme/diet/wm/notion/${userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            notion: notionLink,
        }),
    })
    message.success('노션 링크가 성공적으로 수정되었습니다.')
}, 500)

const onWriteNotionLink = (value: string) => {
    userDetail.value.notionLink = value
    writeNotionLink(props.userId, value)
}

const weeklyMessage = ref('')

const getWeeklyMessage = async (userId: string, weekNumber: number) => {
    const params = {
        weekNumber: weekNumber,
        formeMessageType: 'WEEKLY',
    }
    const response = await adminApi<ApiResponse<string>>(
        `/api/forme/diet/message/${userId}?${stringify(params)}`
    )
    weeklyMessage.value = response.result || ''
}

const fetchData = async () => {
    await setUserDetail(props.userId)
    table.value.fetchData()
    getWeeklyMessage(props.userId, selectedWeek?.weekNumber || 1)
}

const onUpdateCategory = async (value: any) => {
    filterParams.value.category = value
    fetchTable()
}

const fetchTable = async () => {
    table.value.fetchData()
}

const reoderDietFactorTypeList = async (dietFactorTypeList: any[]) => {
    await adminApi(`/api/forme/diet/reorder/find-factor/${props.userId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dietFactorTypeList),
    })
}

const onReorderDietFactorTypeList = async () => {
    await reoderDietFactorTypeList(
        userDetail.value.dietFactorTypeList.map((it: any) => it.dietFactorType)
    )
}

const completeMission = async () => {
    loading.value = true
    await adminApi(
        `/api/forme/diet/mission/complete/${props.userId}?weekNumber=${selectedWeek.weekNumber}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    loading.value = false
}

const createMissionByDietFactor = async () => {
    loading.value = true
    try {
        await adminApi(
            `/api/forme/diet/mission-by-diet-factor/${props.userId}`,
            {
                method: 'POST',
            }
        )
        await setUserDetail(props.userId)
    } catch (e) {
        console.error(e)
        findFactorFail.value = true
    } finally {
        loading.value = false
    }
}

const completeFindFactor = async () => {
    loading.value = true
    await adminApi(`/api/forme/diet/complete/find-factor/${props.userId}`, {
        method: 'POST',
    })
    setUserDetail(props.userId)
    loading.value = false
}

const isFuture = computed(() =>
    isBefore(new Date(), selectedWeek?.startDate || new Date())
)

const collapseKey = ref<Array<number>>([])

return (_ctx: any,_cache: any) => {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_flex = _resolveComponent("a-flex")!

  return (_openBlock(), _createBlock(_component_a_flex, { vertical: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_collapse, {
        "active-key": collapseKey.value,
        "onUpdate:activeKey": _cache[1] || (_cache[1] = ($event: any) => ((collapseKey).value = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_collapse_panel, {
            key: "1",
            header: "사용자 정보"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_card, null, {
                title: _withCtx(() => [
                  _createVNode(_component_a_typography_title, {
                    level: 4,
                    style: {"display":"inline","margin-right":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(userDetail.value.userName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_typography_text, { type: "secondary" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(userDetail.value.age) + "대 / " + _toDisplayString(userDetail.value.gender == 'MALE' ? '남' : '여') + " / " + _toDisplayString(userDetail.value.pregnant ? '임신' : '') + " ID " + _toDisplayString(userDetail.value.userId), 1)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_a_descriptions, { column: 4 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_descriptions_item, { label: "키" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.height), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "시작 체중" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.startWeight) + "kg (BMI " + _toDisplayString(Math.round(userDetail.value.startBmi) || 0) + ") ", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "현재 체중" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.weight) + "kg (BMI " + _toDisplayString(Math.round(userDetail.value.bmi) || 0) + ") ", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "목표 체중" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.targetWeight) + "kg (BMI " + _toDisplayString(Math.round(userDetail.value.targetBmi) || 0) + ") ", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "건강고민" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.higList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "건강 검진" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.mcuList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "복용약물" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.drugList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "알러지" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.allergyList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "기저질환" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.diseaseList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "가족력" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.familyDiseaseList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "체형 고민" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.bodyShapeList?.join(', ')), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_descriptions_item, { label: "비만 가족력" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(userDetail.value.familyObesityList?.join(', ')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form, {
                    "label-col": { span: 2 },
                    "label-align": 'left'
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, { label: "범인 타입" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_space, { wrap: "" }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(Draggable), {
                                modelValue: userDetail.value.dietFactorTypeList,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((userDetail.value.dietFactorTypeList) = $event)),
                                onChange: onReorderDietFactorTypeList,
                                "item-key": "dietFactorType"
                              }, {
                                item: _withCtx(({ element }) => [
                                  (_openBlock(), _createBlock(_component_a_tag, {
                                    key: element.dietFactorType
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_typography_text, { strong: "" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(element.category + ' '), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_a_typography_text, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(element.reason), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024))
                                ]),
                                _: 1
                              }, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_space, {
                            style: {"float":"right"},
                            direction: "vertical"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_1, [
                                (findFactorFail.value)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, " 범인 찾기 실패 "))
                                  : _createCommentVNode("", true),
                                (userDetail.value.findFactorAt)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, " 범인찾기 날짜: " + _toDisplayString(userDetail.value.findFactorAt != null
                                                ? _unref(formatLocalDateTime)(
                                                      userDetail.value.findFactorAt
                                                  )
                                                : ' '), 1))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_a_button, {
                                  onClick: createMissionByDietFactor,
                                  loading: loading.value,
                                  disabled: !userDetail.value.findFactorEnable
                                }, {
                                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                                    _createTextVNode(" 범인 찾기 실행 ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "disabled"])
                              ]),
                              _createElementVNode("div", _hoisted_4, [
                                (userDetail.value.findFactorCompletedAt)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, " 전송 시간: " + _toDisplayString(userDetail.value.findFactorCompletedAt !=
                                            null
                                                ? _unref(formatLocalDateTime)(
                                                      userDetail.value.findFactorCompletedAt
                                                  )
                                                : ' '), 1))
                                  : _createCommentVNode("", true),
                                _createVNode(_component_a_button, {
                                  onClick: completeFindFactor,
                                  type: "primary",
                                  loading: loading.value,
                                  disabled: 
                                            userDetail.value.findFactorCompletedAt
                                        
                                }, {
                                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                                    _createTextVNode(" 범인찾기 리포트 알림톡 전송 완료 ")
                                  ])),
                                  _: 1
                                }, 8, ["loading", "disabled"])
                              ])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, {
                        label: "WM 메모",
                        help: '웰니스마스터만 확인하는 내용으로, 유저에게 공개되지 않아요.'
                      }, {
                        default: _withCtx(() => [
                          _createVNode(Tiptap, {
                            value: userDetail.value.wmMemo,
                            "onUpdate:value": onWriteWmNote
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { label: "문진표" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.answer), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { label: "검진결과 공유" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.healthCheckup), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { label: "다이어트 경험" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.dietExperience), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { label: "다이어트 목표" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.reasonForDiet), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { label: "셀프 원인 분석" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(userDetail.value.wellnessSurvey?.guessReason), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_form_item, { label: "노션 링크" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_input, {
                            value: userDetail.value.notionLink,
                            "onUpdate:value": onWriteNotionLink
                          }, {
                            addonAfter: _withCtx(() => [
                              _createElementVNode("a", {
                                href: userDetail.value.notionLink,
                                target: "_blank",
                                rel: "noopener noreferrer"
                              }, [
                                _createVNode(_unref(LinkOutlined))
                              ], 8, _hoisted_6)
                            ]),
                            _: 1
                          }, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["active-key"]),
      _createVNode(_component_a_form, { style: {"margin-top":"48px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_radio_group, {
                value: filterParams.value.category,
                "onUpdate:value": onUpdateCategory
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_radio_button, null, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("전체")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: "체중" }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("체중")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: "혈당" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("혈당")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: "식단" }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("식단")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: "활동" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode("활동")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_radio_button, { value: "수면" }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("수면")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createVNode(WeekSelectInput, {
                "user-id": __props.userId,
                value: filterParams.value.weekNumber,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((filterParams.value.weekNumber) = $event)),
                onAfterUpdate: fetchData
              }, null, 8, ["user-id", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            label: "주차별 WM 코멘트",
            help: '유저에게 전달하는 메시지로, [메시지 전달] 버튼을 누르면 앱으로 전달돼요.'
          }, {
            default: _withCtx(() => [
              _createVNode(Tiptap, {
                value: weeklyMessage.value,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((weeklyMessage).value = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, " 최근 전송 시간: " + _toDisplayString(_unref(formatLocalDateTime)(userDetail.value.wmMessageWriteAt)), 1),
                _createVNode(_component_a_button, {
                  onClick: _cache[4] || (_cache[4] = 
                            () =>
                                writeMessage(
                                    __props.userId,
                                    'WEEKLY',
                                    weeklyMessage.value,
                                    new Date(),
                                    _unref(selectedWeek)?.weekNumber
                                )
                        ),
                  loading: loading.value,
                  type: "primary",
                  disabled: userDetail.value.wmMessageWriteAt || isFuture.value
                }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode("메세지 전송 ")
                  ])),
                  _: 1
                }, 8, ["loading", "disabled"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(NestedResourceTable, {
        ref_key: "table",
        ref: table,
        title: "미션",
        "create-modal-title": "커스텀 미션 추가",
        params: filterParams.value,
        uri: `/api/forme/diet/mission/${__props.userId}`,
        columns: columns.value,
        "create-spec": createSpec.value,
        "create-rules": rules,
        "update-spec": updateSpec.value,
        "update-rules": rules,
        "primary-key": "userMissionId",
        scroll: { x: 'max-content' }
      }, {
        toolbar: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: completeMission,
            loading: loading.value
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode("미션 알림톡 전송 ")
            ])),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["params", "uri", "columns", "create-spec", "update-spec"])
    ]),
    _: 1
  }))
}
}

})