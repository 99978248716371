<template>
    <a-skeleton v-if="loading" />
    <a-form v-else ref="formRef" :model="form" :rules="rules">
        <InputMapper
            v-for="(key, index) in formKeys"
            :key="index"
            :input-spec="inputSpec[key]"
            :form="form"
            :prop="key"
        />
        <a-form-item class="footer__row">
            <a-affix :offset-bottom="100" style="margin-right: 50px">
                <a-button
                    type="primary"
                    @click.prevent="onSubmit"
                    html-type="submit"
                    :loading="loading"
                >
                    <slot name="button" />
                </a-button>
                <slot name="action" :form="form" :loading="loading" />
            </a-affix>
        </a-form-item>
        <FailResultModal
            ref="failResultModal"
            :title="`${title} 실패`"
            :error-message="errorMessage"
        />
    </a-form>
</template>

<script setup lang="ts">
import InputMapper from '@/components/InputMapper.vue'
import FailResultModal from '@/components/modal/FailResultModal.vue'
import { computed, defineEmits, defineProps, ref } from 'vue'

const formRef = ref()
const failResultModal = ref()
const errorMessage = ref('')

const props = defineProps({
    title: {
        type: String,
    },
    loading: {
        type: Boolean,
    },
    rules: {
        type: Object,
        default: () => ({}),
    },
    inputSpec: {
        type: Object,
        default: () => ({}),
    },
})

const form: Record<string, any> = computed(() => {
    if (props.inputSpec == null) return {}

    return Object.keys(props.inputSpec).reduce((record, key) => {
        if (props.inputSpec[key].value == null) {
            return { ...record }
        }

        return { ...record, [key]: props.inputSpec[key].value }
    }, {})
})

const formKeys = computed<Array<string>>(() => {
    if (props.inputSpec == null) return []
    return [...new Set([...Object.keys(props.inputSpec)])]
})

const emit = defineEmits(['submit'])

const onSubmit = () => {
    return formRef.value
        ?.validate()
        .then(() => {
            return emit('submit', form.value)
        })
        .catch((error: Error) => {
            errorMessage.value =
                '입력 유효성 검증에 실패했습니다. 입력을 확인해주세요.'
            failResultModal.value.open()
            throw error
        })
}
</script>
