import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!
  const _component_reload_outlined = _resolveComponent("reload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createElementBlock("span", null, [
    _createVNode(_component_a_form_item, { name: _ctx.name }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select, {
          options: _ctx.options,
          value: _ctx.value,
          onChange: _ctx.change,
          onSelect: _cache[0] || (_cache[0] = (value) => _ctx.$emit('select', value)),
          style: {"minwidth":"60px","width":"93px"},
          "show-search": ""
        }, null, 8, ["options", "value", "onChange"]),
        (_ctx.showReload)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              onClick: _ctx.refreshOptions,
              style: {"marginleft":"5px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_reload_outlined)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["name"])
  ]))
}