import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResourceTable = _resolveComponent("ResourceTable")!

  return (_openBlock(), _createBlock(_component_ResourceTable, {
    title: "CRM 템플릿",
    uri: "/api/crm-templates",
    "create-spec": _ctx.createSpec,
    "update-spec": _ctx.updateSpec,
    "create-rules": _ctx.createRules,
    "update-rules": _ctx.updateRules,
    scroll: { x: 1400, y: 650 },
    columns: _ctx.columns,
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec", "create-rules", "update-rules", "columns"]))
}