<template>
    <resource-table
        title="식단 영양소 목표"
        uri="/api/food-nutrient-goal"
        :create-spec="createSpec"
        :update-spec="updateSpec"
        :filter-spec="filterSpec"
        :create-rules="rules"
        :update-rules="rules"
        :scroll="{ x: 1000, y: 650 }"
        :columns="columns"
        load-on-mount
        deletable
    />
</template>

<script lang="ts">
import {
    formatEnum,
    formatLocalDate,
    formatLocalDateTime,
    formatLocaleNumber,
} from '@/util/formmater'
import ResourceTable from '@/components/ResourceTable.vue'
import { defineComponent } from 'vue'
import { required } from '@/util/input-validation'
import {
    FormSpecification,
    InputComponents,
    InputType,
    SelectInputMode,
} from '@/components/InputMapper.vue'
import { useEnumTypeStore } from '@/store/enumType'

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FoodIntakeAdvisor',
    components: {
        ResourceTable,
    },
    data() {
        const createSpec = {
            nutrientAllowanceGroupName: {
                label: '영양소 섭취그룹명',
                value: null,
                component: InputComponents.AutoCompleteInput,
                uri: '/api/nutrient-allowance-group/name',
            },
            gender: {
                label: '성별',
                value: null,
                component: InputComponents.EnumSelect,
                typeName: 'gender',
            },
            ageMin: {
                label: '최소 나이(min)',
                value: null,
                component: 'AInputNumber',
            },
            ageMax: {
                label: '최대 나이(max)',
                value: null,
                component: 'AInputNumber',
            },
            goalMin: {
                label: '목표 최소값(min)',
                value: null,
                component: 'AInputNumber',
            },
            goalMax: {
                label: '목표 최대값(max)',
                value: null,
                component: 'AInputNumber',
            },
            unitCode: {
                label: '기본 단위',
                value: null,
                component: 'StandardUnitInput',
            },
            priority: {
                label: '중요도',
                value: null,
                component: 'AInputNumber',
            },
        }
        return {
            selectedRowKeys: [],
            loading: false,
            createSpec,
            updateSpec: { ...createSpec },
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 100,
                    fixed: 'left',
                },
                {
                    title: '영양소 섭취그룹명',
                    width: 100,
                    dataIndex: 'nutrientAllowanceGroupName',
                },
                {
                    title: '성별',
                    dataIndex: 'gender',
                    width: 100,
                    customRender: formatEnum('gender'),
                },
                {
                    title: '최소 나이(min)',
                    dataIndex: 'ageMin',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '최대 나이(max)',
                    dataIndex: 'ageMax',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '목표 최소값(min)',
                    dataIndex: 'goalMin',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                {
                    title: '목표 최대값(max)',
                    dataIndex: 'goalMax',
                    customRender: formatLocaleNumber,
                    align: 'right',
                    width: 100,
                },
                { title: '단위', dataIndex: 'unitExpression' },
            ],
            rules: {
                advisorType: [required],
                nutrientAllowanceGroupName: [required],
                isGood: [required],
            },
        }
    },

    mounted() {
        const enumTypeStore = useEnumTypeStore()
        enumTypeStore.dispatchEnums(['gender'])
    },
})
</script>
