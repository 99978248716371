import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
    InputComponents,
    FormSpecification,
} from '@/components/InputMapper.vue'
import ResourceTable from '@/components/ResourceTable.vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'IntakeUnit',
  setup(__props) {

const createSpec = ref<FormSpecification>({
    name: { label: '이름', value: null },
    step: {
        label: '단계',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const updateSpec = ref<FormSpecification>({
    step: {
        label: '단계',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const filterSpec = ref<FormSpecification>({
    name: { label: '이름', value: null },
    step: {
        label: '단계',
        value: null,
        component: InputComponents.AInputNumber,
    },
})

const columns = [
    {
        title: '이름',
        dataIndex: 'name',
        width: 100,
        fixed: 'left',
        scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
        },
        sorter: true,
    },
    {
        title: '단계',
        dataIndex: 'step',
        width: 100,
    },
]

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ResourceTable, {
    title: "섭취량 단위",
    uri: "/api/intake-unit",
    columns: columns,
    "create-spec": createSpec.value,
    "update-spec": updateSpec.value,
    "filter-spec": filterSpec.value,
    "primary-key": "name",
    "load-on-mount": ""
  }, null, 8, ["create-spec", "update-spec", "filter-spec"]))
}
}

})